import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, Col, Container, Row, Table, Label, Spinner, UncontrolledTooltip, DropdownItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, Alert } from "reactstrap";
import { NumericFormat } from "react-number-format";
import './previewQuote.scss';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
// services
import { QuoteService } from '../../service/quote.service';
import { CustomerService } from "../../../customers/service/customer.service";
import { QuoteCalculatorService } from '../../../quoteCalculator/service/quoteCalculator.service';
// navigation
import { useNavigate } from "react-router-dom";
// image prefix
import { AWS_S3_URL, PREVIEW_QUOTE, SUBSCRIPTION_CALC_TYPE } from "../../../shared/static/constants/constant";
import ConfirmationModal from '../../../shared/components/common/confirmationModal';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Formik, Form, FieldArray } from "formik";
import ImagePreviewModal from '../../../shared/components/common/imagePreviewModal';
import ImageUpload from "../../../shared/components/common/imageUpload";
import TaxSelect from "../../../shared/components/common/addTax";
import QuoteProgressBar from "../../../shared/components/common/quoteProgressbar";
import '../../../shared/components/common/quoteProgressBar.scss';
import { QuoteStatus } from "../../model/quote.model";
// toast message
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ChooseClientDropdown from "../../../shared/components/common/chooseClientDropdwon";
import RichTextEditor from "react-rte";
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import { ImageURLTOBase64Convertor } from "../../../shared/helpers/imageUrlToBase64";

const masterTableLable = {
    location: 'Locations',
    hoopingFee: 'Hooping Fee',
    garmetPerHour: 'Garments / Hour'
}

const PreviewQuote = (props) => {
    const { state } = useLocation();
    const [getMasterTableLoadingStatus, setMasterTableLoadingStatus] = useState({
        isgarmentLoading: false,
        isStitchLoading: false,
        isSetUpIsLoading: false,
        isTechnicleMasterListIsLoading: false,
    });
    const [getPrintingMasterTableLoadingStatus, setPrintingMatserTableLoadingStatus] = useState({
        isGarmentLoading: false,
        isInkLoading: false,
        isSetuupLoading: false,
        isJobCriteriaLoading: false,
        isArtLoading: false
    });

    const [getDeleteinfo, setDeleteInfo] = useState();

    const isEdit = state.mode === 'editMode';
    const [customerQuoteClientList, setCustomerQuoteClientList] = useState([]);
    const [showDropdown, setShowDropdown] = useState();

    const [getTaxInfo, setTaxInfo] = useState([]);
    const [getSelectedTaxInfo, setSelectedTaxInfo] = useState([]);
    const [get_customer_shop_tax_master_uuid, set_customer_shop_tax_master_uuid] = useState('');
    const [getTaxArray, setTaxArray] = useState([]);

    // service
    const quoteService = new QuoteService();
    const customerService = new CustomerService();
    const quoteCalculatorService = new QuoteCalculatorService();
    const [quoteDetails, setQuoteDetails] = useState('');
    const [editModeQuoteDetails, setEditModeQuoteDetails] = useState();
    const [userDetails, setUserDetails] = useState('');

    const [getGarmentsData, setGarmentsData] = useState([]);
    const [getStitchCountData, setStitchData] = useState([]);
    const [getSetUpMasterData, setSetUpMasterData] = useState([]);
    const [getTechnicalMasterData, setTechnicalMatserData] = useState([]);

    const [getPrintingGarmentData, setPrintingGarmentData] = useState([]);
    const [getPrintingInkCountData, setPrintingInkCountData] = useState([]);
    const [getPrintingSetupData, setPrintingSetupData] = useState([]);
    const [getPrintingJobCriteriaData, setPrintingJobCriteriaData] = useState([]);
    const [getPrintingArtCountData, setPrintingArtCountData] = useState([]);
    // for datepicker
    const [expiresAtVal, setExpiresAtVal] = useState(new Date(moment(quoteDetails.quote_expires_at).startOf('day').toISOString()));
    const { date } = {
        date: expiresAtVal
    };
    const [getJobName, setJobName] = useState(quoteDetails?.job_name);
    const [getJobNotes, setJobNotes] = useState(RichTextEditor.createValueFromString(quoteDetails?.job_notes === null ? '' : quoteDetails?.job_notes, 'html'));
    const [isMobileDevice, setisMobiledevice] = useState(false);
    const [isValidPreviewForm, setisValidPreviewForm] = useState(true);
    const [isQuoteSaveIsLoading, setisSaveQuoteIsLoading] = useState(false);
    // for upload item image 
    const [selectedImages, setSelectedImages] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [imgTypeErrorArr, setImgTypeErrorArr] = useState([]);

    const [isLoading, setLoading] = useState(true);
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [isEditMode, setisEditMode] = useState(false);
    // navigation
    const navigate = useNavigate();
    const flatpickrRef = useRef(null);

    const [afterAction, setAfterAction] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState();
    const [isModal, setIsModal] = useState();
    const [isSendQuote, setIsSendQuote] = useState(false);

    // for status step
    const [currentStatus, setCurrentStatus] = useState('');
    const [statuses, setStatuses] = useState([]);

    const [isVerticalCollapsed, setIsVerticalCollapsed] = useState(document.body.classList.contains('vertical-collapsed'));
    const userLoginData = sessionStorage.getItem('userLogin') ? JSON.parse(sessionStorage.getItem('userLogin')) : null;
    const subscriptionDetails = userLoginData?.data?.subscription_details?.subscription_plan_details;
    const [loggedinUserPlan, setLoggedInUserPlan] = useState(
        subscriptionDetails ?
            subscriptionDetails.calculator_type || SUBSCRIPTION_CALC_TYPE.ALL :
            SUBSCRIPTION_CALC_TYPE.ALL
    );
    const [getFormKey, setFormKey] = useState(0);
    // for markup master list table
    const [getMarkUpData, setMarkupData] = useState([
        { markup_percentage: 75, total_cost: 60, price_per_item: 6 },
        { markup_percentage: 60, total_cost: 37.50, price_per_item: 3.75 },
        { markup_percentage: 50, total_cost: 30, price_per_item: 3 },
        { markup_percentage: 40, total_cost: 25, price_per_item: 22.5 }
    ]);

    const [getPrintingMarkupData, setPrintingMarkupData] = useState([
        { markup_percentage: 70, total_cost: 0, price_per_item: 0 },
        { markup_percentage: 60, total_cost: 0, price_per_item: 0 },
        { markup_percentage: 50, total_cost: 0, price_per_item: 0 },
        { markup_percentage: 40, total_cost: 0, price_per_item: 0 }
    ]);

    // for pricing master list table
    const [getPricingMasterList, setPricingMasterList] = useState();

    useEffect(() => {
        getQuoteDetails();
        getCustomerClientList();
        checkBodyClass();
        // getUserDetail();
        quoteCalculatorService.getPricingMatrixSpecificationMasterList().then((response) => {
            if (response.status === 200) {
                setPricingMasterList(response.data);
            }
        })

        if (isEdit) {
            setisEditMode(true);
            if (loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.EMBROIDERY) {
                if (getSetUpMasterData.length === 0 && getGarmentsData.length === 0 && getTechnicalMasterData.length === 0 && getStitchCountData.length === 0) {
                    let loadingStatus = getMaterTableData();
                }
            } if (loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING) {
                if (getPrintingGarmentData.length === 0 && getPrintingInkCountData.length === 0 && getPrintingJobCriteriaData.length === 0 && getPrintingSetupData.length === 0) {
                    let loadingStatus = getPrintingMatserTableData();
                }
            } else if (loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.ALL) {
                if (getSetUpMasterData.length === 0 && getGarmentsData.length === 0 && getTechnicalMasterData.length === 0 && getStitchCountData.length === 0) {
                    let loadingStatus = getMaterTableData();
                }
                if (getPrintingGarmentData.length === 0 && getPrintingInkCountData.length === 0 && getPrintingJobCriteriaData.length === 0 && getPrintingSetupData.length === 0) {
                    let loadingStatus = getPrintingMatserTableData();
                }
            }
        }
    }, [state]);

    const updatedTaxInfo = (newTaxInfo, isDeletedMode) => {
        setTaxInfo(newTaxInfo);
        if (isDeletedMode !== undefined) {
            const updatedItems = editModeQuoteDetails.quote_items.map(item => {
                const taxDetails = item.quote_item_details?.quote_item_tax_details;

                // Filter out the tax details that do not match any tax detail in newTaxInfo
                const updatedTaxDetails = isDeletedMode
                    ? taxDetails?.filter(taxDetail =>
                        newTaxInfo.some(newTax => newTax.customer_shop_tax_master_uuid === taxDetail.customer_shop_tax_master_uuid)
                    )
                    : taxDetails?.map(taxDetail => {
                        const updatedTax = newTaxInfo.find(tax => tax.customer_shop_tax_master_uuid === taxDetail.customer_shop_tax_master_uuid);
                        return updatedTax ? { ...taxDetail, ...updatedTax } : taxDetail;
                    }) || [];

                return {
                    ...item,
                    quote_item_details: {
                        ...item.quote_item_details,
                        quote_item_tax_details: updatedTaxDetails
                    }
                };
            });
            Subtotal(updatedItems);
            setFormKey(getFormKey => getFormKey + 1);
            setEditModeQuoteDetails({
                ...editModeQuoteDetails,
                quote_items: updatedItems
            });
        }
    };

    // for get customer client list
    const getCustomerClientList = () => {
        quoteService.getCustomerQuoteClientList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                setCustomerQuoteClientList(response.data);
            }
        });
    }

    const selectedTaxInfo = (selectedTaxInfo) => {
        setSelectedTaxInfo(selectedTaxInfo);
        const index = editModeQuoteDetails.quote_items.findIndex(item => {
            if (item.quote_item_details.quote_item_uuid !== undefined) {
                return item.quote_item_details.quote_item_uuid === selectedTaxInfo.quote_item_uuid;
            } else {
                return item.quote_item_details.id === +selectedTaxInfo.quote_item_uuid;
            }
        });

        if (index !== -1) { // Check if the element with the target quote_item_uuid exists
            setFormKey(getFormKey => getFormKey + 1);
            const updatedQuoteItems = [...editModeQuoteDetails.quote_items];
            // Update the quote_item_tax_details of the found item directly
            updatedQuoteItems[index] = {
                ...updatedQuoteItems[index],
                quote_item_tax_details: selectedTaxInfo.quote_item_tax_details
            };
            setEditModeQuoteDetails(prevData => {
                const updatedItems = [...prevData.quote_items];
                let quoteDetails = updatedItems[index]?.quote_item_details;
                // if item is already added
                if (quoteDetails.quote_item_uuid !== undefined) {
                    updatedItems[index] = {
                        ...updatedItems[index],
                        quote_item_details: {
                            ...quoteDetails,
                            quote_item_tax_details: selectedTaxInfo.quote_item_tax_details
                        }
                    };
                } else {
                    updatedItems[index] = {
                        ...updatedItems[index],
                        quote_item_details: {
                            ...quoteDetails,
                            quote_item_tax_details: selectedTaxInfo.quote_item_tax_details
                        }
                    };
                }
                Subtotal(updatedItems);
                return { ...prevData, quote_items: updatedItems };
            });
        }
    }

    const getQuoteDetails = () => {
        setLoading(true);
        quoteService.getQuoteDetailsByQuoteId(state?.quote_uuid).then((response) => {
            if (response.status === 200) {
                setQuoteDetails(response.data);
                setEditModeQuoteDetails(response.data);
                setJobNotes(RichTextEditor.createValueFromString(response.data?.job_notes === null || response.data?.job_notes === "" || response.data?.job_notes === "<p><br></p>" ? '' : response.data?.job_notes, 'html'))
                if (!response.data.client_company_name && !response.data.client_name && !response.data.client_email) {
                    setEditModeQuoteDetails(prevData => {
                        return {
                            ...prevData,
                            quote_client_details: '',
                        }
                    });
                }
                Subtotal(response?.data.quote_items);
                getUserDetail();
                setLoading(false);
                setCurrentStatus(response?.data.quote_status);
                setStatuses(response.data.quote_status.toLowerCase() === QuoteStatus.Declined.toLowerCase() ? [QuoteStatus.Draft.toLowerCase(), QuoteStatus.Sent.toLowerCase(), QuoteStatus.Read.toLowerCase(), QuoteStatus.Declined.toLowerCase()] : [QuoteStatus.Draft.toLowerCase(), QuoteStatus.Sent.toLowerCase(), QuoteStatus.Read.toLowerCase(), QuoteStatus.Approved.toLowerCase()]);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.errors[0]?.message, { autoClose: 4000 });
            setLoading(false);
        });
    }

    const Subtotal = (quote_items) => {
        let taxIdentifier = [];
        quote_items.forEach((e, key) => {
            e.quote_item_details.quote_item_tax_details?.forEach((taxInfo) => {
                const taxPercentage = taxInfo.tax_percentage;
                const taxName = taxInfo.tax_name;
                // Check if tax name and percentage are not null
                if (taxPercentage !== null && taxName !== null) {
                    // Find if there's already a tax with the same percentage in taxIdentifier
                    const existingTaxIndex = taxIdentifier.findIndex(tax => +tax.tax_percentage === +taxPercentage && tax.tax_name === taxName);
                    const taxAmount = (+e.quote_item_details.item_quantity * e.quote_item_details.quoted_sales_price) * (taxInfo.tax_percentage / 100);
                    if (existingTaxIndex !== -1) {
                        // If tax with the same percentage already exists, add the amount to it
                        taxIdentifier[existingTaxIndex].Amount = parseFloat((parseFloat(taxIdentifier[existingTaxIndex].Amount) + taxAmount).toFixed(2));
                    } else {
                        // Otherwise, add a new entry
                        taxIdentifier.push({
                            tax_name: taxName,
                            tax_percentage: taxPercentage,
                            Amount: parseFloat(taxAmount.toFixed(2))
                        });
                    }
                }
            });
        });
        setTaxArray(taxIdentifier);
    }

    useEffect(() => {
        if (editModeQuoteDetails && editModeQuoteDetails.quote_item) {
            Subtotal(editModeQuoteDetails.quote_item);
        }
    }, [editModeQuoteDetails]);

    useEffect(() => {

        if (!isLoading && isEditMode) {
            setJobName(quoteDetails.job_name);
            setJobNotes(RichTextEditor.createValueFromString(quoteDetails?.job_notes === null || quoteDetails?.job_notes === '' || quoteDetails?.job_notes === '<p><br></p>' ? '' : quoteDetails?.job_notes, 'html'));
            setExpiresAtVal(new Date(moment(quoteDetails.quote_expires_at).startOf('day').toISOString()));
        }
    }, [isLoading, isEditMode]);

    const isDeleteHandle = () => {
        setFormKey(getFormKey => getFormKey + 1);
        setAfterAction(false);
        if (getDeleteinfo.quote_item_uuid !== undefined) {
            quoteCalculatorService.deleteQuoteItem(getDeleteinfo.quote_item_uuid).then(response => {
                if (response.status === 200) {
                    setAfterAction(true);
                    setTimeout(() => { setAfterAction(true); }, 1000);
                    setQuoteDetails(prevData => {
                        const updatedArray = prevData.quote_items.filter(obj => {
                            return obj.quote_item_details.quote_item_uuid !== getDeleteinfo.quote_item_uuid;
                        });
                        return { ...prevData, quote_items: updatedArray };
                    });
                    setFormKey(prevKey => prevKey + 1);
                    setEditModeQuoteDetails(prevData => {
                        const updatedArray = prevData.quote_items.filter(obj => {
                            return obj.quote_item_details.quote_item_uuid !== getDeleteinfo.quote_item_uuid;
                        });
                        Subtotal(updatedArray);
                        return { ...prevData, quote_items: updatedArray };
                    });
                }
            });
        } else {
            setFormKey(prevKey => prevKey + 1);
            setEditModeQuoteDetails(prevData => {
                const updatedArray = editModeQuoteDetails.quote_items.filter((obj) => obj.quote_item_details.id !== getDeleteinfo.id);
                Subtotal(updatedArray);
                return { ...prevData, quote_items: updatedArray };
            });
            setAfterAction(true);
            setTimeout(() => { setAfterAction(false); }, 1000);
        }
    }

    useEffect(() => {
        function handleResize() {
            setisMobiledevice(window.innerWidth <= 600 || window.innerHeight <= 600);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize); };
    }, []);

    const renameKeysInArray = (array, oldKeyName, newKeyName) => {
        if (Array.isArray(array)) {
            array.forEach((item) => {
                if (item.hasOwnProperty(oldKeyName)) {
                    item[newKeyName] = item[oldKeyName];
                    delete item[oldKeyName];
                }
            });
        }
    }

    const onClickSaveQuote = () => {
        setisSaveQuoteIsLoading(true);
        editModeQuoteDetails.quote_items.forEach(obj => delete obj.quote_item_details.id);
        let quote_item_details = editModeQuoteDetails.quote_items;
        let quote_details = editModeQuoteDetails.quote_items.map(item => {
            return {
                quote_item_setup_details: item.quote_item_details.quote_item_setup_details,
                quote_item_technical_specification_details: item.quote_item_details.quote_item_technical_specification_details,
                quote_item_stitch_counts_details: item.quote_item_details.quote_item_stitch_counts_details,
                quote_item_garments_details: item.quote_item_details.quote_item_garments_details,
                quote_item_markup_details: item.quote_item_details.quote_item_markup_details,
                quote_item_tax_details: item.quote_item_details.quote_item_tax_details === null ? [] : item.quote_item_details.quote_item_tax_details.map(taxDetail => ({ customer_shop_tax_master_uuid: taxDetail.customer_shop_tax_master_uuid })),
                quote_item_inks_details: item.quote_item_details.quote_item_inks_details,
                quote_item_job_criteria_details: item.quote_item_details.quote_item_job_criteria_details,
                quote_item_art_details: item.quote_item_details.quote_item_art_details
            };
        });
        for (let i = 0; i < quote_details.length; i++) {
            quote_details[i].quote_item_details = {};
            if (quote_item_details[i].quote_item_details) {
                for (let key in quote_item_details[i].quote_item_details) {
                    quote_details[i].quote_item_details[key] = quote_item_details[i].quote_item_details[key];
                }
            }
        }
        quote_details.forEach(obj => delete obj.quote_item_details.quote_item_setup_details);
        quote_details.forEach(obj => delete obj.quote_item_details.quote_item_technical_specification_details);
        quote_details.forEach(obj => delete obj.quote_item_details.quote_item_stitch_counts_details);
        quote_details.forEach(obj => delete obj.quote_item_details.quote_item_markup_details);
        quote_details.forEach(obj => delete obj.quote_item_details.quote_item_garments_details);
        quote_details.forEach(obj => delete obj.quote_item_details.quote_item_tax_details);
        quote_details.forEach(obj => delete obj.quote_item_details.quote_item_inks_details);
        quote_details.forEach(obj => delete obj.quote_item_details.quote_item_job_criteria_details);
        quote_details.forEach(obj => delete obj.quote_item_details.quote_item_art_details);

        quote_details.forEach((quoteItem) => {
            const itemType = quoteItem.quote_item_details.quote_item_type;
            if (itemType === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING) {
                delete quoteItem.quote_item_stitch_counts_details;
                delete quoteItem.quote_item_technical_specification_details;
                renameKeysInArray(quoteItem.quote_item_art_details, 'screenprinting_quote_item_art_uuid', 'art_master_uuid')
                renameKeysInArray(quoteItem.quote_item_garments_details, 'quote_item_garments_uuid', 'screenprinting_quote_item_garments_uuid');
                renameKeysInArray(quoteItem.quote_item_setup_details, 'quote_item_setup_uuid', 'screenprinting_quote_item_setup_uuid')
            } else if (itemType === SUBSCRIPTION_CALC_TYPE.EMBROIDERY) {
                delete quoteItem.quote_item_details.profit_margin_percentage;
                delete quoteItem.quote_item_job_criteria_details;
                delete quoteItem.quote_item_inks_details;
            }
        });
        let saveQuote = {
            "quote_details": {
                "job_name": getJobName,
                "job_notes": getJobNotes.toString('html') === '<p><br></p>' || getJobNotes.toString('html') === '' ? null : getJobNotes.toString('html'),
                "quote_expires_at": date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
            },
            "quote_client_details": editModeQuoteDetails?.quote_client_details?.client_company_name ? {
                "client_company_name": editModeQuoteDetails.quote_client_details.client_company_name,
                "client_name": editModeQuoteDetails.quote_client_details.client_name,
                "client_email": editModeQuoteDetails.quote_client_details.client_email,
                "client_address": editModeQuoteDetails.quote_client_details?.client_address?.length === 0 || editModeQuoteDetails.quote_client_details.client_address === null ? '' : editModeQuoteDetails.quote_client_details.client_address,
                "client_city": editModeQuoteDetails.quote_client_details?.client_city,
                "client_state": editModeQuoteDetails.quote_client_details?.client_state,
                "client_country": editModeQuoteDetails.quote_client_details?.client_country,
                "client_zip_code": editModeQuoteDetails.quote_client_details?.client_zip_code,
            } : undefined,
            "quote_items": quote_details
        }
        quoteService.editQuote(quoteDetails.quote_uuid, saveQuote).then((response) => {
            if (response.status === 200) {
                quoteDetails.quote_items = editModeQuoteDetails.quote_items;
                quoteDetails.job_notes = getJobNotes.toString('html');
                quoteDetails.job_name = getJobName;
                quoteDetails.quote_expires_at = editModeQuoteDetails.quote_expires_at;
                setisEditMode(false);
                setisSaveQuoteIsLoading(false);
                quoteService.getQuoteDetailsByQuoteId(state?.quote_uuid).then((response) => {
                    if (response.status === 200) {
                        setQuoteDetails(response?.data);
                        setEditModeQuoteDetails(response?.data);
                        Subtotal(response?.data?.quote_items);
                    }
                });
            }
        });
    }

    const validationPreviewQuoteItemSchema = Yup.object().shape({
        quoteItems: Yup.array().of(
            Yup.object().shape({
                quote_item_details: Yup.object().shape({
                    item_quantity: Yup.number().min(1, PREVIEW_QUOTE.ERR_INVALID_ITEM_QUANTITY).required(PREVIEW_QUOTE.ERR_REQUIRED_ITEM_QUANTITY),
                    quoted_sales_price: Yup.number().min(0, PREVIEW_QUOTE.ERR_INVALID_ITEM_RATE).required(PREVIEW_QUOTE.ERR_REQUIRED_ITEM_RATE),
                    item_name: Yup.string().required(PREVIEW_QUOTE.ERR_REQUIRED_ITEM_DESCRIPTION)
                })
            })
        )
    });

    // for convert date to local
    const UTCtoLocal = (utcDate) => {
        const date = new Date(utcDate);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Dynamic time zone based on user's location
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(localDate);
        return formattedDate;
    }

    // for get user detail
    const getUserDetail = () => {
        setIsDownloadLoading(true);
        customerService.getCustomerDetail(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then(response => {
            if (response.status === 200) {
                setUserDetails(response.data[0]);
                setIsDownloadLoading(false);
                set_customer_shop_tax_master_uuid(response.data[0].customer_shop_info_uuid);
                quoteService.getTaxInfo(response.data[0].customer_shop_info_uuid).then(response => {
                    if (response.status === 200) {
                        setTaxInfo(response.data);
                    }
                });
            }
        });
    }

    // for item image in quote list
    const handleImageChange = (e, rowIndex, setFieldValue) => {
        if (isEditMode && setFieldValue) {
            setFieldValue();
        }

        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const updatedImages = [...selectedImages];
                updatedImages[rowIndex] = e.target.result;
                setSelectedImages(updatedImages);
                setEditModeQuoteDetails(prevData => {
                    const updatedItems = [...prevData.quote_items];
                    let quoteDetails = updatedItems[rowIndex]?.quote_item_details;
                    // if item is already added
                    if (quoteDetails.quote_item_uuid !== undefined) {
                        updatedItems[rowIndex] = {
                            ...updatedItems[rowIndex],
                            quote_item_details: {
                                ...quoteDetails,
                                item_image_base64: updatedImages[rowIndex],
                                "item_image_url": null
                            }
                        };
                    } else {
                        // if item is newly added
                        updatedItems[rowIndex] = {
                            ...updatedItems[rowIndex],
                            quote_item_details: {
                                ...quoteDetails,
                                "item_image_base64": updatedImages[rowIndex],
                            }
                        };
                    }
                    return { ...prevData, quote_items: updatedItems };
                });
                // Clear the error for the current index
                const updatedErrorArr = [...imgTypeErrorArr];
                updatedErrorArr[rowIndex] = null;
                setImgTypeErrorArr(updatedErrorArr);
            };
            reader.readAsDataURL(file);
        } else {
            // Set the error message for the current index
            const updatedErrorArr = [...imgTypeErrorArr];
            updatedErrorArr[rowIndex] = 'Please select a valid image file.';
            setImgTypeErrorArr(updatedErrorArr);
        }
    };

    const handleDeleteImage = (rowIndex, setFieldValue) => {
        // Call setFieldValue to update the item_image_url to null if edit mode
        if (isEditMode && setFieldValue) {
            setFieldValue(`quoteItems[${rowIndex}].quote_item_details.item_image_url`, null);
        }

        const updatedImages = [...selectedImages];
        updatedImages[rowIndex] = null;
        setEditModeQuoteDetails(prevData => {
            const updatedItems = [...prevData.quote_items];
            let quoteDetails = updatedItems[rowIndex]?.quote_item_details;
            // if item is already added
            if (quoteDetails.quote_item_uuid !== undefined) {
                updatedItems[rowIndex] = {
                    ...updatedItems[rowIndex],
                    quote_item_details: {
                        ...quoteDetails,
                        "item_image_url": null,
                        "item_image_base64": '',
                    }
                };
            } else {
                // if item is newly added
                delete updatedItems[rowIndex].quote_item_details.item_image_base64;
            }
            return { ...prevData, quote_items: updatedItems };
        });
        setSelectedImages(updatedImages);
    };

    // for preview image
    const handlePreviewImage = (imageSrc) => {
        setPreviewImage(imageSrc);
    };

    // when quantity change find the rate of Hooping fee
    const rateOfHoopingFee = (pricingArray, quantity) => {
        for (let i = 0; i < pricingArray.length; i++) {
            const pricingObject = pricingArray[i];
            if (
                (quantity <= 24 && pricingObject.no_of_garments <= 24) ||
                (quantity > 24 && quantity <= 49 && pricingObject.no_of_garments === 49) ||
                (quantity > 49 && quantity <= 99 && pricingObject.no_of_garments === 99) ||
                (quantity > 99 && quantity <= 199 && pricingObject.no_of_garments === 199) ||
                (quantity > 199 && quantity <= 499 && pricingObject.no_of_garments === 499) ||
                (quantity > 499 && quantity <= 999 && pricingObject.no_of_garments === 999) ||
                (quantity > 999 && quantity >= 1000 && pricingObject.no_of_garments === 1000)
            ) {
                return pricingObject.rate;
            }
        }
        return null;
    }

    const handleEditQuoteItem = (index, field, value) => {
        setEditModeQuoteDetails(prevData => {
            const updatedItems = [...prevData.quote_items];
            let quoteDetails = updatedItems[index]?.quote_item_details;
            // if item is already added
            if (quoteDetails.quote_item_uuid !== undefined) {
                if (field === 'quoted_sales_price') {
                    let garmentTotal = quoteDetails.quote_item_garments_details.reduce((sum, garment) => sum + (garment.garment_quantity * garment.garment_rate || 0), 0);
                    let stitchCountTotal = +(quoteDetails.quote_item_stitch_counts_details.reduce((sum, stitchCount) => sum + (((stitchCount.stitch_count / 1000) * +stitchCount.stitch_count_rate) * updatedItems[index].quote_item_details.item_quantity || 0), 0)).toFixed(2);
                    let ArtCollectionAndDigitizingFeeTotal = quoteDetails.quote_item_setup_details.reduce((sum, garment) => {
                        return sum + (+garment.setup_rate * +garment.setup_quantity);
                    }, 0);
                    let masterTableTotal = (+garmentTotal + +stitchCountTotal + +ArtCollectionAndDigitizingFeeTotal);
                    let finalPrice = 1 - ((masterTableTotal / +quoteDetails.item_quantity) / value);
                    let salesPercentage = finalPrice === Infinity || finalPrice === -Infinity ? 0 : +(finalPrice * 100).toFixed(1);
                    let hoopingQuantity = quoteDetails.quote_item_setup_details.find((setupData) => { return setupData.setup_label === masterTableLable.hoopingFee }).setup_quantity;
                    let grossSale = +(value * quoteDetails.item_quantity).toFixed(2);
                    let netProfit = +(grossSale - quoteDetails.gross_cost).toFixed(2);
                    updatedItems[index] = {
                        ...updatedItems[index],
                        quote_item_details: {
                            ...quoteDetails,
                            [field]: value,
                            gross_sale: grossSale,
                            net_profit: netProfit,
                            gross_hourly_rate: hoopingQuantity === 0 || hoopingQuantity === 0.0 ? 0 : +(grossSale / hoopingQuantity).toFixed(2),
                            net_hourly_rate: hoopingQuantity === 0 || hoopingQuantity === 0.0 ? 0 : +(netProfit / hoopingQuantity).toFixed(2),
                            quoted_sales_percentage: salesPercentage
                        }
                    };
                    Subtotal(updatedItems);
                } else if (field === "item_quantity") {
                    let location = quoteDetails.quote_item_setup_details.find((setupData) => { return setupData.setup_label === masterTableLable.location }).setup_quantity;
                    let matchingLocation = getPricingMasterList.filter((matchingLocation) => matchingLocation.no_of_locations === location);
                    let rate = rateOfHoopingFee(matchingLocation, value);
                    let embroidoryHeads = getTechnicalMasterData.find((obj) => obj.technical_specification_label === 'Embroidery Heads').technical_specification_quantity;
                    let RPM = getSetUpMasterData.find((obj) => obj.setup_label === 'RPM').setup_quantity;
                    const stitchCountQuantities = getStitchCountData.filter(item => item.stitch_count_label === masterTableLable.stitchCount).map(item => item.quantity || 0);
                    const totalStitchCountQuantity = stitchCountQuantities.reduce((sum, quantity) => sum + quantity || 0, 0);
                    let garmentPerHour = ((60 / (totalStitchCountQuantity / RPM)) * embroidoryHeads) || 0;
                    let hoopingQuantity = value < 1 ? 0 : (value / garmentPerHour || 0).toFixed(1);
                    let locationObjectIndex = quoteDetails.quote_item_setup_details.findIndex((obj) => obj.setup_label === masterTableLable.hoopingFee);
                    quoteDetails.quote_item_setup_details[locationObjectIndex].setup_rate = +rate;
                    quoteDetails.quote_item_setup_details[locationObjectIndex].setup_quantity = +hoopingQuantity;
                    getTechnicalMasterData[0].technical_specification_quantity = garmentPerHour === Infinity || garmentPerHour === -Infinity ? 0 : garmentPerHour;
                    let garmentTotal = quoteDetails.quote_item_garments_details.reduce((sum, garment) => sum + (garment.garment_quantity * garment.garment_rate || 0), 0);
                    let stitchCountTotal = +(quoteDetails.quote_item_stitch_counts_details.reduce((sum, stitchCount) => sum + (((stitchCount.stitch_count / 1000) * +stitchCount.stitch_count_rate) * value || 0), 0)).toFixed(2);
                    let setupmasterTotal = quoteDetails.quote_item_setup_details.reduce((sum, garment) => {
                        return sum + (+garment.setup_rate * +garment.setup_quantity);
                    }, 0);
                    let masterTableTotal = (+garmentTotal + +stitchCountTotal + +setupmasterTotal);
                    let finalPrice = 1 - ((masterTableTotal / +value) / quoteDetails.quoted_sales_price);
                    let salesPercentage = finalPrice === Infinity || finalPrice === -Infinity ? 0 : +(finalPrice * 100).toFixed(1);
                    quoteDetails.quote_item_markup_details.forEach(item => {
                        let markup_percentage = item.markup_percentage / 100
                        let total_cost = (masterTableTotal / (1 - markup_percentage));
                        item.markup_percentage = item.markup_percentage;
                        item.total_cost = +total_cost.toFixed(2);
                        item.price_per_item = value === 0 ? 0 : +(+total_cost.toFixed(2) / value).toFixed(2)
                    });
                    let ArtCollectionAndDigitizingFeeTotal = quoteDetails.quote_item_setup_details.filter((obj) => obj.setup_label === 'Art Creation' || obj.setup_label === 'Digitizing Fee').reduce((sum, garment) => {
                        return sum + (+garment.setup_rate * +garment.setup_quantity);
                    }, 0);
                    let costPerItem = value < 0 ? 0 : +((+garmentTotal + +stitchCountTotal + +ArtCollectionAndDigitizingFeeTotal) / value);
                    let grossCost = +(value * costPerItem).toFixed(2);
                    let grossSale = +(quoteDetails.quoted_sales_price * value).toFixed(2);
                    let netProfit = +(grossSale - grossCost).toFixed(2);
                    updatedItems[index] = {
                        ...updatedItems[index],
                        quote_item_details: {
                            ...quoteDetails,
                            [field]: value,
                            quoted_sales_percentage: salesPercentage,
                            cost_per_item: +costPerItem.toFixed(2),
                            gross_cost: grossCost,
                            gross_sale: grossSale,
                            net_profit: netProfit,
                            net_hourly_rate: +hoopingQuantity === 0 || +hoopingQuantity === 0.0 ? 0 : +(netProfit / +hoopingQuantity).toFixed(2)
                        }
                    };
                    Subtotal(updatedItems);
                } else if (field === "item_description" || field === "item_name") {
                    updatedItems[index] = {
                        ...updatedItems[index],
                        quote_item_details: {
                            ...quoteDetails,
                            [field]: value,
                        }
                    };
                }
            } else {
                // if item is newly added
                if (field === 'quoted_sales_price') {
                    let garmentTotal = quoteDetails.quote_item_garments_details.reduce((sum, garment) => sum + (garment.garment_quantity * garment.garment_rate || 0), 0);
                    let stitchCountTotal = +(quoteDetails.quote_item_stitch_counts_details.reduce((sum, stitchCount) => sum + (((stitchCount.stitch_count / 1000) * +stitchCount.stitch_count_rate) * updatedItems[index].quote_item_details.item_quantity || 0), 0)).toFixed(2);
                    let ArtCollectionAndDigitizingFeeTotal = quoteDetails.quote_item_setup_details.reduce((sum, garment) => {
                        return sum + (+garment.setup_rate * +garment.setup_quantity);
                    }, 0);
                    let masterTableTotal = (+garmentTotal + +stitchCountTotal + +ArtCollectionAndDigitizingFeeTotal);
                    let finalPrice = 1 - ((masterTableTotal / +quoteDetails.item_quantity) / value);
                    let salesPercentage = finalPrice === Infinity || finalPrice === -Infinity ? 0 : +(finalPrice * 100).toFixed(1);
                    let hoopingQuantity = quoteDetails.quote_item_setup_details.find((setupData) => { return setupData.setup_label === masterTableLable.hoopingFee }).setup_quantity;
                    let grossSale = +(value * quoteDetails.item_quantity).toFixed(2);
                    let netProfit = +(grossSale - quoteDetails.gross_cost).toFixed(2);
                    updatedItems[index] = {
                        ...updatedItems[index],
                        quote_item_details: {
                            ...quoteDetails,
                            [field]: value,
                            gross_sale: grossSale,
                            net_profit: netProfit,
                            gross_hourly_rate: hoopingQuantity === 0 || hoopingQuantity === 0.0 ? 0 : +(grossSale / hoopingQuantity).toFixed(2),
                            net_hourly_rate: hoopingQuantity === 0 || hoopingQuantity === 0.0 ? 0 : +(netProfit / hoopingQuantity).toFixed(2),
                            quoted_sales_percentage: salesPercentage
                        }
                    };
                    Subtotal(updatedItems);
                } else if (field === "item_quantity") {
                    quoteDetails.quote_item_garments_details[0].garment_quantity = value;
                    let garmentTotal = quoteDetails.quote_item_garments_details.reduce((sum, garment) => sum + (garment.garment_quantity * garment.garment_rate || 0), 0);
                    quoteDetails.quote_item_stitch_counts_details[0].stitch_count = value * 100;
                    let stitchCountTotal = +(quoteDetails.quote_item_stitch_counts_details.reduce((sum, stitchCount) => sum + (((stitchCount.stitch_count / 1000) * +stitchCount.stitch_count_rate) * value || 0), 0)).toFixed(2);
                    let matchingLocation = getPricingMasterList.filter((matchingLocation) => matchingLocation.no_of_locations === 1);
                    let rate = rateOfHoopingFee(matchingLocation, value);
                    let embroidoryHeads = getTechnicalMasterData.find((obj) => obj.technical_specification_label === 'Embroidery Heads').technical_specification_quantity;
                    let garmentPerHour = ((60 / (value * 100 / 700)) * embroidoryHeads) || 0;
                    let garmentPerHourObject = quoteDetails.quote_item_technical_specification_details.find((obj) => obj.technical_specification_label === masterTableLable.garmetPerHour)
                    garmentPerHourObject.technical_specification_quantity = +garmentPerHour.toFixed(1);
                    let hoopingQuantity = value < 1 ? 0 : +(value / garmentPerHour || 0).toFixed(1);
                    let locationObjectIndex = quoteDetails.quote_item_setup_details.findIndex((obj) => obj.setup_label === masterTableLable.hoopingFee);
                    quoteDetails.quote_item_setup_details[locationObjectIndex].setup_rate = +rate;
                    quoteDetails.quote_item_setup_details[locationObjectIndex].setup_quantity = +hoopingQuantity;
                    let setupmasterTotal = quoteDetails.quote_item_setup_details.reduce((sum, garment) => {
                        return sum + (+garment.setup_rate * +garment.setup_quantity);
                    }, 0);
                    let masterTableTotal = (+garmentTotal + +stitchCountTotal + +setupmasterTotal);
                    quoteDetails.quote_item_markup_details.forEach(item => {
                        let markup_percentage = item.markup_percentage / 100
                        let total_cost = (masterTableTotal / (1 - markup_percentage));
                        item.markup_percentage = item.markup_percentage;
                        item.total_cost = +total_cost.toFixed(2);
                        item.price_per_item = value === 0 ? 0 : +(+total_cost.toFixed(2) / value).toFixed(2)
                    });
                    let finalPrice = 1 - ((masterTableTotal / +value) / quoteDetails.quoted_sales_price);
                    let salesPercentage = finalPrice === Infinity || finalPrice === -Infinity ? 0 : +(finalPrice * 100).toFixed(1);
                    let costPerItem = value < 0 ? 0 : +((+garmentTotal + +stitchCountTotal + 15.00) / value);
                    let grossCost = +(value * costPerItem).toFixed(2);
                    let grossSale = +(quoteDetails.quoted_sales_price * value).toFixed(2);
                    let netProfit = +(grossSale - grossCost).toFixed(2);
                    updatedItems[index] = {
                        ...updatedItems[index],
                        quote_item_details: {
                            ...quoteDetails,
                            [field]: value,
                            quoted_sales_percentage: salesPercentage,
                            cost_per_item: +costPerItem.toFixed(2),
                            gross_cost: grossCost,
                            gross_sale: grossSale,
                            net_profit: netProfit,
                            net_hourly_rate: hoopingQuantity === 0 || hoopingQuantity === 0.0 ? 0 : +(netProfit / hoopingQuantity).toFixed(2),
                            gross_hourly_rate: hoopingQuantity === 0 || hoopingQuantity === 0.0 ? 0 : +(grossSale / hoopingQuantity).toFixed(2)
                        }
                    };
                    Subtotal(updatedItems);
                } else if (field === "item_name" || field === 'item_description') {
                    updatedItems[index] = {
                        ...updatedItems[index],
                        quote_item_details: {
                            ...quoteDetails,
                            [field]: value,
                        }
                    };
                }
            }
            return { ...prevData, quote_items: updatedItems };
        });
    };

    const handleEditPrintingQuoteItem = (index, field, value) => {
        setEditModeQuoteDetails(prevData => {
            const updatedItems = [...prevData.quote_items];
            let quoteDetails = updatedItems[index]?.quote_item_details;
            // if item is already added
            if (quoteDetails.quote_item_uuid !== undefined) {
                if (field === 'quoted_sales_price') {
                    let garmentTotal = quoteDetails.quote_item_garments_details.reduce((sum, garment) => sum + (garment.garment_quantity * garment.garment_rate || 0), 0);
                    let inkTotal = quoteDetails.quote_item_inks_details.reduce((sum, ink) => sum + (ink.ink_quantity * ink.ink_rate || 0), 0);
                    let setupTotal = quoteDetails.quote_item_setup_details.reduce((sum, setup) => sum + (setup.setup_rate * setup.setup_quantity || 0), 0);
                    let masterTableTotal = (+garmentTotal + +inkTotal + +setupTotal);
                    let finalPrice = 1 - ((masterTableTotal / +value) / quoteDetails.quoted_sales_price);
                    let salesPercentage = finalPrice === Infinity || finalPrice === -Infinity ? 0 : +(finalPrice * 100).toFixed(1);
                    let grossSale = +(value * quoteDetails.item_quantity).toFixed(2);
                    let netProfit = +(grossSale - quoteDetails.gross_cost).toFixed(2);
                    let profit_margin = grossSale === 0 ? 0 : ((grossSale - quoteDetails.gross_cost) / grossSale) * 100;
                    updatedItems[index] = {
                        ...updatedItems[index],
                        quote_item_details: {
                            ...quoteDetails,
                            [field]: value,
                            gross_sale: grossSale,
                            net_profit: netProfit,
                            quoted_sales_percentage: salesPercentage,
                            profit_margin_percentage: +(profit_margin).toFixed(2)
                        }
                    };
                    Subtotal(updatedItems)
                } else if (field === "item_quantity") {
                    const shirtsPerHour = quoteDetails.quote_item_job_criteria_details.find((obj) => obj.job_criteria_label === "Shirts / Hour")?.job_criteria_quantity || 0;
                    const locations = quoteDetails.quote_item_job_criteria_details.find((obj) => obj.job_criteria_label === "# of Locations")?.job_criteria_quantity || 0;
                    // Pass 50 if shirtsPerHour is 0
                    const adjustedShirtsPerHour = shirtsPerHour === 0 ? 50 : shirtsPerHour;
                    let shirtInjobAndLaborPerHour = laborPerHourQuantity(value, adjustedShirtsPerHour, locations);
                    // Find the index for 'Shirts in job' in quote_item_job_criteria_details
                    const jobCriteriaIndex = quoteDetails.quote_item_job_criteria_details.findIndex(
                        (item) => item.job_criteria_label === 'Shirts in job'
                    );
                    // If found, update the job_criteria_quantity
                    if (jobCriteriaIndex !== -1) {
                        quoteDetails.quote_item_job_criteria_details[jobCriteriaIndex].job_criteria_quantity = +shirtInjobAndLaborPerHour.shirtinjob;
                    }
                    // Find the index for 'Labor per hour' in quote_item_setup_details
                    const setupDetailsIndex = quoteDetails.quote_item_setup_details.findIndex(
                        (item) => item.setup_label === 'Labor per hour'
                    );
                    // If found, update the setup_quantity
                    if (setupDetailsIndex !== -1) {
                        quoteDetails.quote_item_setup_details[setupDetailsIndex].setup_quantity = +shirtInjobAndLaborPerHour.laborperHour;
                    }
                    const shirtPerHourIndex = quoteDetails.quote_item_job_criteria_details.findIndex(
                        (item) => item.setup_label === 'Shirts / Hour'
                    );
                    if (adjustedShirtsPerHour === 50) {
                        if (shirtPerHourIndex !== -1) {
                            quoteDetails.quote_item_job_criteria_details[shirtPerHourIndex].job_criteria_quantity = adjustedShirtsPerHour;
                        }
                    }
                    quoteDetails.quote_item_garments_details[0].garment_quantity = value;
                    let garmentTotal = quoteDetails.quote_item_garments_details.reduce((sum, garment) => sum + (garment.garment_quantity * garment.garment_rate || 0), 0);
                    let inkTotal = quoteDetails.quote_item_inks_details.reduce((sum, ink) => sum + (ink.ink_quantity * ink.ink_rate || 0), 0);
                    let setupTotal = quoteDetails.quote_item_setup_details.reduce((sum, setup) => sum + (setup.setup_rate * setup.setup_quantity || 0), 0);
                    let masterTableTotal = (+garmentTotal + +inkTotal + +setupTotal);
                    let finalPrice = 1 - ((masterTableTotal / +value) / quoteDetails.quoted_sales_price);
                    let salesPercentage = finalPrice === Infinity || finalPrice === -Infinity ? 0 : +(finalPrice * 100).toFixed(1);
                    quoteDetails.quote_item_markup_details.forEach(item => {
                        let markup_percentage = item.markup_percentage / 100
                        let total_cost = (masterTableTotal / (1 - markup_percentage));
                        item.markup_percentage = item.markup_percentage;
                        item.total_cost = +total_cost.toFixed(2);
                        item.price_per_item = value === 0 ? 0 : +(+total_cost.toFixed(2) / value).toFixed(2)
                    });
                    let costPerItem = value < 0 ? 0 : +((+garmentTotal + +inkTotal) / value);
                    let grossCost = +(value * costPerItem).toFixed(2);
                    let grossSale = +(quoteDetails.quoted_sales_price * value).toFixed(2);
                    let netProfit = +(grossSale - grossCost).toFixed(2);
                    let profit_margin = grossSale === 0 ? 0 : ((grossSale - grossCost) / grossSale) * 100;
                    updatedItems[index] = {
                        ...updatedItems[index],
                        quote_item_details: {
                            ...quoteDetails,
                            [field]: value,
                            quoted_sales_percentage: salesPercentage,
                            cost_per_item: +costPerItem.toFixed(2),
                            gross_cost: grossCost,
                            gross_sale: grossSale,
                            net_profit: netProfit,
                            profit_margin_percentage: +(profit_margin).toFixed(2)
                        }
                    };
                    Subtotal(updatedItems)
                } else if (field === "item_description" || field === "item_name") {
                    updatedItems[index] = {
                        ...updatedItems[index],
                        quote_item_details: {
                            ...quoteDetails,
                            [field]: value,
                        }
                    };
                }
            } else {
                if (field === 'quoted_sales_price') {
                    let garmentTotal = quoteDetails.quote_item_garments_details.reduce((sum, garment) => sum + (garment.garment_quantity * garment.garment_rate || 0), 0);
                    let inkTotal = quoteDetails.quote_item_inks_details.reduce((sum, ink) => sum + (ink.ink_quantity * ink.ink_rate || 0), 0);
                    let setupTotal = quoteDetails.quote_item_setup_details.reduce((sum, setup) => sum + (setup.setup_rate * setup.setup_quantity || 0), 0);
                    let masterTableTotal = (+garmentTotal + +inkTotal + +setupTotal);
                    let finalPrice = 1 - ((masterTableTotal / +value) / quoteDetails.quoted_sales_price);
                    let salesPercentage = finalPrice === Infinity || finalPrice === -Infinity ? 0 : +(finalPrice * 100).toFixed(1);
                    let grossSale = +(value * quoteDetails.item_quantity).toFixed(2);
                    let netProfit = +(grossSale - quoteDetails.gross_cost).toFixed(2);
                    let profit_margin = grossSale === 0 ? 0 : ((grossSale - quoteDetails.gross_cost) / grossSale) * 100;
                    updatedItems[index] = {
                        ...updatedItems[index],
                        quote_item_details: {
                            ...quoteDetails,
                            [field]: value,
                            gross_sale: grossSale,
                            net_profit: netProfit,
                            quoted_sales_percentage: salesPercentage,
                            profit_margin_percentage: +(profit_margin).toFixed(2)
                        }
                    };
                    Subtotal(updatedItems)
                } else if (field === "item_quantity") {
                    const shirtsPerHour = quoteDetails.quote_item_job_criteria_details.find((obj) => obj.job_criteria_label === "Shirts / Hour")?.job_criteria_quantity || 0;
                    const locations = quoteDetails.quote_item_job_criteria_details.find((obj) => obj.job_criteria_label === "# of Locations")?.job_criteria_quantity || 0;
                    // Pass 50 if shirtsPerHour is 0
                    const adjustedShirtsPerHour = shirtsPerHour === 0 ? 50 : shirtsPerHour;
                    let shirtInjobAndLaborPerHour = laborPerHourQuantity(value, adjustedShirtsPerHour, locations);
                    // Find the index for 'Shirts in job' in quote_item_job_criteria_details
                    const jobCriteriaIndex = quoteDetails.quote_item_job_criteria_details.findIndex(
                        (item) => item.job_criteria_label === 'Shirts in job'
                    );
                    // If found, update the job_criteria_quantity
                    if (jobCriteriaIndex !== -1) {
                        quoteDetails.quote_item_job_criteria_details[jobCriteriaIndex].job_criteria_quantity = +shirtInjobAndLaborPerHour.shirtinjob;
                    }
                    // Find the index for 'Labor per hour' in quote_item_setup_details
                    const setupDetailsIndex = quoteDetails.quote_item_setup_details.findIndex(
                        (item) => item.setup_label === 'Labor per hour'
                    );
                    // If found, update the setup_quantity
                    if (setupDetailsIndex !== -1) {
                        quoteDetails.quote_item_setup_details[setupDetailsIndex].setup_quantity = +shirtInjobAndLaborPerHour.laborperHour;
                    }
                    const shirtPerHourIndex = quoteDetails.quote_item_job_criteria_details.findIndex(
                        (item) => item.setup_label === 'Shirts / Hour'
                    );
                    if (adjustedShirtsPerHour === 50) {
                        if (shirtPerHourIndex !== -1) {
                            quoteDetails.quote_item_job_criteria_details[shirtPerHourIndex].job_criteria_quantity = adjustedShirtsPerHour;
                        }
                    }
                    quoteDetails.quote_item_garments_details[0].garment_quantity = value;
                    let garmentTotal = quoteDetails.quote_item_garments_details.reduce((sum, garment) => sum + (garment.garment_quantity * garment.garment_rate || 0), 0);
                    let inkTotal = quoteDetails.quote_item_inks_details.reduce((sum, ink) => sum + (ink.ink_quantity * ink.ink_rate || 0), 0);
                    let setupTotal = quoteDetails.quote_item_setup_details.reduce((sum, setup) => sum + (setup.setup_rate * setup.setup_quantity || 0), 0);
                    let masterTableTotal = (+garmentTotal + +inkTotal + +setupTotal);
                    let finalPrice = 1 - ((masterTableTotal / +value) / quoteDetails.quoted_sales_price);
                    let salesPercentage = finalPrice === Infinity || finalPrice === -Infinity ? 0 : +(finalPrice * 100).toFixed(1);
                    quoteDetails.quote_item_markup_details.forEach(item => {
                        let markup_percentage = item.markup_percentage / 100
                        let total_cost = (masterTableTotal / (1 - markup_percentage));
                        item.markup_percentage = item.markup_percentage;
                        item.total_cost = +total_cost.toFixed(2);
                        item.price_per_item = value === 0 ? 0 : +(+total_cost.toFixed(2) / value).toFixed(2)
                    });
                    let costPerItem = value < 0 ? 0 : +((+garmentTotal + +inkTotal) / value);
                    let grossCost = +(value * costPerItem).toFixed(2);
                    let grossSale = +(quoteDetails.quoted_sales_price * value).toFixed(2);
                    let netProfit = +(grossSale - grossCost).toFixed(2);
                    let profit_margin = grossSale === 0 ? 0 : ((grossSale - grossCost) / grossSale) * 100;
                    updatedItems[index] = {
                        ...updatedItems[index],
                        quote_item_details: {
                            ...quoteDetails,
                            [field]: value,
                            quoted_sales_percentage: salesPercentage,
                            cost_per_item: +costPerItem.toFixed(2),
                            gross_cost: grossCost,
                            gross_sale: grossSale,
                            net_profit: netProfit,
                            profit_margin_percentage: +(profit_margin).toFixed(2)
                        }
                    };
                    Subtotal(updatedItems)
                } else if (field === "item_description" || field === "item_name") {
                    updatedItems[index] = {
                        ...updatedItems[index],
                        quote_item_details: {
                            ...quoteDetails,
                            [field]: value,
                        }
                    };
                }
            }
            return { ...prevData, quote_items: updatedItems };
        });
    }

    const laborPerHourQuantity = (itemQuantity, shirtPerHour, location) => {
        let shirtInJOB = parseFloat(+itemQuantity * +location).toFixed(2);
        let laborPerHour = (+shirtInJOB / +shirtPerHour);
        // Check if laborPerHour is not a finite number (Infinity, -Infinity, NaN)
        if (!isFinite(laborPerHour)) {
            laborPerHour = 0;
        }

        return {
            shirtinjob: shirtInJOB,
            laborperHour: laborPerHour
        }
    };

    // for adding new item based on plan and quote items decide type of the new item
    const determineItemType = (quoteItems) => {
        const types = quoteItems.map(item => item.quote_item_details.quote_item_type);
        const hasEmbroidery = types.includes(SUBSCRIPTION_CALC_TYPE.EMBROIDERY);
        const hasScreenprinting = types.includes(SUBSCRIPTION_CALC_TYPE.SCREENPRINTING);
        if (hasEmbroidery && hasScreenprinting) {
            // If both types exist, randomly assign one
            return Math.random() < 0.5 ? SUBSCRIPTION_CALC_TYPE.EMBROIDERY : SUBSCRIPTION_CALC_TYPE.SCREENPRINTING;
        } else if (hasEmbroidery) {
            return SUBSCRIPTION_CALC_TYPE.EMBROIDERY;
        } else if (hasScreenprinting) {
            return SUBSCRIPTION_CALC_TYPE.SCREENPRINTING;
        } else {
            // Default type if neither is found
            return SUBSCRIPTION_CALC_TYPE.EMBROIDERY;
        }
    }

    const getMaterTableData = async () => {
        setMasterTableLoadingStatus({
            isgarmentLoading: true,
            isSetUpIsLoading: true,
            isStitchLoading: true,
            isTechnicleMasterListIsLoading: true
        });
        const garmentResponse = await quoteCalculatorService.getGarmentMaterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid);
        const stitchResponse = await quoteCalculatorService.getStichCountMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid);
        const technicalResponse = await quoteCalculatorService.getTechnicalSpecificationMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid);
        const setupResponse = await quoteCalculatorService.getSetUpMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid);
        let updatedGarmentData = []
        // Process responses
        if (garmentResponse.status === 200) {
            updatedGarmentData = garmentResponse?.data.map((garmentData) => {
                const garment_quantity = garmentData.garment_quantity || 0;
                const garment_rate = +garmentData.garment_rate || 0;
                return {
                    ...garmentData,
                    garment_quantity,
                    garment_rate,
                };
            });
            setGarmentsData(updatedGarmentData);
        }
        setMasterTableLoadingStatus({ isgarmentLoading: false });
        let updatedStitchData = stitchResponse?.data.map((stitchData) => {
            const stitch_count = 0;
            const stitch_count_rate = +stitchData.stitch_count_rate
            return {
                ...stitchData,
                stitch_count,
                stitch_count_rate
            };
        });
        setStitchData(updatedStitchData);
        setMasterTableLoadingStatus({ isStitchLoading: false });
        setTechnicalMatserData(technicalResponse?.data);
        setMasterTableLoadingStatus({ isTechnicleMasterListIsLoading: false });
        let updatedSetupMatserWithCalculation = setupResponse?.data.map((setupItem) => {
            const setup_quantity = setupItem?.setup_quantity || 0;
            const setup_rate = +setupItem.setup_rate || 0;
            return {
                ...setupItem,
                setup_quantity,
                setup_rate
            };
        });
        let hoopingFeeObject = updatedSetupMatserWithCalculation.find((setupobj) => setupobj.setup_label === masterTableLable.hoopingFee);
        hoopingFeeObject.setup_rate = 6.30;
        let indexToUpdate = updatedSetupMatserWithCalculation.findIndex(obj => obj.setup_master_uuid === hoopingFeeObject.setup_master_uuid);
        if (indexToUpdate !== -1) {
            updatedSetupMatserWithCalculation[indexToUpdate] = hoopingFeeObject;
        }
        setMasterTableLoadingStatus({ isSetUpIsLoading: false });
        setSetUpMasterData(updatedSetupMatserWithCalculation);
        return { loading: getMasterTableLoadingStatus, getgarmentData: updatedGarmentData, setupDetail: updatedSetupMatserWithCalculation, technicalMatser: technicalResponse?.data, stitchCountData: updatedStitchData }
    }

    const getPrintingMatserTableData = async () => {
        setPrintingMatserTableLoadingStatus({
            isGarmentLoading: true,
            isInkLoading: true,
            isSetuupLoading: true,
            isJobCriteriaLoading: true,
            isArtLoading: true
        });
        const garmentResponse = await quoteCalculatorService.getPrintingGarmentMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid);
        const inkResponse = await quoteCalculatorService.getPrintingInkCountMatserList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid);
        const setupResponse = await quoteCalculatorService.getPrintingSetUpMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid);
        const jobCritetraResponse = await quoteCalculatorService.getPrintingJobCriteriaMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid);
        const artResponse = await quoteCalculatorService.getPrintingArtCountData(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid);

        let updatedGarmentData = [];
        // Process responses
        if (garmentResponse.status === 200) {
            updatedGarmentData = garmentResponse?.data.map((garmentData) => {
                const garment_quantity = garmentData.garment_quantity || 0;
                const garment_rate = +garmentData.garment_rate || 0;
                const total = garment_quantity * garment_rate
                return {
                    ...garmentData,
                    garment_quantity,
                    garment_rate,
                    total,
                    garment_master_uuid: garmentData.screenprinting_garment_master_uuid, // Rename key
                    screenprinting_garment_master_uuid: undefined,
                };
            }).map(({ screenprinting_garment_master_uuid, ...rest }) => rest); // Remove old key completely
            setPrintingGarmentData(updatedGarmentData);
        }
        setMasterTableLoadingStatus({ isGarmentLoading: false });

        let updatedArtData = [];
        if (artResponse.status === 200) {
            updatedArtData = artResponse?.data.map((artData) => {
                const art_quantity = +artData.art_quantity || 0;
                const art_rate = +artData.art_rate || 0;
                const total = art_quantity * art_rate;
                return {
                    ...artData,
                    art_quantity,
                    art_rate,
                    total,
                    art_master_uuid: artData.screenprinting_art_master_uuid,
                    screenprinting_art_master_uuid: undefined
                }
            }).map(({ screenprinting_art_master_uuid, ...rest }) => rest);
            setPrintingArtCountData(updatedArtData);
        }
        setMasterTableLoadingStatus({ isArtLoading: false });

        let updatedInkData = [];
        if (inkResponse.status === 200) {
            updatedInkData = inkResponse.data.map((InkData) => {
                const ink_quantity = InkData.ink_quantity || 0;
                const ink_rate = +InkData.ink_rate || 0;
                const total = ink_quantity * ink_rate;
                return {
                    ...InkData,
                    ink_quantity,
                    ink_rate,
                    total,
                    ink_master_uuid: InkData.screenprinting_ink_master_uuid,
                    screenprinting_ink_master_uuid: undefined
                };
            }).map(({ screenprinting_ink_master_uuid, ...rest }) => rest);
            setPrintingInkCountData(updatedInkData);
        }
        setMasterTableLoadingStatus({ isInkLoading: false });

        let updatedSetupResponse = [];
        if (setupResponse.status === 200) {
            updatedSetupResponse = setupResponse.data.map((setupdata) => {
                const setup_rate = +setupdata.setup_rate || 0;
                const setup_quantity = +setupdata.setup_quantity || 0;
                const total = setup_rate * setup_quantity;
                return {
                    ...setupdata,
                    setup_rate,
                    setup_quantity,
                    total,
                    setup_master_uuid: setupdata.screenprinting_setup_master_uuid,
                    screenprinting_setup_master_uuid: undefined
                };
            }).map(({ screenprinting_setup_master_uuid, ...rest }) => rest);
            setPrintingSetupData(updatedSetupResponse)
        }
        setMasterTableLoadingStatus({ isSetUpIsLoading: false });

        let updatedJobCriteriaResponse = [];
        if (jobCritetraResponse.status === 200) {
            updatedJobCriteriaResponse = jobCritetraResponse.data.map((jobCriteria) => {
                return {
                    ...jobCriteria,
                    job_criteria_master_uuid: jobCriteria.screenprinting_job_criteria_master_uuid,
                    screenprinting_job_criteria_master_uuid: undefined
                };
            }).map(({ screenprinting_job_criteria_master_uuid, ...rest }) => rest);
            setPrintingJobCriteriaData(updatedJobCriteriaResponse);
        }
        setPrintingMatserTableLoadingStatus({ isJobCriteriaLoading: false })
        return { loading: getPrintingMasterTableLoadingStatus, getgarmentData: updatedGarmentData, getInkData: updatedInkData, getSetupData: getSetUpMasterData, getJobCriteria: updatedJobCriteriaResponse }
    }

    // Utility function to convert HTML string to PDF and download it
    const downloadPdf = async (htmlString, file_name) => {
        // Create a temporary container to hold the HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;

        document.body.appendChild(tempDiv);

        // Find all images in the HTML and convert them to base64
        const images = tempDiv.querySelectorAll('img');
        const imagePromises = Array.from(images).map(async (img) => {
            const url = img.src;
            if (url.startsWith('http')) {
                const base64 = await ImageURLTOBase64Convertor.convertImageUrlToBase64(url);
                img.src = base64;
            }
        });

        // Wait for all images to be converted
        await Promise.all(imagePromises);

        // Set up options for html2pdf
        const opt = {
            margin: [0.2, 0.1, 0.2, 0.1],
            filename: file_name,
            html2canvas: {
                scale: 2,
            },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            enableLinks: true
        };

        // Convert the HTML to PDF
        try {
            await html2pdf().from(tempDiv).set(opt).save();
        } catch (error) {
            console.error("PDF generation error:", error);
        } finally {
            document.body.removeChild(tempDiv);
        }
    };

    // for download quote
    const onClickDownload = () => {
        setIsDownloadLoading(true);
        const quote = {
            "quote_uuid": state.quote_uuid
        }
        quoteService.downloadQuote(quote).then(async (response) => {
            if (response.status === 200) {
                setTimeout(async () => {
                    await downloadPdf(response.data, `quote_${quoteDetails?.job_name}_${quoteDetails.quote_number}.pdf`);
                    setIsDownloadLoading(false);
                }, 2000);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.errors[0]?.message, { autoClose: 4000 });
            setIsDownloadLoading(false);
        });
    };

    const handleFlatpickrInstance = (flatpickr) => {
        flatpickrRef.current = flatpickr;
    };

    // delete quote api call 
    const isDeleteQuoteHandle = () => {
        quoteService.deleteQuote(state.quote_uuid).then((response) => {
            if (response.status === 200) {
                setAfterAction(true);
                navigate(`/quote-list`);
            }
        });
    }

    // send quote api call
    const isSendHandle = () => {
        const sendQuoteId = {
            "quote_uuid": state.quote_uuid
        }
        quoteService.sendQuote(sendQuoteId).then((response) => {
            if (response.status === 200) {
                setAfterAction(true);
                setIsSendQuote(false);
                getQuoteDetails();
            }
        });
    }
    // for delete quote
    const onClickAction = (data, isSend) => {
        setIsModal(data);
        setAfterAction(false);
        setConfirmationModal(true);
        setIsSendQuote(isSend);
    }

    // check sidebar collpsed
    const checkBodyClass = () => {
        document.body.classList.contains('vertical-collpsed') ? setIsVerticalCollapsed(true) : setIsVerticalCollapsed(false);
    }

    useEffect(() => {
        const observer = new MutationObserver(() => {
            checkBodyClass();
        });
        observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });
        // Clean up the observer when the component unmounts
        return () => observer.disconnect();
    }, [isVerticalCollapsed]);

    // mark as approved quote api call 
    const isApprovedHandle = () => {
        const data = {
            "quote_uuid": state.quote_uuid,
            "quote_status": currentStatus !== 'approved' ? 'approved' : 'unapproved'
        }
        quoteService.Change_quote_status(data).then((response) => {
            if (response.status === 200) {
                setAfterAction(true);
                getQuoteDetails();
            }
        });
    }

    // for convert image url to base64
    const fetchImageAsBase64 = async (url) => {
        try {
            const mimeType = 'image/jpeg';
            const response = await fetch(url);
            const arrayBuffer = await response.arrayBuffer();
            const base64String = btoa(
                new Uint8Array(arrayBuffer)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            return `data:${mimeType};base64,${base64String}`;
        } catch (error) {
            console.error("Error fetching image:", error);
            return null;
        }
    }

    // for save quote for duplicate quote
    const saveQuote = async () => {
        try {
            let quoteItems = quoteDetails.quote_items;
            // Map over quoteItems and create an array of promises
            let imagePromises = quoteItems.map(async obj => {
                if (obj.quote_item_details.item_image_url) {
                    // Fetch base64 string and assign it to obj
                    obj.quote_item_details.item_image_base64 = await fetchImageAsBase64(AWS_S3_URL + obj.quote_item_details.item_image_url);
                }
                return obj;
            });

            // Wait for all promises to resolve
            quoteItems = await Promise.all(imagePromises);
            let saveQuoteItems = quoteItems.map(obj => {
                let updated_garments = obj?.quote_item_details.quote_item_type === "Screenprinting" ?
                    obj?.quote_item_details?.quote_item_garments_details?.map(val => {
                        const { screenprinting_quote_item_garments_uuid, ...newObject } = val;
                        return newObject;
                    }) : obj?.quote_item_details?.quote_item_garments_details?.map(val => {
                        const { quote_item_garments_uuid, ...newObject } = val;
                        return newObject;
                    });
                let updated_markup = obj?.quote_item_details.quote_item_type === "Screenprinting" ?
                    obj?.quote_item_details?.quote_item_markup_details?.map(val => {
                        const { screenprinting_quote_item_markup_details_uuid, ...newObject } = val;
                        return newObject;
                    }) : obj?.quote_item_details?.quote_item_markup_details?.map(val => {
                        const { quote_item_markup_details_uuid, ...newObject } = val;
                        return newObject;
                    });
                let updated_setup = obj?.quote_item_details.quote_item_type === "Screenprinting" ?
                    obj?.quote_item_details?.quote_item_setup_details?.map(val => {
                        const { screenprinting_quote_item_setup_uuid, ...newObject } = val;
                        return newObject;
                    }) : obj?.quote_item_details?.quote_item_setup_details?.map(val => {
                        const { quote_item_setup_uuid, ...newObject } = val;
                        return newObject;
                    });
                let updated_stitch_count = obj?.quote_item_details?.quote_item_stitch_counts_details?.map(val => {
                    const { quote_item_stitch_count_uuid, ...newObject } = val;
                    return newObject;
                });
                let updated_technical_specification = obj?.quote_item_details?.quote_item_technical_specification_details?.map(val => {
                    const { quote_item_technical_specification_uuid, ...newObject } = val;
                    return newObject;
                });

                let updated_job_criteria = obj?.quote_item_details?.quote_item_job_criteria_details?.map(val => {
                    const { screenprinting_quote_item_job_criteria_uuid, ...newObject } = val;
                    return newObject;
                });
                let updated_ink_data = obj?.quote_item_details?.quote_item_inks_details?.map(val => {
                    const { screenprinting_quote_item_inks_uuid, ...newObject } = val;
                    return newObject;
                });
                let updated_art_data = obj?.quote_item_details?.quote_item_art_details?.map((val) => {
                    const { screenprinting_quote_item_art_uuid, ...newObj } = val;
                    return newObj
                })
                return {
                    quote_item_details: {
                        cost_per_item: obj?.quote_item_details?.cost_per_item,
                        gross_cost: obj?.quote_item_details?.gross_cost,
                        gross_hourly_rate: obj?.quote_item_details?.gross_hourly_rate,
                        gross_sale: obj?.quote_item_details?.gross_sale,
                        item_description: obj?.quote_item_details?.item_description,
                        item_name: obj?.quote_item_details?.item_name,
                        item_image_base64: obj?.quote_item_details?.item_image_base64,
                        item_quantity: obj?.quote_item_details?.item_quantity,
                        net_hourly_rate: obj?.quote_item_details?.net_hourly_rate,
                        net_profit: obj?.quote_item_details?.net_profit,
                        quoted_sales_percentage: obj?.quote_item_details?.quoted_sales_percentage,
                        quoted_sales_price: obj?.quote_item_details?.quoted_sales_price,
                        profit_margin_percentage: obj?.quote_item_details?.profit_margin_percentage,
                        quote_item_type: obj?.quote_item_details?.quote_item_type
                    },
                    quote_item_garments_details: updated_garments,
                    quote_item_markup_details: updated_markup,
                    quote_item_setup_details: updated_setup,
                    quote_item_tax_details: obj.quote_item_details.quote_item_tax_details === null ? [] : obj.quote_item_details.quote_item_tax_details.map(({ quote_item_tax_mapping_uuid, ...rest }) => rest),
                    ...(obj?.quote_item_details.quote_item_type === "Screenprinting" ?
                        {
                            quote_item_inks_details: updated_ink_data,
                            quote_item_job_criteria_details: updated_job_criteria,
                            quote_item_art_details: updated_art_data
                        } : {
                            quote_item_stitch_counts_details: updated_stitch_count,
                            quote_item_technical_specification_details: updated_technical_specification,
                        }
                    )
                };
            });
            setIsDownloadLoading(true);
            var date = new Date(quoteDetails?.quote_expires_at.toString().replace('IST', ''));
            let saveQuoteReq = {
                "user_uuid": JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid,
                "quote_details": {
                    "job_name": quoteDetails?.job_name,
                    "job_notes": quoteDetails?.job_notes,
                    "quote_expires_at": date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
                },
                "quote_items": saveQuoteItems
            }
            quoteCalculatorService.saveQuote(saveQuoteReq).then((response) => {
                if (response.status === 200) {
                    setIsDownloadLoading(false);
                    navigate('/preview-quote', { state: { mode: 'editMode', quote_uuid: response.data.quote_uuid } });
                }
            }).finally(() => {
                setIsDownloadLoading(false);
            });
        } catch (error) {
            console.error("Error saving quote:", error);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content preview-quote-page pb-0">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <div className="row page-title-box d-flex align-items-center">
                        <h4 className="col-md-4 col-6 my-2 font-size-18">{isEditMode ? 'Edit Quote' : 'Preview quote'}</h4>
                        <div className="col-md-4 col-6 desktop-statusbar"> <QuoteProgressBar statuses={statuses ? statuses : []} currentStatus={currentStatus} /></div>
                        <div className="col-md-4 col-6 page-title-right py-1 d-flex justify-content-end">
                            {
                                (!isEditMode && currentStatus !== 'approved') && (
                                    <ol className="breadcrumb m-0 d-flex align-items-center position-relative">
                                        <button type="button" onClick={async () => {
                                            setisEditMode(true);
                                            if (loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.EMBROIDERY) {
                                                if (getSetUpMasterData.length === 0 && getGarmentsData.length === 0 && getTechnicalMasterData.length === 0 && getStitchCountData.length === 0) {
                                                    let loadingStatus = await getMaterTableData();
                                                }
                                            } if (loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING) {
                                                if (getPrintingGarmentData.length === 0 && getPrintingInkCountData.length === 0 && getPrintingJobCriteriaData.length === 0 && getPrintingSetupData.length === 0) {
                                                    let loadingStatus = await getPrintingMatserTableData();
                                                }
                                            } else if (loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.ALL) {
                                                if (getSetUpMasterData.length === 0 && getGarmentsData.length === 0 && getTechnicalMasterData.length === 0 && getStitchCountData.length === 0) {
                                                    let loadingStatus = await getMaterTableData();
                                                }
                                                if (getPrintingGarmentData.length === 0 && getPrintingInkCountData.length === 0 && getPrintingJobCriteriaData.length === 0 && getPrintingSetupData.length === 0) {
                                                    let loadingStatus = await getPrintingMatserTableData();
                                                }
                                            }
                                        }} className="add-customer py-1 btn btn-success">
                                            <i className="mdi mdi-pencil me-2" />
                                            Edit
                                        </button>
                                    </ol>
                                )}
                            <UncontrolledDropdown className="action-dropdown ms-2 d-flex justify-content-center">
                                <DropdownToggle tag="a" className="card-drop">
                                    <i className="mdi mdi-dots-vertical font-size-18 dropdown-icon"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end mt-2">
                                    <DropdownItem
                                        onClick={() => {
                                            onClickDownload()
                                        }} className="d-flex align-items-center">
                                        <i className="bx bx-download font-size-16 me-2 dropdown-menu-icon" id="downloadtooltip"></i>
                                        <span className="dropdown-menu-text">Download</span>
                                    </DropdownItem>
                                    {(quoteDetails?.client_name && quoteDetails?.client_email) && <DropdownItem
                                        onClick={() => {
                                            onClickAction('Sent quote', true)
                                        }} className="d-flex align-items-center">
                                        <i className="bx bx-send font-size-16 me-2 dropdown-menu-icon" id="edittooltip"></i>
                                        <span className="dropdown-menu-text">Send</span>
                                    </DropdownItem>}
                                    {!isEditMode && <DropdownItem
                                        onClick={() => {
                                            saveQuote();
                                        }} className="d-flex align-items-center">
                                        <i className="dripicons-duplicate font-size-16 me-2 dropdown-menu-icon" id="downloadtooltip"></i>
                                        <span className="dropdown-menu-text">Duplicate</span>
                                    </DropdownItem>}
                                    {(quoteDetails?.client_name && quoteDetails?.client_email) && <DropdownItem
                                        onClick={() => {
                                            onClickAction('Approved quote', false)
                                        }} className="d-flex align-items-center">
                                        <i className={`${currentStatus !== 'approved' ? 'far fa-thumbs-up' : 'far fa-thumbs-down'} font-size-16 me-2 dropdown-menu-icon`} id="edittooltip"></i>
                                        <span className="dropdown-menu-text">{currentStatus !== 'approved' ? 'Mark as approved' : 'Mark as unapproved'}</span>
                                    </DropdownItem>
                                    }
                                    {
                                        (currentStatus === 'approved' && quoteDetails.invoice_uuid === null) && (
                                            <DropdownItem
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    navigate('/preview-invoice', { state: { quote_uuid: quoteDetails.quote_uuid, mode: 'create-from-list' } });
                                                }}
                                                className="d-flex align-items-center">
                                                <i className="mdi mdi-plus  font-size-16 me-2 dropdown-menu-icon" id="downloadtooltip"></i>
                                                <span className="dropdown-menu-text">Create Invoice</span>
                                            </DropdownItem>
                                        )
                                    }
                                    <DropdownItem
                                        onClick={() => {
                                            onClickAction('Delete quote', false)
                                        }} className="d-flex align-items-center">
                                        <i className="mdi mdi-trash-can font-size-16 me-2 text-danger dropdown-menu-icon" id="deletetooltip"></i>
                                        <span className="dropdown-menu-text">Delete</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                        <div className="text-center col-12 d-flex justify-content-center mobile-statusbar mt-3"> <QuoteProgressBar statuses={statuses ? statuses : []} currentStatus={currentStatus} /></div>
                    </div>
                    {quoteDetails.invoice_number !== null &&
                        <div>
                            {
                                !isLoading && (
                                    <Alert className={`${isVerticalCollapsed ? 'success-alert-width' : ''} invoice_number_alert alert-info border-0 px-md-3 px-sm-1 px-2 py-3 mb-4 alert-message`}>
                                        <span className="cursor-pointer me-1 border-bottom border-dark invoice_number" onClick={() => { navigate(`/preview-invoice`, { state: { mode: 'previewInvoice', invoice_uuid: quoteDetails.invoice_uuid } }) }}>
                                            "{"invoice no. "}{quoteDetails?.invoice_number}"</span>   was automatically created from your automation.
                                    </Alert>
                                )
                            }
                        </div>
                    }
                    {/* mark as approved alert */}
                    {quoteDetails.quote_status === 'approved' &&
                        <Alert className={` ${isVerticalCollapsed ? 'success-alert-width' : ''} d-flex align-items-center mb-4 success-alert alert-message px-md-3 px-1`}>
                            <i className="bx bx-check text-success me-2 check-icon"></i>
                            <span>  Quote is marked as approved on {' '}
                                <strong>{UTCtoLocal(quoteDetails.approved_on)}.</strong></span>
                        </Alert>}
                    {
                        quoteDetails.quote_status === 'declined' &&
                        <Alert color="danger" className={` ${isVerticalCollapsed ? 'success-alert-width' : ''}  d-flex align-items-center mb-4 alert-message px-md-4 px-3`}>
                            <span> This quote has been marked as Declined.</span>
                        </Alert>
                    }
                    <Row>
                        <Col lg="12">
                            <Card className={isVerticalCollapsed ? "preview-quote-card mb-4" : "mb-4"}>
                                <CardBody className="px-lg-5 px-3 position-relative">
                                    {/* header */}
                                    {
                                        isEditMode ? (
                                            <div className="d-sm-none d-block mb-3">
                                                <input className="form-control mb-0 mb-0 d-sm-none d-block" name="itemName" placeholder="enter job name"
                                                    onChange={(e) => { setJobName(e.target.value) }} value={getJobName} autoComplete="off" />
                                                <p className="mb-0 error-message ">{getJobName === '' ? PREVIEW_QUOTE.ERR_REQUIRED_JOB_NAME : getJobName?.length > 40 ? PREVIEW_QUOTE.ERR_INVALID_JOB_NAME_LENGTH : ''}</p>
                                            </div>
                                        ) : (
                                            <h4 className="mb-0 mb-4  d-sm-none d-block text-center font-weight-600 font-size-22">{quoteDetails.job_name}</h4>
                                        )
                                    }
                                    <div className="preview-quote-title d-flex justify-content-between align-items-center">
                                        {/* shop logo image */}
                                        <div className="shop-logo d-flex align-items-center">
                                            <span className="font-size-30 font-weight-100 me-2 me-md-3 cursor-pointer back-arrow" onClick={() => {
                                                navigate('/quote-list');
                                            }}> {'<'} </span>
                                            {!isLoading && quoteDetails?.shop_logo_url !== null && quoteDetails.shop_logo_url !== 'null' &&
                                                <img src={AWS_S3_URL + quoteDetails?.shop_logo_url} alt="shop-img" className="logo-dark-element preview-quote-shop-logo cursor-pointer" onClick={() => setPreviewImage(AWS_S3_URL + quoteDetails?.shop_logo_url)} />
                                            }
                                        </div>
                                        {
                                            isEditMode ? (
                                                <div className="d-sm-block d-none">
                                                    <input className="form-control d-sm-block d-none my-0 job_name_input" onChange={(e) => { setJobName(e.target.value); }} value={getJobName} autoComplete="off" />
                                                    <p className="mb-0 error-message width-180">{getJobName === '' ? PREVIEW_QUOTE.ERR_REQUIRED_JOB_NAME : getJobName?.length > 40 ? PREVIEW_QUOTE.ERR_INVALID_JOB_NAME_LENGTH : ''}</p>
                                                </div>
                                            ) : (
                                                <h4 className="mb-0 align-items-center d-sm-flex d-none font-weight-600 font-size-22">{quoteDetails.job_name}</h4>
                                            )
                                        }
                                        {/* quote id */}
                                        <div>
                                            <h4 className="font-size-16 my-0 color-secondary mt-1">
                                                Quote #{quoteDetails.quote_number}
                                            </h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <Row>
                                        {/* shop details */}
                                        <Col md="6">
                                            <address className="mb-3 mb-md-5">
                                                <p className="gray-text mb-3">From</p>
                                                <p className="mb-2 name-font-weight">{quoteDetails.name}</p>
                                                <p className="mb-2">{quoteDetails.shop_name}</p>
                                                <p className="mb-0">{quoteDetails.shop_address}</p>
                                                <p className="mb-0">{quoteDetails.shop_city}{quoteDetails.shop_city && ','} {quoteDetails.shop_state}</p>
                                                <p className="mb-2">{quoteDetails.shop_zip_code}{quoteDetails.shop_zip_code && ','} {quoteDetails.shop_country}</p>
                                                <p className="mb-0">{quoteDetails.shop_contact_number}</p>
                                                <p className="email-break mb-1">{quoteDetails.shop_email}</p>
                                            </address>
                                        </Col>
                                        <Col md="6" className="d-flex justify-content-md-end">
                                            <address className="mb-5 to-container ">
                                                <p className="mb-2 gray-text ps-0 ps-md-2">To</p>
                                                {
                                                    !isLoading && isEditMode && quoteDetails.quote_status === 'draft' && !showDropdown && editModeQuoteDetails.quote_client_details !== '' && (
                                                        <>
                                                            <div className="d-flex selected-recipient-container form-control justify-content-between border-1 pt-2 pb-2 px-2">
                                                                <div className="selected-client-name-container">
                                                                    <p className="mb-1 d-flex"><Label className="mb-0 me-1">Company Name:</Label><span className={`selected-client-info d-block ${editModeQuoteDetails?.quote_client_details?.client_company_name ? editModeQuoteDetails.quote_client_details?.client_company_name.length > 14 ? 'cursor-pointer' : '' : quoteDetails?.client_company_name.length > 14 ? 'cursor-pointer' : ''}`}
                                                                        id="company_name"> {editModeQuoteDetails?.quote_client_details?.client_company_name ? editModeQuoteDetails?.quote_client_details?.client_company_name : quoteDetails?.client_company_name}
                                                                    </span>
                                                                    </p>
                                                                    {
                                                                        (editModeQuoteDetails.quote_client_details?.client_company_name ? editModeQuoteDetails.quote_client_details?.client_company_name.length > 14 : quoteDetails?.client_company_name.length > 14) && (
                                                                            <UncontrolledTooltip target='company_name' style={{ background: 'black' }}>
                                                                                {editModeQuoteDetails?.quote_client_details?.client_company_name ? editModeQuoteDetails.quote_client_details?.client_company_name : quoteDetails?.client_company_name}
                                                                            </UncontrolledTooltip>
                                                                        )
                                                                    }
                                                                    <p className="mb-1 d-flex"><Label className="mb-0 me-1">Client Name:</Label><span className={`selected-client-info d-block ${editModeQuoteDetails?.quote_client_details?.client_name ? editModeQuoteDetails.quote_client_details?.client_name.length > 14 ? 'cursor-pointer' : '' : quoteDetails?.client_name.length > 14 ? 'cursor-pointer' : ''}`}
                                                                        id="client_name"> {editModeQuoteDetails?.quote_client_details?.client_name ? editModeQuoteDetails?.quote_client_details?.client_name : quoteDetails?.client_name}
                                                                    </span>
                                                                    </p>
                                                                    {
                                                                        (editModeQuoteDetails.quote_client_details?.client_name ? editModeQuoteDetails.quote_client_details?.client_name.length > 14 : quoteDetails?.client_name.length > 14) && (
                                                                            <UncontrolledTooltip target='client_name' style={{ background: 'black' }}>
                                                                                {editModeQuoteDetails?.quote_client_details?.client_name ? editModeQuoteDetails?.quote_client_details?.client_name : quoteDetails?.client_name}
                                                                            </UncontrolledTooltip>
                                                                        )
                                                                    }
                                                                    <p className="mb-0 d-flex"> <Label className="mb-0 me-1">Email:</Label><span className={`email-break selected-client-info d-block
                                                                        ${editModeQuoteDetails?.quote_client_details?.client_email ? editModeQuoteDetails.quote_client_details?.client_email.length > 14 ? 'cursor-pointer' : '' : quoteDetails?.client_email.length > 14 ? 'cursor-pointer' : ''}
                                                                    `} id="client_edit_name"> {editModeQuoteDetails?.quote_client_details?.client_email ? editModeQuoteDetails?.quote_client_details?.client_email : quoteDetails?.client_email}</span></p>
                                                                    {
                                                                        (editModeQuoteDetails.quote_client_details?.client_email ? editModeQuoteDetails.quote_client_details?.client_email.length > 14 : quoteDetails?.client_email.length > 14) && (
                                                                            <UncontrolledTooltip target='client_edit_name' style={{ background: 'black' }}>
                                                                                {editModeQuoteDetails.quote_client_details?.client_email ? editModeQuoteDetails.quote_client_details?.client_email : quoteDetails?.client_email}
                                                                            </UncontrolledTooltip>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <i className="mdi mdi-file-replace-outline font-size-16 me-2 text-success choose-recipient-icon cursor-pointer" id="Change-recipient"
                                                                        onClick={() => {
                                                                            setEditModeQuoteDetails(prevData => {
                                                                                return {
                                                                                    ...prevData,
                                                                                    quote_client_details: '',
                                                                                    client_company_name: '',
                                                                                    client_name: '',
                                                                                    client_email: '',
                                                                                    client_address: '',
                                                                                    client_city: '',
                                                                                    client_state: '',
                                                                                    client_country: '',
                                                                                    client_zip_code: ''
                                                                                };
                                                                            });
                                                                        }}
                                                                    />
                                                                    <UncontrolledTooltip target="Change-recipient" style={{ background: 'black' }}>
                                                                        change
                                                                    </UncontrolledTooltip>
                                                                </div>
                                                            </div>
                                                            {
                                                                <>{
                                                                    editModeQuoteDetails?.client_address && (
                                                                        <p className="mb-0 client-address ps-0 ps-md-2">{editModeQuoteDetails.client_address}</p>
                                                                    )}
                                                                    <p className="mb-0 client-address ps-0 ps-md-2">{editModeQuoteDetails.client_city}{editModeQuoteDetails.client_city && ','} {editModeQuoteDetails.client_state}</p>
                                                                    <p className="mb-2 client-address ps-0 ps-md-2">{editModeQuoteDetails.client_zip_code}{editModeQuoteDetails.client_zip_code && ','} {editModeQuoteDetails.client_country}</p>
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    isEditMode && !isLoading && (
                                                        <>
                                                            <ChooseClientDropdown customerQuoteClientList={customerQuoteClientList} showDropdown={showDropdown} toggleDropdown={() => { setShowDropdown(!showDropdown) }} button_title={'Choose a recipient'} fromComponent={'preview-quote'}
                                                                invoice_client_details={editModeQuoteDetails?.quote_client_details}
                                                                onAction={(option) => {
                                                                    setEditModeQuoteDetails(prev => {
                                                                        return {
                                                                            ...prev,
                                                                            client_company_name: '',
                                                                            client_email: '',
                                                                            client_address: option.client_address,
                                                                            client_city: option.client_city,
                                                                            client_state: option.client_state,
                                                                            client_country: option.client_country,
                                                                            client_zip_code: option.client_zip_code,
                                                                            quote_client_details: option
                                                                        };
                                                                    });
                                                                    if (!customerQuoteClientList.some(obj => obj.client_company_name === option.client_company_name && obj.client_email === option.client_email)) {
                                                                        getCustomerClientList();
                                                                    }
                                                                }} />
                                                        </>

                                                    )
                                                }
                                                {
                                                    ((!isEditMode && quoteDetails.client_email) || (isEditMode && quoteDetails && quoteDetails?.quote_status !== 'draft')) && (
                                                        <div className="d-flex form-control justify-content-between border-0 pt-2 pb-2 px-md-2 px-0">
                                                            <div className="selected-client-name-container-preview">
                                                                <p className="mb-1"><Label className="mb-0 me-1">Company Name:</Label> {quoteDetails && quoteDetails?.client_company_name ? quoteDetails?.client_company_name : ''}</p>
                                                                <p className="mb-1"><Label className="mb-0 me-1">Client Name:</Label>{quoteDetails && quoteDetails?.client_name}</p>
                                                                <span className=" client-address">
                                                                    <Label className="mb-0 me-1">Email:</Label>
                                                                    {quoteDetails?.client_email}
                                                                </span>
                                                                {
                                                                    quoteDetails?.client_address && (
                                                                        <div className=" client-address mt-1">
                                                                            <Label className="mb-0 me-1">Address:</Label>
                                                                            {quoteDetails.client_address}
                                                                        </div>
                                                                    )
                                                                }
                                                                <p className="mb-0 client-address">{quoteDetails.client_city}{quoteDetails.client_city && ','} {quoteDetails.client_state}</p>
                                                                <p className="mb-2 client-address">{quoteDetails.client_zip_code}{quoteDetails.client_zip_code && ','} {quoteDetails.client_country}</p>
                                                            </div>
                                                        </div>)
                                                }
                                                <div className={`mb-0 ps-md-2 ps-0 pt-2 expires-width  ${isEditMode ? 'd-flex align-items-center' : 'd-block'}`}> <Label className={`mb-0 align-items-center me-1`}>Expires:</Label>
                                                    {!isLoading && (isEditMode ? <div className="input-group border-0">
                                                        <Flatpickr
                                                            ref={handleFlatpickrInstance}
                                                            className="form-control d-block flatpicker-expires-field"
                                                            placeholder="M dd,yyyy"
                                                            value={date}
                                                            id="expiresAt"
                                                            onOpen={() => {
                                                                let minDate = "today"; // Default minDate is today
                                                                const quoteExpiresAt = moment(quoteDetails.quote_expires_at).startOf('day'); // Expiration date at start of the day
                                                                const today = moment().startOf('day'); // Today's date at start of the day

                                                                // Check if quote_expires_at exists and compare using moment that if date is before today
                                                                if (quoteDetails && quoteDetails.quote_expires_at && quoteExpiresAt.isBefore(today)) {

                                                                    // if it is before today's date/ past date set min date to past date
                                                                    minDate = quoteExpiresAt.toDate();
                                                                }

                                                                // Set the minDate in Flatpickr
                                                                flatpickrRef.current?.flatpickr?.set("minDate", minDate);

                                                                if (!expiresAtVal) {
                                                                    setExpiresAtVal('');
                                                                }
                                                            }}
                                                            options={{
                                                                altInput: true,
                                                                altFormat: "d F, Y",
                                                                dateFormat: "m-d-Y"
                                                            }}
                                                            onChange={([date]) => {
                                                                setExpiresAtVal(date);
                                                                setEditModeQuoteDetails(prev => {
                                                                    return {
                                                                        ...prev,
                                                                        quote_expires_at: moment(date).startOf('day').toISOString()
                                                                    };
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                        : <span>{UTCtoLocal(quoteDetails.quote_expires_at)}</span>)} </div>
                                            </address>
                                        </Col>
                                    </Row>
                                    {/* quote table  */}
                                    <div className="table-responsive">
                                        {/* if it is not eidt mode */}
                                        {
                                            !isEditMode && (
                                                <Table className="table-nowrap preview-quote-table">
                                                    <thead>
                                                        <tr>
                                                            <th className={`table-th `}>Item</th>
                                                            <th className={`table-th `}>Name</th>
                                                            <th className={`table-th  text-center`}>Quantity </th>
                                                            <th className={`table-th text-end table-header-rate`}>Rate</th>
                                                            <th className={`text-end table-th`}>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    {
                                                        quoteDetails && quoteDetails?.quote_items && quoteDetails?.quote_items.length > 0 && !isEditMode && (
                                                            <tbody>
                                                                {quoteDetails?.quote_items?.map((e, key) => {
                                                                    return (<tr key={key}>
                                                                        <td className={`text-start table-data ${e.quote_item_details.quote_item_tax_details === null ? 'align-middle' : 'align-top'}  position-relative img-td`}>
                                                                            <ImageUpload
                                                                                selectedImage={selectedImages[key]}
                                                                                handleImageChange={handleImageChange}
                                                                                handlePreviewImage={handlePreviewImage}
                                                                                handleDeleteImage={handleDeleteImage}
                                                                                index={key}
                                                                                quoteItemDetails={e.quote_item_details}
                                                                                isEditMode={isEditMode}
                                                                                isFromPreviewQuote={true}
                                                                            />
                                                                            {imgTypeErrorArr && imgTypeErrorArr[key] && (
                                                                                <p className="text-danger error-message mb-0 mt-2">
                                                                                    {imgTypeErrorArr[key]}
                                                                                </p>
                                                                            )}
                                                                        </td>
                                                                        <td className={`table-data ${e.quote_item_details.quote_item_tax_details === null ? 'align-middle' : 'align-top'} invoice-name-description`}>
                                                                            <div className="invoice-data-container">
                                                                                <p className="mb-0">
                                                                                    {e.quote_item_details?.item_name}
                                                                                </p>
                                                                                <p className="description mb-0 invoice-info">
                                                                                    {e.quote_item_details?.item_description}
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                        <td className={`text-center table-data ${e.quote_item_details.quote_item_tax_details === null ? 'align-middle' : 'align-top'}`}>
                                                                            {e.quote_item_details.item_quantity}
                                                                        </td>
                                                                        <td className={` table-data text-end ${e.quote_item_details.quote_item_tax_details === null ? 'align-middle' : 'align-top'}`}>
                                                                            {'$' + e.quote_item_details.quoted_sales_price.toFixed(2)}
                                                                            {e.quote_item_details.quote_item_tax_details && e.quote_item_details.quote_item_tax_details.map((taxInfo, taxIndex) => (
                                                                                <div className="d-flex justify-content-end" key={taxIndex}>
                                                                                    <div className="d-flex justify-content-between tax_detail_row">
                                                                                        {taxInfo.tax_name && (
                                                                                            <span id={`text_name${taxIndex}-${key}`} className={`mt-1 me-1 tax-display-quote-items-popup ${taxInfo.tax_name.length > (isMobileDevice ? 10 : 13) ? 'cursor-pointor tax-display-quote-items-elipsis' : ''}`}>{taxInfo.tax_name}</span>
                                                                                        )}
                                                                                        {taxInfo.tax_name && taxInfo.tax_name.length > (isMobileDevice ? 10 : 13) && (
                                                                                            <UncontrolledTooltip placement="bottom" target={`text_name${taxIndex}-${key}`}>{taxInfo.tax_name}</UncontrolledTooltip>
                                                                                        )}
                                                                                        {taxInfo.tax_percentage && (
                                                                                            <span className="mb-0 mt-1">{parseFloat(taxInfo.tax_percentage).toFixed(2)}%</span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </td>
                                                                        <td className={` text-end ${e.quote_item_details.quote_item_tax_details === null ? 'align-middle' : 'align-top'} table-data `}>
                                                                            {'$' + (e.quote_item_details.item_quantity * e.quote_item_details.quoted_sales_price).toFixed(2)}
                                                                            {e.quote_item_details.quote_item_tax_details && e.quote_item_details.quote_item_tax_details.map((taxInfo, index) => (
                                                                                taxInfo.tax_percentage && (
                                                                                    <div className="mt-1 text-end" key={index}>
                                                                                        <span className="text-end">${((e.quote_item_details.item_quantity * e.quote_item_details.quoted_sales_price) * (taxInfo.tax_percentage / 100)).toFixed(2)}</span>
                                                                                    </div>
                                                                                )
                                                                            ))}
                                                                        </td>
                                                                    </tr>)
                                                                })}
                                                                <tr >
                                                                    <td className="border-0" colSpan={6}>
                                                                        <div className="d-flex justify-content-end mt-3">
                                                                            <div className="d-flex justify-content-between subtotal-container" key={getFormKey}>
                                                                                <div>
                                                                                    <div className=" mb-2">
                                                                                        <span >Subtotal</span>
                                                                                    </div>
                                                                                    {getTaxArray.map((data, index) => (
                                                                                        data.tax_name && typeof data.tax_percentage === 'number' && (
                                                                                            <div className="my-2" key={index}>
                                                                                                <span>{data.tax_name + '(' + parseFloat(data.tax_percentage).toFixed(2) + '%)'}</span>
                                                                                            </div>
                                                                                        )
                                                                                    ))}
                                                                                </div>
                                                                                <div>
                                                                                    <p className="m-0 text-end">
                                                                                        ${isLoading ? 0.00 :
                                                                                            Number(
                                                                                                quoteDetails.quote_items?.reduce((a, v) => {
                                                                                                    const itemQuantity = +v.quote_item_details.item_quantity;
                                                                                                    const quotedSalesPrice = +v.quote_item_details.quoted_sales_price;
                                                                                                    return a + (itemQuantity * quotedSalesPrice);
                                                                                                }, 0).toFixed(2)
                                                                                            ).toFixed(2)
                                                                                        }
                                                                                    </p>
                                                                                    <p className="text-end mb-0">
                                                                                        {isLoading ? (
                                                                                            '00'
                                                                                        ) : (
                                                                                            getTaxArray.map((data, index) => (
                                                                                                data.Amount && (
                                                                                                    <div className="my-2" key={index}>
                                                                                                        ${data.Amount.toFixed(2)}
                                                                                                    </div>
                                                                                                )
                                                                                            ))
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-end mt-2">
                                                                            <div className="d-flex justify-content-between total-container">
                                                                                <div>
                                                                                    <strong className="color-secondary mt-3">Total</strong>
                                                                                </div>
                                                                                <div>
                                                                                    <h4 className="total-value color-secondary">
                                                                                        ${(getTaxArray.reduce((acc, data) => acc + data.Amount, 0) + (quoteDetails.quote_items?.reduce((acc, v) => acc + (+v.quote_item_details.item_quantity * +v.quote_item_details.quoted_sales_price), 0) || 0)).toFixed(2)}
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    }
                                                </Table>
                                            )
                                        }
                                        {/* if it is edit mode */}
                                        {
                                            isEditMode && editModeQuoteDetails && editModeQuoteDetails?.quote_items && (
                                                <Formik
                                                    key={getFormKey}
                                                    initialValues={{
                                                        quoteItems: editModeQuoteDetails?.quote_items || [],
                                                    }}
                                                    validationSchema={validationPreviewQuoteItemSchema}
                                                >
                                                    {({ values, setFieldValue, errors, isValid }) => {
                                                        setisValidPreviewForm(isValid);
                                                        const handleAddItem = async () => {
                                                            const newItemType = determineItemType(editModeQuoteDetails?.quote_items);
                                                            const newItem = {
                                                                quote_item_details: {
                                                                    id: editModeQuoteDetails?.quote_items.length + 1,
                                                                    item_name: null,
                                                                    item_description: '',
                                                                    item_quantity: null,
                                                                    quoted_sales_price: null,
                                                                    cost_per_item: 0,
                                                                    gross_cost: 0,
                                                                    gross_hourly_rate: 0,
                                                                    gross_sale: 0,
                                                                    net_hourly_rate: 0,
                                                                    net_profit: 0,
                                                                    profit_margin_percentage: 0,
                                                                    quoted_sales_percentage: 0,
                                                                    quote_item_garments_details: newItemType === SUBSCRIPTION_CALC_TYPE.EMBROIDERY ? getGarmentsData : getPrintingGarmentData,
                                                                    quote_item_setup_details: newItemType === SUBSCRIPTION_CALC_TYPE.EMBROIDERY ? getSetUpMasterData : getPrintingSetupData,
                                                                    quote_item_stitch_counts_details: newItemType === SUBSCRIPTION_CALC_TYPE.EMBROIDERY ? getStitchCountData : [],
                                                                    quote_item_technical_specification_details: newItemType === SUBSCRIPTION_CALC_TYPE.EMBROIDERY ? getTechnicalMasterData : [],
                                                                    quote_item_markup_details: newItemType === SUBSCRIPTION_CALC_TYPE.EMBROIDERY ? getMarkUpData : getPrintingMarkupData,
                                                                    quote_item_tax_details: null,
                                                                    quote_item_type: newItemType,
                                                                    quote_item_job_criteria_details: newItemType === SUBSCRIPTION_CALC_TYPE.EMBROIDERY ? [] : getPrintingJobCriteriaData,
                                                                    quote_item_inks_details: newItemType === SUBSCRIPTION_CALC_TYPE.EMBROIDERY ? [] : getPrintingInkCountData
                                                                },
                                                            }
                                                            setEditModeQuoteDetails(prevData => {
                                                                const updatedItems = [...prevData.quote_items, newItem];
                                                                Subtotal(updatedItems);
                                                                return { ...prevData, quote_items: updatedItems };
                                                            });
                                                            setFieldValue('quoteItems', [...values.quoteItems, newItem], true);
                                                        }

                                                        return (
                                                            <Form>
                                                                <FieldArray name="quoteItems">
                                                                    {({ push, remove }) => (
                                                                        <Table className="table-nowrap preview-quote-table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className={`table-th width-120`}>Item</th>
                                                                                    <th className={`table-th width-500 preview-table-th`}>Name <span className="text-danger">*</span> </th>
                                                                                    <th className={`table-th  text-start width-250`}>Quantity <span className="text-danger">*</span></th>
                                                                                    <th className={`table-th text-start width-200`}>Rate <span className="text-danger">*</span></th>
                                                                                    <th className={`text-end table-th width-120`}>Amount</th>
                                                                                    <th className="table-th text-end width-120"> </th>
                                                                                </tr>
                                                                            </thead>
                                                                            {
                                                                                (
                                                                                    loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.EMBROIDERY ?
                                                                                        (
                                                                                            !getMasterTableLoadingStatus.isSetUpIsLoading || !getMasterTableLoadingStatus.isStitchLoading || !getMasterTableLoadingStatus.isTechnicleMasterListIsLoading || !getMasterTableLoadingStatus.isgarmentLoading
                                                                                        ) : loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING ?
                                                                                            (
                                                                                                !getPrintingMasterTableLoadingStatus.isGarmentLoading || !getPrintingMasterTableLoadingStatus.isInkLoading || !getPrintingMasterTableLoadingStatus.isArtLoading || !getPrintingMasterTableLoadingStatus.isJobCriteriaLoading || !getPrintingMasterTableLoadingStatus.isSetuupLoading
                                                                                            ) : (
                                                                                                !getMasterTableLoadingStatus.isSetUpIsLoading || !getMasterTableLoadingStatus.isStitchLoading || !getMasterTableLoadingStatus.isTechnicleMasterListIsLoading || !getMasterTableLoadingStatus.isgarmentLoading ||
                                                                                                !getPrintingMasterTableLoadingStatus.isGarmentLoading || !getPrintingMasterTableLoadingStatus.isInkLoading || !getPrintingMasterTableLoadingStatus.isArtLoading || !getPrintingMasterTableLoadingStatus.isJobCriteriaLoading || !getPrintingMasterTableLoadingStatus.isSetuupLoading
                                                                                            ) &&
                                                                                            <tbody>
                                                                                                {values.quoteItems.map((item, index) => (
                                                                                                    <tr key={index}>
                                                                                                        <td className={`text-start width-120 align-top position-relative img-td`}>
                                                                                                            <ImageUpload
                                                                                                                selectedImage={selectedImages[index]}
                                                                                                                handleImageChange={(e, index) => handleImageChange(e, index, setFieldValue)}
                                                                                                                handlePreviewImage={handlePreviewImage}
                                                                                                                handleDeleteImage={(index) => handleDeleteImage(index, setFieldValue)}
                                                                                                                index={index}
                                                                                                                quoteItemDetails={item.quote_item_details}
                                                                                                                isEditMode={isEditMode}
                                                                                                                isFromPreviewQuote={true}
                                                                                                            />
                                                                                                            {imgTypeErrorArr && imgTypeErrorArr[index] && (
                                                                                                                <p className="text-danger error-message mb-0 mt-2">
                                                                                                                    {imgTypeErrorArr[index]}
                                                                                                                </p>
                                                                                                            )}
                                                                                                        </td>
                                                                                                        <td className="ps-2 ps-sm-3">
                                                                                                            <input type="text" className="width-300 preview-input-placeholder  form-control" id={`item_name.${index}`}
                                                                                                                name={`quoteItems.${index}.quote_item_details.item_name`}
                                                                                                                placeholder="Enter item name" value={item.quote_item_details.item_name} onChange={(e) => {
                                                                                                                    handleEditQuoteItem(index, 'item_name', e.target.value);
                                                                                                                    setFieldValue(`quoteItems[${index}].quote_item_details.item_name`, e.target.value || '', true);
                                                                                                                }} />
                                                                                                            {
                                                                                                                errors && errors?.quoteItems && errors?.quoteItems[index] && errors?.quoteItems[index]?.quote_item_details && errors?.quoteItems[index]?.quote_item_details?.item_name && item.quote_item_details?.item_name !== null && (
                                                                                                                    <p className="mb-0 error-message">{errors?.quoteItems[index]?.quote_item_details?.item_name}</p>
                                                                                                                )
                                                                                                            }
                                                                                                            <textarea className="width-300 preview-input-placeholder  form-control mt-2 " id={`item_description.${index}`}
                                                                                                                name={`quoteItems.${index}.quote_item_details.item_description`}
                                                                                                                placeholder="Enter item description" value={item.quote_item_details.item_description} onChange={(e) => {
                                                                                                                    handleEditQuoteItem(index, 'item_description', e.target.value);
                                                                                                                    setFieldValue(`quoteItems[${index}].quote_item_details.item_description`, e.target.value || '', true);
                                                                                                                }}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td className={`${isEditMode ? 'text-start align-top ps-2 ps-sm-3' : 'text-center table-data'}  `}>
                                                                                                            <NumericFormat
                                                                                                                id="item_quantity" decimalScale={0} value={item.quote_item_details.item_quantity} className="form-control preview-input-placeholder width-100"
                                                                                                                name={`quoteItems.${index}.quote_item_details.item_quantity`} placeholder="Enter quantity" onKeyDown={(e) => {
                                                                                                                    if (e.key === '-') {
                                                                                                                        e.preventDefault();
                                                                                                                    }
                                                                                                                }} onChange={(e) => {
                                                                                                                    setFieldValue(`quoteItems[${index}].quote_item_details.item_quantity`, e.target.value === '' ? null : +e.target.value, true);
                                                                                                                    if (item.quote_item_details?.quote_item_type === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING) {
                                                                                                                        handleEditPrintingQuoteItem(index, 'item_quantity', e.target.value === '' ? null : +e.target.value);
                                                                                                                    } else if (item.quote_item_details?.quote_item_type === SUBSCRIPTION_CALC_TYPE.EMBROIDERY) {
                                                                                                                        handleEditQuoteItem(index, 'item_quantity', e.target.value === '' ? null : +e.target.value);
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                            {
                                                                                                                errors && errors?.quoteItems && errors?.quoteItems[index] && errors?.quoteItems[index]?.quote_item_details && errors?.quoteItems[index]?.quote_item_details?.item_quantity && item.quote_item_details?.item_quantity !== null && (
                                                                                                                    <p className="mb-0 error-message">{errors?.quoteItems[index]?.quote_item_details?.item_quantity}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td className={` ${isEditMode ? 'align-top ps-2 ps-sm-3' : 'table-data text-end'} `}>
                                                                                                            <NumericFormat
                                                                                                                decimalScale={2} id="quoted_sales_price" value={item.quote_item_details.quoted_sales_price} className="form-control preview-input-placeholder width-100"
                                                                                                                name={`quoteItems.${index}.quote_item_details.quoted_sales_price`} placeholder="Enter price" onKeyDown={(e) => {
                                                                                                                    if (e.key === '-') {
                                                                                                                        e.preventDefault();
                                                                                                                    }
                                                                                                                    let value = e.target.value.trim(); // Trim to remove any leading or trailing spaces
                                                                                                                    if (value.endsWith('.')) {
                                                                                                                        value += '0'; // Append a zero
                                                                                                                    }
                                                                                                                    value = value === '' ? null : +value;
                                                                                                                    setFieldValue(`quoteItems[${index}].quote_item_details.quoted_sales_price`, value, true);
                                                                                                                    if (item.quote_item_details?.quote_item_type === SUBSCRIPTION_CALC_TYPE.EMBROIDERY) {
                                                                                                                        handleEditQuoteItem(index, 'quoted_sales_price', value);
                                                                                                                    } else if (item.quote_item_details?.quote_item_type === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING) {
                                                                                                                        handleEditPrintingQuoteItem(index, 'quoted_sales_price', value);
                                                                                                                    }
                                                                                                                }} onChange={(e) => {
                                                                                                                    let value = e.target.value.trim(); // Trim to remove any leading or trailing spaces
                                                                                                                    if (value.endsWith('.')) {
                                                                                                                        value += '0'; // Append a zero
                                                                                                                    }
                                                                                                                    value = value === '' ? null : +value;
                                                                                                                    setFieldValue(`quoteItems[${index}].quote_item_details.quoted_sales_price`, value, true);
                                                                                                                    if (item.quote_item_details?.quote_item_type === SUBSCRIPTION_CALC_TYPE.EMBROIDERY) {
                                                                                                                        handleEditQuoteItem(index, 'quoted_sales_price', value);
                                                                                                                    } else if (item.quote_item_details?.quote_item_type === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING) {
                                                                                                                        handleEditPrintingQuoteItem(index, 'quoted_sales_price', value);
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                            {
                                                                                                                errors && errors?.quoteItems && errors?.quoteItems[index] && errors?.quoteItems[index]?.quote_item_details && errors?.quoteItems[index]?.quote_item_details?.quoted_sales_price && item.quote_item_details?.quoted_sales_price !== null && (
                                                                                                                    <p className="mb-0 error-message">{errors?.quoteItems[index]?.quote_item_details?.quoted_sales_price}</p>
                                                                                                                )
                                                                                                            }
                                                                                                            <p className="form-control pb-0 border-0 ps-0">
                                                                                                                <TaxSelect options={getTaxInfo}
                                                                                                                    updatedTaxInfo={updatedTaxInfo}
                                                                                                                    shouldShowSelectedTax={true}
                                                                                                                    componentName={'previewQuote'}
                                                                                                                    customer_shop_tax_master_uuid={get_customer_shop_tax_master_uuid}
                                                                                                                    selectedTaxInfo={selectedTaxInfo}
                                                                                                                    shouldRefreshSelectedTaxInfo={getSelectedTaxInfo.length === 0 ? true : false}
                                                                                                                    getAlreadyAddedTax={
                                                                                                                        item.quote_item_details.quote_item_tax_details === null ? {
                                                                                                                            quote_item_uuid: item.quote_item_details.quote_item_uuid === undefined ? item.quote_item_details.id : item.quote_item_details.quote_item_uuid
                                                                                                                            , quote_item_tax_details: []
                                                                                                                        } : {
                                                                                                                            quote_item_uuid: item.quote_item_details.quote_item_uuid === undefined ? item.quote_item_details.id : item.quote_item_details.quote_item_uuid,
                                                                                                                            quote_item_tax_details: item.quote_item_details.quote_item_tax_details
                                                                                                                        }} />
                                                                                                            </p>
                                                                                                        </td>
                                                                                                        <td className={` text-end ${isEditMode ? ' pe-1 pe-sm-3' : ' table-data'}`}>
                                                                                                            <NumericFormat decimalScale={2} prefix="$" className="border-0 text-end width-120 height-40" value={(item.quote_item_details.item_quantity * item.quote_item_details.quoted_sales_price).toFixed(2)} />
                                                                                                            {
                                                                                                                item.quote_item_details.quote_item_tax_details && item.quote_item_details.quote_item_tax_details.map((taxInfo, index) => (
                                                                                                                    <div className="mt-1 text-end align-midlle height-50">
                                                                                                                        <p className="text-end mb-0 mt-3">${((item.quote_item_details.item_quantity * item.quote_item_details.quoted_sales_price) * (taxInfo.tax_percentage / 100)).toFixed(2)}</p>
                                                                                                                    </div>
                                                                                                                ))
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td className={`text-end ${isEditMode ? 'align-top' : ' table-data'}`} >
                                                                                                            <i className="mdi mdi-trash-can me-sm-3 text-danger font-size-16 cursor-pointer delete-icon" id={`deletetooltip${index}`} onClick={() => { setConfirmationModal(true); setIsModal('Delete item'); setDeleteInfo(editModeQuoteDetails.quote_items[index].quote_item_details) }} />
                                                                                                            <UncontrolledTooltip placement="bottom" target={`deletetooltip${index}`}>Delete</UncontrolledTooltip>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                                <tr >
                                                                                                    <td colSpan={6} className="border-0 ps-0">
                                                                                                        <button className="btn-secondary ms-0 add-button btn btn-soft-success waves-light btn " onClick={handleAddItem}> <i className="mdi mdi-plus p-0 m-0 me-1" /><span className="me-1">Add Item</span> </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                {
                                                                                                    editModeQuoteDetails.quote_items.length !== 0 && (
                                                                                                        <tr >
                                                                                                            <td className="border-0" colSpan={6}>
                                                                                                                <div className="d-flex justify-content-end mt-3">
                                                                                                                    <div className="d-flex justify-content-between subtotal-container">
                                                                                                                        <div key={getFormKey}>
                                                                                                                            <div className=" mb-2">
                                                                                                                                <span >Subtotal</span>
                                                                                                                            </div>
                                                                                                                            {
                                                                                                                                getTaxArray.map((data) => (
                                                                                                                                    <div className="my-2">
                                                                                                                                        <span>{data.tax_name + '(' + parseFloat(data.tax_percentage).toFixed(2) + '%)'}</span>
                                                                                                                                    </div>
                                                                                                                                ))
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div key={getFormKey}>
                                                                                                                            <p className="m-0 text-end" >
                                                                                                                                ${isLoading ? 0.00 :
                                                                                                                                    Number(
                                                                                                                                        editModeQuoteDetails.quote_items?.reduce((a, v) => {
                                                                                                                                            const itemQuantity = +v.quote_item_details.item_quantity;
                                                                                                                                            const quotedSalesPrice = +v.quote_item_details.quoted_sales_price;
                                                                                                                                            return a + (itemQuantity * quotedSalesPrice);
                                                                                                                                        }, 0).toFixed(2)
                                                                                                                                    ).toFixed(2)
                                                                                                                                }
                                                                                                                            </p>
                                                                                                                            <p className="text-end mb-0">
                                                                                                                                {
                                                                                                                                    isLoading ? '00' : getTaxArray.map((data) => (
                                                                                                                                        <div className="my-2">${data.Amount.toFixed(2)}</div>
                                                                                                                                    ))
                                                                                                                                }
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="d-flex justify-content-end mt-2">
                                                                                                                    <div className="d-flex justify-content-between total-container">
                                                                                                                        <div>
                                                                                                                            <strong className="color-secondary mt-3">Total</strong>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <h4 className="total-value color-secondary" key={getFormKey}>
                                                                                                                                ${(getTaxArray.reduce((acc, data) => acc + data.Amount, 0) + (editModeQuoteDetails.quote_items?.reduce((acc, v) => acc + (+v.quote_item_details.item_quantity * +v.quote_item_details.quoted_sales_price), 0) || 0)).toFixed(2)}
                                                                                                                            </h4>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                }
                                                                                            </tbody>
                                                                                )
                                                                            }
                                                                        </Table>
                                                                    )}
                                                                </FieldArray>
                                                            </Form>
                                                        )
                                                    }}
                                                </Formik>
                                            )
                                        }
                                        {
                                            !isLoading && quoteDetails && quoteDetails?.quote_items && quoteDetails?.quote_items.length === 0 && !isEditMode && (
                                                <div className="d-flex justify-content-center align-items-center no-item-added-to-quote-info"><i className="bx bx-info-circle me-1" /> No items added to quote</div>
                                            )
                                        }
                                    </div>
                                    {/* notes */}
                                    {
                                        !isLoading && (
                                            <Row className=" mt-5">
                                                <div className="justify-content-between align-items-center">
                                                    <div className="mb-3">
                                                        {
                                                            isEditMode ? (
                                                                <h5 className="color-secondary">Notes</h5>
                                                            ) : !isEditMode && quoteDetails.job_notes !== null && quoteDetails.job_notes !== "" && quoteDetails.job_notes !== '<p><br></p>' ? (
                                                                <h5 className="color-secondary">Notes</h5>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            !isLoading && isEditMode && (<RichTextEditor editorClassName="editor custom-editor"
                                                                editorStyle={{ fontSize: "13px", fontFamily: "Poppins, sans-serif", maxHeight: '170px', overflow: 'scroll' }}
                                                                placeholder="Enter quote notes"
                                                                value={getJobNotes}
                                                                onChange={(e) => { setJobNotes(e) }} />)
                                                        }
                                                        {
                                                            !isLoading && !isEditMode && (
                                                                (
                                                                    <div dangerouslySetInnerHTML={{ __html: getJobNotes.toString('html') }}></div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    isEditMode && (
                                                        <div className="d-flex justify-content-end">
                                                            <button className="btn me-3 btn-outline" type="button" onClick={() => {
                                                                setisEditMode(false); setEditModeQuoteDetails(prevData => {
                                                                    const updatedArray = quoteDetails.quote_items
                                                                    Subtotal(updatedArray);
                                                                    setJobNotes(RichTextEditor.createValueFromString(prevData?.job_notes === null ? '' : prevData?.job_notes, 'html'));
                                                                    return { ...prevData, quote_items: updatedArray }
                                                                });
                                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                                                setisSaveQuoteIsLoading(false);
                                                            }}>cancel</button>
                                                            <button className="btn-fill  btn d-flex align-items-center" type="button" disabled={getJobName === '' || getJobName?.length > 40 || !isValidPreviewForm || ((showDropdown === undefined || !showDropdown) && editModeQuoteDetails.quote_client_details === '') || (!quoteDetails.client_name) && (!quoteDetails.client_company_name && (!editModeQuoteDetails.quote_client_details || editModeQuoteDetails.quote_client_details === ''))} onClick={() => { onClickSaveQuote(); }}>{isQuoteSaveIsLoading ? <Spinner color="light" className='me-1' /> : ''} save</button>
                                                        </div>
                                                    )
                                                }
                                            </Row>
                                        )
                                    }
                                    {/* Modal for image preview */}
                                    <ImagePreviewModal imgSrc={previewImage} onCloseClick={() => setPreviewImage(null)}></ImagePreviewModal>
                                </CardBody>
                                {(isLoading || isDownloadLoading) && <Spinner className='me-2 position-absolute preview-quote-spinner' />}
                                {/* {(getMasterTableLoadingStatus.isSetUpIsLoading || getMasterTableLoadingStatus.isStitchLoading || getMasterTableLoadingStatus.isTechnicleMasterListIsLoading || getMasterTableLoadingStatus.isgarmentLoading) && <Spinner className='me-2 position-absolute preview-quote-spinner' />} */}
                                {(loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.EMBROIDERY ?
                                    (
                                        getMasterTableLoadingStatus.isSetUpIsLoading || getMasterTableLoadingStatus.isStitchLoading || getMasterTableLoadingStatus.isTechnicleMasterListIsLoading || getMasterTableLoadingStatus.isgarmentLoading
                                    ) : loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING ?
                                        (
                                            getPrintingMasterTableLoadingStatus.isGarmentLoading || getPrintingMasterTableLoadingStatus.isInkLoading || getPrintingMasterTableLoadingStatus.isArtLoading || getPrintingMasterTableLoadingStatus.isJobCriteriaLoading || getPrintingMasterTableLoadingStatus.isSetuupLoading
                                        ) : (
                                            getMasterTableLoadingStatus.isSetUpIsLoading || getMasterTableLoadingStatus.isStitchLoading || getMasterTableLoadingStatus.isTechnicleMasterListIsLoading || getMasterTableLoadingStatus.isgarmentLoading ||
                                            getPrintingMasterTableLoadingStatus.isGarmentLoading || getPrintingMasterTableLoadingStatus.isInkLoading || getPrintingMasterTableLoadingStatus.isArtLoading || getPrintingMasterTableLoadingStatus.isJobCriteriaLoading || getPrintingMasterTableLoadingStatus.isSetuupLoading
                                        ) &&
                                        <Spinner className='me-2 position-absolute preview-quote-spinner' />
                                )}
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmationModal
                    show={confirmationModal}
                    onCloseClick={() => setConfirmationModal(false)}
                    title={isModal === 'Delete item' ? 'Delete item' : isModal === 'Sent quote' ? 'Send quote' : isModal === 'Delete quote' ? 'Delete quote' : isModal === 'Approved quote' && currentStatus !== 'approved' ? 'Mark as approved' : isModal === 'Approved quote' && currentStatus === 'approved' ? 'Mark as unapproved' : ''}
                    description={isModal === 'Delete item' ? 'Are you sure you want to delete this quote item?' : isModal === 'Sent quote' ? '' : isModal === 'Delete quote' ? 'Are you sure you want to delete this quote?' : isModal === 'Approved quote' && currentStatus !== 'approved' ? 'Are you sure you want to mark this quote as approved?' : isModal === 'Approved quote' && currentStatus === 'approved' ? 'Are you sure you want to mark this quote as unapproved?' : ''}
                    onAction={() => isModal === 'Delete item' ? isDeleteHandle() : isModal === 'Sent quote' ? isSendHandle() : isModal === 'Approved quote' ? isApprovedHandle() : isDeleteQuoteHandle()}
                    isSendQuote={isSendQuote}
                    afterAction={afterAction}
                    actionName={isModal === 'Sent quote' ? 'Send' : isModal === 'Approved quote' && currentStatus !== 'approved' ? 'Approve' : isModal === 'Approved quote' && currentStatus === 'approved' ? 'Unapprove' : 'Delete'}
                    quote={quoteDetails}
                />
            </div>
            {/* toast message */}
            <ToastContainer />
        </React.Fragment >
    );
}

export default PreviewQuote;