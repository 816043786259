import { Row, Modal, ModalBody, ModalHeader, Alert, UncontrolledTooltip } from "reactstrap";
import { useFormik } from "formik";
import { UPDATE_BANK_ACCOUNT } from "../../static/constants/constant";
import * as Yup from 'yup';
import Spinners from './spinner';
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import '../../../shopOwnerGetPaid/component/getPaid/getPaid.scss';
import { ShopOwnerGetPaid } from "../../../shopOwnerGetPaid/service/ShopOwnerGetPaid.service";

const ChangeLinkedAccount = ({ show, onCloseClick, title, actionName, isAccountUpdated }) => {
    const shopOwnerGetPaidService = new ShopOwnerGetPaid();

    const [isLoading, setLoading] = useState(false);

    const bankAccountDetail = useFormik({
        initialValues: {
            account_holder_name: '',
            account_number: '',
            country: '',
            routing_number: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            account_holder_name: Yup.string().matches(/^[a-zA-Z\s]+$/, UPDATE_BANK_ACCOUNT.ERR_INVALID_ACCOUNT_HOLDER_NAME),
            account_number: Yup.number().required(UPDATE_BANK_ACCOUNT.ERR_ACCOUNT_NUMBER_REQUIRED).min(100000000, UPDATE_BANK_ACCOUNT.ERR_INVALID_ACCOUNT_NUMBER).max(999999999999999999, UPDATE_BANK_ACCOUNT.ERR_INVALID_ACCOUNT_NUMBER),
            country: Yup.string().required(UPDATE_BANK_ACCOUNT.ERR_COUNTRY_CODE_REQUIRED).matches(/^[A-Z]{2}$/, UPDATE_BANK_ACCOUNT.ERR_INVALID_COUNTRY_CODE),
            routing_number: Yup.string()
                .matches(/^\d{9}$/, UPDATE_BANK_ACCOUNT.ERR_INVALID_ROUTING_NUMBER)
        }),
        onSubmit: (values) => {
            const userLoginData = JSON.parse(sessionStorage.getItem('userLogin'))?.data.user_uuid;
            values.user_uuid = userLoginData;
            setLoading(true);
            ChangeLinkedAccount.updateAccount(values).then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    isAccountUpdated(true);
                    onCloseClick();
                }
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                setLoading(false);
            })
        },
    });

    useEffect(() => {
        if (show) {
            bankAccountDetail.resetForm();
        }
    }, [show]);

    return (
        <Modal className='delete-customer-modal' size="md" isOpen={show} centered={true}>
            {/* header */}
            <ModalHeader toggle={() => onCloseClick()} tag="h4">
                {title}
            </ModalHeader>
            <div className="modal-content">
                <ModalBody>
                    <Alert className="alert-message alert-info">
                        <div className="d-flex">
                            <div className="mt-2">
                                <i className="bi bi-info-circle me-2  " />
                            </div>
                            <div>
                                <p className="mb-0 fw-bold">Printshop will never share your bank account information with third parties.</p>
                                <small>By using this feature, you authorize Printshop to show your invoice recipients your bank account information.</small>
                            </div>
                        </div>
                    </Alert>
                    <form onSubmit={bankAccountDetail.handleSubmit}>
                        <Row>
                            <div className="mb-3">
                                <label htmlFor="account_holder_name" className="form-label">Account holder name</label>
                                <input
                                    type="text"
                                    id="account_holder_name"
                                    name="account_holder_name"
                                    placeholder='Enter account holder name'
                                    className={`form-control ${bankAccountDetail.touched.account_holder_name && bankAccountDetail.errors.account_holder_name ? 'is-invalid' : ''}`}
                                    onChange={bankAccountDetail.handleChange}
                                    onBlur={bankAccountDetail.handleBlur}
                                    value={bankAccountDetail.values.account_holder_name}
                                    onKeyDown={(e) => {
                                        // Check if the pressed key is a number
                                        if (/\d/.test(e.key)) {
                                            e.preventDefault(); // Prevent typing the number
                                        }
                                    }}
                                />
                                {bankAccountDetail.touched.account_holder_name && bankAccountDetail.errors.account_holder_name && <div className="invalid-feedback">{bankAccountDetail.errors.account_holder_name}</div>}
                            </div>
                            <div className="mb-3 ">
                                <label htmlFor="account_number" className="form-label">Account number <span className="text-danger">*</span> </label>
                                <NumericFormat
                                    onKeyDown={(e) => {
                                        if (e.key === '-' || e.keyCode === 189) {
                                            e.preventDefault();
                                        }
                                    }}
                                    id="account_number"
                                    className={`form-control ${bankAccountDetail.touched.account_number && bankAccountDetail.errors.account_number ? 'is-invalid' : ''}`}
                                    value={bankAccountDetail.values.account_number}
                                    placeholder="Enter account number"
                                    onChange={bankAccountDetail.handleChange}
                                    onBlur={bankAccountDetail.handleBlur}
                                />
                                {bankAccountDetail.touched.account_number && bankAccountDetail.errors.account_number && <div className="invalid-feedback">{bankAccountDetail.errors.account_number}</div>}
                            </div>
                            <div className="mb-3 ">
                                <label htmlFor="country">Country code  <span className="text-danger pe-1">*</span><i className="bi bi-info-circle me-1 cursor-pointer text-dark" id="country_code_info" /> </label>
                                <UncontrolledTooltip target={'country_code_info'} placement="bottom">
                                    Enter a 2-digit country code. For example, 'US' for the United States
                                </UncontrolledTooltip>
                                <input
                                    id="country"
                                    className={`form-control ${bankAccountDetail.touched.country && bankAccountDetail.errors.country ? 'is-invalid' : ''}`}
                                    placeholder="Enter country code"
                                    value={bankAccountDetail.values.country}
                                    onChange={bankAccountDetail.handleChange}
                                    onBlur={bankAccountDetail.handleBlur}
                                    onKeyDown={(e) => {
                                        // Check if the pressed key is a number
                                        if (/\d/.test(e.key)) {
                                            e.preventDefault(); // Prevent typing the number
                                        }
                                    }}
                                />
                                {bankAccountDetail.touched.country && bankAccountDetail.errors.country && <div className="invalid-feedback">{bankAccountDetail.errors.country}</div>}
                            </div>
                            <div className="mb-3 ">
                                <label htmlFor="routing_number">Routing number </label>
                                <NumericFormat
                                    onKeyDown={(e) => {
                                        if (e.key === '-' || e.keyCode === 189) {
                                            e.preventDefault();
                                        }
                                    }}
                                    id="routing_number"
                                    className={`form-control ${bankAccountDetail.touched.routing_number && bankAccountDetail.errors.routing_number ? 'is-invalid' : ''}`}
                                    placeholder="Enter routing number"
                                    value={bankAccountDetail.values.routing_number}
                                    onChange={bankAccountDetail.handleChange}
                                    onBlur={bankAccountDetail.handleBlur}
                                />
                                {bankAccountDetail.touched.routing_number && bankAccountDetail.errors.routing_number && <div className="invalid-feedback">{bankAccountDetail.errors.routing_number}</div>}
                            </div>
                        </Row>
                        {/* footer */}
                        <div className="hstack justify-content-end mb-0">
                            <button type="button" className={`${title === 'Confirmation' ? 'btn btn-outline' : 'btn-delete-cancel'}  me-3`} onClick={onCloseClick}>Cancel</button>
                            <button type="submit" className={`btn btn-fill  d-flex`} disabled={!bankAccountDetail.isValid || bankAccountDetail.values.account_number === '' || bankAccountDetail.values.country === ''}>
                                {isLoading ? <Spinners setLoading={setLoading} /> : ''}
                                {actionName}
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </div>
        </Modal>
    )
}

export default ChangeLinkedAccount
