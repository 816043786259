import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import Switch from "react-switch";
import { CALCULATOR_TYPE } from '../../static/constants/constant';
import './quoteCalcTabView.scss';

const QuoteCalcTabView = (props) => {
    const [customActiveTab, setCustomActiveTab] = useState(CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR);
    const [checked, setChecked] = useState(true);

    // for switch toggle
    const handleChange = nextChecked => {
        props.onToggle(nextChecked);
        setChecked(nextChecked);
    };

    // for tab change
    const toggleCustom = (tab) => {
        props.activeTabView(tab);
        if (customActiveTab !== tab) setCustomActiveTab(tab);
    };

    return (
        <Row className={`${props.toggleView ? 'quote-calc-tab-view' : ''}`}>
            <Col className="col-12 mt-0 mt-sm-2  mb-2">
                <div className={`page-title-box d-block d-sm-flex align-items-center justify-content-between ${props.toggleView ? 'pb-0' : ''} `}>
                    <Nav tabs className=" nav-justified custom-quote-cal-tab">
                        <NavItem id={customActiveTab === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR ? "step1" : undefined} >
                            <NavLink

                                style={{ cursor: "pointer" }}
                                className={`nav-link ${customActiveTab === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR ? 'active-tab' : ''}`}
                                onClick={() => {
                                    if (customActiveTab !== CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR) {
                                        toggleCustom(CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR);
                                    }
                                }}
                            >
                                <span className="">Embroidery Calculator</span>
                            </NavLink>
                        </NavItem>
                        <NavItem id={customActiveTab === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR ? "step1" : undefined}>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={`nav-link ${customActiveTab === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR ? 'active-tab' : ''}`}
                                onClick={() => {
                                    if (customActiveTab !== CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR) {
                                        toggleCustom(CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR);
                                    }
                                }}
                            >
                                <span className="">Screen printing Calculator</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    {/* Quick Tour and Switch */}
                    <div className="page-title-right py-1">
                        <ol className={`breadcrumb m-0 d-flex align-items-center position-relative tab-view-quick-tour mt-2 mt-sm-0`}>
                            {/* quick tour button */}
                            {
                                props.quickTour &&
                                <>
                                    <i className="bx bx-info-circle me-1 quick-tour-icon" />
                                    <button type="button"
                                        color="success"
                                        className='py-1 quick-tour-btn pe-1 ps-3'
                                        onClick={props.handleQuickTour}>
                                        Quick Tour
                                    </button>
                                </>
                            }
                            {/* switch */}
                            {
                                props.toggleView &&
                                <Switch className="d-none d-md-block ms-3" onChange={handleChange}
                                    height={40}
                                    width={70}
                                    checked={checked}
                                    uncheckedIcon={
                                        <i className="bx bx-grid-vertical switch-icon"></i>
                                    }
                                    checkedIcon={
                                        <i className="bx bx-grid-horizontal switch-icon"></i>
                                    }
                                    handleDiameter={28}
                                />
                            }
                        </ol>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

QuoteCalcTabView.propTypes = {
    quickTour: PropTypes.bool,
    toggleView: PropTypes.bool,
    onToggle: PropTypes.func,
    handleQuickTour: PropTypes.func,
};

export default QuoteCalcTabView;
