import { useParams } from "react-router-dom";
import { ShopOwnerGetPaid } from "../../shopOwnerGetPaid/service/ShopOwnerGetPaid.service";
import React, { useState } from "react";
import { Container, Card, CardBody, Spinner } from "reactstrap";
import './ShopOwnerStripeConnectReturn.scss';
import { toast, ToastContainer } from "react-toastify";

const ShopOwnerStripeConnectReturn = () => {
    const ShopOwnerGetPaidService = new ShopOwnerGetPaid();
    const { user_uuid } = useParams();
    const [accountStatus, setAccountStatus] = useState();

    const getAccountStatus = () => {
        ShopOwnerGetPaidService.verifyStripeAccountStatus(user_uuid).then(response => {
            if (response.status === 200) {
                setAccountStatus(response.data.status);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.errors[0]?.message, { autoClose: 4000 });
        });
    }

    useState(() => {
        setAccountStatus(undefined);
        setTimeout(() => {
            getAccountStatus();
        }, 25000);
    }, []);

    return (
        <React.Fragment>
            {
                accountStatus === undefined ? (
                    <Spinner className='me-2 position-absolute download-quote-spinner' />
                ) : accountStatus === 'completed' ? (
                    <Container fluid className="px-0 payment-return">
                        <div className="animation-container">
                            <Card className="animation_card">
                                <CardBody className="animation_card_body">
                                    <div className="text-left">
                                        <i className="mdi-bank-check mdi payment_status_image text-success" />
                                        <h1 className="text-left fw-bold mb-2 mt-3  ps-1">Account set-up has been completed</h1>
                                        <p className="ps-1">Feel free to close this tab and return to your previous one.</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </Container>
                ) : (
                    <Container fluid className="px-0 payment-return">
                        <div className="animation-container">
                            <Card className="animation_card">
                                <CardBody className="animation_card_body">
                                    <div className="text-left">
                                        <i className="mdi-bank-plus mdi payment_status_image text-danger " />
                                        <h1 className="text-left fw-bold mb-2 mt-3  ps-1">Oh no, your account set-up has been failed</h1>
                                        <p className="ps-1">Could you return to your previous tab and try again?</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </Container>
                )
            }
            <ToastContainer />
        </React.Fragment>
    )
}

export default ShopOwnerStripeConnectReturn;