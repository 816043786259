// import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import withRouter from "../shared/components/common/withRouter";
import { changeSidebarType } from "../../store/actions";
// Layout Related Components
import Header from "./header";
import Sidebar from "./sidebar";
import SubscriptionMiddleware from "../shared/components/common/subscriptionMiddleware";
//redux
import { useDispatch } from "react-redux";
import { layoutModeTypes, layoutWidthTypes, leftBarThemeImageTypes, leftSideBarThemeTypes, leftSidebarTypes, topBarThemeTypes } from "../shared/static/constants/layout";
import { authenticatedUserInfo } from '../../../src/atoms';
import { useAtom } from 'jotai';
import axios from "axios";
import 'react-loading-skeleton/dist/skeleton.css';
import { UserProvider } from '../profile/component/userContext';
import { useNavigate } from "react-router-dom";

const Layout = props => {
  const dispatch = useDispatch();
  // for middleware
  const [showMiddlewareModal, setShowMiddlewareModal] = useState(false);
  const [middlewareMessage, setMiddlewareMessage] = useState('');

  const [getUserInfo, setUserInfo] = useAtom(authenticatedUserInfo);

  // axios http interceptor for request 
  axios.interceptors.request.use((request) => {
    if (request.headers) {
      if (getUserInfo !== null) {
        request.headers['Authorization'] = getUserInfo.token;
      }
    }
    return request;
  }, (error) => {
    return Promise.reject(error);
  });

  // axios http interceptor for response
  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    // when unauthorized or not found , redirect to login page
    if (error.response.status === 401 || error.response.status === 404) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/login";
    } else if (error.response.status === 403) {
      setShowMiddlewareModal(true);
      setMiddlewareMessage(error.response.data.errors[0].message);
    }
    return error
  });

  const selectLayoutProperties = {
    isPreloader: false,
    layoutModeType: layoutModeTypes.LIGHT,
    leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
    leftSideBarType: leftSidebarTypes.DEFAULT,
    layoutWidth: layoutWidthTypes.FLUID,
    topbarTheme: topBarThemeTypes.LIGHT,
    showRightSidebar: false,
    leftSideBarTheme: leftSideBarThemeTypes.LIGHT
  };

  const { isPreloader, leftSideBarThemeImage, layoutWidth, leftSideBarType, topbarTheme, showRightSidebar, leftSideBarTheme, layoutModeType } = selectLayoutProperties;

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const navigate = useNavigate();

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);

  return (
    // <React.Fragment>
    <UserProvider>
      <div id="layout-wrapper">
        {(window.location.pathname !== '/pricing' && window.location.pathname !== '/pay-subscription') && <> <Header toggleMenuCallback={toggleMenuCallback} />
          <Sidebar
            theme={leftSideBarTheme}
            type={leftSideBarType}
            isMobile={isMobile}
          /> </>}
        <div className={`main-content ${(window.location.pathname === '/pricing' || window.location.pathname === '/pay-subscription') ? 'ms-0' : ''}`}>{props.children}</div>
      </div>
      <SubscriptionMiddleware
        title={'Upgrade subscription plan'}
        show={showMiddlewareModal}
        description={middlewareMessage}
        onclickcancle={() => {
          setShowMiddlewareModal(false);
          sessionStorage.clear();
          setUserInfo(null);
          window.location.reload();
        }}
        isFromAuthGuard={false}
        closeModal={() => {
          setShowMiddlewareModal(false);
        }}
      />
    </UserProvider>
  );
};

// Layout.propTypes = {
//   changeSidebarType: PropTypes.func,
//   children: PropTypes.any,
//   isPreloader: PropTypes.any,
//   layoutWidth: PropTypes.any,
//   leftSideBarTheme: PropTypes.any,
//   leftSideBarThemeImage: PropTypes.any,
//   leftSideBarType: PropTypes.any,
//   location: PropTypes.object,
//   topbarTheme: PropTypes.any,
// };

export default withRouter(Layout);
