// for quote status
const PeopleType = {
    All: 'All Types',
    Lead: 'Lead',
    Client: 'Client',
    Other: 'Other',
}

export {
    PeopleType
}