import { Modal, ModalHeader, ModalBody, Row, Col, Label } from "reactstrap";
import 'flatpickr/dist/plugins/monthSelect/style.css';
import {  paymentMessage } from "../../static/constants/constant";
import {  useState } from "react";
import './creditcardPopup.scss';
import { CustomerService } from "../../../customers/service/customer.service";
import Spinners from './spinner';
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useStripe, useElements, Elements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { config } from "../../services/api.config";

// live one test key
const stripePromise = loadStripe(config.stripe.key);

const CheckOutForm = ({ show, oncloseClick, onSubmit, isEndClient, payableAmount, invoiceID, userID }) => {
    const stripe = useStripe();
    const elements = useElements();
    const customerservice = new CustomerService();
    const [isLoading, setisLoading] = useState(false);

    const [error, setError] = useState();
    const handleSubmit = async (event) => {
        event.preventDefault();
        setisLoading(true)
        if (!stripe || !elements) {
            return;
        }

        const { token, error } = await stripe.createToken(elements.getElement(CardNumberElement));

        if (error) {
            setError(error.message)
            setisLoading(false)
        } else {
            setError();
            setisLoading(true);
            if (isEndClient) {
                const client_payment_info = {
                    'invoice_uuid': invoiceID,
                    'client_uuid': userID,
                    'card_token': token.id,
                    'payment_amount': parseFloat(parseFloat(payableAmount).toFixed(2))
                }
                customerservice.payInvoice(client_payment_info).then((response) => {
                    if (response.status === 200) {
                        oncloseClick();
                        onSubmit({ status: 'done', message: paymentMessage.INVOICE_PAYMENT_SUCCESSFULL });
                        setTimeout(() => {
                            setisLoading(false);
                        }, 1000);
                    } else {
                        oncloseClick();
                        toast.error(response.response.data.errors[0].message, { autoClose: 4000 });
                        onSubmit({ status: 'error', message: response.response.data.errors[0].message, card_number: token.card.last4, card_type: token.card.brand });
                        setTimeout(() => {
                            setisLoading(false);
                        }, 1000);
                    }
                }).finally((error) => {
                    setisLoading(false);
                });
            } else {
                const client_card_info = {
                    user_uuid: JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid,
                    card_token: token.id
                };
                setisLoading(true);
                customerservice.createCustomerCardPayment(client_card_info).then((response) => {
                    if (response.status === 200) {
                        customerservice.customerCardPaymentMethod(client_card_info.user_uuid).then((response) => {
                            if (response.status === 200) {
                                oncloseClick();
                                onSubmit(response.data);
                                setTimeout(() => {
                                    setisLoading(false);
                                }, 1000);
                            }
                        });
                    } else {
                        toast.error(response.response.data.errors[0].message, { autoClose: 4000 });
                    }
                }).finally((error) => {
                    setisLoading(false);
                });
            }

        }
    };


    return (
        <span>
            <Modal className='add-card-modal ' size="md" isOpen={show} centered={true}>
                <ModalHeader toggle={oncloseClick} tag="h4" className="border-0 ">
                    Credit/Debit Card
                </ModalHeader>
                <div className="border-0">
                    <ModalBody className="pt-0">
                        <form onSubmit={handleSubmit}>
                            <Row className="mb-4">
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex card-icon-container justify-content-center align-items-center lign-items-center me-2">
                                        <img src="/assets/images/visa.svg" alt="visa card logo" className="card_logo" />
                                        <img src="/assets/images/mastercard.svg" alt="master card logo" className="card_logo" />
                                        <img src="/assets/images/unionpay.svg" alt="union pay logo" className="card_logo" />
                                        <img src="/assets/images/american-express.svg" alt="american expres logo" className="card_logo" />
                                    </div>
                                    <div className="d-flex align-itens-center">
                                        <i className="bx bx-lock-alt text-success position-relative payment-lock"></i>
                                        <div className="ms-2">
                                            <small className="secure-payment">Secure payment powered by stripe</small>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <Col >
                                    <Label>Card number <span className="text-danger">*</span> </Label>
                                    <CardNumberElement className="form-control" onChange={() => { setError() }} />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Label>Expiry date <span className="text-danger">*</span></Label>
                                    <CardExpiryElement className="form-control" onChange={() => { setError() }} />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Label>CVC <span className="text-danger">*</span></Label>
                                    <CardCvcElement className="form-control" onChange={() => { setError() }} />
                                </Col>
                            </Row>
                            {
                                isEndClient && (
                                    <Row className="">
                                        <Col className="text-start payable-amountInfo">
                                            <p className="mb-0">You will be paying a total of <span className="fw-bold">${payableAmount}</span> </p>
                                        </Col>
                                    </Row>
                                )
                            }
                            <Row >
                                <Col>
                                    {error && <div className={`error-message ${isEndClient ? 'mt-3' : ''} `}>{error}</div>}
                                </Col>
                            </Row>
                            <div className="hstack justify-content-end mb-0 mt-3">
                                {
                                    !isEndClient && (
                                        <button type="button" className="btn-outline me-3 " onClick={oncloseClick} >Cancel</button>
                                    )
                                }
                                <button type="submit" className={`btn ${isEndClient ? 'w-100 justify-content-center ' : ''} btn-fill d-flex`} disabled={!stripe} >
                                    {isLoading ? <Spinners setLoading={setisLoading} /> : ''}
                                    {isEndClient ? 'Pay now' : 'Save'}
                                </button>
                            </div>
                        </form>
                    </ModalBody>
                </div>
            </Modal>
            {/* toast message */}
            <ToastContainer />
        </span>

    )
}

export default function CreditCardPopup({ show, oncloseClick, onSubmit, isEndClient, payableAmount, invoiceID, userID }) {


    return (
        <Elements stripe={stripePromise}>
            <CheckOutForm show={show} oncloseClick={oncloseClick} onSubmit={onSubmit} isEndClient={isEndClient} payableAmount={payableAmount} invoiceID={invoiceID} userID={userID} />
        </Elements>
    )
}

