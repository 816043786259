import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Table, UncontrolledTooltip, Label, Spinner, Modal, ModalHeader, ModalBody, Input, Form, FormFeedback, Alert } from "reactstrap";
// navigation
import { useParams } from "react-router-dom";
import { QuoteService } from "../../quotes/service/quote.service";
// image prefix
import { AWS_S3_URL } from "../../shared/static/constants/constant";
import ImagePreviewModal from "../../shared/components/common/imagePreviewModal";
import './standaloneQuotePreview.scss';
// loader
import Spinners from '../../shared/components/common/spinner';
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Regex, identityModalErr } from "../../shared/static/constants/constant";
import moment from 'moment';
import ConfirmationModal from "../../shared/components/common/confirmationModal";
import html2pdf from 'html2pdf.js';
import { ToastContainer, toast } from "react-toastify";
import { ImageURLTOBase64Convertor } from "../../shared/helpers/imageUrlToBase64";

const StandaloneQuotePreview = (props) => {
    const [quoteDetails, setQuoteDetails] = useState('');
    const { quoteID } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [previewImage, setPreviewImage] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState();
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [getTaxArray, setTaxArray] = useState([]);
    const [isMobileDevice, setisMobiledevice] = useState(false);

    const [statusConfirmationModal, setStatusConfirmationModal] = useState();
    const [isModal, setIsModal] = useState();
    const [afterAction, setAfterAction] = useState(false);

    // service
    const quoteService = new QuoteService();
    useEffect(() => {
        getIdentityInfo();
        getQuoteDetails();
    }, []);
    // for get identityInfo
    const getIdentityInfo = () => {
        quoteService.getIdentityInfo(quoteID).then((response) => {
            if (response.status === 200) {
                if (response.data !== null) {
                    setIsOpenModal(false);
                } else {
                    setIsOpenModal(true);
                }
            }
        });
    }
    // for get quote details
    const getQuoteDetails = () => {
        setLoading(true);
        quoteService.getQuoteDetailsByQuoteId(quoteID).then((response) => {
            setQuoteDetails(response.data);
            setLoading(false);
            // add isExpired in list
            response.data.isExpired = moment(response.data.quote_expires_at).isBefore(new Date());
            let taxIdentifier = [];
            response.data.quote_items.forEach((e, key) => {
                e.quote_item_details.quote_item_tax_details?.forEach((taxInfo) => {
                    const taxPercentage = taxInfo.tax_percentage;
                    const taxName = taxInfo.tax_name;

                    // Check if tax name and percentage are not null
                    if (taxPercentage !== null && taxName !== null) {
                        const taxAmount = (+e.quote_item_details.item_quantity * e.quote_item_details.quoted_sales_price) * (taxInfo.tax_percentage / 100);

                        // Find if there's already a tax with the same percentage in taxIdentifier
                        const existingTaxIndex = taxIdentifier.findIndex(tax => +tax.tax_percentage === +taxPercentage && tax.tax_name === taxName);

                        if (existingTaxIndex !== -1) {
                            // If tax with the same percentage already exists, add the amount to it
                            taxIdentifier[existingTaxIndex].Amount += taxAmount;
                        } else {
                            // Otherwise, add a new entry
                            taxIdentifier.push({
                                tax_name: taxName,
                                tax_percentage: taxPercentage,
                                Amount: taxAmount
                            });
                        }
                    }
                });
            });
            setTaxArray(taxIdentifier);
        }).catch(error => {
            toast.error(error?.response?.data?.errors[0]?.message, { autoClose: 4000 });
            setLoading(false);
        });
    }
    // for convert date to local
    const displayUtcToLocal = (utcDate) => {
        const date = new Date(utcDate);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Dynamic time zone based on user's location
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(localDate);
        return formattedDate;
    }

    // for preview image
    const handlePreviewImage = (imageSrc) => {
        setPreviewImage(imageSrc);
    };

    useEffect(() => {
        function handleResize() {
            setisMobiledevice(window.innerWidth <= 600 || window.innerHeight <= 600);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize); };
    }, []); 

    // Utility function to convert HTML string to PDF and download it
    const downloadPdf = async (htmlString, file_name) => {
        // Create a temporary container to hold the HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
         
        document.body.appendChild(tempDiv);

        // Find all images in the HTML and convert them to base64
        const images = tempDiv.querySelectorAll('img');
        const imagePromises = Array.from(images).map(async (img) => {
            const url = img.src;
            if (url.startsWith('http')) {
                const base64 = await ImageURLTOBase64Convertor.convertImageUrlToBase64(url);
                img.src = base64;
            }
        });

        // Wait for all images to be converted
        await Promise.all(imagePromises);

        // Set up options for html2pdf
        const opt = {
            margin: [0.2, 0.1, 0.2, 0.1],
            filename: file_name,
            html2canvas: {
                scale: 2,
            },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            enableLinks: true
        };

        // Convert the HTML to PDF
        try {
            await html2pdf().from(tempDiv).set(opt).save();
        } catch (error) {
            console.error("PDF generation error:", error);
        } finally {
            document.body.removeChild(tempDiv);
        }
    };

    // for download quote
    const onClickDownload = () => {
        setIsDownloadLoading(true);
        const quote = {
            "quote_uuid": quoteID
        }
        quoteService.downloadQuote(quote).then(async (response) => {
            if (response.status === 200) {
                setTimeout(async () => {
                    await downloadPdf(response.data , `quote_${quoteDetails?.job_name}_${quoteDetails.quote_number}.pdf`)
                    setIsDownloadLoading(false);
                }, 2000);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.errors[0]?.message, { autoClose: 4000 });
            setIsDownloadLoading(false);
        });
    }
    // on change status
    const onChangeStatus = (status) => {
        setIsModal(status);
        setAfterAction(false);
        setStatusConfirmationModal(true);
    }
    // confirm form
    const confirmationModal = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            email: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required(identityModalErr.ERR_NAME_REQUIRED)
                .matches(Regex.ONLY_CHAR, identityModalErr.ERR_NAME_INVALID),
            email: Yup.string().required(identityModalErr.ERR_EMAIL_REQUIRED)
                .email(identityModalErr.ERR_EMAIL_INVALID)
        }),
        onSubmit: (values) => {
            submitClientIdentity();
        },
    });
    const submitClientIdentity = () => {
        setLoading(true);
        const data = {
            "quote_uuid": quoteID,
            "client_name": confirmationModal.values.name,
            "client_email": confirmationModal.values.email
        }
        quoteService.submitClientIdentity(data).then((response) => {
            if (response.status === 200) {
                setLoading(false);
                setIsOpenModal(false);
            }
        });
    }

    // for change status
    const changeStatusHandle = () => {
        const data = {
            "quote_uuid": quoteID,
            "quote_status": isModal
        }
        quoteService.Change_quote_status(data).then((response) => {
            if (response.status === 200) {
                setAfterAction(true);
                getQuoteDetails();
            }
        });
    }

    return (
        <React.Fragment>
            <>
                <div className="standalone-component pt-0">
                    {< div className="">
                        <Container fluid className="px-0">
                            {/* Render Breadcrumb */}
                            <div className="align-items-center justify-content-between pb-0 px-3 mb-4 header-div d-block d-md-flex pt-2 pt-md-0">
                                <h4 className="my-2 font-size-13">
                                    <p className="job-name mb-1">{quoteDetails?.job_name}</p>
                                    {
                                        !isLoading && quoteDetails !== undefined && (<p className="from-name mb-0">From {quoteDetails.name} <span className="span-round"></span> {quoteDetails.quote_status === 'approved' ? 'Approved on' : 'Sent on'} {quoteDetails.quote_status !== 'approved' ? displayUtcToLocal(quoteDetails?.sent_on) : displayUtcToLocal(quoteDetails?.approved_on)}</p>)
                                    }
                                </h4>
                                <div className="page-title-right py-1">
                                    <ol className="breadcrumb m-0 d-flex align-items-center position-relative pe-0 justify-content-end">
                                        {(quoteDetails.quote_status !== 'approved' && quoteDetails.quote_status !== 'declined' && !quoteDetails.isExpired) && <>
                                            <button type="button" onClick={() => { onChangeStatus('approved'); }} className="download-btn me-3 py-1 btn btn-fill d-flex align-items-center">
                                                Approve
                                            </button>
                                            <button type="button" onClick={() => { onChangeStatus('declined'); }} className="decline-btn me-3 py-1 btn btn-outline d-flex align-items-center"
                                                disabled={quoteDetails.quote_status === 'approved'}>
                                                Decline
                                            </button>
                                        </>}
                                        <button type="button" onClick={() => { onClickDownload(); }} className="download-btn py-1 btn btn-outline d-flex align-items-center">
                                            {!isDownloadLoading ? <i className="bx bx-download me-2 download-icon" /> :
                                                <Spinner color="light" className='me-2' />
                                            }
                                            Download
                                        </button>
                                    </ol>
                                </div>
                            </div>
                            {/* mark as approved alert */}
                            {quoteDetails.quote_status === 'approved' &&
                                <Alert className="d-flex align-items-center mb-4 alert-message success-alert px-md-3 px-1">
                                    <i className="bx bx-check text-success me-2 check-icon"></i>
                                    <span>  You marked this quote as approved on {' '}
                                        <strong>
                                            {displayUtcToLocal(quoteDetails.approved_on)}.</strong></span>
                                </Alert>}
                            {
                                ((quoteDetails.isExpired && quoteDetails.quote_status !== 'approved') || quoteDetails.quote_status === 'declined') &&
                                <Alert color="danger" className="d-flex align-items-center mb-4 alert-message px-md-4 px-3">
                                    {quoteDetails.isExpired && quoteDetails.quote_status !== 'declined' ?
                                        <span span > This quote was expired on {' '}
                                            <strong>
                                                {displayUtcToLocal(quoteDetails.quote_expires_at)}.</strong></span> : <span> This quote has been marked as Declined.</span>
                                    }
                                </Alert>
                            }
                            <Row className="gx-0 mx-4 card-row">
                                <Col lg="12">
                                    <Card className="mb-4 preview-quote-card">
                                        <CardBody className="px-lg-5 px-3">
                                            <div className="preview-quote-title d-flex justify-content-between align-items-center">
                                                {/* shop logo image */}
                                                <div className="shop-logo d-flex align-items-center cursor-pointer">
                                                    {quoteDetails.shop_logo_url !== null && quoteDetails.shop_logo_url !== 'null' &&
                                                        <img src={AWS_S3_URL + quoteDetails.shop_logo_url} onClick={() => setPreviewImage(AWS_S3_URL + quoteDetails.shop_logo_url)} alt="logo-dark" className="logo-dark-element preview-quote-shop-logo" />
                                                    }
                                                </div>
                                                {/* quote id */}
                                                <div>
                                                    <h4 className="font-size-16 my-0 color-secondary mt-1">
                                                        Quote #{quoteDetails.quote_number}
                                                    </h4>
                                                </div>
                                            </div>
                                            <hr />
                                            <Row>
                                                {/* shop details */}
                                                <Col md="6">
                                                    <address className="mb-3 mb-md-5">
                                                        <p className="gray-text mb-3">From</p>
                                                        <p className="mb-2 name-font-weight">{quoteDetails?.name}</p>
                                                        <p className="mb-2">{quoteDetails.shop_name}</p>
                                                        <p className="mb-0">{quoteDetails.shop_address}</p>
                                                        <p className="mb-0">{quoteDetails.shop_city}{quoteDetails.shop_city && ','} {quoteDetails.shop_state}</p>
                                                        <p className="mb-2">{quoteDetails.shop_zip_code}{quoteDetails.shop_zip_code && ','} {quoteDetails.shop_country}</p>
                                                        <p className="mb-0">{quoteDetails.shop_contact_number}</p>
                                                        <p className="email-break mb-1">{quoteDetails.shop_email}</p>
                                                    </address>
                                                </Col>
                                                <Col md="6" className="d-flex justify-content-md-end ">
                                                    <address className="mb-5 to-section">
                                                        <p className="mb-3 gray-text">To</p>
                                                        <div className="d-flex justify-content-between border-0 ">
                                                            <div className="selected-client-name-container">
                                                                <p className="mb-1"><Label className="mb-0 me-1">Company Name:</Label> {quoteDetails.client_company_name}</p>
                                                                <p className="mb-1"><Label className="mb-0 me-1">Client Name:</Label>{quoteDetails?.client_name}</p>
                                                                <p className="mb-1 "> <Label className="mb-0 me-1">Email:</Label> {quoteDetails.client_email}</p>
                                                                {
                                                                    quoteDetails?.client_address && (
                                                                        <span className="pb-2">
                                                                            <Label className="mb-0 me-1">Address:</Label>
                                                                            {quoteDetails?.client_address}
                                                                        </span>
                                                                    )
                                                                }
                                                                {/* <p className="mb-0 me-1">Twsr</p>
                                                                <p className="mb-0 me-1">Test</p> */}
                                                                <p className="mb-0 me-1">{quoteDetails?.invoice_client_details?.client_city}{quoteDetails?.invoice_client_details?.client_city && ','} {quoteDetails?.invoice_client_details?.client_state}</p>
                                                                <p className="mb-0 me-1">{quoteDetails?.invoice_client_details?.client_zip_code}{quoteDetails?.invoice_client_details?.client_zip_code && ','} {quoteDetails?.invoice_client_details?.client_country}</p>
                                                            </div>
                                                        </div>
                                                        <p className={`mb-0 d-block pt-3`}> <Label className={`mb-0`}>Expires:</Label>
                                                            {!isLoading && quoteDetails !== undefined && quoteDetails?.quote_expires_at !== undefined && <span> {displayUtcToLocal(quoteDetails?.quote_expires_at)}</span>} </p>
                                                    </address>
                                                </Col>
                                                {/* quote table */}
                                                <div className="table-responsive px-2">
                                                    <Table className="table-nowrap preview-quote-table">
                                                        <thead>
                                                            <tr>
                                                                <th className={`table-th `}>Item</th>
                                                                <th className={`table-th `}>Name</th>
                                                                <th className={`table-th  text-center`}>Quantity </th>
                                                                <th className={`table-th text-end table-header-rate`}>Rate</th>
                                                                <th className={`text-end table-th`}>Amount</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            quoteDetails && quoteDetails?.quote_items && quoteDetails?.quote_items.length > 0 && (
                                                                <tbody>
                                                                    {quoteDetails?.quote_items?.map((e, key) => {
                                                                        return (<tr key={key}>
                                                                            <td className={`text-start table-data ${e.quote_item_details.quote_item_tax_details === null ? 'align-middle' : 'align-top'}  position-relative img-td`}>
                                                                                {(e.quote_item_details.item_image_url) &&
                                                                                    (<div className="preview-quote-img-preview-div d-flex justify-content-start">
                                                                                        <div className="image-preview cursor-pointer">
                                                                                            <img className="image-preview" src={`${AWS_S3_URL + e.quote_item_details.item_image_url}`} alt="Selected" onClick={() => handlePreviewImage(`${AWS_S3_URL + e.quote_item_details.item_image_url}`)} />
                                                                                        </div>
                                                                                    </div>)
                                                                                }
                                                                            </td>
                                                                            <td className={`table-data ${e.quote_item_details.quote_item_tax_details === null ? 'align-middle' : 'align-top'} invoice-name-description`}>
                                                                                <div className="invoice-data-container">
                                                                                    <p className="mb-0">
                                                                                        {e.quote_item_details?.item_name}
                                                                                    </p>
                                                                                    <p className="description mb-0 invoice-info">
                                                                                        {e.quote_item_details?.item_description}
                                                                                    </p>
                                                                                </div>
                                                                            </td>
                                                                            <td className={`text-center table-data ${e.quote_item_details.quote_item_tax_details === null ? 'align-middle' : 'align-top'}`}>
                                                                                {e.quote_item_details.item_quantity}
                                                                            </td>
                                                                            <td className={` table-data text-end ${e.quote_item_details.quote_item_tax_details === null ? 'align-middle' : 'align-top'}`}>
                                                                                {'$' + e.quote_item_details.quoted_sales_price.toFixed(2)}
                                                                                {e.quote_item_details.quote_item_tax_details && e.quote_item_details.quote_item_tax_details.map((taxInfo, taxIndex) => (
                                                                                    <div className="d-flex justify-content-end" key={taxIndex}>
                                                                                        <div className="d-flex justify-content-between tax_detail_row">
                                                                                            {taxInfo.tax_name && (
                                                                                                <span id={`text_name${taxIndex}-${key}`} className={`mt-1 me-1 tax-display-quote-items-popup ${taxInfo.tax_name.length > (isMobileDevice ? 10 : 13) ? 'cursor-pointor tax-display-quote-items-elipsis' : ''}`}>{taxInfo.tax_name}</span>
                                                                                            )}
                                                                                            {taxInfo.tax_name && taxInfo.tax_name.length > (isMobileDevice ? 10 : 13) && (
                                                                                                <UncontrolledTooltip placement="bottom" target={`text_name${taxIndex}-${key}`}>{taxInfo.tax_name}</UncontrolledTooltip>
                                                                                            )}
                                                                                            {taxInfo.tax_percentage && (
                                                                                                <span className="mb-0 mt-1">{parseFloat(taxInfo.tax_percentage).toFixed(2)}%</span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </td>
                                                                            <td className={` text-end ${e.quote_item_details.quote_item_tax_details === null ? 'align-middle' : 'align-top'} table-data `}>
                                                                                {'$' + (e.quote_item_details.item_quantity * e.quote_item_details.quoted_sales_price).toFixed(2)}
                                                                                {e.quote_item_details.quote_item_tax_details && e.quote_item_details.quote_item_tax_details.map((taxInfo, index) => (
                                                                                    taxInfo.tax_percentage && (
                                                                                        <div className="mt-1 text-end" key={index}>
                                                                                            <span className="text-end">${((e.quote_item_details.item_quantity * e.quote_item_details.quoted_sales_price) * (taxInfo.tax_percentage / 100)).toFixed(2)}</span>
                                                                                        </div>
                                                                                    )
                                                                                ))}
                                                                            </td>
                                                                        </tr>)
                                                                    })}
                                                                    <tr >
                                                                        <td className="border-0" colSpan={5}>
                                                                            <div className="d-flex justify-content-end mt-3">
                                                                                <div className="d-flex justify-content-between subtotal-container">
                                                                                    <div>
                                                                                        <div className=" mb-2">
                                                                                            <span >Subtotal</span>
                                                                                        </div>
                                                                                        {getTaxArray.map((data, index) => (
                                                                                            data.tax_name && data.tax_percentage && (
                                                                                                <div className="my-2" key={index}>
                                                                                                    <span>{data.tax_name + '(' + data.tax_percentage.toFixed(2) + '%)'}</span>
                                                                                                </div>
                                                                                            )
                                                                                        ))}
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className="m-0 text-end">
                                                                                            ${isLoading ? 0.00 :
                                                                                                Number(
                                                                                                    quoteDetails.quote_items?.reduce((a, v) => {
                                                                                                        const itemQuantity = +v.quote_item_details.item_quantity;
                                                                                                        const quotedSalesPrice = +v.quote_item_details.quoted_sales_price;
                                                                                                        return a + (itemQuantity * quotedSalesPrice);
                                                                                                    }, 0).toFixed(2)
                                                                                                ).toFixed(2)
                                                                                            }
                                                                                        </p>
                                                                                        <p className="text-end mb-0">
                                                                                            {
                                                                                                isLoading ? '00' : getTaxArray.map((data) => (
                                                                                                    <div className="my-2">${data.Amount.toFixed(2)}</div>
                                                                                                ))
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex justify-content-end mt-2">
                                                                                <div className="d-flex justify-content-between total-container">
                                                                                    <div>
                                                                                        <strong className="color-secondary mt-3">Total</strong>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h4 className="total-value color-secondary">
                                                                                            ${(getTaxArray.reduce((acc, data) => acc + data.Amount, 0) + (quoteDetails.quote_items?.reduce((acc, v) => acc + (+v.quote_item_details.item_quantity * +v.quote_item_details.quoted_sales_price), 0) || 0)).toFixed(2)}
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                        }
                                                    </Table>
                                                    {
                                                        !isLoading && quoteDetails && quoteDetails?.quote_items && quoteDetails?.quote_items.length === 0 && (
                                                            <div className="d-flex justify-content-center align-items-center no-item-added-to-quote-info"><i className="bx bx-info-circle me-1" /> No items added to quote</div>
                                                        )
                                                    }
                                                </div>
                                                {/* notes */}
                                                {
                                                    !isLoading && quoteDetails && quoteDetails.job_notes !== null && quoteDetails.job_notes !== '<p><br></p>' && (
                                                        <div className="mt-5">
                                                            <div className="d-sm-flex d-block justify-content-between align-items-center">
                                                                <div className="mb-3">
                                                                    <h5 className="color-secondary">Notes</h5>
                                                                    <div dangerouslySetInnerHTML={{ __html: quoteDetails === undefined ? '' : quoteDetails?.job_notes.toString('html') }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </Row>
                                            {/* Modal for image preview */}
                                            <ImagePreviewModal imgSrc={previewImage} onCloseClick={() => setPreviewImage(null)}></ImagePreviewModal>
                                        </CardBody>
                                        {isLoading && <Spinner className='me-2 position-absolute preview-quote-spinner' />}
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>}
                    {/* for “Let us know your identity” pop-up */}
                    {(isOpenModal) && <Modal isOpen={isOpenModal} className="identity-modal my-0 h-100 d-flex align-items-center">
                        <ModalHeader tag="h4">
                            {"Let us know your identity"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    confirmationModal.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col>
                                        <div className="mb-3">
                                            <Label className="form-label">Name <span className='text-danger'>*</span></Label>
                                            <Input
                                                name="name"
                                                type="text"
                                                placeholder="Enter name"
                                                autoComplete='off'
                                                className="form-control"
                                                onChange={confirmationModal.handleChange}
                                                onBlur={confirmationModal.handleBlur}
                                                value={confirmationModal.values.name || ""}
                                                invalid={
                                                    confirmationModal.touched.name && confirmationModal.errors.name ? true : false
                                                }
                                            />
                                            {confirmationModal.touched.name && confirmationModal.errors.name ? (
                                                <FormFeedback type="invalid">{confirmationModal.errors.name}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Email <span className='text-danger'>*</span></Label>
                                            <Input
                                                name="email"
                                                type="email"
                                                placeholder="Enter email"
                                                autoComplete='off'
                                                className="form-control"
                                                onChange={confirmationModal.handleChange}
                                                onBlur={confirmationModal.handleBlur}
                                                value={confirmationModal.values.email || ""}
                                                invalid={
                                                    confirmationModal.touched.email && confirmationModal.errors.email ? true : false
                                                }
                                            />
                                            {confirmationModal.touched.email && confirmationModal.errors.email ? (
                                                <FormFeedback type="invalid">{confirmationModal.errors.email}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                {/* footer */}
                                <Row>
                                    <Col>
                                        <div className="d-flex justify-content-end">
                                            <button
                                                type="button"
                                                className="btn-fill save-user d-flex"
                                                disabled={!confirmationModal.isValid || !confirmationModal.dirty}
                                                onClick={() => submitClientIdentity()}
                                            >
                                                {isLoading ? <Spinners setLoading={setLoading} /> : ''}
                                                Continue
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>}

                    <ConfirmationModal
                        show={statusConfirmationModal}
                        onCloseClick={() => setStatusConfirmationModal(false)}
                        title={isModal === 'approved' ? 'Mark as approved' : isModal === 'declined' ? 'Decline' : ''}
                        description={isModal === 'approved' ? 'Are you sure you want to mark this quote as approved?' : isModal === 'declined' ? 'Are you sure you want to decline this quote?' : ''}
                        onAction={() => changeStatusHandle()}
                        afterAction={afterAction}
                        actionName={isModal === 'approved' ? 'Approve' : isModal === 'declined' ? 'Decline' : ''}
                        quote={quoteDetails}
                    />
                </div>
            </>
            {/* toast message */}
            <ToastContainer />
        </React.Fragment >
    )
}

export default StandaloneQuotePreview;