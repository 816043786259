import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, UncontrolledTooltip, Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import './addtax.scss';
import { NEW_TAX } from '../../static/constants/constant';
import { NumericFormat } from 'react-number-format';
import * as Yup from "yup";
import { useFormik } from "formik";
import ConfirmationModal from '../../../shared/components/common/confirmationModal';
import { QuoteService } from '../../../quotes/service/quote.service';
import Spinners from './spinner';

const TaxSelect = ({ options, updatedTaxInfo, shouldShowSelectedTax, componentName, customer_shop_tax_master_uuid, selectedTaxInfo, shouldRefreshSelectedTaxInfo, getAlreadyAddedTax }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSelectedDropdown, setShowSelectedDropSown] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [show, setshow] = useState();
    const [isModal, setIsModal] = useState();
    const [getTaxInfo, setTaxInfo] = useState(options);
    const [editTaxInfo, setEditTaxInfo] = useState();
    const [isEditMode, setisEditMode] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [afterAction, setAfterAction] = useState(false);
    const quoteService = new QuoteService();
    const [isEditLoading, setisEditIsloading] = useState(false);
    const [addedTaxForPreviewQuote, setAddedTaxForPreviewQuote] = useState(
        (componentName === 'previewQuote' ? getAlreadyAddedTax?.quote_item_tax_details : getAlreadyAddedTax?.invoice_item_tax_details)?.filter(
            tax => tax.tax_name !== null && tax.tax_percentage !== null
        ) || []
    );

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    useEffect(() => {
        setTaxInfo(options);
        if (componentName === 'previewQuote' || componentName === 'previewInvoice') {
            setSelectedOptions(getAlreadyAddedTax);
        }
    }, [options, componentName, getAlreadyAddedTax]);

    useEffect(() => {
        if (componentName === 'quote-calculator' && shouldRefreshSelectedTaxInfo === false) {
            let taxInfo = JSON.parse(localStorage.getItem('taxInfo') || '[]');
            setSelectedOptions(taxInfo);
        } else if (shouldRefreshSelectedTaxInfo === true && componentName === 'quote-calculator') {
            selectedTaxInfo([]);
            setSelectedOptions([]);
            localStorage.removeItem('taxInfo');
        }
    }, [shouldRefreshSelectedTaxInfo]);

    const handleOptionSelect = (option, quote_item_uuid) => {
        setShowDropdown(!showDropdown);
        if (componentName === 'previewQuote' || componentName === 'previewInvoice') {
            if (!addedTaxForPreviewQuote || !addedTaxForPreviewQuote.some((selectedOption) => selectedOption.customer_shop_tax_master_uuid === option.customer_shop_tax_master_uuid)) {
                setAddedTaxForPreviewQuote([...addedTaxForPreviewQuote, option]);
                selectedTaxInfo({ quote_item_uuid: quote_item_uuid, quote_item_tax_details: [...addedTaxForPreviewQuote, option] });
            }
        } else {
            if (!selectedOptions.some((selectedOption) => selectedOption.customer_shop_tax_master_uuid === option.customer_shop_tax_master_uuid)) {
                setSelectedOptions([...selectedOptions, option]);
                if (componentName === 'quote-calculator') {
                    localStorage.setItem('taxInfo', JSON.stringify([...selectedOptions, option]));
                    selectedTaxInfo([...selectedOptions, option]);
                }
            }
        }
        // Check if the option is already selected
        setInputValue('');
    };

    const handleRemoveOption = (optionToRemove, quote_item_uuid) => {
        if (componentName === 'previewQuote' || componentName === 'previewInvoice') {
            setAddedTaxForPreviewQuote(addedTaxForPreviewQuote.filter(option => option.customer_shop_tax_master_uuid !== optionToRemove.customer_shop_tax_master_uuid));
            selectedTaxInfo({ quote_item_uuid: quote_item_uuid, quote_item_tax_details: addedTaxForPreviewQuote.filter(option => option.customer_shop_tax_master_uuid !== optionToRemove.customer_shop_tax_master_uuid) });
        } else {
            setSelectedOptions(selectedOptions.filter(option => option.customer_shop_tax_master_uuid !== optionToRemove.customer_shop_tax_master_uuid));
            selectedTaxInfo(selectedOptions.filter(option => option.customer_shop_tax_master_uuid !== optionToRemove.customer_shop_tax_master_uuid));
            if (componentName === 'quote-calculator') {
                localStorage.setItem('taxInfo', JSON.stringify(selectedOptions.filter(option => option.customer_shop_tax_master_uuid !== optionToRemove.customer_shop_tax_master_uuid)))
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            tax_name: null,
            tax_percentage: null,
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            tax_name: Yup.string().required(NEW_TAX.ERR_TAX_NAME_REQUIRED).matches(/[^\d]/, NEW_TAX.ERR_INVALID_TAX_NAME),
            tax_percentage: Yup.number().required(NEW_TAX.ERR_TAX_RATE_REQUIRED).min(0, NEW_TAX.ERR_INVALID_TAX_VALUE).max(100, NEW_TAX.ERR_INVALID_TAX_MAX_LENGTH)
        }),
        onSubmit: (values, { resetForm }) => {
            if (isEditMode) {
                if (editTaxInfo.customer_shop_tax_master_uuid === undefined) {
                    const updateTaxInfo = getTaxInfo.map(tax => {
                        if (tax.id === editTaxInfo.id) {
                            return { ...tax, tax_name: formik.values.tax_name, tax_percentage: formik.values.tax_percentage }
                        }
                        return tax
                    });
                    if (componentName === 'userProfile' || componentName === 'editCustomer' || componentName === 'quote-calculator' || componentName === 'previewQuote' || componentName === 'previewInvoice') {
                        let editedTaxInfo = {
                            "customer_shop_tax_master_uuid": editTaxInfo.customer_shop_tax_master_uuid,
                            "tax_name": formik.values.tax_name,
                            "tax_percentage": formik.values.tax_percentage
                        }
                        quoteService.editTaxInfo(editedTaxInfo).then(response => {
                            if (response.status === 200) {
                                setisEditMode(false);
                                onCloseClick();
                                setConfirmationModal(false);
                                setAfterAction(true);
                                setTimeout(() => { setAfterAction(false); }, 100);
                                setTaxInfo(updateTaxInfo);
                                updatedTaxInfo(updateTaxInfo, false);
                                formik.resetForm();
                                if ((componentName === 'quote-calculator' || componentName === 'previewQuote') && selectedOptions.length !== 0) {
                                    let taxinfo = updateTaxInfo.filter(taxInfo =>
                                        selectedOptions.quote_item_tax_details.some(data =>
                                            data.customer_shop_tax_master_uuid === taxInfo.customer_shop_tax_master_uuid
                                        )
                                    );
                                    setSelectedOptions(taxinfo);
                                } else if (componentName === 'previewInvoice' && selectedOptions.length !== 0) {
                                    let taxinfo = updateTaxInfo.filter(taxInfo =>
                                        selectedOptions.invoice_item_tax_details.some(data =>
                                            data.customer_shop_tax_master_uuid === taxInfo.customer_shop_tax_master_uuid
                                        )
                                    );
                                    setSelectedOptions(taxinfo)
                                }
                            }
                        });
                    } else {
                        formik.resetForm();
                        setTaxInfo(updateTaxInfo);
                        updatedTaxInfo(updateTaxInfo);
                        setisEditMode(false);
                        onCloseClick();
                        setConfirmationModal(false);
                    }
                } else {
                    setConfirmationModal(true);
                    setIsModal('edit_tax');
                }
            } else {
                const newTaxInfo = [...getTaxInfo, { id: getTaxInfo.length + 1, tax_name: values.tax_name, tax_percentage: values.tax_percentage }];
                if (componentName !== 'previewQuote' || componentName !== 'previewInvoice') {
                    updatedTaxInfo(newTaxInfo);
                    setTaxInfo(newTaxInfo);
                }
                if (componentName === 'userProfile' || componentName === 'editCustomer' || componentName === 'quote-calculator' || componentName === 'previewQuote' || componentName === 'previewInvoice') {
                    newTaxInfo.forEach(element => { delete element.id });
                    newTaxInfo.forEach(element => { element.tax_percentage = +element.tax_percentage; });
                    let taxInfo = {
                        "customer_shop_info_uuid": customer_shop_tax_master_uuid,
                        "tax_items": newTaxInfo
                    }
                    setisEditIsloading(true);
                    quoteService.addTaxInfo(taxInfo).then(response => {
                        if (response.status === 200) {
                            quoteService.getTaxInfo(customer_shop_tax_master_uuid).then((response) => {
                                if (response.status === 200) {
                                    setTaxInfo(response.data);
                                    updatedTaxInfo(response.data);
                                    setisEditIsloading(false);
                                    onCloseClick();
                                }
                            })
                        }
                    })
                } else if (componentName === 'register') {
                    resetForm();
                    onCloseClick();
                }
            }
        },
    });

    const confirmEditTaxInfo = () => {
        const updateTaxInfo = getTaxInfo.map(tax => {
            if (tax.customer_shop_tax_master_uuid === editTaxInfo.customer_shop_tax_master_uuid) {
                return { ...tax, tax_name: formik.values.tax_name, tax_percentage: +formik.values.tax_percentage }
            }
            return tax;
        });
        let editedTaxInfo = {
            "customer_shop_tax_master_uuid": editTaxInfo.customer_shop_tax_master_uuid,
            "tax_name": formik.values.tax_name,
            "tax_percentage": formik.values.tax_percentage
        }
        quoteService.editTaxInfo(editedTaxInfo).then(response => {
            setIsModal();
            if (response.status === 200) {
                setisEditMode(false);
                onCloseClick();
                setTaxInfo(updateTaxInfo);
                updatedTaxInfo(updateTaxInfo, false);
                formik.resetForm();
                setAfterAction(true);
                setTimeout(() => { setAfterAction(false); }, 100);
                setConfirmationModal(false);
                if (componentName === 'quote-calculator' && selectedOptions.length !== 0) {
                    let taxinfo = updateTaxInfo.filter(taxInfo =>
                        selectedOptions.some(data =>
                            data.customer_shop_tax_master_uuid === taxInfo.customer_shop_tax_master_uuid
                        )
                    );
                    setSelectedOptions(taxinfo);
                } else if (componentName === 'previewQuote' && selectedOptions.quote_item_tax_details.length !== 0) {
                    let taxinfo = updateTaxInfo.filter(taxInfo =>
                        selectedOptions.quote_item_tax_details.some(data =>
                            data.customer_shop_tax_master_uuid === taxInfo.customer_shop_tax_master_uuid
                        )
                    );
                    setAddedTaxForPreviewQuote(taxinfo);
                } else if (componentName === 'previewInvoice' && selectedOptions.invoice_item_tax_details.length !== 0) {
                    let taxinfo = updateTaxInfo.filter(taxInfo =>
                        selectedOptions.invoice_item_tax_details.some(data =>
                            data.customer_shop_tax_master_uuid === taxInfo.customer_shop_tax_master_uuid
                        )
                    );
                    setAddedTaxForPreviewQuote(taxinfo)
                }
            }
        });
    }

    const onCloseClick = () => {
        setshow(false)
    }

    const isDeleteHandle = () => {
        if (editTaxInfo.customer_shop_tax_master_uuid === undefined) {
            setAfterAction(true);
            setTimeout(() => { setAfterAction(false); }, 100);
            let taxInfoWithDelete = getTaxInfo.filter((tax) => tax.id !== editTaxInfo.id);
            setTaxInfo(taxInfoWithDelete);
            updatedTaxInfo(taxInfoWithDelete);
        } else {
            setAfterAction(false);
            quoteService.deleteTaxInfo(editTaxInfo.customer_shop_tax_master_uuid).then(response => {
                if (response.status === 200) {
                    quoteService.getTaxInfo(customer_shop_tax_master_uuid).then((response) => {
                        if (response.status === 200) {
                            setTaxInfo(response.data);
                            setAfterAction(true);
                            let taxInfoWithDelete = response.data.filter((tax) => tax.customer_shop_tax_master_uuid !== editTaxInfo.customer_shop_tax_master_uuid);
                            updatedTaxInfo(taxInfoWithDelete, true);
                            setTimeout(() => { setAfterAction(false); }, 100);
                            setSelectedOptions(componentName === 'quote-calculator' ? selectedOptions.filter((tax) => tax.customer_shop_tax_master_uuid !== editTaxInfo.customer_shop_tax_master_uuid) : taxInfoWithDelete)
                        }
                    })
                }
            })
        }
    }

    return (
        <div id='step4' className={`add-tax-container ${componentName === 'quote-calculator' && selectedOptions?.length === 0 ? 'd-flex justify-content-start' : componentName === 'quote-calculator' && selectedOptions?.length !== 0 ? 'd-flex justify-content-between' : ''} ${componentName === 'previewQuote' && getAlreadyAddedTax !== undefined ? 'd-block' : ''}`}>
            {
                shouldShowSelectedTax && !showDropdown && (componentName === 'previewQuote' || componentName === 'previewInvoice') && (
                    <div className={`row mb-2 previewQuote-row `}>
                        {addedTaxForPreviewQuote && addedTaxForPreviewQuote && addedTaxForPreviewQuote.map((taxObj, optionIndex) => (
                            <div className={`align-items-center border p-3 mt-1 mb-1 me-1 previewQuote-selected-Dropdown justify-content-between d-flex`}>
                                <div>
                                    <p className='mb-0 text-start option-tax-name' id={`tax_name${optionIndex}-${getAlreadyAddedTax?.invoice_item_uuid}-${getAlreadyAddedTax?.quote_item_uuid}`}>{taxObj?.tax_name}</p>
                                    {
                                        taxObj?.tax_name?.length > 18 && (
                                            <UncontrolledTooltip target={`tax_name${optionIndex}-${getAlreadyAddedTax?.invoice_item_uuid}-${getAlreadyAddedTax.quote_item_uuid}`} style={{ background: 'black' }}>
                                                {taxObj.tax_name}
                                            </UncontrolledTooltip>
                                        )
                                    }
                                    <p className='mb-0'>{parseFloat(taxObj?.tax_percentage).toFixed(2)}%</p>
                                </div>
                                <div className='d-flex'>
                                    <button className='btn p-0 border-0' type='button'><i id={`delete${optionIndex}-${getAlreadyAddedTax.invoice_item_uuid}-${getAlreadyAddedTax.quote_item_uuid}`} onClick={() => { handleRemoveOption(taxObj, componentName === 'previewInvoice' ? getAlreadyAddedTax.invoice_item_uuid.toString() : getAlreadyAddedTax.quote_item_uuid.toString()); }} className='mdi mdi-trash-can font-size-16 me-1 text-danger dropdown-menu-icon' /></button>
                                    <UncontrolledTooltip placement="top" target={`delete${optionIndex}-${getAlreadyAddedTax.invoice_item_uuid}-${getAlreadyAddedTax.quote_item_uuid}`} style={{ background: 'black' }}>
                                        Delete
                                    </UncontrolledTooltip>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }
            {showDropdown ? (
                <div className={`dropdown-container ${componentName === 'previewInvoice' ? 'text-start' : ''} ${componentName !== 'quote-calculator' ? 'mt-2' : ''} `}>
                    <Dropdown
                        isOpen={showDropdown}
                        toggle={() => setShowDropdown(!showDropdown)}
                        className="d-inline-block"
                        onClick={(e) => { e.preventDefault(); }}
                    >
                        <DropdownToggle
                            className={`btn d-flex border-0 ps-0 align-items-center ${componentName === 'quote-calculator' ? 'p-0' : ''}`}
                            id="page-header-user-dropdown"
                            tag="button"
                            onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                        >
                            <input
                                type="text"
                                value={inputValue}
                                onChange={(e) => { setInputValue(e.target.value); }}
                                placeholder="find or add a tax.."
                                className={`d-block tax-search-bar ${componentName === 'quote-calculator' ? 'search-bar' : (componentName === 'previewQuote' || componentName === 'previewInvoice') ? 'previewQuoteSearchBar' : componentName === 'previewInvoice' ? "" : ''}`}
                                onBlur={() => { setInputValue(''); }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 32) {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setInputValue(inputValue + ' ');
                                    }
                                }}
                                onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                            />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu px-2" >
                            <button className='btn btn-success add-new-tax-btn d-flex align-items-center' type='button' onClick={() => { setshow(true); setisEditMode(false); formik.resetForm(); setShowDropdown(!showDropdown); }}><i className="me-1 bx bx-plus-circle" /> Add a new tax</button>
                            <hr className='mt-1 mb-1' />
                            {options
                                .filter((option) =>
                                    option.tax_name.toLowerCase().includes(inputValue.toLowerCase())
                                )
                                .map((option, index) => (
                                    <button className={`btn d-flex dropdwon-option align-items-center justify-content-between ${(componentName === 'register' || componentName === 'userProfile' || componentName === 'editCustomer' || componentName === 'AddCustomer') ? 'tax-list' : 'tax-list-hover'}`} onClick={() => handleOptionSelect(option, componentName === 'previewQuote' ? getAlreadyAddedTax?.quote_item_uuid.toString() : getAlreadyAddedTax?.invoice_item_uuid.toString())}>
                                        <div>
                                            <p className='mb-0 text-start option-tax-name' id={`tax_name${index}`}>{option.tax_name}</p>
                                            {
                                                option.tax_name.length > 18 && (
                                                    <UncontrolledTooltip target={`tax_name${index}`} style={{ background: 'black' }}>
                                                        {option.tax_name}
                                                    </UncontrolledTooltip>
                                                )
                                            }
                                            <p className='mb-0 text-start'>{parseFloat(option.tax_percentage).toFixed(2)}{'%'}</p>
                                        </div>
                                        <div className='d-flex'>
                                            <button className='btn p-0 border-0' type='button'><i onClick={(e) => { setShowDropdown(false); setisEditMode(true); e.stopPropagation(); e.preventDefault(); setEditTaxInfo(option); setIsModal('edit_tax'); formik.setValues({ tax_name: option.tax_name, tax_percentage: +option.tax_percentage }); setshow(true); }} id={`edit${index}`} className='mdi mdi-pencil font-size-16 me-2 text-success' /></button>
                                            <UncontrolledTooltip placement="top" target={`edit${index}`} style={{ background: 'black' }}>
                                                Edit
                                            </UncontrolledTooltip>
                                            <button className='btn p-0 border-0' type='button'><i id={`delete${index}`} onClick={(e) => { setShowDropdown(false); e.stopPropagation(); e.preventDefault(); setConfirmationModal(true); setEditTaxInfo(option); setIsModal(); }} className='mdi mdi-trash-can font-size-16 me-1 text-danger dropdown-menu-icon' /></button>
                                            <UncontrolledTooltip placement="top" target={`delete${index}`} style={{ background: 'black' }}>
                                                Delete
                                            </UncontrolledTooltip>
                                        </div>
                                    </button>
                                ))}
                            {
                                options.filter((option) => option.tax_name.toLowerCase().includes(inputValue.toLocaleLowerCase())).length === 0 && (
                                    <p className='d-flex justify-content-center align-items-center no-tax-info pe-2'><i className='bx bx-info-circle me-1' /> No tax available.</p>
                                )
                            }
                        </DropdownMenu>
                    </Dropdown>
                </div>
            ) : (
                (componentName === 'previewQuote' || componentName === 'previewInvoice') ? (
                    <div className='d-flex justify-content-between'>
                        <button className={`btn  add-text-button ${componentName === 'quote-calculator' && selectedOptions && selectedOptions?.length === 0 ? 'mt-2' : ''} ${componentName !== 'quote-calculator' ? 'mt-3 p-0' : ' p-0'}  d-flex align-items-center `} type='button' onClick={toggleDropdown}><i className="me-1 bx bx-plus-circle" /> Add Tax</button>
                    </div>
                ) : (
                    <button className={`btn  add-text-button ${componentName === 'quote-calculator' && selectedOptions && selectedOptions?.length === 0 ? 'mt-2' : ''} ${componentName !== 'quote-calculator' ? 'mt-3 p-0' : ' p-0'}  d-flex align-items-center `} type='button' onClick={toggleDropdown}><i className="me-1 bx bx-plus-circle" /> Add Tax</button>
                )
            )}
            {
                (shouldShowSelectedTax && selectedOptions.length !== 0 && !showDropdown && (componentName === 'quote-calculator')) && (
                    <div className={`row ${componentName !== 'quote-calculator' ? 'mt-2 ' : ''} `}>
                        <Dropdown
                            isOpen={showSelectedDropdown}
                            toggle={() => setShowSelectedDropSown(!showSelectedDropdown)}
                            className="d-flex justify-content-center"
                            onClick={(e) => { e.preventDefault(); }}
                        >
                            <DropdownToggle
                                className={`btn d-flex border-0 pe-0  align-items-center ${componentName === 'quote-calculator' ? '' : ''}`}
                                id="page-header-user-dropdown"
                                tag="button"
                                onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                            >
                                <span className='me-1'>{selectedOptions.length}</span>
                                <i className='bi login-eye right-5px  bi-eye font-size-16' id='show-selected-option' />
                                <UncontrolledTooltip target='show-selected-option' style={{ background: 'black' }}>
                                    view added tax
                                </UncontrolledTooltip>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu px-2" >
                                {componentName === 'quote-calculator' && selectedOptions.map((option, index) => (
                                    <div className={`align-items-center border p-3 mt-1 mb-1 me-1 selected-dropdown ${componentName === 'quote-calculator' ? 'col-12  px-2  ' : ''} justify-content-between d-flex`} key={index}>
                                        <div>
                                            <p className='mb-0 text-start option-tax-name' id={`tax_name${index}`}>{option.tax_name}</p>
                                            {
                                                option.tax_name.length > 18 && (
                                                    <UncontrolledTooltip target={`tax_name${index}`} style={{ background: 'black' }}>
                                                        {option.tax_name}
                                                    </UncontrolledTooltip>
                                                )
                                            }
                                            <p className='mb-0'>{option.tax_percentage}%</p>
                                        </div>
                                        <div className='d-flex'>
                                            <button className='btn p-0 border-0' type='button'><i id={`delete${index}`} onClick={() => handleRemoveOption(option)} className='mdi mdi-trash-can font-size-16 me-1 text-danger dropdown-menu-icon' /></button>
                                            <UncontrolledTooltip placement="top" target={`delete${index}`} style={{ background: 'black' }}>
                                                Delete
                                            </UncontrolledTooltip>
                                        </div>
                                    </div>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                )
            }
            <Modal className='delete-customer-modal' size="md" isOpen={show} centered={true}>
                {/* header */}
                <ModalHeader toggle={() => onCloseClick()} tag="h4">
                    {!isEditMode ? 'New tax' : 'Edit tax'}
                </ModalHeader>
                <div className="modal-content">
                    <ModalBody>
                        <form onSubmit={formik.handleSubmit}>
                            <Row>
                                <div className="mb-3">
                                    <label htmlFor="taxName" className="form-label">Tax Name <span className='text-danger'>*</span></label>
                                    <input
                                        type="text"
                                        id="taxName"
                                        name="tax_name"
                                        placeholder='Enter Tax name'
                                        className={`form-control ${formik.touched.tax_name && formik.errors.tax_name ? 'is-invalid' : ''}`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.tax_name}
                                    />
                                    {formik.touched.tax_name && formik.errors.tax_name && <div className="invalid-feedback">{formik.errors.tax_name}</div>}
                                </div>
                            </Row>
                            <Row className='mb-3'>
                                <Col className=''>
                                    <label htmlFor="taxRate" className="form-label">Tax Rate <span className='text-danger'>*</span></label>
                                    <label htmlFor="taxRate" className="visually-hidden">Tax Rate </label>
                                    <div className='input-group'>
                                        <div className='input-group-text'>
                                            %
                                        </div>
                                        <NumericFormat
                                            placeholder='0.00'
                                            type="number"
                                            id="taxRate"
                                            decimalScale={2}
                                            name="tax_percentage"
                                            className={`form-control tax-input-field-popup  ${formik.touched.tax_percentage && formik.errors.tax_percentage ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.tax_percentage}
                                            invalid={
                                                formik.errors.tax_percentage && formik.touched.tax_percentage ? true : false
                                            } onKeyDown={(e) => {
                                                if (e.key === '-') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        {formik.touched.tax_percentage && formik.errors.tax_percentage && <div className="invalid-feedback">{formik.errors.tax_percentage}</div>}
                                    </div>
                                </Col>
                            </Row>
                            {/* footer */}
                            <div className="hstack justify-content-end mb-0">
                                <button type="button" className="btn-outline me-3" onClick={onCloseClick}>Cancel</button>
                                <button type="submit" className={`btn btn-fill  d-flex`} disabled={!formik.isValid || formik.values.tax_name === null || formik.values.tax_name === ' '}>
                                    {isEditLoading ? <Spinners setLoading={setisEditIsloading} /> : ''} Save
                                </button>
                            </div>
                        </form>
                    </ModalBody>
                </div>
            </Modal>
            <ConfirmationModal
                show={confirmationModal}
                onCloseClick={() => setConfirmationModal(false)}
                title={isModal === 'edit_tax' ? 'Confirmation' : 'Delete Tax'}
                description={isModal === 'edit_tax' ? 'Saving the changes to tax will apply it to any draft document that uses it. Do you want to continue?' : 'Are you sure you want to delete this Tax?'}
                onAction={() => isModal === 'edit_tax' ? confirmEditTaxInfo() : isDeleteHandle()}
                afterAction={afterAction}
                actionName={isModal === 'edit_tax' ? 'Save Changes' : 'Delete'}
            />
        </div>
    );
};

export default TaxSelect;
