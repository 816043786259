import { TAX_DEDUCTION } from '../../shared/services/api.config';
import { ApiService } from '../../shared/services/api.service';

export class TaxDeductionService { 
    apiService = new ApiService();

    async taxDeductionList(selectedMonth, selectedYear, user_uuid) {
        return await this.apiService.get(TAX_DEDUCTION.TAX_DEDUCTION_LIST.replace(/{user_uuid}/, user_uuid) , { 'month' : selectedMonth , 'year' : selectedYear}).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }
}