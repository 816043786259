import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Card, CardBody, Row, Col, Badge, Table, UncontrolledTooltip, Nav, NavItem, NavLink, Form, Collapse, Alert, Label, Input } from "reactstrap";
import './dashboard.scss';
import classnames from "classnames";
import ReactApexChart from "react-apexcharts";
import { Formik, Field, FieldArray } from "formik";
import Skeleton from 'react-loading-skeleton';
import getChartColorsArray from "../shared/components/common/chartsDynamicColor";
import { NumericFormat } from "react-number-format";
import 'flatpickr/dist/themes/material_green.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useAtom } from 'jotai';
import { SUBSCRIBERS_EVOLUTION_START_YEAR, SUBSCRIPTION_CALC_TYPE, SUBSCRIPTION_PLAN_UUID } from '../../module/shared/static/constants/constant';
import { authenticatedUserInfo } from "../../atoms";
import { QuoteCalculatorService } from "../quoteCalculator/service/quoteCalculator.service";
import Spinners from '../shared/components/common/spinner';
import ConfirmationModal from "../shared/components/common/confirmationModal";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { DashboardService } from "./service/dashboard.service";
import { ShopOwnerGetPaid } from "../shopOwnerGetPaid/service/ShopOwnerGetPaid.service";
import { useNavigate } from "react-router-dom";

const Dashboard = props => {
  // service
  const quoteCalculatorService = new QuoteCalculatorService();
  const dashboardService = new DashboardService();
  const ShopOwnerGetPaidService = new ShopOwnerGetPaid();

  //meta title
  document.title = "The Printshop Master";

  // For customer panel
  const [userRole, setUserRole] = useState(JSON.parse(sessionStorage.getItem("userLogin")).data?.role_name);
  const userLoginData = sessionStorage.getItem('userLogin') ? JSON.parse(sessionStorage.getItem('userLogin')) : null;
  const subscriptionDetails = userLoginData?.data?.subscription_details?.subscription_plan_details;
  const [loggedinUserPlan, setLoggedInUserPlan] = useState(
    subscriptionDetails ?
      subscriptionDetails.calculator_type || SUBSCRIPTION_CALC_TYPE.ALL :
      SUBSCRIPTION_CALC_TYPE.ALL
  );
  const [activeSetupTable, setActiveSetupTable] = useState(
    loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.EMBROIDERY || loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.ALL
      ? SUBSCRIPTION_CALC_TYPE.EMBROIDERY
      : SUBSCRIPTION_CALC_TYPE.SCREENPRINTING)

  // for setup default rate
  const [isUpdateDefaultRateIsLoading, setUpdateDefaultRateIsLoading] = useState(false);
  const [isSaveDefaultRateLoading, setIsSaveDefaultRateLoading] = useState(false);
  const [col1, setcol1] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [masterTableLoadingStatus, setMasterTableLoadingStatus] = useState({
    isGarmentisLoading: false,
    isStitchCountIsLoading: false,
    isSetupIsLoading: false,
    isTechnicalSpecificationIsLoading: false
  });
  const [getPrintingMasterTableLoadingStatus, setPrintingMatserTableLoadingStatus] = useState({
    isGarmentLoading: false,
    isInkLoading: false,
    isSetuupLoading: false,
    isJobCriteriaLoading: false,
    isArtLoading: false
  });
  const navigate = useNavigate();
  const [setupCountKey, setSetupCountKey] = useState(0);
  const [confirmationPoup, setConfirmationPopup] = useState(false);
  const [garmentsMasterData, setGarmentsMasterData] = useState([]);
  const [stitchCountMasterData, setStitchcountMasterData] = useState([]);
  const [setupMasterData, setSetupMasterData] = useState([]);
  const [technicalSpecificationData, setTechnicalSpecificationData] = useState([]);
  const [updatedGarmentsData, setUpdatedGarmentsData] = useState([]);
  const [updatedStitchCountMasterData, setUpdatedStitchCountMasterData] = useState([]);
  const [updatedSetupMasterData, setUpdatedSetupMasterData] = useState([]);
  const [updatedTechnicalSpecificationData, setUpdatedTechnicalSpecificationData] = useState([]);

  const [printingGarmentData, setPrintingGarmentData] = useState([]);
  const [printingInkData, setPrintingInkData] = useState([]);
  const [printingSetupData, setPrintingSetupData] = useState([]);
  const [printingJobCriteriaData, setPrintingJobCriteriaData] = useState([]);
  const [printingArtData, setPrintingArtData] = useState([]);

  const [updatedPrintingGarmentData, setUpdatedPrintingGarmentData] = useState([]);
  const [updatedPrintingInkData, setUpdatedPrintingInkData] = useState([]);
  const [updatedPrintingSetupData, setUpdatedPrintingSetupData] = useState([]);
  const [updatedPrintingJobCriteriaData, setUpdatedPrintingJobCriteriaData] = useState([]);
  const [updatedPrintingArtData, setUpdatedPrintingArtData] = useState([]);

  // For customer panel
  // const [duration, setDuration] = useState('year');
  const [customerSeries, setCustomerSeries] = useState([]);
  const apexsalesAnalyticsChartColors = getChartColorsArray('["rgba(0,194,133,0.3)", "rgba(220,53,69,0.3)"]');
  const [selectedYear, setSelectedYear] = useState();
  const [customerDashboardStatistic, setCustomerDashboardStatistic] = useState();
  const [durationFilterVal, setDurationFilterVal] = useState('year');
  const [customerGraphLabel, setCustomerGraphLabel] = useState([]);
  // get session storage
  const [getUserInfo, setUserInfo] = useAtom(authenticatedUserInfo);
  const [shouldShowPaymentMessage, setshouldShowPaymentMessage] = useState(false);
  // for adnin statisctics 
  const [adminDashboardStatisctics, setAdminDashboardStatistics] = useState();
  const [isAdminStatisticsIsLoading, setisAdminStatisticsisLoading] = useState(false);
  const [adminSubscribersbyMonth, setAdminsubscribersBymonth] = useState([]);

  const [shopownerQuoteInvoiceStatisticsLoading, setisShopownerQuoteInvoiceStatisticsLoading] = useState(false);
  const [shopownerDashboardStatistics, setisShopownerDashboardStatistics] = useState(false);

  // DEFAULT data for customer graph 
  useEffect(() => {
    setCustomerSeries([{
      name: 'Quotes',
      type: 'column',
      data: []
    }, {
      name: 'Invoices',
      type: 'column',
      data: []
    }]);
  }, []);

  //based on user role call API
  useEffect(() => {
    if (userRole === 'admin') {
      setisAdminStatisticsisLoading(true);
      dashboardService.adminDashboardStatisctics(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
        if (response.status === 200) {
          setAdminDashboardStatistics(response.data[0]);
          setisAdminStatisticsisLoading(false);
        }
      });
      getAdminSubscribersByYear(moment(selectedYear).format('YYYY'));
    } else {
      if (getUserInfo?.data?.subscription_details.subscription_plan_details === null || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_PREMIUM_PLAN || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_PREMIUM_PLAN || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.STANDARD_PLAN) {
        getCustomerDashboardStatistic();
        customerQuoteInvoiceStatistics(durationFilterVal);
      }
    }
  }, [userRole]);

  useEffect(() => {
    const subscriptionDetails = getUserInfo?.data?.subscription_details?.subscription_plan_details;
    // Get the current calculator type from userLoginData
    const calculatorType = subscriptionDetails ? subscriptionDetails.calculator_type || SUBSCRIPTION_CALC_TYPE.ALL : SUBSCRIPTION_CALC_TYPE.ALL;
    // Set loggedinUserPlan
    setLoggedInUserPlan(calculatorType);

    setActiveSetupTable(calculatorType === SUBSCRIPTION_CALC_TYPE.EMBROIDERY || calculatorType === SUBSCRIPTION_CALC_TYPE.ALL
      ? SUBSCRIPTION_CALC_TYPE.EMBROIDERY
      : SUBSCRIPTION_CALC_TYPE.SCREENPRINTING)

    if (userRole === 'customer') {
      if (getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.BASIC_PALN || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_BASIC_PLAN || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_BASIC_PLAN) {
        setSetupCountKey(prevKey => prevKey + 1);
        setcol1(!col1);
        setisEditMode(false);
        setUpdatedGarmentsData(garmentsMasterData);
        setUpdatedSetupMasterData(setupMasterData);
        setUpdatedTechnicalSpecificationData(technicalSpecificationData);
        setUpdatedSetupMasterData(setupMasterData);
        setUpdatedPrintingGarmentData(printingGarmentData);
        setUpdatedPrintingInkData(printingInkData);
        setUpdatedPrintingJobCriteriaData(printingJobCriteriaData);
        setUpdatedPrintingSetupData(printingSetupData);
      }
    }
  }, [getUserInfo])

  // get subscribers data dashboard data for admin 
  const getAdminSubscribersByYear = (year) => {
    dashboardService.adminTotalSubscribersStatistics(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid, year).then((response) => {
      if (response.status === 200) {
        let subscribersArray = response.data;
        const finalArray = subscribersArray.map(item => {
          // Extract the value of "total_subscribers" from each object
          const value = Object.values(item)[0].total_subscribers;
          // Convert the value to a number and return
          return parseInt(value, 10);
        })
        setAdminsubscribersBymonth(finalArray);
      }
    });
  }

  // customer monthly earning donut chart
  const customerMonthlyEarning = {
    labels: ["Paid Invoices", "Unpaid Invoices"],
    colors: apexsalesAnalyticsChartColors,
    legend: { show: false },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
  };

  // get customer dashboard statistic
  const getCustomerDashboardStatistic = () => {
    setisShopownerDashboardStatistics(true);
    dashboardService.getCustomerDashboardStatistic(getUserInfo?.data.user_uuid).then(response => {
      if (response.status === 200) {
        setCustomerDashboardStatistic(response['data'][0]);
        setisShopownerDashboardStatistics(false);
      }
    });
  }

  useState(() => {
    if (userRole === 'customer' && (getUserInfo.data.subscription_details.subscription_plan_details === null || getUserInfo.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.STANDARD_PLAN)) {
      ShopOwnerGetPaidService.verifyStripeAccountStatus(JSON.parse(sessionStorage.getItem('userLogin'))?.data.user_uuid).then((response) => {
        if (response.status === 200) {
          response.data.status === 'not_completed' ? setshouldShowPaymentMessage(true) : setshouldShowPaymentMessage(false);
        }
      });
    }
  }, []);

  // update lable for bar graph
  const updateCustomerGraphLabel = (labels) => {
    setCustomerGraphLabel(labels);
  };

  // update bar chart value
  const updateCustomerSeries = (data) => {
    setCustomerSeries(data);
  }

  // get statistics data for shop owner
  const customerQuoteInvoiceStatistics = (duration) => {
    setisShopownerQuoteInvoiceStatisticsLoading(true);
    setCustomerSeries([]);
    dashboardService.customerQuoteInvoiceStatistics(getUserInfo?.data.user_uuid, duration)
      .then(response => {

        setisShopownerQuoteInvoiceStatisticsLoading(false);
        if (response.status === 200) {
          if (duration === 'year') {
            const labels = response['data']['quotes'].map(item => item.year_number);
            const filteredYears = labels
              .map(year => parseInt(year)) // Convert each string to a number
              .filter(year => year >= SUBSCRIBERS_EVOLUTION_START_YEAR);
            updateCustomerGraphLabel(filteredYears.map(year => year.toString()));

            const filteredYearsNumeric = filteredYears.map(year => parseInt(year));

            // Filter quotes data for years present in filteredYears
            const quotesData = response.data.quotes
              .filter(item => filteredYearsNumeric.includes(parseInt(item.year_number)))
              .map(item => parseInt(item.sent_quote));

            // Filter invoices data for years present in filteredYears
            const invoicesData = response.data.invoices
              .filter(item => filteredYearsNumeric.includes(parseInt(item.year_number)))
              .map(item => parseInt(item.sent_invoice));

            const customerSeries = [
              {
                name: 'Quotes',
                type: 'column',
                data: quotesData
              },
              {
                name: 'Invoices',
                type: 'column',
                data: invoicesData
              }
            ];

            updateCustomerSeries(customerSeries);
          } else if (duration === 'month') {
            const existingMonths = [...new Set([...response.data.quotes.map(item => item.month_number), ...response.data.invoices.map(item => item.month_number)])];
            const labels = [
              'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
              'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];
            updateCustomerGraphLabel(labels);

            let quotesData = Array.from({ length: 12 }).fill(0);
            let invoicesData = Array.from({ length: 12 }).fill(0);

            response.data.quotes.forEach(item => {
              const index = existingMonths.indexOf(item.month_number);
              if (index !== -1) {
                quotesData[existingMonths[index] - 1] = parseInt(item.sent_quote);
              }
            });

            // Update invoicesData based on existingMonths
            response.data.invoices.forEach(item => {
              const index = existingMonths.indexOf(item.month_number);
              if (index !== -1) {
                invoicesData[existingMonths[index] - 1] = parseInt(item.sent_invoice);
              }
            });


            const customerSeries = [
              {
                name: 'Quotes',
                type: 'column',
                data: quotesData
              },
              {
                name: 'Invoices',
                type: 'column',
                data: invoicesData
              }
            ];

            updateCustomerSeries(customerSeries);
          } else if (duration === 'week') {
            const labels = [];
            for (let i = 0; i < response.data.invoices.length; i++) {
              const quote = response.data.invoices[i];
              const startDate = moment(quote.week_start_date);
              const endDate = moment(quote.week_end_date);
              const formattedStartDate = startDate.format('D MMM');
              const formattedEndDate = endDate.format('D MMM');
              labels.push(`${formattedStartDate} - ${formattedEndDate}`);
            }
            const getcountFor = getCountsForWeeks(labels, response.data.quotes, response.data.invoices)
            updateCustomerGraphLabel(labels);

            const customerSeries = [
              {
                name: 'Quotes',
                type: 'column',
                data: getcountFor.quotesData
              },
              {
                name: 'Invoices',
                type: 'column',
                data: getcountFor.invoicesData
              }
            ];
            updateCustomerSeries(customerSeries);
          }
        }
      });
  };

  // get array of shop owner panel chart for week filter
  const getCountsForWeeks = (weekLabels, quotes, invoices) => {
    const quotesData = [];
    const invoicesData = [];
    weekLabels.forEach((label, index) => {
      const start = moment(label.split(' - ')[0], 'DD MMM');
      const end = moment(label.split(' - ')[1], 'DD MMM').endOf('day');
      const quote = quotes.find(q => moment(q.week_start_date) >= start && moment(q.week_end_date) <= end);
      const invoice = invoices.find(i => moment(i.week_start_date) >= start && moment(i.week_end_date) <= end);
      const quoteCount = quote ? parseInt(quote.sent_quote) : 0;
      const invoiceCount = invoice ? parseInt(invoice.sent_invoice) : 0;
      quotesData.push(quoteCount);
      invoicesData.push(invoiceCount);
    });
    return { quotesData, invoicesData };
  };

  var statisticsApplicationColors = getChartColorsArray('[ "--secondary-green","--primary-green", "--primary-green", "--bs-info"]');

  // change duration for filter shop owner panel
  const changeDuration = (duration) => {
    setDurationFilterVal(duration);
    customerQuoteInvoiceStatistics(duration);
  };

  // for setup master default rate
  const onchangeMatserTable = () => {
    if (!isEditMode) {
      setisEditMode(!isEditMode);
    }
  }

  // accordain for setup default rate
  useEffect(() => {
    if (col1 === true && userRole === 'customer') {
      if (activeSetupTable === SUBSCRIPTION_CALC_TYPE.EMBROIDERY && garmentsMasterData.length === 0 && stitchCountMasterData.length === 0 && setupMasterData.length === 0 && technicalSpecificationData.length === 0) {
        getMasterTableData();
      } else if (activeSetupTable === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING && printingGarmentData.length === 0 && printingInkData.length === 0 && printingJobCriteriaData.length === 0 && printingSetupData.length === 0) {
        getPrintingMatserTableData();
      }
    }
  }, [col1, activeSetupTable]);

  // get master data for shop owner panel
  const getMasterTableData = () => {
    setMasterTableLoadingStatus({
      isGarmentisLoading: true,
      isStitchCountIsLoading: true,
      isSetupIsLoading: true,
      isTechnicalSpecificationIsLoading: true
    });
    quoteCalculatorService.getGarmentMaterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then(response => {
      if (response.status === 200) {
        setGarmentsMasterData(response.data);
        setUpdatedGarmentsData(response.data);
      }
    }).finally(() => {
      setMasterTableLoadingStatus(prev => {
        return {
          ...prev,
          isGarmentisLoading: false
        }
      });
    });
    quoteCalculatorService.getSetUpMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then(response => {
      if (response.status === 200) {
        setSetupMasterData(response.data.map(({ setup_quantity, ...rest }) => rest).filter(obj => obj.setup_label === 'Digitizing Fee' || obj.setup_label === 'Art Creation'));
        setUpdatedSetupMasterData(response.data.map(({ setup_quantity, ...rest }) => rest).filter(obj => obj.setup_label === 'Digitizing Fee' || obj.setup_label === 'Art Creation'));
      }
    }).finally(() => {
      setMasterTableLoadingStatus(prev => {
        return {
          ...prev,
          isSetupIsLoading: false
        }
      });
    });
    quoteCalculatorService.getStichCountMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then(response => {
      if (response.status === 200) {
        setStitchcountMasterData(response.data);
        setUpdatedStitchCountMasterData(response.data);
      }
    }).finally(() => {
      setMasterTableLoadingStatus(prev => {
        return {
          ...prev,
          isStitchCountIsLoading: false
        }
      });
    });
    quoteCalculatorService.getTechnicalSpecificationMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then(response => {
      if (response.status === 200) {
        setTechnicalSpecificationData(response.data.filter((obj) => obj.technical_specification_label === 'Embroidery Heads'));
        setUpdatedTechnicalSpecificationData(response.data.filter((obj) => obj.technical_specification_label === 'Embroidery Heads'));
      }
    }).finally(() => {
      setMasterTableLoadingStatus(prev => {
        return {
          ...prev,
          isTechnicalSpecificationIsLoading: false
        }
      });
    });
  }

  // get master data for shop owner table 
  const getPrintingMatserTableData = () => {
    setPrintingMatserTableLoadingStatus({
      isGarmentLoading: true,
      isInkLoading: true,
      isSetuupLoading: true,
      isJobCriteriaLoading: true,
      isArtLoading: true
    });
    quoteCalculatorService.getPrintingGarmentMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then(response => {
      if (response.status === 200) {
        setPrintingGarmentData(response.data);
        setUpdatedPrintingGarmentData(response.data);
      }
    }).finally(() => {
      setPrintingMatserTableLoadingStatus(prev => {
        return {
          ...prev,
          isGarmentLoading: false
        }
      });
    });

    quoteCalculatorService.getPrintingArtCountData(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then(response => {
      if (response.status === 200) {
        setPrintingArtData(response.data);
        setUpdatedPrintingArtData(response.data);
      }
    }).finally(() => {
      setPrintingMatserTableLoadingStatus(prev => {
        return {
          ...prev,
          isArtLoading: false
        }
      });
    })

    quoteCalculatorService.getPrintingInkCountMatserList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then(response => {
      if (response.status === 200) {
        setPrintingInkData(response.data);
        setUpdatedPrintingInkData(response.data);
      }
    }).finally(() => {
      setPrintingMatserTableLoadingStatus(prev => {
        return {
          ...prev,
          isInkLoading: false
        }
      });
    });

    quoteCalculatorService.getPrintingJobCriteriaMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then(response => {
      if (response.status === 200) {
        setPrintingJobCriteriaData(response.data);
        setUpdatedPrintingJobCriteriaData(response.data);
      }
    }).finally(() => {
      setPrintingMatserTableLoadingStatus(prev => {
        return {
          ...prev,
          isJobCriteriaLoading: false
        }
      });
    });

    quoteCalculatorService.getPrintingSetUpMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then(response => {
      if (response.status === 200) {
        setPrintingSetupData(response.data);
        setUpdatedPrintingSetupData(response.data);
      }
    }).finally(() => {
      setPrintingMatserTableLoadingStatus(prev => {
        return {
          ...prev,
          isSetuupLoading: false
        }
      });
    });
  }

  // year data for admin dashboard chart data
  const yearData = [
    {
      name: "Subscriber",
      data: adminSubscribersbyMonth
    }
  ];

  // width for bar chart for shop owner panel
  const getBarWidthForDuration = (duration) => {
    if (duration === 'year') {
      return '5%';
    } else if (duration === 'month') {
      return '50%';
    } else if (duration === 'week') {
      return '20%';
    }
  };

  // shop owner bar chart options
  const customerOptions = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
      offsetY: 10,
    },
    stroke: {
      width: [0, 0, 2, 2],
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: getBarWidthForDuration(durationFilterVal)
      }
    },
    fill: {
      opacity: [1, 1, 0.1, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    },
    labels: customerGraphLabel, // Pass customerGraphLabel as labels
    colors: statisticsApplicationColors,
    markers: {
      size: 0
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (value) {
          return value % 1 !== 0 ? '' : value;
        }
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " sent";
          }
          return y;
        }
      }
    },
    series: customerSeries,
  };

  // for admin panel 
  const stackedColumnChartColors = getChartColorsArray('["--secondary-green", "--bs-success", "--bs-info"]');

  const currentYear = new Date().getFullYear();

  // Generate options for years from 2020 to current year
  const yearOptions = [];
  for (let year = SUBSCRIBERS_EVOLUTION_START_YEAR; year <= currentYear; year++) {
    yearOptions.push(
      <option key={year} value={year} className="option-year" style={{ backgroundColor: selectedYear === year ? 'var(--secondary-green)' : '' }}>
        {year}
      </option>
    );
  }

  // on change year for admin dashboard
  const onChangeYear = value => {
    setSelectedYear(value);
    getAdminSubscribersByYear(moment(value).format('YYYY'));
  };

  // admin br chart options
  const adminOptions = {
    chart: {
      stacked: !0,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: !0
      }
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "28%"
      }
    },
    dataLabels: {
      enabled: !1
    },
    xaxis: {
      show: true,
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      labels: {
        show: true
      },
    },
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom"
    },
    fill: {
      opacity: 1
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (value) {
          return value % 1 !== 0 ? '' : value;
        }
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toString();
        }
      }
    }
  }

  // for admin panel monthly earning tooltip
  // Create a new Date object to get the current date
  const currentDate = new Date();

  // Get the current month
  const currentMonthIndex = currentDate.getMonth(); // 0-indexed, January is 0, February is 1, etc.
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const currentMonth = months[currentMonthIndex];

  // Get the current year
  const currentYearForMonthlyEarning = currentDate.getFullYear();

  // Concatenate the current month and year
  const currentMonthYear = `${currentMonth} ${currentYearForMonthlyEarning}`;

  // admin monthly earning donut chart
  const adminMonthlyEarning = {
    labels: ["Paid Subscribers", "Unpaid Subscribers"],
    colors: apexsalesAnalyticsChartColors,
    legend: { show: false },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
  };

  // Custom CSS to style the percentage value
  const customCSS = `
.apexcharts-pie-label {
  fill: var(--primary-dark-green) !important; // Change the color of the percentage value
  font-weight: bold; // Optionally adjust other styles
}
`;

  const UTCtoLocal = (utcDate) => {
    const date = new Date(utcDate);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Dynamic time zone based on user's location
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(localDate);
    return formattedDate;
  }

  // Inject custom CSS
  const styleTag = document.createElement('style');
  styleTag.innerHTML = customCSS;
  document.head.appendChild(styleTag);

  // on click save default rate
  const onclickSaveDefaultrate = () => {
    setUpdateDefaultRateIsLoading(true);
    if (activeSetupTable === SUBSCRIPTION_CALC_TYPE.EMBROIDERY) {
      let updatedDefaultRate = {
        'garments_details': updatedGarmentsData.map(({ garment_label, ...rest }) => ({
          ...rest,
          garment_rate: Number(rest.garment_rate)
        })),
        'stitch_counts_details': updatedStitchCountMasterData.map(({ stitch_count_label, ...rest }) => ({
          ...rest,
          stitch_count_rate: Number(rest.stitch_count_rate)
        })),
        'setup_details': updatedSetupMasterData.map(({ setup_label, ...rest }) => ({
          ...rest,
          setup_rate: Number(rest.setup_rate)
        })),
        'technical_specification_details': updatedTechnicalSpecificationData.map(({ technical_specification_label, ...rest }) => ({
          ...rest,
          technical_specification_quantity: Number(rest.technical_specification_quantity)
        }))
      }
      quoteCalculatorService.setupDefaultrate(updatedDefaultRate, JSON.parse(sessionStorage.getItem("userLogin")).data?.user_uuid).then((response) => {
        if (response.status === 200) {
          getMasterTableData();
          setisEditMode(false);
        }
      }).finally(() => {
        setUpdateDefaultRateIsLoading(false);
      });
    } else if (activeSetupTable === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING) {
      let updatedDefaultPrintingRate = {
        "screenprinting_garments_details": updatedPrintingGarmentData.map(({ screenprinting_garment_master_uuid, garment_rate }) => ({
          screenprinting_garment_master_uuid,
          garment_rate: parseFloat(garment_rate)
        })),
        "screenprinting_inks_details": updatedPrintingInkData.map(({ screenprinting_ink_master_uuid, ink_rate }) => ({
          screenprinting_ink_master_uuid,
          ink_rate: parseFloat(ink_rate)
        })),
        "screenprinting_setup_details": updatedPrintingSetupData.map(({ screenprinting_setup_master_uuid, setup_rate, setup_quantity }) => ({
          screenprinting_setup_master_uuid,
          setup_rate: parseFloat(setup_rate),
          setup_quantity: parseFloat(setup_quantity)
        })),
        "screenprinting_job_criteria_details": updatedPrintingJobCriteriaData
          .filter(({ job_criteria_label }) => job_criteria_label === "Shirts / Hour") // Filter to include only "Shirts / Hour"
          .map(({ screenprinting_job_criteria_master_uuid, job_criteria_quantity }) => ({
            screenprinting_job_criteria_master_uuid,
            job_criteria_quantity: Number(job_criteria_quantity)
          })),
        "screenprinting_art_details": updatedPrintingArtData.map(({ screenprinting_art_master_uuid, art_rate }) => ({
          screenprinting_art_master_uuid,
          art_rate: Number(art_rate)
        }))
      };

      quoteCalculatorService.setupPrintingDefaultRate(updatedDefaultPrintingRate, JSON.parse(sessionStorage.getItem("userLogin")).data?.user_uuid).then((response) => {
        if (response.status === 200) {
          setisEditMode(false);
          getPrintingMatserTableData();
        }
      }).finally(() => {
        setUpdateDefaultRateIsLoading(false);
      })
    }
  }

  const changeSelectedRate = (selectedBtn) => {
    setActiveSetupTable(selectedBtn);
    setSetupCountKey(prevKey => prevKey + 1);
    setisEditMode(false);
    setUpdatedGarmentsData(garmentsMasterData);
    setUpdatedSetupMasterData(setupMasterData);
    setUpdatedTechnicalSpecificationData(technicalSpecificationData);
    setUpdatedSetupMasterData(setupMasterData);
    setUpdatedPrintingGarmentData(printingGarmentData);
    setUpdatedPrintingInkData(printingInkData);
    setUpdatedPrintingJobCriteriaData(printingJobCriteriaData);
    setUpdatedPrintingSetupData(printingSetupData);
  }

  // on click cancel default rate
  const onclickCancleSetupRates = () => {
    setSetupCountKey(prevKey => prevKey + 1);
    setcol1(!col1);
    setisEditMode(false);
    setUpdatedGarmentsData(garmentsMasterData);
    setUpdatedSetupMasterData(setupMasterData);
    setUpdatedTechnicalSpecificationData(technicalSpecificationData);
    setUpdatedSetupMasterData(setupMasterData);
    setUpdatedPrintingGarmentData(printingGarmentData);
    setUpdatedPrintingInkData(printingInkData);
    setUpdatedPrintingJobCriteriaData(printingJobCriteriaData);
    setUpdatedPrintingSetupData(printingSetupData);
    setUpdatedPrintingArtData(printingArtData);
  }

  // refresh to default rate
  const resetToDefault = () => {
    setIsSaveDefaultRateLoading(false);
    if (activeSetupTable === SUBSCRIPTION_CALC_TYPE.EMBROIDERY) {
      quoteCalculatorService.resetToDefault({ 'user_uuid': JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid }).then((response) => {
        if (response.status === 200) {
          getMasterTableData();
          toast.success(response.data.message, { autoClose: 3000 });
          setisEditMode(false);
        }
      }).finally(() => {
        setIsSaveDefaultRateLoading(true);
      });
    } else if (activeSetupTable === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING) {
      quoteCalculatorService.screenPrintingResetToDefault({ 'user_uuid': JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid }).then((response) => {
        if (response.status === 200) {
          getPrintingMatserTableData();
          toast.success(response.data.message, { autoClose: 3000 });
          setisEditMode(false);
        }
      }).finally(() => {
        setIsSaveDefaultRateLoading(true);
      });
    }
  }

  return (
    <React.Fragment >
      {userRole === 'customer' &&
        <div className="page-content dashboard-page">
          <Container fluid>
            {
              shouldShowPaymentMessage && JSON.parse(sessionStorage.getItem('userLogin'))?.data?.subscription_details !== null && (
                <div>
                  {
                    JSON.parse(sessionStorage.getItem('userLogin'))?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.STANDARD_PLAN &&
                    (
                      <Row className="mt-3">
                        <Col>
                          <Alert className={`paymentInfo alert-info border-0 px-md-3 px-sm-1 px-2 py-3 mb-0 alert-message`}>
                            <div className="d-flex align-items-center">
                              <div>
                                <i className="mdi-bank-plus mdi home-icon font-size-18 ms-2 me-2" />
                              </div>
                              <div className="d-flex align-items-center w-100 justify-content-between">
                                <div>
                                  Please set up your bank account on Stripe to receive payments for invoices from your client.
                                </div>
                                <div className="d-flex justify-content-start d-none d-xl-flex">
                                  <p className=" mb-0 cursor-pointer setup-account" onClick={() => { navigate('/get-paid'); }}>Set-up Account to get Paid</p>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-start ms-4 ps-2 d-flex d-xl-none mt-1">
                              <p className="mb-0 cursor-pointer setup-account" onClick={() => { navigate('/get-paid'); }}>Set-up Account to get Paid</p>
                            </div>
                          </Alert>
                        </Col>
                      </Row>
                    )
                  }
                </div>
              )
            }
            {
              (getUserInfo?.data?.subscription_details.subscription_plan_details === null || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_PREMIUM_PLAN || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_PREMIUM_PLAN || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.STANDARD_PLAN) && (
                <Row className="mt-3">
                  <div className="equal-height-col welcome-card-col">
                    <Card className="welcome-card">
                      <CardBody>
                        <div className="text-primary ">
                          <i className="font-size-20 home-icon mdi-home-flood mdi"></i>
                          <h5 className="color-primary">Welcome {JSON.parse(sessionStorage.getItem('userLogin'))?.data.name}!</h5>
                          <p className="welcome-card-p mb-0">Get ready to dive in and discover everything you need at your fingertips for Quotes & Invoices.</p>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="equal-height-col customer-statistic-card">
                    <Card>
                      <CardBody>
                        {
                          shopownerDashboardStatistics ? (<div>
                            <div className="d-flex align-items-center">
                              <div>
                                <Skeleton circle width={30} height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft me-2" />
                              </div>
                              <div className="flex-grow-1 ml-2">
                                <Skeleton height={33} width="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                              </div>
                            </div>
                            <Skeleton height={33} width="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="mt-4 bg-soft" />
                          </div>) : (
                            <div>
                              <div className="mb-3 pb-lg-1 d-flex align-items-center">
                                <div className="avatar-xs me-3">
                                  <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18">
                                    <i className="bx bx bxs-receipt" />
                                  </span>
                                </div>
                                <h5 className="font-size-14 mb-0">Total Orders</h5>
                              </div>
                              <div className="text-muted mt-1 font-weight-600 "  >
                                <h3 className="mb-0  text_number ps-2 pt-0" id="total_orders">
                                  {customerDashboardStatistic?.total_orders}{" "}
                                </h3>
                                {
                                  customerDashboardStatistic?.total_orders?.length > 9 && (
                                    <UncontrolledTooltip target={'total_orders'}>{customerDashboardStatistic?.total_orders}</UncontrolledTooltip>
                                  )
                                }
                              </div>
                            </div>
                          )
                        }
                      </CardBody>
                    </Card>
                  </div>
                  <div className="equal-height-col customer-statistic-card">
                    <Card>
                      <CardBody>
                        {
                          shopownerDashboardStatistics ? (<div>
                            <div className="d-flex align-items-center">
                              <div>
                                <Skeleton circle width={30} height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft me-2" />
                              </div>
                              <div className="flex-grow-1 ml-2">
                                <Skeleton height={33} width="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                              </div>
                            </div>
                            <Skeleton height={33} width="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="mt-4 bg-soft" />
                          </div>) : (
                            <div>
                              <div className="mb-3 pb-lg-1 d-flex align-items-center">
                                <div className="avatar-xs me-3">
                                  <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18">
                                    <i className="fas fa-money-check-alt fas" />
                                  </span>
                                </div>
                                <h5 className="font-size-14 mb-0">Total Revenue</h5>
                              </div>
                              <div className="text-muted mt-1 font-weight-600 "  >
                                <h3 id="total_revenue" className="mb-0 text_number pt-0 ps-2">
                                  ${customerDashboardStatistic?.total_revenue || 0}{" "}
                                </h3>
                                {
                                  customerDashboardStatistic?.total_revenue?.length > 7 && (
                                    <UncontrolledTooltip target={'total_revenue'}>${customerDashboardStatistic?.total_revenue}</UncontrolledTooltip>
                                  )
                                }
                              </div>
                            </div>
                          )
                        }
                      </CardBody>
                    </Card>
                  </div>
                  <div className="equal-height-col customer-statistic-card">
                    <Card>
                      <CardBody>
                        {
                          shopownerDashboardStatistics ? (<div>
                            <div className="d-flex align-items-center">
                              <div>
                                <Skeleton circle width={30} height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft me-2" />
                              </div>
                              <div className="flex-grow-1 ml-2">
                                <Skeleton height={33} width="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                              </div>
                            </div>
                            <Skeleton height={33} width="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="mt-4 bg-soft" />
                          </div>) : (
                            <div>
                              <div className="mb-3 pb-lg-1 d-flex align-items-center">
                                <div className="avatar-xs me-3">
                                  <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18">
                                    <i className="fas fa-donate" />
                                  </span>
                                </div>
                                <h5 className="font-size-14 mb-0">Total Due invoice</h5>
                              </div>
                              <div className="text-muted mt-1 font-weight-600 "  >
                                <h3 className="mb-0 text_number ps-2 pt-0" id="total_due_invoices">
                                  {customerDashboardStatistic?.total_due_invoices}{" "}
                                </h3>
                                {
                                  customerDashboardStatistic?.total_due_invoices?.length > 9 && (
                                    <UncontrolledTooltip target={'total_due_invoices'}>{customerDashboardStatistic?.total_due_invoices}</UncontrolledTooltip>
                                  )
                                }
                              </div>
                            </div>
                          )
                        }
                      </CardBody>
                    </Card>
                  </div>
                </Row>
              )
            }
            {
              (getUserInfo?.data?.subscription_details.subscription_plan_details === null || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_PREMIUM_PLAN || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_PREMIUM_PLAN || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.STANDARD_PLAN) && (
                <Row>
                  <Col className="col-xl-8">
                    <Card>
                      <CardBody>
                        <div className="d-sm-flex flex-wrap">
                          <h4 className="card-title mb-4">Quote sent & Invoice sent</h4>
                          <div className="ms-auto">
                            <Nav pills className="d-flex justify-content-end">
                              <NavItem>
                                <NavLink className={durationFilterVal === 'week' ? 'graph-tab active' : 'graph-tab'} href="#" onClick={() => changeDuration('week')}>Week</NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink className={durationFilterVal === 'month' ? 'graph-tab active' : 'graph-tab'} href="#" onClick={() => changeDuration('month')}>Month</NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink className={durationFilterVal === 'year' ? 'graph-tab active' : 'graph-tab'} href="#" onClick={() => changeDuration('year')}>Year</NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        </div>
                        <ReactApexChart
                          options={shopownerQuoteInvoiceStatisticsLoading ? [] : customerOptions}
                          series={shopownerQuoteInvoiceStatisticsLoading ? [] : customerSeries}
                          type="line"
                          height="350"
                          className="apex-charts pb-2"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="4">
                    <Card className="monthly-earning-card">
                      <CardBody>
                        <h4 className="card-title mt-0">Monthly Invoices & Earnings</h4>
                        <Row>
                          <Col sm="12 d-flex flex-column justify-content-center">
                          </Col>
                        </Row>
                        <Row>
                          <Col className="d-flex justify-content-center">
                            <div>
                              <div id="donut-chart">
                                {
                                  shopownerDashboardStatistics ? (
                                    <div style={{ width: '250px', height: '250px', position: 'relative' }}>
                                      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '210px', height: '210px', borderRadius: '50%', overflow: 'hidden' }}>
                                        <Skeleton type="circle" width="100%" height="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" />
                                      </div>
                                      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '150px', height: '150px', borderRadius: '50%', overflow: 'hidden', backgroundColor: 'var(--primary-white)' }}>
                                        <Skeleton type="circle" width="100%" height="100%" baseColor='var(--primary-white)' highlightColor="var(--primary-white)" />
                                      </div>
                                    </div>
                                  ) : (
                                    (customerDashboardStatistic?.total_invoices_current_month !== 0 && customerDashboardStatistic?.total_invoices_current_month !== '0') ? (
                                      <ReactApexChart
                                        options={customerMonthlyEarning}
                                        series={[parseInt(customerDashboardStatistic?.total_paid_invoices_current_month), parseInt(customerDashboardStatistic?.total_invoices_current_month) - parseInt(customerDashboardStatistic?.total_paid_invoices_current_month)]}
                                        type="donut"
                                        height={260}
                                        className="apex-charts"
                                      />
                                    ) : (
                                      <div className="no_records">
                                        <i className="bi bi-info-circle me-2"></i>
                                        <span>No invoices have been created</span>
                                      </div>
                                    )
                                  )
                                }
                              </div>
                              <h3 className="text-center cursor-pointer" id="monthly-earn-amount">${shopownerDashboardStatistics ? '00.00' : customerDashboardStatistic?.total_paid_invoice_amount_current_month || '00.00'}</h3>
                              <UncontrolledTooltip placement="bottom" target="monthly-earn-amount" style={{ background: 'black' }}>
                                The amount earned from invoice(s) in {currentMonthYear}.
                              </UncontrolledTooltip>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-xxl-3 mx-0">
                          <Col xl="12">
                            <div className="row total-subscriber-row d-flex py-2 text-center">
                              <div className="col-4 total-subscriber">
                                <h5 className="mb-1 text-yellow font-weight-500">
                                  {shopownerDashboardStatistics ? 0 : customerDashboardStatistic?.total_invoices_current_month}
                                </h5>
                                <p className="mb-0 font-size-12">
                                  Total Invoices
                                </p>
                              </div>
                              <div className="col-4 total-subscriber">
                                <h5 className="mb-1 color-secondary font-weight-500">
                                  {shopownerDashboardStatistics ? 0 : customerDashboardStatistic?.total_paid_invoices_current_month}
                                </h5>
                                <p className="mb-0 font-size-12">
                                  Paid Invoices
                                </p>
                              </div>
                              <div className="col-4">
                                <h5 className="mb-1 text-danger font-weight-500">
                                  {shopownerDashboardStatistics ? 0 : customerDashboardStatistic?.total_invoices_current_month - customerDashboardStatistic?.total_paid_invoices_current_month}
                                </h5>
                                <p className="mb-0 font-size-12">
                                  Unpaid Invoices
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )
            }
            <Row>
              <div className="" id="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames(
                        "accordion-button payment_info-button fw-bold",
                        { collapsed: !col1 }
                      )}
                      type="button"
                      onClick={() => { setcol1(!col1); onclickCancleSetupRates(); }}
                      style={{ cursor: "pointer" }}
                    >
                      {!col1 ? <i className="bx font-size-18 me-2 bx-caret-down"></i> : <i className="bx me-2 font-size-18 bx-caret-up"></i>} Setup Rates
                    </button>
                  </h2>
                  <Collapse isOpen={col1} className="accordion-collapse">
                    <Card>
                      <CardBody className="setup-rated-container">
                        <div className="d-blcok d-sm-flex  justify-content-between mb-3">
                          <div >
                            {
                              loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.ALL && (
                                <div>
                                  <div className="form-check form-check-inline font-size-16">
                                    <Input
                                      type="radio"
                                      value="1"
                                      id="customRadioInline1"
                                      name="customRadioInline1"
                                      className="form-check-input radio-btn-label"
                                      defaultChecked
                                      onClick={() => changeSelectedRate(SUBSCRIPTION_CALC_TYPE.EMBROIDERY)}
                                    />
                                    <Label
                                      className="form-check-label font-size-13 client-radio-label"
                                      htmlFor="customRadioInline1"
                                    >
                                      Embroidery
                                    </Label>
                                  </div>
                                  <div className="form-check form-check-inline font-size-16">
                                    <Input
                                      type="radio"
                                      value="2"
                                      id="customRadioInline2"
                                      name="customRadioInline1"
                                      className="form-check-input radio-btn-label"
                                      onClick={() => changeSelectedRate(SUBSCRIPTION_CALC_TYPE.SCREENPRINTING)}
                                    />
                                    <Label
                                      className="form-check-label font-size-13 client-radio-label"
                                      htmlFor="customRadioInline2">
                                      Screen Printing
                                    </Label>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                          <div className="d-flex justify-content-end mt-2 mt-sm-0">
                            <button className="btn btn-sm btn-success  d-flex align-items-center justify-content-center" onClick={() => { setConfirmationPopup(true); }}> Reset To Default</button>
                          </div>
                        </div>
                        {
                          activeSetupTable === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING ?
                            <Row className="">
                              <Col lg={3} sm={6} className="">
                                <Card className="card-contianer  mb-lg-0 mb-4 ">
                                  <CardBody className="">
                                    {
                                      getPrintingMasterTableLoadingStatus.isGarmentLoading ? (
                                        <div className="table-responsive">
                                          <table className="table align-middle mb-0 table-nowrap">
                                            <thead className="table-light">
                                              <tr>
                                                <th className="calculator-th width-70">Garments</th>
                                                <th className="calculator-th text-center">Rate</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {[...Array(5)].map((_, index) => (
                                                <tr key={index}>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                  </td>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        <Formik
                                          key={setupCountKey}
                                          initialValues={{
                                            garments: updatedPrintingGarmentData,
                                          }}
                                          render={({ values, setFieldValue }) => (
                                            <Form>
                                              <div className="table-responsive">
                                                <Table className="table align-middle mb-0 table-nowrap">
                                                  <thead className="table-light">
                                                    <tr>
                                                      <th className="calculator-th width-70">Garments</th>
                                                      <th className="calculator-th text-center">Rate</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="calculator-table-body">
                                                    <FieldArray
                                                      name="garments"
                                                      render={(arrayHelpers) => (
                                                        <>
                                                          {values?.garments?.map((garment, index) => (
                                                            <tr key={index}>
                                                              <td className="calculator-td">
                                                                {garment.garment_label}
                                                              </td>
                                                              <td className="calculator-td">
                                                                <Field
                                                                  name={`garments[${index}].garment_rate`}
                                                                  render={({ field }) => (
                                                                    <NumericFormat
                                                                      prefix="$"
                                                                      decimalScale={2}
                                                                      value={field.value || 0}
                                                                      className="form-control input"
                                                                      onChange={(e) => {
                                                                        onchangeMatserTable();
                                                                        // find the total of quantity and garment_rate based on rate and update the state
                                                                        const newRate = e.target.value.replace('$', '');
                                                                        const newValue = newRate === '' ? 0 : newRate; // Set value to 0 if input is empty
                                                                        setFieldValue(`garments[${index}].garment_rate`, newValue, false);
                                                                        setUpdatedPrintingGarmentData((prevGarmentsData) => {
                                                                          const updatedGarmentsData = [...prevGarmentsData];
                                                                          updatedGarmentsData[index] = {
                                                                            ...updatedGarmentsData[index],
                                                                            garment_rate: newValue,
                                                                          };
                                                                          return updatedGarmentsData;
                                                                        });
                                                                      }}
                                                                      onKeyDown={(e) => {
                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                          e.preventDefault();
                                                                        }
                                                                      }}
                                                                      onKeyUp={(e) => {
                                                                        if (e.key === 'Backspace' && field.value === 0) {
                                                                          setSetupCountKey(prevKey => prevKey + 1);
                                                                          setFieldValue(`garments[${index}].garment_rate`, 0.00, false);
                                                                          setUpdatedGarmentsData((prevGarmentsData) => {
                                                                            const updatedGarmentsData = [...prevGarmentsData];
                                                                            updatedGarmentsData[index] = {
                                                                              ...updatedGarmentsData[index],
                                                                              garment_rate: '0.00',
                                                                            };
                                                                            return updatedGarmentsData;
                                                                          });
                                                                        }
                                                                      }}
                                                                    />
                                                                  )}
                                                                />

                                                              </td>
                                                            </tr>
                                                          ))}
                                                        </>
                                                      )}
                                                    />
                                                  </tbody>
                                                </Table>
                                              </div>
                                            </Form>
                                          )}
                                        />
                                      )
                                    }
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col lg={3} sm={6} className="">
                                <Card className="card-contianer  mb-lg-0 mb-4 ">
                                  <CardBody className="">
                                    {
                                      getPrintingMasterTableLoadingStatus.isInkLoading ? (
                                        <div className="table-responsive">
                                          <table className="table align-middle mb-0 table-nowrap">
                                            <thead className="table-light">
                                              <tr>
                                                <th className="calculator-th width-70">Ink</th>
                                                <th className="calculator-th text-center">Rate</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {[...Array(5)].map((_, index) => (
                                                <tr key={index}>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                  </td>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        <Formik
                                          key={setupCountKey}
                                          initialValues={{
                                            ink: updatedPrintingInkData,
                                          }}
                                          render={({ values, setFieldValue }) => (
                                            <Form>
                                              <div className="table-responsive">
                                                <Table className="table align-middle mb-0 table-nowrap">
                                                  <thead className="table-light">
                                                    <tr>
                                                      <th className="calculator-th width-70">Ink</th>
                                                      <th className="calculator-th text-center">Rate</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="calculator-table-body">
                                                    <FieldArray
                                                      name="ink"
                                                      render={(arrayHelpers) => (
                                                        <>
                                                          {values?.ink?.map((ink, index) => (
                                                            <tr key={index}>
                                                              <td className="calculator-td">
                                                                {ink.ink_label}
                                                              </td>
                                                              <td className="calculator-td">
                                                                <Field
                                                                  name={`ink[${index}].ink_rate`}
                                                                  render={({ field }) => (
                                                                    <NumericFormat
                                                                      prefix="$"
                                                                      decimalScale={2}
                                                                      value={field.value || 0}
                                                                      className="form-control input"
                                                                      onChange={(e) => {
                                                                        onchangeMatserTable();
                                                                        // find the total of quantity and garment_rate based on rate and update the state
                                                                        const newRate = e.target.value.replace('$', '');
                                                                        const newValue = newRate === '' ? 0 : newRate; // Set value to 0 if input is empty
                                                                        setFieldValue(`ink[${index}].ink_rate`, newValue, false);
                                                                        setUpdatedPrintingInkData((prevInkData) => {
                                                                          const updatedInkData = [...prevInkData];
                                                                          updatedInkData[index] = {
                                                                            ...updatedInkData[index],
                                                                            ink_rate: newValue,
                                                                          };
                                                                          return updatedInkData;
                                                                        });
                                                                      }}
                                                                      onKeyDown={(e) => {
                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                          e.preventDefault();
                                                                        }
                                                                      }}
                                                                      onKeyUp={(e) => {
                                                                        if (e.key === 'Backspace' && field.value === 0) {
                                                                          setSetupCountKey(prevKey => prevKey + 1);
                                                                          setFieldValue(`garments[${index}].ink_rate`, 0.00, false);
                                                                          setUpdatedPrintingInkData((prevInkData) => {
                                                                            const updatedInkData = [...prevInkData];
                                                                            updatedInkData[index] = {
                                                                              ...updatedInkData[index],
                                                                              ink_rate: "0.0",
                                                                            };
                                                                            return updatedInkData;
                                                                          });
                                                                        }
                                                                      }}
                                                                    />
                                                                  )}
                                                                />
                                                              </td>
                                                            </tr>
                                                          ))}
                                                        </>
                                                      )}
                                                    />
                                                  </tbody>
                                                </Table>
                                              </div>
                                            </Form>
                                          )}
                                        />
                                      )
                                    }
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col lg={3} sm={6} className="">
                                <Card className="card-contianer  mb-lg-0 mb-4 ">
                                  <CardBody className="">
                                    {
                                      getPrintingMasterTableLoadingStatus.isSetuupLoading ? (
                                        <div className="table-responsive">
                                          <table className="table align-middle mb-0 table-nowrap">
                                            <thead className="table-light">
                                              <tr>
                                                <th className="calculator-th width-30">Setup</th>
                                                <th className="calculator-th text-center width-30">Quantity</th>
                                                <th className="calculator-th text-center width-30">Rate</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {[...Array(5)].map((_, index) => (
                                                <tr key={index}>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                  </td>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                  </td>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        <Formik
                                          key={setupCountKey}
                                          initialValues={{
                                            setup: updatedPrintingSetupData,
                                          }}
                                          render={({ values, setFieldValue }) => (
                                            <Form>
                                              <div className="table-responsive">
                                                <Table className="table align-middle mb-0 table-nowrap">
                                                  <thead className="table-light">
                                                    <tr>
                                                      <th className="calculator-th width-30">Setup</th>
                                                      <th className="calculator-th text-center width-30">Quantity</th>
                                                      <th className="calculator-th text-center width-30">Rate</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="calculator-table-body">
                                                    <FieldArray
                                                      name="ink"
                                                      render={(arrayHelpers) => (
                                                        <>
                                                          {values?.setup?.map((setup, index) => (
                                                            <tr key={index}>
                                                              <td className="calculator-td">
                                                                {setup.setup_label}
                                                              </td>
                                                              <td className="calculator-td">
                                                                <td className="calculator-td">
                                                                  {(setup.setup_label === 'Pre-Print' || setup.setup_label === 'Clean Up') && (
                                                                    <Field
                                                                      name={`setup[${index}].setup_quantity`}
                                                                      render={({ field }) => (
                                                                        <NumericFormat
                                                                          decimalScale={0}
                                                                          value={field.value || 0}
                                                                          className="form-control input"
                                                                          onChange={(e) => {
                                                                            onchangeMatserTable();
                                                                            // Find the total of quantity and garment_rate based on rate and update the state
                                                                            const newRate = e.target.value.replace('$', '');

                                                                            // Remove leading zeros before setting the new value
                                                                            const newValue = newRate.replace(/^0+/, '') || '0'; // Replace leading zeros, set to '0' if empty

                                                                            setFieldValue(`setup[${index}].setup_quantity`, newValue, false);
                                                                            setUpdatedPrintingSetupData((prevSetupData) => {
                                                                              const updatedSetupData = [...prevSetupData];
                                                                              updatedSetupData[index] = {
                                                                                ...updatedSetupData[index],
                                                                                setup_quantity: newValue,
                                                                              };
                                                                              return updatedSetupData;
                                                                            });
                                                                          }}
                                                                          onKeyDown={(e) => {
                                                                            if (e.key === '-' || e.keyCode === 189) {
                                                                              e.preventDefault();
                                                                            }
                                                                          }}
                                                                          onKeyUp={(e) => {
                                                                            if (e.key === 'Backspace' && field.value === 0) {
                                                                              setSetupCountKey(prevKey => prevKey + 1);
                                                                              setFieldValue(`setup[${index}].setup_quantity`, 0.00, false);
                                                                              setUpdatedPrintingSetupData((prevSetupData) => {
                                                                                const updatedSetupData = [...prevSetupData];
                                                                                updatedSetupData[index] = {
                                                                                  ...updatedSetupData[index],
                                                                                  setup_quantity: "0.0",
                                                                                };
                                                                                return updatedSetupData;
                                                                              });
                                                                            }
                                                                          }}
                                                                        />
                                                                      )}
                                                                    />
                                                                  )}
                                                                </td>
                                                              </td>
                                                              <td className="calculator-td">
                                                                <Field
                                                                  name={`setup[${index}].setup_rate`}
                                                                  render={({ field }) => (
                                                                    <NumericFormat
                                                                      prefix="$"
                                                                      decimalScale={2}
                                                                      value={field.value || 0}
                                                                      className="form-control input"
                                                                      onChange={(e) => {
                                                                        onchangeMatserTable();
                                                                        // find the total of quantity and garment_rate based on rate and update the state
                                                                        const newRate = e.target.value.replace('$', '');
                                                                        const newValue = newRate === '' ? 0 : newRate; // Set value to 0 if input is empty
                                                                        setFieldValue(`setup[${index}].setup_rate`, newValue, false);
                                                                        setUpdatedPrintingSetupData((prevSetupData) => {
                                                                          const updatedSeupData = [...prevSetupData];
                                                                          updatedSeupData[index] = {
                                                                            ...updatedSeupData[index],
                                                                            setup_rate: newValue,
                                                                          };
                                                                          return updatedSeupData;
                                                                        });
                                                                      }}
                                                                      onKeyDown={(e) => {
                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                          e.preventDefault();
                                                                        }
                                                                      }}
                                                                      onKeyUp={(e) => {
                                                                        if (e.key === 'Backspace' && field.value === 0) {
                                                                          setSetupCountKey(prevKey => prevKey + 1);
                                                                          setFieldValue(`setup[${index}].setup_rate`, 0.00, false);
                                                                          setUpdatedPrintingSetupData((prevSetupData) => {
                                                                            const updatedSeupData = [...prevSetupData];
                                                                            updatedSeupData[index] = {
                                                                              ...updatedSeupData[index],
                                                                              setup_rate: "0.0",
                                                                            };
                                                                            return updatedSeupData;
                                                                          });
                                                                        }
                                                                      }}
                                                                    />
                                                                  )}
                                                                />
                                                              </td>
                                                            </tr>
                                                          ))}
                                                        </>
                                                      )}
                                                    />
                                                  </tbody>
                                                </Table>
                                              </div>
                                            </Form>
                                          )}
                                        />
                                      )
                                    }
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col lg={3} sm={6} className="">
                                <Card className="card-contianer mb-4 ">
                                  <CardBody className="">
                                    {
                                      getPrintingMasterTableLoadingStatus.isArtLoading ? (
                                        <div className="table-responsive">
                                          <table className="table align-middle mb-0 table-nowrap">
                                            <thead className="table-light">
                                              <tr>
                                                <th className="calculator-th width-70">Art</th>
                                                <th className="calculator-th text-center">Rate</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {[...Array(2)].map((_, index) => (
                                                <tr key={index}>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                  </td>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        <Formik
                                          key={setupCountKey}
                                          initialValues={{
                                            artData: updatedPrintingArtData,
                                          }}
                                          render={({ values, setFieldValue }) => (
                                            <Form>
                                              <div className="table-responsive">
                                                <Table className="table align-middle mb-0 table-nowrap">
                                                  <thead className="table-light">
                                                    <tr>
                                                      <th className="calculator-th width-70">Art</th>
                                                      <th className="calculator-th text-center">Rate</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="calculator-table-body">
                                                    <FieldArray
                                                      name="artData"
                                                      render={(arrayHelpers) => (
                                                        <>
                                                          {values?.artData?.map((artData, index) => (
                                                            // Conditionally render the row only if the label is "Shirts / Hour"
                                                            <tr key={index}>
                                                              <td className="calculator-td">
                                                                {artData.art_label}
                                                              </td>
                                                              <td className="calculator-td">
                                                                <Field
                                                                  name={`artData[${index}].art_rate`}
                                                                  render={({ field }) => (
                                                                    <NumericFormat
                                                                      prefix="$"
                                                                      decimalScale={2}
                                                                      value={field.value || 0}
                                                                      className="form-control input"
                                                                      onChange={(e) => {
                                                                        onchangeMatserTable();
                                                                        // Find the total of quantity and garment_rate based on rate and update the state
                                                                        const newRate = e.target.value.replace('$', '');
                                                                        // Remove leading zeros before setting the new value
                                                                        const newValue = newRate.replace(/^0+/, '') || '0'; // Replace leading zeros, set to '0' if empty
                                                                        setFieldValue(`artData[${index}].art_rate`, newValue, false);
                                                                        setUpdatedPrintingArtData((prevsJobriteriaData) => {
                                                                          const updatedJobCriteriaData = [...prevsJobriteriaData];
                                                                          updatedJobCriteriaData[index] = {
                                                                            ...updatedJobCriteriaData[index],
                                                                            art_rate: newValue,
                                                                          };
                                                                          return updatedJobCriteriaData;
                                                                        });
                                                                      }}
                                                                      onKeyDown={(e) => {
                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                          e.preventDefault();
                                                                        }
                                                                      }}
                                                                      onKeyUp={(e) => {
                                                                        // Allow setting the value to 0.0 when Backspace is pressed
                                                                        if (e.key === 'Backspace' && field.value === '0') {
                                                                          setSetupCountKey(prevKey => prevKey + 1);
                                                                          setFieldValue(`artData[${index}].art_rate`, '0.00', false);
                                                                          setUpdatedPrintingArtData((prevsJobriteriaData) => {
                                                                            const updatedJobCriteriaData = [...prevsJobriteriaData];
                                                                            updatedJobCriteriaData[index] = {
                                                                              ...updatedJobCriteriaData[index],
                                                                              art_rate: '0.0',
                                                                            };
                                                                            return updatedJobCriteriaData;
                                                                          });
                                                                        }
                                                                      }}
                                                                    />
                                                                  )}
                                                                />
                                                              </td>
                                                            </tr>
                                                          ))}
                                                        </>
                                                      )}
                                                    />

                                                  </tbody>
                                                </Table>
                                              </div>
                                            </Form>
                                          )}
                                        />
                                      )
                                    }
                                  </CardBody>
                                </Card>
                                <Card className="card-contianer  mb-lg-0 mb-4 ">
                                  <CardBody className="">
                                    {
                                      getPrintingMasterTableLoadingStatus.isJobCriteriaLoading ? (
                                        <div className="table-responsive">
                                          <table className="table align-middle mb-0 table-nowrap">
                                            <thead className="table-light">
                                              <tr>
                                                <th className="calculator-th width-70">Job Criteria</th>
                                                <th className="calculator-th text-center">Rate</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {[...Array(2)].map((_, index) => (
                                                <tr key={index}>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                  </td>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        <Formik
                                          key={setupCountKey}
                                          initialValues={{
                                            jobCriteria: updatedPrintingJobCriteriaData,
                                          }}
                                          render={({ values, setFieldValue }) => (
                                            <Form>
                                              <div className="table-responsive">
                                                <Table className="table align-middle mb-0 table-nowrap">
                                                  <thead className="table-light">
                                                    <tr>
                                                      <th className="calculator-th width-70">Job Criteria</th>
                                                      <th className="calculator-th text-center">Rate</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="calculator-table-body">
                                                    <FieldArray
                                                      name="jobCriteria"
                                                      render={(arrayHelpers) => (
                                                        <>
                                                          {values?.jobCriteria?.map((jobCriteria, index) => (
                                                            // Conditionally render the row only if the label is "Shirts / Hour"
                                                            jobCriteria.job_criteria_label === "Shirts / Hour" && (
                                                              <tr key={index}>
                                                                <td className="calculator-td">
                                                                  {jobCriteria.job_criteria_label}
                                                                </td>
                                                                <td className="calculator-td">
                                                                  <Field
                                                                    name={`jobCriteria[${index}].job_criteria_quantity`}
                                                                    render={({ field }) => (
                                                                      <NumericFormat
                                                                        decimalScale={0}
                                                                        value={field.value || 0}
                                                                        className="form-control input"
                                                                        onChange={(e) => {
                                                                          onchangeMatserTable();
                                                                          // Find the total of quantity and garment_rate based on rate and update the state
                                                                          const newRate = e.target.value.replace('$', '');
                                                                          // Remove leading zeros before setting the new value
                                                                          const newValue = newRate.replace(/^0+/, '') || '0'; // Replace leading zeros, set to '0' if empty
                                                                          setFieldValue(`jobCriteria[${index}].job_criteria_quantity`, newValue, false);
                                                                          setUpdatedPrintingJobCriteriaData((prevsJobriteriaData) => {
                                                                            const updatedJobCriteriaData = [...prevsJobriteriaData];
                                                                            updatedJobCriteriaData[index] = {
                                                                              ...updatedJobCriteriaData[index],
                                                                              job_criteria_quantity: newValue,
                                                                            };
                                                                            return updatedJobCriteriaData;
                                                                          });
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                          if (e.key === '-' || e.keyCode === 189) {
                                                                            e.preventDefault();
                                                                          }
                                                                        }}
                                                                        onKeyUp={(e) => {
                                                                          // Allow setting the value to 0.0 when Backspace is pressed
                                                                          if (e.key === 'Backspace' && field.value === '0') {
                                                                            setSetupCountKey(prevKey => prevKey + 1);
                                                                            setFieldValue(`jobCriteria[${index}].job_criteria_quantity`, '0.00', false);
                                                                            setUpdatedPrintingJobCriteriaData((prevsJobriteriaData) => {
                                                                              const updatedJobCriteriaData = [...prevsJobriteriaData];
                                                                              updatedJobCriteriaData[index] = {
                                                                                ...updatedJobCriteriaData[index],
                                                                                job_criteria_quantity: '0.0',
                                                                              };
                                                                              return updatedJobCriteriaData;
                                                                            });
                                                                          }
                                                                        }}
                                                                      />
                                                                    )}
                                                                  />
                                                                </td>
                                                              </tr>
                                                            )
                                                          ))}
                                                        </>
                                                      )}
                                                    />

                                                  </tbody>
                                                </Table>
                                              </div>
                                            </Form>
                                          )}
                                        />
                                      )
                                    }
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                            : <Row className="">
                              <Col lg={3} sm={6} className="">
                                <Card className="card-contianer  mb-lg-0 mb-4 ">
                                  <CardBody className="">
                                    {
                                      masterTableLoadingStatus.isGarmentisLoading ? (
                                        <div className="table-responsive">
                                          <table className="table align-middle mb-0 table-nowrap">
                                            <thead className="table-light">
                                              <tr>
                                                <th className="calculator-th width-70">Garments</th>
                                                <th className="calculator-th text-center">Rate</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {[...Array(5)].map((_, index) => (
                                                <tr key={index}>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                  </td>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        <Formik
                                          key={setupCountKey}
                                          initialValues={{
                                            garments: updatedGarmentsData,
                                          }}
                                          render={({ values, setFieldValue }) => (
                                            <Form>
                                              <div className="table-responsive">
                                                <Table className="table align-middle mb-0 table-nowrap">
                                                  <thead className="table-light">
                                                    <tr>
                                                      <th className="calculator-th width-70">Garments</th>
                                                      <th className="calculator-th text-center">Rate</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="calculator-table-body">
                                                    <FieldArray
                                                      name="garments"
                                                      render={(arrayHelpers) => (
                                                        <>
                                                          {values?.garments?.map((garment, index) => (
                                                            <tr key={index}>
                                                              <td className="calculator-td">
                                                                {garment.garment_label}
                                                              </td>
                                                              <td className="calculator-td">
                                                                <Field
                                                                  name={`garments[${index}].garment_rate`}
                                                                  render={({ field }) => (
                                                                    <NumericFormat
                                                                      prefix="$"
                                                                      decimalScale={2}
                                                                      value={field.value || 0}
                                                                      className="form-control input"
                                                                      onChange={(e) => {
                                                                        onchangeMatserTable();
                                                                        // find the total of quantity and garment_rate based on rate and update the state
                                                                        const newRate = e.target.value.replace('$', '');
                                                                        const newValue = newRate === '' ? 0 : newRate; // Set value to 0 if input is empty
                                                                        setFieldValue(`garments[${index}].garment_rate`, newValue, false);
                                                                        setUpdatedGarmentsData((prevGarmentsData) => {
                                                                          const updatedGarmentsData = [...prevGarmentsData];
                                                                          updatedGarmentsData[index] = {
                                                                            ...updatedGarmentsData[index],
                                                                            garment_rate: newValue,
                                                                          };
                                                                          return updatedGarmentsData;
                                                                        });
                                                                      }}
                                                                      onKeyDown={(e) => {
                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                          e.preventDefault();
                                                                        }
                                                                      }}
                                                                      onKeyUp={(e) => {
                                                                        if (e.key === 'Backspace' && field.value === 0) {
                                                                          setSetupCountKey(prevKey => prevKey + 1);
                                                                          setFieldValue(`garments[${index}].garment_rate`, 0.00, false);
                                                                          setUpdatedGarmentsData((prevGarmentsData) => {
                                                                            const updatedGarmentsData = [...prevGarmentsData];
                                                                            updatedGarmentsData[index] = {
                                                                              ...updatedGarmentsData[index],
                                                                              garment_rate: '0.00',
                                                                            };
                                                                            return updatedGarmentsData;
                                                                          });
                                                                        }
                                                                      }}
                                                                    />
                                                                  )}
                                                                />

                                                              </td>
                                                            </tr>
                                                          ))}

                                                        </>
                                                      )}
                                                    />
                                                  </tbody>
                                                </Table>
                                              </div>
                                            </Form>
                                          )}
                                        />
                                      )
                                    }
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col lg={3} sm={6} className="">
                                <Card className="card-contianer mb-lg-0 mb-4">
                                  <CardBody>
                                    {
                                      masterTableLoadingStatus.isStitchCountIsLoading ? (
                                        <div className="table-responsive">
                                          <table className="table align-middle mb-0 table-nowrap">
                                            <thead className="table-light">
                                              <tr>
                                                <th className="calculator-th width-70">Stitch Count	</th>
                                                <th className="calculator-th text-center">Rate</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {[...Array(5)].map((_, index) => (
                                                <tr key={index}>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                  </td>

                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        <Formik
                                          key={setupCountKey}
                                          initialValues={{
                                            stitchcount: updatedStitchCountMasterData,
                                          }}
                                          render={({ values, setFieldValue }) => (
                                            <Form>
                                              <div className="table-responsive">
                                                <Table className="table align-middle mb-0 table-nowrap">
                                                  <thead className="table-light">
                                                    <tr>
                                                      <th className="calculator-th width-70">Stitch Count	</th>
                                                      <th className="calculator-th text-center">Rate</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="calculator-table-body">
                                                    <FieldArray
                                                      name="stitchcount"
                                                      render={(arrayHelpers) => (
                                                        <>
                                                          {values?.stitchcount?.map((stitchcount, index) => (
                                                            <tr key={index}>
                                                              <td className="calculator-td">
                                                                {stitchcount.stitch_count_label}
                                                              </td>
                                                              <td className="calculator-td">
                                                                <Field
                                                                  name={`stitchcount[${index}].stitch_count_rate`}
                                                                  render={({ field }) => (
                                                                    <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control input"
                                                                      onChange={(e) => {
                                                                        onchangeMatserTable();
                                                                        // find the total of quantity and stitch_count_rate based on rate and update the state
                                                                        const newRate = e.target.value.replace('$', '');
                                                                        setFieldValue(`stitchcount[${index}].stitch_count_rate`, newRate === '' ? 0 : newRate, false);
                                                                        setUpdatedStitchCountMasterData((prevGarmentsData) => {
                                                                          const updatedGarmentsData = [...prevGarmentsData];
                                                                          updatedGarmentsData[index] = {
                                                                            ...updatedGarmentsData[index],
                                                                            stitch_count_rate: newRate === '' ? 0 : newRate,
                                                                          };
                                                                          return updatedGarmentsData;
                                                                        });
                                                                      }}
                                                                      onKeyDown={(e) => {
                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                          e.preventDefault();
                                                                        }
                                                                      }}
                                                                      onKeyUp={(e) => {
                                                                        if (e.key === 'Backspace' && field.value === 0) {
                                                                          setSetupCountKey(prevKey => prevKey + 1);
                                                                          setFieldValue(`stitchcount[${index}].stitch_count_rate`, 0.00, false);
                                                                          setUpdatedStitchCountMasterData((prevGarmentsData) => {
                                                                            const updatedGarmentsData = [...prevGarmentsData];
                                                                            updatedGarmentsData[index] = {
                                                                              ...updatedGarmentsData[index],
                                                                              stitch_count_rate: '0.00',
                                                                            };
                                                                            return updatedGarmentsData;
                                                                          });
                                                                        }
                                                                      }}
                                                                    />
                                                                  )}
                                                                />
                                                              </td>
                                                            </tr>
                                                          ))}

                                                        </>
                                                      )}
                                                    />
                                                  </tbody>
                                                </Table>
                                              </div>
                                            </Form>
                                          )}
                                        />
                                      )
                                    }
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col lg={3} sm={6} className="">
                                <Card className="card-contianer">
                                  <CardBody>
                                    {
                                      masterTableLoadingStatus.isSetupIsLoading ? (
                                        <div className="table-responsive">
                                          <table className="table align-middle mb-0 table-nowrap">
                                            <thead className="table-light">
                                              <tr>
                                                <th className="calculator-th width-70">Setup</th>
                                                <th className="calculator-th text-center">Rate</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {[...Array(5)].map((_, index) => (
                                                <tr key={index}>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                  </td>

                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        <Formik
                                          key={setupCountKey}
                                          initialValues={{
                                            stitchcount: updatedSetupMasterData,
                                          }}
                                          render={({ values, setFieldValue }) => (
                                            <Form>
                                              <div className="table-responsive">
                                                <Table className="table align-middle mb-0 table-nowrap">
                                                  <thead className="table-light">
                                                    <tr>
                                                      <th className="calculator-th width-70">Setup</th>
                                                      <th className="calculator-th text-center">Rate</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="calculator-table-body">
                                                    <FieldArray
                                                      name="stitchcount"
                                                      render={(arrayHelpers) => (
                                                        <>
                                                          {values?.stitchcount?.map((stitchcount, index) => (
                                                            <tr key={index}>
                                                              <td className="calculator-td">
                                                                {stitchcount.setup_label}
                                                              </td>
                                                              <td className="calculator-td">
                                                                <Field
                                                                  name={`stitchcount[${index}].setup_rate`}
                                                                  render={({ field }) => (
                                                                    <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control input"
                                                                      onChange={(e) => {
                                                                        onchangeMatserTable();
                                                                        // find the total of quantity and stitch_count_rate based on rate and update the state
                                                                        const newRate = e.target.value.replace('$', '');
                                                                        setFieldValue(`stitchcount[${index}].setup_rate`, newRate === '' ? 0 : newRate, false);
                                                                        setUpdatedSetupMasterData((prevGarmentsData) => {
                                                                          const updatedGarmentsData = [...prevGarmentsData];
                                                                          updatedGarmentsData[index] = {
                                                                            ...updatedGarmentsData[index],
                                                                            setup_rate: newRate === '' ? 0 : newRate,
                                                                          };
                                                                          return updatedGarmentsData;
                                                                        });
                                                                      }}
                                                                      onKeyDown={(e) => {
                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                          e.preventDefault();
                                                                        }
                                                                      }}
                                                                      onKeyUp={(e) => {
                                                                        if (e.key === 'Backspace' && field.value === 0) {
                                                                          setSetupCountKey(prevKey => prevKey + 1);
                                                                          setFieldValue(`technicalspecification[${index}].technical_specification_quantity`, 0, false);
                                                                          setFieldValue(`stitchcount[${index}].setup_rate`, 0, false);
                                                                          setUpdatedSetupMasterData((prevGarmentsData) => {
                                                                            const updatedGarmentsData = [...prevGarmentsData];
                                                                            updatedGarmentsData[index] = {
                                                                              ...updatedGarmentsData[index],
                                                                              setup_rate: '0.00',
                                                                            };
                                                                            return updatedGarmentsData;
                                                                          });
                                                                        }
                                                                      }}
                                                                    />
                                                                  )}
                                                                />
                                                              </td>
                                                            </tr>
                                                          ))}
                                                        </>
                                                      )}
                                                    />
                                                  </tbody>
                                                </Table>
                                              </div>
                                            </Form>
                                          )}
                                        />
                                      )
                                    }
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col lg={3} sm={6} className="">
                                <Card className="card-contianer">
                                  <CardBody>
                                    {
                                      masterTableLoadingStatus.isTechnicalSpecificationIsLoading ? (
                                        <div className="table-responsive">
                                          <table className="table align-middle mb-0 table-nowrap">
                                            <thead className="table-light">
                                              <tr>
                                                <th className="calculator-th width-70">Technical Specification	</th>
                                                <th className="calculator-th text-center">Rate</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {[...Array(5)].map((_, index) => (
                                                <tr key={index}>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                  </td>
                                                  <td className="calculator-td">
                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        <Formik
                                          key={setupCountKey}
                                          initialValues={{
                                            technicalspecification: updatedTechnicalSpecificationData,
                                          }}
                                          render={({ values, setFieldValue }) => (
                                            <Form>
                                              <div className="table-responsive">
                                                <Table className="table align-middle mb-0 table-nowrap">
                                                  <thead className="table-light">
                                                    <tr>
                                                      <th className="calculator-th width-70">Technical Specification	</th>
                                                      <th className="calculator-th text-center">Rate</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="calculator-table-body">
                                                    <FieldArray
                                                      name="technicalspecification"
                                                      render={(arrayHelpers) => (
                                                        <>
                                                          {values?.technicalspecification?.map((technicalspecification, index) => (
                                                            <tr key={index}>
                                                              <td className="calculator-td">
                                                                {technicalspecification.technical_specification_label}<span className="text-danger">*</span>
                                                              </td>
                                                              <td className="calculator-td">
                                                                <Field
                                                                  name={`technicalspecification[${index}].technical_specification_quantity`}
                                                                  render={({ field }) => (
                                                                    <NumericFormat decimalScale={2} value={field.value || 0} className="form-control input"
                                                                      onChange={(e) => {
                                                                        onchangeMatserTable();
                                                                        const newRateNumber = parseFloat(e.target.value.length === 0 ? '0' : e.target.value); // Convert to number
                                                                        setFieldValue(`technicalspecification[${index}].technical_specification_quantity`, newRateNumber, false);
                                                                        setUpdatedTechnicalSpecificationData((prevGarmentsData) => {
                                                                          const updatedGarmentsData = [...prevGarmentsData];
                                                                          updatedGarmentsData[index] = {
                                                                            ...updatedGarmentsData[index],
                                                                            technical_specification_quantity: newRateNumber,
                                                                          };
                                                                          return updatedGarmentsData;
                                                                        });
                                                                      }}
                                                                      onKeyDown={(e) => {
                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                          e.preventDefault();
                                                                        }
                                                                      }}
                                                                    />
                                                                  )}
                                                                />
                                                              </td>
                                                            </tr>
                                                          ))}
                                                        </>
                                                      )}
                                                    />
                                                  </tbody>
                                                </Table>
                                              </div>
                                            </Form>
                                          )}
                                        />
                                      )
                                    }
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                        }
                        {
                          isEditMode && (
                            <div className="d-flex justify-content-end">
                              <button className="btn btn-outline me-2" onClick={() => { onclickCancleSetupRates(); }}>Cancel</button>
                              <button className="btn btn-fill d-flex align-items-center justify-content-center" disabled={activeSetupTable === SUBSCRIPTION_CALC_TYPE.EMBROIDERY ? updatedTechnicalSpecificationData[0].technical_specification_quantity === '0' : false} onClick={() => { onclickSaveDefaultrate(); }}>{isUpdateDefaultRateIsLoading && <Spinners setLoading={setUpdateDefaultRateIsLoading} />} Save</button>
                            </div>
                          )
                        }
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      }
      {userRole === 'admin' &&
        <div className="page-content dashboard-page">
          <Container fluid>
            <Row className="mt-3">
              <Col sm="6" xl="3">
                <Card>
                  <CardBody>
                    {
                      isAdminStatisticsIsLoading ? (
                        <div>
                          <div className="d-flex align-items-center">
                            <div>
                              <Skeleton circle width={30} height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft me-2" />
                            </div>
                            <div className="flex-grow-1 ml-2">
                              <Skeleton height={33} width="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                            </div>
                          </div>
                          <Skeleton height={33} width="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="mt-3 bg-soft" />
                        </div>
                      ) : (
                        <div>
                          <div className="mb-3 pb-lg-1 d-flex align-items-center">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18">
                                <i className="fas fa-users-cog" />
                              </span>
                            </div>
                            <h5 className="font-size-14 mb-0">Customers On Trial</h5>
                          </div>
                          <div className="text-muted mt-1 font-weight-600"  >
                            <h3 className="mb-0 text_number ps-2" id="total_customers_on_trial_admin">
                              {adminDashboardStatisctics === undefined ? 0 : adminDashboardStatisctics?.total_customers_on_trial}{" "}
                            </h3>
                            {
                              adminDashboardStatisctics?.total_customers_on_trial?.length > 9 && (
                                <UncontrolledTooltip target={'total_customers_on_trial_admin'}>{adminDashboardStatisctics?.total_customers_on_trial}</UncontrolledTooltip>
                              )
                            }
                          </div>
                        </div>
                      )
                    }
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6" xl="3">
                <Card>
                  <CardBody>
                    {
                      isAdminStatisticsIsLoading ? (
                        <div>
                          <div className="d-flex align-items-center">
                            <div>
                              <Skeleton circle width={30} height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft me-2" />
                            </div>
                            <div className="flex-grow-1 ml-2">
                              <Skeleton height={33} width="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                            </div>
                          </div>
                          <Skeleton height={33} width="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="mt-3 bg-soft" />
                        </div>
                      ) : (
                        <div>
                          <div className="mb-3 pb-lg-1 d-flex align-items-center">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18">
                                <i className="mdi-account-clock mdi" />
                              </span>
                            </div>
                            <h5 className="font-size-14 mb-0">Pending Subscription To Renew</h5>
                          </div>
                          <div className="text-muted mt-1 font-weight-600"  >
                            <h3 className="mb-0 text_number ps-2" id="remaining_to_renew_subscriptions_admin">
                              {adminDashboardStatisctics === undefined ? 0 : adminDashboardStatisctics?.remaining_to_renew_subscriptions}{" "}
                            </h3>
                            {
                              adminDashboardStatisctics?.remaining_to_renew_subscriptions?.length > 9 && (
                                <UncontrolledTooltip target={'remaining_to_renew_subscriptions_admin'}>{adminDashboardStatisctics?.remaining_to_renew_subscriptions}</UncontrolledTooltip>
                              )
                            }
                          </div>
                        </div>
                      )
                    }
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6" xl="3">
                <Card>
                  <CardBody>
                    {
                      isAdminStatisticsIsLoading ? (
                        <div>
                          <div className="d-flex align-items-center">
                            <div>
                              <Skeleton circle width={30} height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft me-2" />
                            </div>
                            <div className="flex-grow-1 ml-2">
                              <Skeleton height={33} width="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                            </div>
                          </div>
                          <Skeleton height={33} width="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="mt-3 bg-soft" />
                        </div>
                      ) : (
                        <div>
                          <div className="mb-3 pb-lg-1 d-flex align-items-center">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18">
                                <i className="mdi-account-multiple-plus mdi" />
                              </span>
                            </div>
                            <h5 className="font-size-14 mb-0">Total Subscribers</h5>
                          </div>
                          <div className="text-muted mt-1 font-weight-600"  >
                            <h3 className="mb-0 text_number ps-2" id="total_subscribers_admin">
                              {adminDashboardStatisctics === undefined ? 0 : adminDashboardStatisctics?.total_subscribers}{" "}
                            </h3>
                            {
                              adminDashboardStatisctics?.total_subscribers?.length > 9 && (
                                <UncontrolledTooltip target={'total_subscribers_admin'}>{adminDashboardStatisctics?.total_subscribers}</UncontrolledTooltip>
                              )
                            }
                          </div>
                        </div>
                      )
                    }
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6" xl="3">
                <Card>
                  <CardBody>
                    {
                      isAdminStatisticsIsLoading ? (
                        <div>
                          <div className="d-flex align-items-center">
                            <div>
                              <Skeleton circle width={30} height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft me-2" />
                            </div>
                            <div className="flex-grow-1 ml-2">
                              <Skeleton height={33} width="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                            </div>
                          </div>
                          <Skeleton height={33} width="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="mt-3 bg-soft" />
                        </div>
                      ) : (
                        <>
                          <div className="mb-3 pb-lg-1 d-flex align-items-center">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18">
                                <i className="fas fa-money-check-alt fas" />
                              </span>
                            </div>
                            <h5 className="font-size-14 mb-0">Total Revenue</h5>
                          </div>
                          <div className="text-muted mt-1 font-weight-600"  >
                            <h3 className="mb-0 text_number ps-2" id="total_revenue_admin">
                              ${adminDashboardStatisctics === undefined ? 0 : adminDashboardStatisctics?.total_revenue}{" "}
                            </h3>
                            {
                              adminDashboardStatisctics?.total_revenue?.length > 7 && (
                                <UncontrolledTooltip target={'total_revenue_admin'}>${adminDashboardStatisctics?.total_revenue}</UncontrolledTooltip>
                              )
                            }
                          </div>
                        </>
                      )
                    }
                  </CardBody>
                </Card>
              </Col>
              {/* for subscriber evolution graph */}
              <Col xl="8">
                <Card>
                  <CardBody className="graph-mobile">
                    <div className="d-sm-flex flex-wrap justify-content-between">
                      <h4 className="card-title d-flex align-items-center mb-md-0">Subscriber Evolution by Month</h4>
                      <div className="float-end">
                        <div className="input-group input-group-sm">
                          <select
                            className="form-select form-select-sm"
                            value={selectedYear}
                            onChange={(e) => {
                              onChangeYear(e.target.value);
                            }}
                          >
                            {yearOptions}
                          </select>
                          <label className="input-group-text">Year</label>
                        </div>
                      </div>
                    </div>
                    <ReactApexChart
                      options={adminOptions}
                      series={[...yearData]}
                      type="bar"
                      height="359"
                      className="apex-charts dashboard-monthly-apex-charts"
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card className="monthly-earning-card">
                  <CardBody>
                    <h4 className="card-title mt-0">Monthly Subscriptions & Earnings</h4>
                    <Row>
                      <Col sm="12 d-flex flex-column justify-content-center">
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <div>
                          {
                            isAdminStatisticsIsLoading ? (
                              <div style={{ width: '250px', height: '250px', position: 'relative' }}>
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '210px', height: '210px', borderRadius: '50%', overflow: 'hidden' }}>
                                  <Skeleton type="circle" width="100%" height="100%" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" />
                                </div>
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '150px', height: '150px', borderRadius: '50%', overflow: 'hidden', backgroundColor: 'var(--primary-white)' }}>
                                  <Skeleton type="circle" width="100%" height="100%" baseColor='var(--primary-white)' highlightColor="var(--primary-white)" />
                                </div>
                              </div>
                            ) : (adminDashboardStatisctics?.total_subscribers_current_month !== 0 && adminDashboardStatisctics?.total_subscribers_current_month !== '0') ? (<div id="donut-chart">
                              <ReactApexChart
                                options={adminMonthlyEarning}
                                series={[+adminDashboardStatisctics?.total_paid_subscribers_current_month, adminDashboardStatisctics?.total_subscribers_current_month - adminDashboardStatisctics?.total_paid_subscribers_current_month]}
                                type="donut"
                                height={260}
                                className="apex-charts"
                              />
                            </div>) : (

                              <div className="no_records">
                                <i className="bi bi-info-circle me-2"></i>
                                <span>No subscription have been created</span>
                              </div>
                            )
                          }
                          <h3 className="text-center cursor-pointer" id="monthly-earn-amount">${adminDashboardStatisctics === undefined ? 0.00 : adminDashboardStatisctics?.current_month_earning === null ? 0.00 : adminDashboardStatisctics?.current_month_earning}</h3>
                          <UncontrolledTooltip placement="bottom" target="monthly-earn-amount" style={{ background: 'black' }}>
                            The amount earned from subscription(s) in {currentMonthYear}.
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-xxl-3 mx-0">
                      <Col xl="12">
                        <div className="row total-subscriber-row d-flex py-2 text-center">
                          <div className="col-4 total-subscriber">
                            <h5 className="mb-1 text-yellow font-weight-500">
                              {adminDashboardStatisctics === undefined ? 0 : adminDashboardStatisctics?.total_subscribers_current_month}
                            </h5>
                            <p className="mb-0 font-size-12">
                              Total Subscribers
                            </p>
                          </div>
                          <div className="col-4 total-subscriber">
                            <h5 className="mb-1 color-secondary font-weight-500">
                              {adminDashboardStatisctics === undefined ? 0 : adminDashboardStatisctics?.total_paid_subscribers_current_month}
                            </h5>
                            <p className="mb-0 font-size-12">
                              Paid Subscribers
                            </p>
                          </div>
                          <div className="col-4">
                            <h5 className="mb-1 text-danger font-weight-500">
                              {adminDashboardStatisctics === undefined ? 0 : adminDashboardStatisctics?.total_subscribers_current_month - adminDashboardStatisctics?.total_paid_subscribers_current_month}
                            </h5>
                            <p className="mb-0 font-size-12">
                              Unpaid Subscribers
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="mb-4 h4 card-title">Latest Transaction</div>
                    <div className="table-responsive">
                      <Table >
                        <thead className="table-sticky-header">
                          <tr role="row">
                            <th className="transaction-head">Transaction ID</th>
                            <th className="transaction-head">User</th>
                            <th className="transaction-head">Created at</th>
                            <th className="transaction-head">Paid amount</th>
                            <th className="transaction-head">Paid on</th>
                            <th className="transaction-head">Payment Status</th>
                            <th className="transaction-head">	Payment Method</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            !isAdminStatisticsIsLoading && adminDashboardStatisctics?.latest_transactions?.length === 0 && (
                              <tr className="border-0 ">
                                <td colSpan={7} className="text-center border-0 ">
                                  <p className="mt-3 mb-0 latets-transaction-found"><i className="bi bi-info-circle me-2" /> No Latest Transaction Found</p>
                                </td>
                              </tr>
                            )
                          }
                          {
                            isAdminStatisticsIsLoading ? (
                              [...Array(5)].map((_, index) => (
                                <tr key={index}>
                                  <td className="calculator-td">
                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                  </td>
                                  <td className="calculator-td">
                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                  </td> <td className="calculator-td">
                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                  </td> <td className="calculator-td">
                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                  </td> <td className="calculator-td">
                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                  </td> <td className="calculator-td">
                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                  </td>
                                  <td className="calculator-td">
                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                  </td>
                                </tr>
                              ))
                            ) : (
                              adminDashboardStatisctics?.latest_transactions?.map((obj, index) => (
                                <tr>
                                  <td><span className="d-block transaction-id white-space">{obj?.transaction_id === null ? '----' : obj?.transaction_id}</span></td>
                                  <td><span className={`${obj?.user_name.legend > 10 ? 'transaction-ellipsis' : ''}  transaction-user white-space d-block`} id={`user_name${index}`}>{obj?.user_name}  {
                                    obj.user_name?.length > 10 && (
                                      <UncontrolledTooltip target={`user_name${index}`}>{obj?.user_name}</UncontrolledTooltip>
                                    )
                                  }  </span></td>
                                  <td><span className="created-at white-space d-block">{UTCtoLocal(obj?.created_at)}</span></td>
                                  <td><span className="paid-amount white-space d-block">{obj?.payment_amount}</span></td>
                                  <td><span className="paid-on white-space d-block">{UTCtoLocal(obj?.paid_on)}</span></td>
                                  <td ><span className="payment-status white-space">{obj.payment_status === "succeeded" ? (<Badge className={`approved-status  me-1 quote-date-div status-badge text-dark w-100`}>
                                    {(obj.payment_status).toUpperCase()}
                                  </Badge>) : (
                                    <Badge className={`approved-status  me-1 quote-date-div status-badge text-dark w-100`}>
                                      {(obj.payment_status).toUpperCase()}
                                    </Badge>
                                  )} </span> </td>
                                  <td  ><span className="payment-method white-space">{obj?.payment_method}</span></td>
                                </tr>
                              ))
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      }
      <ConfirmationModal
        show={confirmationPoup}
        title={'Reset Default Rate'}
        description={`Are you sure you want to reset your ${activeSetupTable === SUBSCRIPTION_CALC_TYPE.EMBROIDERY ? SUBSCRIPTION_CALC_TYPE.EMBROIDERY : SUBSCRIPTION_CALC_TYPE.SCREENPRINTING} rates to default rates?`}
        actionName={'Confirm'}
        onAction={() => resetToDefault()}
        afterAction={isSaveDefaultRateLoading}
        onCloseClick={() => setConfirmationPopup(false)}
      />
      {/* toast message */}
      <ToastContainer />
    </React.Fragment >
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default Dashboard;
