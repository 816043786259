import React, { useEffect, useState, useCallback, useRef } from "react";
import { Card, Col, Row, Modal, ModalHeader, ModalBody, UncontrolledTooltip, Input, FormFeedback, Label, Form } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//Dropzone
import { useDropzone } from 'react-dropzone';
import { registerValidationErr, Regex } from '../../../shared/static/constants/constant';
// service
import { CustomerService } from '../../service/customer.service';
// navigation
import { Link } from "react-router-dom";
import { PatternFormat } from "react-number-format";
import { AWS_S3_URL } from '../../../shared/static/constants/constant';
// loader
import Spinners from '../../../shared/components/common/spinner';
// toast message
import { ToastContainer } from "react-toastify";
import { CustomValidator } from '../../../shared/helpers/customValidators';
import AvatarEditor from 'react-avatar-editor';
import TaxSelect from "../../../shared/components/common/addTax";
import { QuoteService } from '../../../quotes/service/quote.service';
import ImagePreviewModal from '../../../shared/components/common/imagePreviewModal';
import RichTextEditor from "react-rte";

const CreateCustomer = (props) => {
    const [customerShop_uuid, setCustomerShop_uuid] = useState('');
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [quoteNotes, setQuoteNotes] = useState(RichTextEditor.createEmptyValue());
    const [invoiceNotes, setInvoiceNotes] = useState(RichTextEditor.createEmptyValue());
    const [shopContactNo, setShopContactNo] = useState('');
    const [shopContactNoTouched, setShopContactNoTouched] = useState(false);
    const [isLoading, setLoading] = useState(false);
    // toggle eye icon for password
    const [visiblePassword, setVisiblePassword] = useState(false);
    // auto generate password
    const [getPassword, setPassword] = useState('');
    // profile picture upload
    const [selectedProfilePic, setselectedProfilePic] = useState();
    const [profilePicUrl, setProfilePicUrl] = useState([]);
    // shop logo
    const [shopLogoUrl, setShopLogoUrl] = useState([]);
    // shop logo file upload
    const [selectedFiles, setselectedFiles] = useState();

    const cropRef = useRef(null);
    const [selectedFileType, setselectedFiletype] = useState('');
    const [slideValue, setSlideValue] = useState(10);
    const [ishowcropimage, setishowCropimage] = useState();
    const [previewImage, setPreviewImage] = useState(null);

    const [profilePicTypeError, setProfilePicTypeError] = useState();
    const [shopLogoTypeError, setShopLogoTypeError] = useState();

    // service
    const customerService = new CustomerService();
    const quoteService = new QuoteService();

    useEffect(() => {
        setModal(props.isOpen);
        setIsEdit(props.isEdit);
        if (props.customer) {
            setProfilePicUrl(props.customer.profile_pic);
            setShopLogoUrl(props.customer.shop_logo_url);
            setCustomerShop_uuid(props.customer.customer_shop_info_uuid);
            setQuoteNotes(RichTextEditor.createValueFromString(props.customer.quote_notes === null || props.customer.quote_notes === "" || props.customer.quote_notes === "<p><br></p>" || props.customer.quote_notes === "null" ? '' : props.customer.quote_notes, 'html'));
            setInvoiceNotes(RichTextEditor.createValueFromString(props.customer.invoice_notes === null || props.customer.invoice_notes === "" || props.customer.invoice_notes === "<p><br></p>" || props.customer.invoice_notes === "null" ? '' : props.customer.invoice_notes, 'html'));
            quoteService.getTaxInfo(props.customer.customer_shop_info_uuid).then((response) => {
                if (response.status === 200) {
                    setTaxInfo(response.data);
                }
            });
        }
    }, [props.isOpen, props.customer]);

    // for tax modal popup
    const [taxModal, setTaxModal] = useState(false);
    const [getTaxInfo, setTaxInfo] = useState([]);

    // if password is empty then generate password.
    if (getPassword === '') {
        const fetchPassword = async () => {
            try {
                const response = await customerService.randomGeneratePassword();
                setPassword(response);
            } catch (error) {
                console.error('Error fetching password:', error);
            }
        };
        fetchPassword();
    }

    // form
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            customerName: (props.customer && props.customer.name) || '',
            customerEmail: (props.customer && props.customer.email) || '',
            password: (props.customer && props.customer.password) || getPassword,
            shopName: (props.customer && props.customer.shop_name) || '',
            shopLogo: (props.customer && props.customer.shop_logo_url) || '',
            shopEmail: (props.customer && props.customer.shop_email) || '',
            shopAddress: (props.customer && props.customer.shop_address) || '',
            shopCity: (props.customer && props.customer.shop_city) || '',
            shopState: (props.customer && props.customer.shop_state) || '',
            shopCountry: (props.customer && props.customer.shop_country) || '',
            shopZipCode: (props.customer && props.customer.shop_zip_code) || '',
            profilePic: (props.customer && props.customer.profile_pic) || '',
        },
        validationSchema: Yup.object({
            customerName: Yup.string().required(registerValidationErr.ERR_NAME_REQUIRED)
                .matches(Regex.ONLY_CHAR, registerValidationErr.ERR_NAME_INVALID),
            customerEmail: Yup.string().required(registerValidationErr.ERR_EMAIL_REQUIRED)
                .email(registerValidationErr.ERR_EMAIL_INVALID),
            shopName: Yup.string().required(registerValidationErr.ERR_SHOP_NAME_REQUIRED)
                .matches(Regex.SHOP_NAME, registerValidationErr.ERR_SHOPNAME_INVALID),
            shopEmail: Yup.string().required(registerValidationErr.ERR_SHOP_EMAIL_REQUIRED)
                .email(registerValidationErr.ERR_EMAIL_INVALID),
            shopCity: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_CITY_INVALID),
            shopState: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_STATE_INVALID),
            shopCountry: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_COUNTRY_INVALID),
            password: Yup.string()
                .required(registerValidationErr.ERR_PASSWORD_REQUIRED)
                .min(8, registerValidationErr.ERR_PASSWORD_INVALID)
                .test(
                    'validator-custom-password',
                    async function (value) {
                        const validation = await CustomValidator.passwordValidation(value);
                        if (!validation?.isValid) {
                            return this.createError({
                                path: this.path,
                                message: validation?.errorMessage,
                            });
                        } else {
                            return true;
                        }
                    }
                ),
            shopZipCode: Yup.string().matches(Regex.ZIP_CODE, registerValidationErr.ERR_INVALID_ZIP_CODE)
        }),
        onSubmit: (values) => {
            setLoading(true);
            const customer = {
                name: validation.values.customerName,
                email: validation.values.customerEmail,
                password: validation.values.password,
                profile_pic: isEdit ? ((profilePicUrl === null || profilePicUrl === 'null') && (selectedProfilePic === undefined || selectedProfilePic === 'undefined' || !selectedProfilePic) ? null : selectedProfilePic ? selectedProfilePic : '') : selectedProfilePic,
                shop_name: validation.values.shopName,
                shop_logo: isEdit ? ((shopLogoUrl === null || shopLogoUrl === 'null') && (selectedFiles === undefined || selectedFiles === 'undefined' || !selectedFiles) ? null : selectedFiles ? selectedFiles : '') : selectedFiles,
                shop_email: validation.values.shopEmail,
                shop_contact_number: shopContactNo,
                shop_address: validation.values.shopAddress,
                shop_city: validation.values.shopCity,
                shop_state: validation.values.shopState,
                shop_country: validation.values.shopCountry,
                shop_zip_code: validation.values.shopZipCode,
                quote_notes: quoteNotes.toString('html') === "<p><br></p>" || quoteNotes.toString('html') === "" ? null : quoteNotes.toString('html'),
                invoice_notes: invoiceNotes.toString('html') === "<p><br></p>" || invoiceNotes.toString('html') === "" ? null : invoiceNotes.toString('html')
            };
            isEdit ? updateCustomer(customer) : createCustomer(customer);
        },
    });

    // check file type
    function fileTypeImg(file, fileType) {
        fileType === 'profilePic' ? setselectedProfilePic(file) : setselectedFiles(file);
    }

    // delete profile pic
    function removeProfilePic() {
        if (isEdit) {
            setProfilePicUrl(null);
            setselectedProfilePic('');
        } else {
            setselectedProfilePic('');
        }
    }

    // delete selected logo file
    function removeFile() {
        if (isEdit) {
            setShopLogoUrl(null);
            setselectedFiles('');
        } else {
            setselectedFiles('');
        }
    }

    // cropimage and replace with orignal image
    const handlePreviewSave = async () => {
        if (cropRef) {
            const dataUrl = cropRef.current.getImage().toDataURL();
            const result = await fetch(dataUrl);
            const blob = await result.blob();
            const randomString = generateRandomString(8);
            const uniqueFileName = `${randomString}_${selectedFileType === 'shopLogo' ? selectedFiles.path : selectedProfilePic.path}`;
            let croppedImageFile = new File([blob], uniqueFileName, { type: "image/" });
            croppedImageFile.preview = URL.createObjectURL(blob);
            selectedFileType === 'shopLogo' ? setselectedFiles(croppedImageFile) : setselectedProfilePic(croppedImageFile);
            croppedImageFile = '';
            setishowCropimage(false);
            setSlideValue(10);
        }
    }

    function generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    // api call for add customer
    const createCustomer = (addCustomer) => {
        const formData = new FormData();
        Object.entries(addCustomer).forEach(([key, value]) => {
            formData.append(key, value);
        });
        customerService.addCustomer(formData).then(response => {
            if (response.status === 200) {
                if (getTaxInfo.some(obj => obj.hasOwnProperty('id'))) {
                    getTaxInfo.forEach(obj => delete obj.id);
                    getTaxInfo.forEach(obj => obj.tax_percentage = +obj.tax_percentage);
                    let taxInfo = {
                        "customer_shop_info_uuid": response.data.customer_shop_info_uuid,
                        "tax_items": getTaxInfo
                    }
                    quoteService.addTaxInfo(taxInfo).then(response => {
                        if (response.status === 200) {
                            closeModal();
                            props.isSubmitted();
                        }
                    }).finally(() => {
                        setLoading(false);
                    });
                } else {
                    setLoading(false);
                    closeModal();
                    props.isSubmitted();
                }
            } else {
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        })
    }
    // api call for edit customer
    const updateCustomer = (editCustomer) => {
        const formData = new FormData();
        Object.entries(editCustomer).forEach(([key, value]) => {
            formData.append(key, value);
        });
        customerService.editCustomer(props.customer.user_uuid, formData).then(response => {
            if (response.status === 200) {
                closeModal();
                props.isSubmitted();
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const updatedTaxInfo = (newTaxInfo) => {
        setTaxInfo(newTaxInfo);
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: useCallback((files, rejectedFiles) => {
            if (rejectedFiles && rejectedFiles.length > 0) {
                setProfilePicTypeError('Please select a valid image file.');
            } else {
                setProfilePicTypeError('');
                setishowCropimage(true);
                setselectedFiletype('profilePic');
                files.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        fileType: fileTypeImg(file, 'profilePic')
                    })
                );
            }
        }),
        multiple: false
    });

    const { getRootProps: getshopLogoRootProps, getInputProps: getShopLogoInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: useCallback((files, rejectedFiles) => {
            if (rejectedFiles && rejectedFiles.length > 0) {
                setShopLogoTypeError('Please select a valid image file.');
            } else {
                setShopLogoTypeError('');
                setishowCropimage(true);
                setselectedFiletype('shopLogo');
                files.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        fileType: fileTypeImg(file, 'shopLogo')
                    })
                );
            }
        }),
        multiple: false
    })

    // on close modal
    const closeModal = () => {
        props.close();
        setTaxInfo([]);
        validation.resetForm();
        setShopContactNo('');
        setselectedProfilePic('');
        setselectedFiles('');
        setPassword('');
        setVisiblePassword(false);
        setShopContactNoTouched(false);
        if (isEdit) {
            props.resetCustomerDetail();
        }
        setProfilePicUrl('');
        setShopLogoUrl('');
    }

    return (
        <React.Fragment>
            {/* add/edit customer modal */}
            <Modal isOpen={modal} toggle={() => closeModal()} className="add-edit-customer-modal">
                {/* modal header */}
                <ModalHeader toggle={() => closeModal()} tag="h4">
                    {!!isEdit ? "Edit Customer" : "Add Customer"}
                </ModalHeader>
                {/* modal body */}
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Row className="pe-0">
                                <Col md={12} lg={7} xl={9} className="customer-detail-section">
                                    {/*customer name */}
                                    <div className="mb-3">
                                        <Label className="form-label">Name <span className='text-danger'>*</span></Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="customerName"
                                            placeholder="Enter Name"
                                            value={validation.values.customerName}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.customerName && validation.errors.customerName ? true : false
                                            }
                                        />
                                        {validation.touched.customerName && validation.errors.customerName ? (
                                            <FormFeedback type="invalid">{validation.errors.customerName}</FormFeedback>
                                        ) : null}
                                    </div>
                                    <Row>
                                        {/*customer email */}
                                        <Col className={isEdit ? 'col-md-12 col-12' : 'col-md-6 col-12'}>
                                            <div className="mb-3">
                                                <Label className="form-label">Email <span className='text-danger'>*</span></Label>
                                                <Input
                                                    id="customerEmail"
                                                    name="customerEmail"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    autoComplete='off'
                                                    value={validation.values.customerEmail || ""}
                                                    invalid={
                                                        validation.touched.customerEmail && validation.errors.customerEmail ? true : false
                                                    }
                                                    disabled={isEdit}
                                                />
                                                {validation.touched.customerEmail && validation.errors.customerEmail ? (
                                                    <FormFeedback type="invalid">{validation.errors.customerEmail}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        {/* password */}
                                        {!isEdit && <Col className="col-md-6 col-12">
                                            <div className="mb-3 position-relative">
                                                <Label className="form-label">Password <span className='text-danger'>*</span> <i className="bx bx-info-circle register-info-icon position-relative" id="passwordInfo">
                                                    <div className='info_tooltip'>
                                                        <ul className='mt-1 ps-1 pe-1'>
                                                            <li className="mb-2">Your password length must be greater than or equal to 8</li>
                                                            <li className="mb-2">Any three among below four conditions should match,</li>
                                                            <ul>
                                                                <li className="mb-2">Must contain at least 1 uppercase character</li>
                                                                <li className="mb-2">Must contain at least 1 lowercase character</li>
                                                                <li className="mb-2">Must contain at least 1 numeric value</li>
                                                                <li className="mb-2">Must contain at least 1 special character</li>
                                                            </ul>
                                                        </ul>
                                                    </div></i></Label>
                                                <Input
                                                    name="password"
                                                    type={visiblePassword ? 'text' : 'password'}
                                                    placeholder="Password"
                                                    autoComplete='off'
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.password || ""}
                                                    invalid={
                                                        validation.touched.password && validation.errors.password ? true : false
                                                    }
                                                    className="pe-5 password-field-padding"
                                                />
                                                {validation.values.password.length > 0 && (<i className={`bi login-eye ${!(validation.touched.password && validation.errors.password) ? 'right-5px' : ''}  ${visiblePassword ? 'bi-eye' : 'bi-eye-slash'}`} onClick={() => { setVisiblePassword(!visiblePassword) }}></i>)}
                                                {validation.touched.password && validation.errors.password ? (
                                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                ) : null}
                                            </div></Col>}
                                    </Row>
                                </Col>
                                <Col className="pe-0">
                                    <div className="mb-3 mb-lg-0">
                                        <Label>Profile Picture</Label>
                                        {/* drag and drop profile pic */}
                                        {(!selectedProfilePic) && (props.customer == null || (props.customer !== null && (!profilePicUrl) | profilePicUrl === 'null' | profilePicUrl === 'undefined')) ? (
                                            <div className={`dropzone justify-content-center ${shopLogoTypeError ? 'mb-1' : ''} `}>
                                                <div
                                                    className="dz-message needsclick p-0 my-3"
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} />
                                                    <div className="mb-0">
                                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                    </div>
                                                    <h6 className="font-size-11">Drop files here or click to upload.</h6>
                                                </div>
                                            </div>
                                        ) : (<div>
                                            {selectedProfilePic &&
                                                <>
                                                    <div className="dropzone-previews" id="file-previews">
                                                        <Card
                                                            className="mt-0 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete"
                                                        >
                                                            <div className="p-2 my-0">
                                                                <Row>
                                                                    <Col className="col-12 col-md-3 d-flex justify-content-center col-lg-12">
                                                                        <img
                                                                            data-dz-thumbnail=""
                                                                            height="80"
                                                                            className="rounded bg-light preview-logo-img  mb-1 cursor-pointer"
                                                                            alt={selectedProfilePic.name}
                                                                            src={selectedProfilePic.preview}
                                                                            onClick={() => setPreviewImage(selectedProfilePic.preview)}
                                                                        />
                                                                    </Col>
                                                                    <Col className="col-12 col-md-9 col-lg-12 my-auto">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <Link
                                                                                to="#"
                                                                                className="text-muted font-weight-bold profilePicName mt-1" id="profilePicName"
                                                                            >
                                                                                {selectedProfilePic.name}
                                                                            </Link>
                                                                            <UncontrolledTooltip placement="bottom" target="profilePicName" style={{ background: 'black' }}>
                                                                                {selectedProfilePic.name}
                                                                            </UncontrolledTooltip>
                                                                            <i className="bx bx-trash cursor-pointer font-size-17 mt-1 text-danger" id="profilePicIcon" onClick={() => {
                                                                                removeProfilePic()
                                                                            }}></i>
                                                                            <UncontrolledTooltip placement="bottom" target="profilePicIcon" style={{ background: 'black' }}>
                                                                                Remove
                                                                            </UncontrolledTooltip>
                                                                        </div></Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </>
                                            }
                                        </div>)}
                                        <span className="text-danger image-error-msg">{profilePicTypeError}</span>
                                        {props.customer && (profilePicUrl && profilePicUrl !== 'null' && profilePicUrl !== 'undefined') ?
                                            <div className="dropzone-previews" id="file-previews">
                                                <Card
                                                    className="mt-0 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete">
                                                    <div className="p-2 my-0">
                                                        <Row>
                                                            <Col className="col-12 col-md-3 d-flex justify-content-center col-lg-12">
                                                                <img
                                                                    data-dz-thumbnail=""
                                                                    height="80"
                                                                    className=" rounded bg-light preview-logo-img mb-1 cursor-pointer"
                                                                    alt={'profile_pic'}
                                                                    src={`${AWS_S3_URL + profilePicUrl}`}
                                                                    onClick={() => setPreviewImage(AWS_S3_URL + profilePicUrl)}
                                                                />
                                                            </Col>
                                                            <Col className="col-12 col-md-9 col-lg-12 my-auto">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <Link
                                                                        to="#"
                                                                        className="text-muted font-weight-bold profilePicName mt-1" id="profilePicName"
                                                                    >
                                                                        {'profile_pic' + profilePicUrl.toString().substring(profilePicUrl.lastIndexOf("."), profilePicUrl.length)}
                                                                    </Link>
                                                                    <i className="bx bx-trash cursor-pointer font-size-17 mt-1 text-danger" id="profilePicIcon" onClick={() => {
                                                                        removeProfilePic()
                                                                    }}></i>
                                                                    <UncontrolledTooltip placement="bottom" target="profilePicIcon" style={{ background: 'black' }}>
                                                                        Remove
                                                                    </UncontrolledTooltip>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            </div>
                                            : ''}
                                    </div>
                                </Col>
                            </Row>
                            {/* shop info */}
                            <Row className="pe-0">
                                <Col md={12} lg={7} xl={9} className="shop-mandatory-detail">
                                    {/* shop name */}
                                    <div className="mb-3">
                                        <Label className="form-label">Shop Name <span className='text-danger'>*</span></Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="shopName"
                                            placeholder="Enter Name"
                                            value={validation.values.shopName}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.shopName && validation.errors.shopName ? true : false
                                            }
                                        />
                                        {validation.touched.shopName && validation.errors.shopName ? (
                                            <FormFeedback type="invalid">{validation.errors.shopName}</FormFeedback>
                                        ) : null}
                                    </div>
                                    <Row>
                                        {/* shop email */}
                                        <Col className="col-md-6 col-12">
                                            <div className="mb-3">
                                                <Label for="shopEmail">
                                                    Shop Email <span className='text-danger'>*</span>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="shopEmail"
                                                    placeholder="Enter Email"
                                                    value={validation.values.shopEmail || ''}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    invalid={
                                                        validation.touched.shopEmail && validation.errors.shopEmail ? true : false
                                                    }
                                                />
                                                {validation.touched.shopEmail && validation.errors.shopEmail ? (
                                                    <FormFeedback type="invalid">{validation.errors.shopEmail}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col className="col-md-6 col-12">
                                            <div className="mb-3">
                                                <Label for="shopContactNo">
                                                    Shop Contact No. <span className='text-danger'>*</span>
                                                </Label>
                                                <PatternFormat
                                                    className="form-control"
                                                    id="shopContactNo"
                                                    name="phone"
                                                    placeholder="Enter Contact No."
                                                    value={props.customer !== null && props.customer.shop_contact_number ? props.customer.shop_contact_number : (validation.values.shopContactNumber || "")}
                                                    onChange={validation.handleChange}
                                                    format="###-###-####"
                                                    onBlur={(e) => {
                                                        setShopContactNoTouched(true);
                                                    }}
                                                    onValueChange={(values) => {
                                                        setShopContactNo(values.value);
                                                        setShopContactNoTouched(false);
                                                    }}
                                                />
                                                {(shopContactNo && shopContactNo?.length >= 0 && shopContactNo?.length < 10) ? <span className="invalid-file">{registerValidationErr.ERR_CONTACT_NO_INVALID}</span> : ''}
                                                {(shopContactNoTouched && !shopContactNo) ? <span className="invalid-file">{registerValidationErr.ERR_SHOP_CONTACT_NUMBER_REQUIRED}</span> : ''}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                {/* shop logo */}
                                <Col className="pe-0">
                                    <div className="mb-3">
                                        <Label>Shop Logo</Label>
                                        {/* drag and drop logo image */}
                                        {(!selectedFiles) && (props.customer == null || (props.customer !== null && (!shopLogoUrl || shopLogoUrl === 'null' || shopLogoUrl === 'undefined'))) ? (
                                            <div className={`dropzone justify-content-center ${shopLogoTypeError ? 'mb-1' : ''}`}>
                                                <div
                                                    className="dz-message needsclick p-0 my-3"
                                                    {...getshopLogoRootProps()}
                                                >
                                                    <input {...getShopLogoInputProps()} />
                                                    <div className="mb-0">
                                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                    </div>
                                                    <h6 className="drag-drop-text">Drop files here or click to upload.</h6>
                                                </div>
                                            </div>
                                        ) : (<div >
                                            {selectedFiles &&
                                                <>
                                                    <div className="dropzone-previews" id="file-previews">
                                                        <Card
                                                            className="mt-0 mb-0 shadow-none  dz-processing dz-image-preview dz-success dz-complete"
                                                        >
                                                            <div className="p-2 my-0">
                                                                <Row>
                                                                    <Col className="col-12 d-flex justify-content-center col-md-3 col-lg-12">
                                                                        <img
                                                                            data-dz-thumbnail=""
                                                                            height="80"
                                                                            className="rounded bg-light preview-logo-img mb-1 cursor-pointer"
                                                                            alt={selectedFiles.name}
                                                                            src={selectedFiles.preview}
                                                                            onClick={() => setPreviewImage(selectedFiles.preview)}
                                                                        />
                                                                    </Col>
                                                                    <Col className="col-12 col-md-9 col-lg-12 my-auto">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <Link
                                                                                to="#"
                                                                                className="text-muted font-weight-bold profilePicName mt-1" id="logoName"
                                                                            >
                                                                                {selectedFiles.name}
                                                                            </Link>
                                                                            <UncontrolledTooltip placement="bottom" target="logoName" style={{ background: 'black' }}>
                                                                                {selectedFiles.name}
                                                                            </UncontrolledTooltip>
                                                                            <i className="bx bx-trash cursor-pointer font-size-17 mt-1 text-danger" id="shopLogoIcon" onClick={() => {
                                                                                removeFile()
                                                                            }}></i>
                                                                            <UncontrolledTooltip placement="bottom" target="shopLogoIcon" style={{ background: 'black' }}>
                                                                                Remove
                                                                            </UncontrolledTooltip>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </>
                                            }
                                        </div>)}
                                        <span className="text-danger image-error-msg">{shopLogoTypeError}</span>
                                        {props.customer && (shopLogoUrl && shopLogoUrl !== 'null' && shopLogoUrl !== 'undefined') ?
                                            <div className="dropzone-previews" id="file-previews">
                                                <Card
                                                    className="mt-0 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete">
                                                    <div className="p-2 my-0">
                                                        <Row>
                                                            <Col className="col-12 d-flex justify-content-center col-md-3 col-lg-12">
                                                                <img
                                                                    data-dz-thumbnail=""
                                                                    height="80"
                                                                    className="rounded bg-light preview-logo-img mb-1 cursor-pointer"
                                                                    alt={'profile_pic'}
                                                                    src={`${AWS_S3_URL + shopLogoUrl}`}
                                                                    onClick={() => setPreviewImage(AWS_S3_URL + shopLogoUrl)}
                                                                />
                                                            </Col>
                                                            <Col className="col-12 col-md-9 col-lg-12 my-auto">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <Link
                                                                        to="#"
                                                                        className="text-muted font-weight-bold profilePicName mt-1" id="profilePicName"
                                                                    >
                                                                        {'shop_logo' + shopLogoUrl.toString().substring(shopLogoUrl.lastIndexOf("."), shopLogoUrl.length)}
                                                                    </Link>
                                                                    <i className="bx bx-trash cursor-pointer font-size-17 mt-1 text-danger" id="shopLogoIcon" onClick={() => {
                                                                        removeFile()
                                                                    }}></i>
                                                                    <UncontrolledTooltip placement="bottom" target="shopLogoIcon" style={{ background: 'black' }}>
                                                                        Remove
                                                                    </UncontrolledTooltip>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            </div>
                                            : ''}
                                    </div>
                                </Col>
                            </Row>
                            <Col className="col-12">
                                {/* shop address */}
                                <div className="mb-3">
                                    <Label for="shopAddress">
                                        Shop Address
                                    </Label>
                                    <textarea
                                        className="form-control"
                                        id="shopAddress"
                                        placeholder="Enter Address"
                                        rows="2"
                                        value={validation.values.shopAddress || ''}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                    ></textarea>
                                </div>
                            </Col>
                            <Row className="pe-0">
                                <Col className="col-md-6 col-12 shop-city-col">
                                    {/* shop city */}
                                    <div className="mb-3">
                                        <Label for="shopCity">
                                            City
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="shopCity"
                                            placeholder="Enter City"
                                            value={validation.values.shopCity || ''}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.shopCity && validation.errors.shopCity ? true : false
                                            }
                                        />
                                        {validation.touched.shopCity && validation.errors.shopCity ? (
                                            <FormFeedback type="invalid">{validation.errors.shopCity}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col className="col-md-6 col-12 pe-0">
                                    {/* shop state */}
                                    <div className="mb-3">
                                        <Label for="shopState">
                                            State
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="shopState"
                                            placeholder="Enter State"
                                            value={validation.values.shopState || ''}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.shopState && validation.errors.shopState ? true : false
                                            }
                                        />
                                        {validation.touched.shopState && validation.errors.shopState ? (
                                            <FormFeedback type="invalid">{validation.errors.shopState}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pe-0">
                                <Col className="col-md-6 col-12 shop-country-col" >
                                    {/* shop country */}
                                    <div className="mb-3">
                                        <Label for="shopCountry">
                                            Country
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="shopCountry"
                                            placeholder="Enter Country"
                                            value={validation.values.shopCountry || ''}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.shopCountry && validation.errors.shopCountry ? true : false
                                            }
                                        />
                                        {validation.touched.shopCountry && validation.errors.shopCountry ? (
                                            <FormFeedback type="invalid">{validation.errors.shopCountry}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col className="col-md-6 col-12 pe-0">
                                    {/* zip code */}
                                    <div className="mb-3">
                                        <Label for="shopZipCode">
                                            Zip Code
                                        </Label>

                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="shopZipCode"
                                            placeholder="Enter Zip Code"
                                            value={validation.values.shopZipCode || ''}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                // Remove non-numeric and limit to 10 characters
                                                const formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10);

                                                // Check if formattedValue is empty or just a hyphen
                                                if (formattedValue.length > 5) {
                                                    // Format value with a hyphen only once
                                                    validation.handleChange({
                                                        target: {
                                                            name: 'shopZipCode',
                                                            value: `${formattedValue.slice(0, 5)}-${formattedValue.slice(5)}`,
                                                        },
                                                    });
                                                } else {
                                                    // Set the formatted value without adding a hyphen
                                                    validation.handleChange({
                                                        target: {
                                                            name: 'shopZipCode',
                                                            value: formattedValue,
                                                        },
                                                    });
                                                }
                                            }}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.shopZipCode && validation.errors.shopZipCode ? true : false
                                            }
                                            onKeyDown={(e) => {
                                                // Allow Backspace if the length is greater than 0
                                                if (e.key === 'Backspace' && validation.values.shopZipCode.length > 0) {
                                                    return;
                                                }

                                                // Limit to 10 characters including hyphen
                                                if (e.target.value.length >= 10 && e.key !== 'Backspace') {
                                                    e.preventDefault();
                                                }
                                                // Allow numeric digits, hyphen, and Backspace
                                                if (!(
                                                    (e.key >= '0' && e.key <= '9') ||
                                                    e.key === 'Backspace' || e.key === '-'
                                                )) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        {validation.touched.shopZipCode && validation.errors.shopZipCode ? (
                                            <FormFeedback type="invalid">{validation.errors.shopZipCode}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                            <Row className=" pe-0">
                                <Col className="col-12 pe-0 ">
                                    {/* zip code */}
                                    <div className="">
                                        <Label for="quote_notes">
                                            Quote Notes <i className="bx bx-info-circle cursor-pointer notes-info-icon position-absolute">
                                                <snall className="quotes_info_tooltip">Notes will be included in every quote</snall>
                                            </i>
                                        </Label>
                                        {
                                            (props.isEdit && props.customer && props.customer.quote_notes !== null && props.customer !== undefined && props.customer.quote_notes !== undefined) ? (
                                                <RichTextEditor editorClassName="editor custom-editor"
                                                    editorStyle={{ fontSize: "13px", fontFamily: "Poppins, sans-serif", maxHeight: '170px', overflow: 'scroll' }}
                                                    placeholder="Enter quote notes"
                                                    value={quoteNotes}
                                                    onChange={(e) => { setQuoteNotes(e) }} />
                                            ) : (
                                                <RichTextEditor editorClassName="editor custom-editor"
                                                    editorStyle={{ fontSize: "13px", fontFamily: "Poppins, sans-serif", maxHeight: '170px', overflow: 'scroll' }}
                                                    placeholder="Enter quote notes"
                                                    value={quoteNotes}
                                                    onChange={(e) => { setQuoteNotes(e) }} />
                                            )
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-0 mt-3 pe-0">
                                <Col className="col-12 pe-0">
                                    <div>
                                        <Label for="invoice_notes">
                                            Invoice Notes <i className="bx bx-info-circle cursor-pointer notes-info-icon position-absolute">
                                                <snall className="quotes_info_tooltip">Notes will be included in every invoice</snall>
                                            </i>
                                        </Label>
                                        {
                                            (props.isEdit && props.customer && props.customer.quote_notes !== null && props.customer !== undefined && props.customer.quote_notes !== undefined) ? (
                                                <RichTextEditor editorClassName="editor custom-editor"
                                                    editorStyle={{ fontSize: "13px", fontFamily: "Poppins, sans-serif", maxHeight: '170px', overflow: 'scroll' }}
                                                    placeholder="Enter invoice notes"
                                                    value={invoiceNotes}
                                                    onChange={(e) => { setInvoiceNotes(e) }} />
                                            ) : (
                                                <RichTextEditor editorClassName="editor custom-editor"
                                                    editorStyle={{ fontSize: "13px", fontFamily: "Poppins, sans-serif", maxHeight: '170px', overflow: 'scroll' }}
                                                    placeholder="Enter invoice notes"
                                                    value={invoiceNotes}
                                                    onChange={(e) => { setInvoiceNotes(e) }} />
                                            )
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-0 pe-0">
                                <Col className="col-12 Pe-0">
                                    <TaxSelect show={taxModal} options={getTaxInfo} updatedTaxInfo={updatedTaxInfo} shouldShowSelectedTax={false} componentName={isEdit ? 'editCustomer' : 'AddCustomer'} customer_shop_tax_master_uuid={customerShop_uuid} />
                                </Col>
                            </Row>
                        </Row>
                        {/* footer */}
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-end">
                                    <button
                                        className="btn-outline me-3"
                                        type="button"
                                        onClick={() => closeModal()}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn-fill save-user d-flex"
                                        disabled={(!validation?.isValid) || (shopContactNo.length >= 0 && shopContactNo.length < 10)}
                                    >
                                        {isLoading ? <Spinners setLoading={setLoading} /> : ''}
                                        Save
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                {/* toast message */}
                <ToastContainer />
            </Modal>
            {/* crop profile crop */}
            <Modal isOpen={ishowcropimage} centered={true} toggle={() => { setishowCropimage(false); selectedFileType === 'shopLogo' ? setselectedFiles(null) : setselectedProfilePic(null); }} className="image-crop-modal mb-0">
                {/* modal header */}
                <ModalHeader toggle={() => { setishowCropimage(false); selectedFileType === 'shopLogo' ? setselectedFiles(null) : setselectedProfilePic(null); }} tag="h4">
                </ModalHeader>
                <div className="modal-body pb-0">
                    <div className="modal-dialog  modal_dialog_container">
                        <div className="modal-content border-0 modal_content paragraph_modal">
                            <div className="modal-body  border-0 modal_body pt-0 pb-0">
                                <div className='avatar-editor-wrapper'>
                                    <AvatarEditor
                                        ref={cropRef}
                                        image={selectedFileType === 'shopLogo' ? selectedFiles?.preview : selectedProfilePic?.preview}
                                        style={{ width: "100%", height: "100%" }}
                                        border={50}
                                        borderRadius={0}
                                        color={[0, 0, 0, 0.72]}
                                        scale={slideValue / 10}
                                        rotate={0}
                                    />
                                </div>
                                <div
                                    className="input-group preview-zoom-slider-wrapper my-3"
                                >
                                    <input
                                        type="range"
                                        min={10}
                                        max={50}
                                        value={slideValue}
                                        onChange={(e) => setSlideValue(e.target.value)}
                                        className="form-range slider preview-zoom-slider"
                                    />
                                </div>
                            </div>
                            <div className='modal-footer pb-0 modal_footer mt-0 border-0 justify-content-end'>
                                <button className='btn me-3 btn-outline '
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setishowCropimage(false);
                                        selectedFileType === 'shopLogo' ? setselectedFiles(null) : setselectedProfilePic(null);
                                    }}>
                                    <div>Cancel</div>
                                </button>
                                <button type="submit" className="btn btn-fill save_user " onClick={handlePreviewSave}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* Modal for image preview */}
            <ImagePreviewModal imgSrc={previewImage} onCloseClick={() => setPreviewImage(null)}></ImagePreviewModal>
        </React.Fragment>
    )
}

export default CreateCustomer;