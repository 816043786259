import React, { useEffect, useRef, useState } from "react";
import './createSubscription.scss';
import { Card, CardBody, Col, Row, Modal, ModalHeader, ModalBody, Label,Spinner,UncontrolledTooltip,} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Spinners from '../../../shared/components/common/spinner';
import { Formik, Form } from 'formik';
import { CustomerService } from '../../service/customer.service';
import moment from 'moment';
import Switch from "react-switch";
import ConfirmationModal from "../../../shared/components/common/confirmationModal";
import {Calculator_Types,PLAN_DURATION, SUBSCRIPTION_PLAN_UUID, TAX_NAME,TAX_RATE} from "../../../shared/static/constants/constant";
import { ToastContainer, toast } from "react-toastify";

const CreateSubscription = (props) => {
  const [taxAmount, setTaxAmount] = useState();
  const [modal, setIsModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isEditIsLoading, setIsEditIsloading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [subscriptionDetails, setSubscriptionDeails] = useState();
  const [discountMessage, setDiscountMessage] = useState([]);
  const [getSubscriptionPlan, setSubscriptionPlan] = useState([]);
  const [subscriptionKey, setsubscriptionkey] = useState(0);
  const [payableAmount, setPayableAmount] = useState("0");
  const [discountApplied_uuid, setDiscountApplied_uuid] = useState([]);
  const [bascicDiscountPlanList, setBasicDiscountPlanList] = useState([]);
  const [standardDiscountPlanList, setStandardDiscountPlanList] = useState([]);
  const [isPaymentInfoIsLoading, setiPaymentInfoIsLoading] = useState(true);
  const [IsDefaultPlan, SetIsDefaultPlan] = useState(false);
  const [getCalculatorTypePlans, setCalculatorTypePlans] = useState([]);
  const [CalculatorType, setCalculatorType] = useState("");

  const [isEditSubscriptionDetialsLoading, setisEditSubscriptionDetailsIsLoading] = useState(true);
  const [getsubscriptionDetailByUserId, setsubscriptionDetailByUserId] = useState('')
  const [endDate, setEndDate] = useState();

  //Function to check the subscription plan name is overflow
  const [IsPlanNameOverflowing, setIsPlanNameOverflowing] = useState([]);
  const SubscriptionPlanenamesref = useRef([]);
  useEffect(() => {
    const checkOverflow = () => {
      const overflowing = [];
      SubscriptionPlanenamesref.current.forEach((ref, index) => {
        if (ref && ref.scrollWidth > ref.clientWidth) {
          overflowing.push(index);
        }
      });
      setIsPlanNameOverflowing(overflowing);
    };

    checkOverflow(); // Initial check
    window.addEventListener("resize", checkOverflow);

    // Clean on unmount
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [getCalculatorTypePlans]);

    // service
    const customerService = new CustomerService();

    const closeModal = () => {
      props.close();
      setPayableAmount("0");
      setSubscriptionPlan([]);
      setDiscountApplied_uuid([]);
      setiPaymentInfoIsLoading(true);
      setisEditSubscriptionDetailsIsLoading(false);
      setBasicDiscountPlanList([]);
      setStandardDiscountPlanList([]);
      setDiscountMessage([]);
    };

    useEffect(() => {
        setIsEdit(props.isEdit);
        setIsModal(props.isOpen);
        if (props.isOpen === true && !props.isEdit) {
            fetchSubscriptionPlans();
        }
        if (props.isEdit) {
            setisEditSubscriptionDetailsIsLoading(true);
            customerService.getSubscriptionplanDetailByUserId(props.user_uuid).then((response) => {
                if (response.status === 200) {
                    let subscriptionDta = response.data;
                    setsubscriptionDetailByUserId(subscriptionDta);
                    setisEditSubscriptionDetailsIsLoading(false);
                    setsubscriptionkey((subscriptionKey) => subscriptionKey + 1);
                    customerService.getSubscriptionPlanList().then((response) => {
                        setSubscriptionPlan(response.data);
                        const plan = response.data.find((plan) => plan.subscription_plan_uuid === subscriptionDta.subscription_plan_details.subscription_plan_uuid);
                        if (plan) {
                            if (subscriptionDta.subscription_plan_details.discount_information.length === 0) {
                                setPayableAmount(plan.price_per_month);
                                setiPaymentInfoIsLoading(false);
                            } else {
                                setPayableAmount(plan.price_per_year);
                                getDiscountDetailByPlan(plan.subscription_plan_uuid, plan.price_per_year);
                            }
                        }
                    });
                }
            });
        }
    }, [props.isOpen, props.isEdit]);

  ///Function Handle Default plan & payable amount
  const handleDefaultPlan = (duration) => {
    const defaultPlanuuId = getSubscriptionPlan.find(
      (plan) => plan.calculator_type === CalculatorType
    );

    if (defaultPlanuuId) {
      return getPayAbleAmount(defaultPlanuuId.subscription_plan_uuid, duration);
    }
  };

  useEffect(() => {
    // Get active plan if in edit mode
    if (isEdit) {
      const activePlan = getSubscriptionPlan.find(
        (plan) =>
          plan.subscription_plan_uuid ===
          getsubscriptionDetailByUserId.subscription_plan_details
            .subscription_plan_uuid
      );
      if (activePlan) {
        setCalculatorType(activePlan.calculator_type);
        SetIsDefaultPlan(false);
      }
    } else {
      setCalculatorType(Calculator_Types.Calculator_type_all);
      handleDefaultPlan(PLAN_DURATION.YERALY);
      SetIsDefaultPlan(true);
    }
  }, [isEdit, getSubscriptionPlan, getsubscriptionDetailByUserId]);

  useEffect(() => {
    //plans show accroding to selected calculator type
    const filteredPlans = getSubscriptionPlan.filter(
      (plan) => plan.calculator_type === CalculatorType
    );
    setCalculatorTypePlans(filteredPlans);
    if (filteredPlans > 0) {
      getPayAbleAmount(
        filteredPlans[0].subscription_plan_uuid,
        PLAN_DURATION.YERALY
      );
    }
  }, [CalculatorType, getSubscriptionPlan]);


    const UTCtoLocal = (utcDate) => {
        const date = new Date(utcDate);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Dynamic time zone based on user's location
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(localDate);
        return formattedDate;
    }

    async function fetchSubscriptionPlans() {
        try {
            const response = await customerService.getSubscriptionPlanList();
            if (response.status === 200) {
                setSubscriptionPlan(response.data);
                if (!props.isEdit) {
                    getDiscountDetailByPlan(response.data[1].subscription_plan_uuid,payableAmount);
                }
                setsubscriptionkey((subscriptionKey) => subscriptionKey + 1);
            }
        } catch (error) {
          console.error("An error occurred while fetching subscription plans:", error);
        }
    }

    const getPayAbleAmount = (selectedPlan, duration) => {
        const plan = getSubscriptionPlan.find((plan) => plan.subscription_plan_uuid === selectedPlan);
        if (plan) {
            if (duration === PLAN_DURATION.MONTHLY) {
                setPayableAmount(plan.price_per_month);
                setiPaymentInfoIsLoading(false);
            } else {
                setPayableAmount(plan.price_per_year);
                getDiscountDetailByPlan(plan.subscription_plan_uuid, plan.price_per_year);
            }
        }
    };

    async function getDiscountDetailByPlan(subscription_uuid, price) {
        try {
            if (bascicDiscountPlanList.length === 0 || standardDiscountPlanList.length === 0) {
                setiPaymentInfoIsLoading(true);
                const response = await customerService.getSubscriptionDiscountListbySubscriptionPanId(subscription_uuid);
                if (response.status === 200) {
                    setiPaymentInfoIsLoading(false);
                    let uuidArray = [];
                    setDiscountApplied_uuid([]);
                    let discountMessageArray = [];
                    let oneMonthFreeDiscount = response.data.find((discount) => {
                        return discount.discount_type === 'first_50';
                    });
                    let overallDiscount = response.data.find((discount) => {return discount.discount_type !== "first_50";});
                    if (oneMonthFreeDiscount !== undefined && oneMonthFreeDiscount.no_of_time_applied < 50) {
                        setStandardDiscountPlanList(response.data);
                        let oneMonthAmount = price - oneMonthFreeDiscount.discount_amount;
                        let discountWithPercentage = oneMonthAmount * (overallDiscount.discount_percentage / 100);
                        setPayableAmount((oneMonthAmount - discountWithPercentage).toFixed(2));
                        uuidArray.push(oneMonthFreeDiscount.subscription_discount_master_uuid);
                        discountMessageArray.push(oneMonthFreeDiscount.discount_description);
                    } else {
                        setBasicDiscountPlanList(response.data);
                        let discountPercentage = overallDiscount.discount_percentage / 100;
                        let finalAmount = price * (1 - discountPercentage);
                        setPayableAmount(finalAmount.toFixed(2));
                    }
                    uuidArray.push(overallDiscount.subscription_discount_master_uuid);
                    discountMessageArray.push(overallDiscount.discount_description);
                    setDiscountApplied_uuid(uuidArray);
                    setDiscountMessage(discountMessageArray);
                }
            } else {
                let uuidArray = [];
                let discountMessageArray = [];
                let allDiscount = bascicDiscountPlanList.concat(standardDiscountPlanList);
                let oneMonthFreeDiscount = allDiscount.find((discount) => {
                    return ( discount.discount_type === "first_50" && discount.subscription_plan_uuid === subscription_uuid);
                });
                if (oneMonthFreeDiscount !== undefined && oneMonthFreeDiscount.no_of_time_applied < 50) {
                    let otherDiscount = standardDiscountPlanList.find((discount) => discount.discount_type !== "first_50");
                    let oneMonthAmount = price - oneMonthFreeDiscount.discount_amount;
                    let discountWithPercentage = oneMonthAmount * (+otherDiscount.discount_percentage / 100);
                    setPayableAmount((+oneMonthAmount - +discountWithPercentage).toFixed(2));
                    uuidArray.push(oneMonthFreeDiscount.subscription_discount_master_uuid );
                    uuidArray.push(oneMonthFreeDiscount.subscription_discount_master_uuid);
                    discountMessageArray.push(oneMonthFreeDiscount.discount_description);
                    discountMessageArray.push(otherDiscount.discount_description);
                } else {
                    let discountPercentage = bascicDiscountPlanList[0].discount_percentage / 100;
                    let finalAmount = price * (1 - discountPercentage);
                    setPayableAmount(finalAmount.toFixed(2));
                    uuidArray.push(bascicDiscountPlanList[0].subscription_discount_master_uuid);
                    discountMessageArray.push(bascicDiscountPlanList[0].discount_description);
                }
                setDiscountMessage(discountMessageArray);
                setDiscountApplied_uuid(uuidArray);
            }
        } catch (error) { console.error("An error occurred while fetching subscription plan discont detail", error);
    }
  }
  const formateDate = (date) => {return date.toISOString().split("T")[0];};

  const finalFormateDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

    const editSubscriptionPlan = () => {
        let updateSubscriptionInfo = {
            subscription_plan_details: {
                subscription_plan_uuid: subscriptionDetails.subscription_plan_uuid,
                user_uuid: props.user_uuid,
                start_date: moment(subscriptionDetails.range.from).format("YYYY-MM-DD"),
                end_date: moment(subscriptionDetails.range.to).format("YYYY-MM-DD"),
                discount_applied: subscriptionDetails.subscription_duration === PLAN_DURATION.MONTHLY ? null : discountApplied_uuid,
                subscription_duration: subscriptionDetails.subscription_duration,
            },
            subscription_plan_payment_details: subscriptionDetails.paymentDone === true ? {
                payment_amount: +payableAmount + taxAmount,
                tax_amount: taxAmount,
                payment_method: "manually",
                payment_status: "succeeded",
            } : null,
        };
        setIsEditIsloading(false);
        customerService.updateSubscriptionPlan(props.user_subscription_plan_uuid, updateSubscriptionInfo).then((response) => {
            if (response.status === 200) {
                closeModal();
                props.isSubmitted();
                setConfirmationModal(false);
                setSubscriptionDeails();
            }
        }).finally(() => {
            setIsEditIsloading(true);
        });
    };

    useEffect(() => {
        let percentageDecimal = TAX_RATE / 100;
        let percentageAmount = payableAmount * percentageDecimal;
        setTaxAmount(+(+percentageAmount.toFixed(2)));
    }, [payableAmount]);

    useEffect(() => {
        var from = moment();
        var endDate = new Date(moment(from).format("DD MMM YYYY"));
        endDate.setFullYear(endDate.getFullYear() + 1);
        endDate.setDate(endDate.getDate() - 1);
        setEndDate(endDate);
    }, []);

  // Function to handle calculator type changes
  const handleCalculatorTypeChange = (setFieldValue, values, type) => {
    //manage states
    setDiscountApplied_uuid([]);
    setBasicDiscountPlanList([]);
    setStandardDiscountPlanList([]);
    SetIsDefaultPlan(true);

    const selectedType = type;
    setCalculatorType(selectedType);

    const filteredPlans = getSubscriptionPlan.filter(
      (plan) => plan.calculator_type === selectedType
    );

    setFieldValue("plan_payable_amount", filteredPlans);

    if (filteredPlans.length > 0) {
      setFieldValue(
        "subscription_plan_uuid",
        filteredPlans[0].subscription_plan_uuid
      );
      getPayAbleAmount(
        filteredPlans[0].subscription_plan_uuid,
        values.subscription_duration
      );
    }
  };

  return (
    <React.Fragment>
      {/* add/edit subscriptionModal */}
      <Modal size="lg" isOpen={modal} toggle={() => closeModal()} centered={true} className="create-subscription-plan-modal">
        <ModalHeader toggle={() => closeModal()} tag="h4">
          {!!isEdit ? "Edit Subscription Plan" : "Create Subscription Plan"}
        </ModalHeader>
        <ModalBody>
          {isPaymentInfoIsLoading && isEditSubscriptionDetialsLoading && (<Spinner className="me-2 position-absolute download-quote-spinner" />)}
          {isPaymentInfoIsLoading && (<Spinner className="me-2 position-absolute download-quote-spinner" />)}
          <Formik
            key={subscriptionKey}
            initialValues={{
              subscription_plan_uuid:
                isEdit &&
                getsubscriptionDetailByUserId &&
                getsubscriptionDetailByUserId.subscription_plan_details
                  ? getsubscriptionDetailByUserId.subscription_plan_details
                      .subscription_plan_uuid
                  : getCalculatorTypePlans[0]?.subscription_plan_uuid || "",
              subscription_duration: !!isEdit
                ? getsubscriptionDetailByUserId &&
                  getsubscriptionDetailByUserId.subscription_plan_details
                    .subscription_duration
                : PLAN_DURATION.YERALY,

              range: {
                from: !!isEdit
                  ? getsubscriptionDetailByUserId &&
                    formateDate(
                      new Date(
                        getsubscriptionDetailByUserId.subscription_plan_details.start_date
                      )
                    )
                  : formateDate(new Date()),
                to: !!isEdit
                  ? getsubscriptionDetailByUserId &&
                    formateDate(
                      new Date(
                        getsubscriptionDetailByUserId.subscription_plan_details.end_date
                      )
                    )
                  : endDate,
              },
              paymentDone: !!isEdit
                ? getsubscriptionDetailByUserId &&
                  getsubscriptionDetailByUserId.subscription_plan_details
                    .user_subscription_plan_payment_uuid !== null
                  ? true
                  : false
                : true,
            }}
            onSubmit={(values, actions) => {
              if (!!isEdit) {
                setConfirmationModal(true);
                setSubscriptionDeails(values);
              } else {
                let subScriptionInfo = {
                  subscription_plan_uuid: values.subscription_plan_uuid,
                  user_uuid: props.user_uuid,
                  start_date: moment(UTCtoLocal(values.range.from)).format("YYYY-MM-DD"),
                  end_date: moment(UTCtoLocal(values.range.to)).format("YYYY-MM-DD"),
                  subscription_duration: values.subscription_duration,
                };
                if (values.subscription_duration !== PLAN_DURATION.MONTHLY) {
                  subScriptionInfo.discount_applied = discountApplied_uuid;
                }
                setLoading(true);
                customerService
                  .createUserSubscriptionPlan(subScriptionInfo)
                  .then((response) => {
                    if (response.status === 200) {
                      if (values.paymentDone) {
                        let paymentInfo = {
                          user_subscription_plan_uuid:
                            response.data.user_subscription_plan_uuid,
                          payment_method: "manually",
                          payment_amount: +taxAmount + +payableAmount,
                          tax_amount: +taxAmount,
                        };
                        customerService
                          .createUserSubscriptionPayment(paymentInfo)
                          .then((response) => {
                            if (response.status === 200) {
                              closeModal();
                              props.isSubmitted();
                            }
                          })
                          .finally(() => {
                            setLoading(false);
                          });
                      } else {
                        setLoading(false);
                        closeModal();
                        props.isSubmitted();
                      }
                    }
                  })
                  .catch((error) => {
                    toast.error(error?.response?.data?.errors[0]?.message, {
                      autoClose: 4000,
                    });
                    setLoading(false);
                  });
              }
            }}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <div className="">
                  <div className="d-flex pricing-plan-section justify-content-between align-items-end align-items-sm-center mb-1 ms-4">
                    <Label className="form-label choose-pricing-plan-label">
                      Choose pricing plan<span className="text-danger">*</span>
                    </Label>
                    <div className="mb-0 ">
                      <div className="d-flex type-options-switch me-4">
                        <div className="d-flex align-items-center">
                          <Card className="type-option-card mb-0 h-100 me-0 ">
                            <CardBody className="pt-0 h-100 pb-0 pe-0 ps-0 ps-sm-auto">
                              <label className=" card-radio-label">
                                <input
                                  type="radio"
                                  name="type_option"
                                  id="embroidery"
                                  className="card-radio-input"
                                  readOnly
                                  value="Embroidery"
                                  checked={
                                    CalculatorType ===
                                    Calculator_Types.Calculator_type_embroidery
                                  }
                                  onChange={() => {
                                    handleCalculatorTypeChange(
                                      setFieldValue,
                                      values,
                                      Calculator_Types.Calculator_type_embroidery
                                    );
                                  }}
                                />
                                <div className="card-radio card-radio-duration pt-0 py-2 ">
                                  <span className="selected-radio-button">
                                    Embroidery
                                  </span>
                                </div>
                              </label>
                            </CardBody>
                          </Card>
                          <Card className="type-option-card mb-0 h-100 d-inline-block">
                            <CardBody className="pt-0 h-100 pb-0 pe-0 ps-0">
                              <label className=" card-radio-label">
                                <input
                                  type="radio"
                                  name="type_option"
                                  id="screenprinting"
                                  className="card-radio-input"
                                  readOnly
                                  value="Screen Printing"
                                  checked={
                                    CalculatorType ===
                                    Calculator_Types.Calculator_type_screenprinting
                                  }
                                  onChange={() => {
                                    handleCalculatorTypeChange(
                                      setFieldValue,
                                      values,
                                      Calculator_Types.Calculator_type_screenprinting
                                    );
                                  }}
                                />
                                <div className="card-radio card-radio-duration pt-0 py-2 ">
                                  <span className="selected-radio-button">
                                    Screen Printing
                                  </span>
                                </div>
                              </label>
                            </CardBody>
                          </Card>
                          <Card className="type-option-card mb-0 h-100 d-inline-block">
                            <CardBody className="pt-0 h-100 pb-0 pe-0 ps-0">
                              <label className=" card-radio-label">
                                <input
                                  type="radio"
                                  name="type_option"
                                  id="both"
                                  className="card-radio-input"
                                  readOnly
                                  value="Both"
                                  checked={
                                    CalculatorType ===
                                    Calculator_Types.Calculator_type_all
                                  }
                                  onChange={() => {
                                    handleCalculatorTypeChange(
                                      setFieldValue,
                                      values,
                                      Calculator_Types.Calculator_type_all
                                    );
                                  }}
                                />
                                <div className="card-radio card-radio-duration pt-0 py-2 ">
                                  <span className="selected-radio-button">
                                    Both
                                  </span>
                                </div>
                              </label>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row className="gy-md-2">
                    {getCalculatorTypePlans &&
                      getCalculatorTypePlans.map((plan, index) => (
                        <Col
                          key={plan.subscription_plan_uuid}
                          className="mb-3 mb-md-0 "
                          md="12"
                          lg="6"
                        >
                          <Card
                            className={`plan-box mb-0 h-100 pricing-card ${
                              values.subscription_plan_uuid ===
                              plan.subscription_plan_uuid
                                ? "selected"
                                : ""
                            }`}
                          >
                            <CardBody
                              className={`pt-0 h-100 pb-0 ${
                                index === 0 ? "pe-lg-1" : "ps-lg-1"
                              }`}
                            >
                              <label className="card-radio-label mb-2 px-1">
                                <input
                                  type="radio"
                                  name="subscription_plan_uuid"
                                  className="card-radio-input"
                                  readOnly
                                  value={plan.subscription_plan_uuid}
                                  checked={
                                    values.subscription_plan_uuid ===
                                      plan.subscription_plan_uuid ||
                                    (values.subscription_plan_uuid === "" &&
                                      index === 0)
                                  }
                                  onChange={() => {
                                    setFieldValue(
                                      "subscription_plan_uuid",
                                      plan.subscription_plan_uuid
                                    );
                                    getPayAbleAmount(
                                      plan.subscription_plan_uuid,
                                      values.subscription_duration
                                    );
                                    SetIsDefaultPlan(false);
                                  }}
                                />
                                <div
                                  className={`card-radio ${
                                    plan.subscription_plan_uuid ===
                                      SUBSCRIPTION_PLAN_UUID.EMBROIDERY_PREMIUM_PLAN ||
                                    plan.subscription_plan_uuid ===
                                      SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_PREMIUM_PLAN ||
                                    plan.subscription_plan_uuid ===
                                      SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_PREMIUM_PLAN
                                      ? "pricing-standard-card"
                                      : ""
                                  } `}
                                >
                                  <div className="">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                      <div className="d-flex">
                                        <div>
                                          <h5
                                            ref={(el) =>
                                              (SubscriptionPlanenamesref.current[
                                                index
                                              ] = el)
                                            }
                                            className="d-block d-sm-inline mb-0 subscription-plan-name"
                                            id={`SubscriptionPlanNameTooltip${index}`}
                                          >
                                            {plan.plan_name}
                                            {/* Show tooltip only for small devices if text is overflowing */}
                                            {window.innerWidth <= 480 &&
                                              IsPlanNameOverflowing.includes(
                                                index
                                              ) && (
                                                <UncontrolledTooltip
                                                  placement="bottom"
                                                  target={`SubscriptionPlanNameTooltip${index}`}
                                                >
                                                  {plan.plan_name}
                                                </UncontrolledTooltip>
                                              )}
                                          </h5>
                                        </div>
                                        <div>
                                          <i
                                            id={`Plan${index}`}
                                            className="bx bx-info-circle cursor-pointer text-yellow font-size-14 ms-2 position-relative plan-info-icon"
                                          />
                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target={`Plan${index}`}
                                          >
                                            <p className="text-start plan-descrition mt-2 mb-0">
                                              {plan.plan_description}
                                            </p>
                                          </UncontrolledTooltip>
                                        </div>
                                      </div>
                                      <i
                                        className={`bx 
                                          ${
                                            plan.subscription_plan_uuid ===
                                              SUBSCRIPTION_PLAN_UUID.EMBROIDERY_PREMIUM_PLAN ||
                                            plan.subscription_plan_uuid ===
                                              SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_PREMIUM_PLAN ||
                                            plan.subscription_plan_uuid ===
                                              SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_PREMIUM_PLAN
                                              ? "bx-run"
                                              : "bx-walk"
                                          }
                                          h1 text-yellow mb-0`}
                                        style={{ lineHeight: 1 }}
                                      />
                                    </div>
                                    <div className="plan-details-container">
                                      {plan.subscription_plan_uuid ===
                                        SUBSCRIPTION_PLAN_UUID.BASIC_PALN && (
                                        <p className="mb-0 text-start text-muted text-wrap">
                                          <i className="bx bx-check text-muted me-2"></i>
                                          Embroidery Quote Calculator
                                        </p>
                                      )}

                                      {plan.subscription_plan_uuid ===
                                        SUBSCRIPTION_PLAN_UUID.EMBROIDERY_PREMIUM_PLAN && (
                                        <>
                                          <p className="mb-1 text-start text-muted">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Dashboard
                                          </p>
                                          <p className="mb-1 text-start text-muted text-wrap">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Embroidery Quote Calculator
                                          </p>
                                          <p className="mb-1 text-start text-muted">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Quotes
                                          </p>
                                          <p className="mb-0 text-start text-muted">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Invoices
                                          </p>
                                        </>
                                      )}

                                      {plan.subscription_plan_uuid ===
                                        SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_BASIC_PLAN && (
                                        <>
                                          <p className="mb-1 text-start text-muted text-wrap">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Screen-printing Quote Calculator
                                          </p>
                                        </>
                                      )}

                                      {plan.subscription_plan_uuid ===
                                        SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_PREMIUM_PLAN && (
                                        <>
                                          <p className="mb-1 text-start text-muted">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Dashboard
                                          </p>
                                          <p className="mb-1 text-start text-muted text-wrap">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Screen-Printing Quote Calculator
                                          </p>
                                          <p className="mb-1 text-start text-muted">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Quotes
                                          </p>
                                          <p className="mb-0 text-start text-muted">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Invoices
                                          </p>
                                        </>
                                      )}
                                      {plan.subscription_plan_uuid ===
                                        SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_BASIC_PLAN && (
                                        <>
                                          <p className="mb-1 text-start text-muted text-wrap">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Embroidery Quote Calculator
                                          </p>
                                          <p className="mb-1 text-start text-muted text-wrap">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Screen-printing Quote Calculator
                                          </p>
                                        </>
                                      )}

                                      {plan.subscription_plan_uuid ===
                                        SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_PREMIUM_PLAN && (
                                        <>
                                          <p className="mb-1 text-start text-muted">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Dashboard
                                          </p>
                                          <p className="mb-1 text-start text-muted text-wrap">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Embroidery Quote Calculator
                                          </p>
                                          <p className="mb-1 text-start text-muted text-wrap">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Screen-Printing Quote Calculator
                                          </p>
                                          <p className="mb-1 text-start text-muted">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Quotes
                                          </p>
                                          <p className="mb-0 text-start text-muted">
                                            <i className="bx bx-check text-muted me-2"></i>
                                            Invoices
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="pt-4 mt-1 price-content">
                                    <h2 className="price">
                                      {`$${
                                        values.subscription_duration ===
                                        PLAN_DURATION.MONTHLY
                                          ? plan.price_per_month
                                          : plan.price_per_year
                                      }/ `}
                                      <span className="font-size-13">
                                        {values.subscription_duration ===
                                        PLAN_DURATION.MONTHLY
                                          ? "month"
                                          : "year"}
                                      </span>
                                    </h2>
                                  </div>
                                </div>
                              </label>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </div>
                <Row>
                  <div className="d-flex  align-items-center  justify-content-between choose-plan-duration-wrapper">
                    <div className="d-lg-flex align-items-center ms-md-3 ms-3 mt-2 mb-2 choose-plan-duration-section ps-2 ">
                      <Label className="form-label mb-0 me-3 text-nowrap">
                        Choose plan duration
                        <span className="text-danger">*</span>
                      </Label>
                      <Flatpickr
                        className="form-control d-block range-picker ms-0"
                        placeholder="M dd, yyyy"
                        id="start_date"
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          mode: "range",
                          defaultDate: [values.range.from, values.range.to],
                          minDate: isEdit
                            ? moment(
                                getsubscriptionDetailByUserId &&
                                  getsubscriptionDetailByUserId.subscription_plan_details &&
                                  getsubscriptionDetailByUserId
                                    .subscription_plan_details.start_date
                              ).isAfter(moment(), "day")
                              ? moment().format("YYYY-MM-DD")
                              : getsubscriptionDetailByUserId &&
                                getsubscriptionDetailByUserId.subscription_plan_details &&
                                getsubscriptionDetailByUserId
                                  .subscription_plan_details.start_date
                              ? getsubscriptionDetailByUserId.subscription_plan_details.start_date.split(
                                  "T"
                                )[0]
                              : null
                            : moment().format("YYYY-MM-DD"),
                        }}
                        value={[values.range.from, values.range.to]}
                        onChange={([from, to]) => {
                          setFieldValue("range.from", from);
                          var endDate = new Date(
                            moment(from).format("DD MMM YYYY")
                          );
                          if (
                            values.subscription_duration ===
                            PLAN_DURATION.MONTHLY
                          ) {
                            endDate.setMonth(
                              new Date(
                                moment(from).format("DD MMM YYYY")
                              ).getMonth() + 1
                            );
                            endDate.setDate(endDate.getDate() - 1);
                          } else {
                            endDate.setFullYear(endDate.getFullYear() + 1);
                            endDate.setDate(endDate.getDate() - 1);
                          }
                          setFieldValue(
                            "range.to",
                            moment(endDate).format("YYYY-MM-DD")
                          );
                        }}
                      />
                    </div>
                    <div
                      className="btn-group d-flex mb-3 me-4 btn-group-example plan-duration-switch mt-md-1 mt-lg-3"
                      role="group"
                    >
                      <button
                        type="button"
                        className={`btn btn-sm w-sm plan-duration-button ${
                          values.subscription_duration === PLAN_DURATION.MONTHLY
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          IsDefaultPlan
                            ? handleDefaultPlan(PLAN_DURATION.MONTHLY)
                            : getPayAbleAmount(
                                values.subscription_plan_uuid,
                                PLAN_DURATION.MONTHLY
                              );
                          setFieldValue(
                            "subscription_duration",
                            PLAN_DURATION.MONTHLY
                          );
                          const startDate = formateDate(
                            new Date(values.range.from)
                          );
                          setFieldValue("range.from", startDate);
                          const endDate = new Date(
                            moment(startDate).format("DD MMM YYYY")
                          );
                          endDate.setMonth(
                            new Date(
                              moment(startDate).format("DD MMM YYYY")
                            ).getMonth() + 1
                          );
                          endDate.setDate(endDate.getDate() - 1);
                          setFieldValue("range.to", endDate);
                        }}
                      >
                        Monthly
                      </button>
                      <button
                        type="button"
                        className={`btn btn-sm w-sm plan-duration-button ${
                          values.subscription_duration === PLAN_DURATION.YERALY
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          IsDefaultPlan
                            ? handleDefaultPlan(PLAN_DURATION.YERALY)
                            : getPayAbleAmount(
                                values.subscription_plan_uuid,
                                PLAN_DURATION.YERALY
                              );
                          setFieldValue(
                            "subscription_duration",
                            PLAN_DURATION.YERALY
                          );
                          const startDate = formateDate(
                            new Date(values.range.from)
                          );
                          var expireDate = new Date(
                            moment(startDate).format("DD MMM YYYY")
                          );
                          expireDate.setFullYear(expireDate.getFullYear() + 1);
                          expireDate.setDate(expireDate.getDate() - 1);
                          setFieldValue("range.from", startDate);
                          setFieldValue("range.to", expireDate);
                        }}
                      >
                        Yearly
                      </button>
                    </div>
                  </div>
                </Row>
                <div className="ms-2">
                  <Row className="mt-3">
                    {values.subscription_duration === PLAN_DURATION.YERALY &&
                      !isPaymentInfoIsLoading && (
                        <div className="col-12 ps-0">
                          <div className="ms-4 ps-2 mb-3 py-2 pt-md-2 pt-4 me-4 discount-container">
                            {discountMessage.map((message, index) => (
                              <p
                                key={index}
                                className="d-flex discount-info mb-1"
                              >
                                <i className="bx me-2 text-warning bx-minus font-size-18" />
                                {message}
                              </p>
                            ))}
                            <i
                              className="bx me-2 text-warning bxs-discount font-size-36"
                              style={{
                                position: "absolute",
                                top: "-18px",
                                bottom: "53px",
                                right: "64px",
                              }}
                            ></i>
                          </div>
                        </div>
                      )}
                  </Row>
                  <Row className="d-lg-flex d-none">
                    <Col className="mb-2 mt-md-2">
                      <div className="mt-2 justify-content-end d-flex me-4">
                        <div className="d-flex justify-content-between subtotal-container">
                          <div>
                            <div className="mb-2">
                              <span>Subtotal</span>
                            </div>
                            <div className="my-2 tax-name">
                              <span>
                                {TAX_NAME}({TAX_RATE}%)
                              </span>
                            </div>
                          </div>
                          <div>
                            <div className="m-0 text-end">
                              <p className="m-0 text-end">${payableAmount}</p>
                              <p className="text-end mb-0">
                                <div className="my-2">${taxAmount}</div>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col className="mb-2">
                      <div className="ms-3 me-4 d-sm-flex d-block align-items-sm-center justify-content-between">
                        <div className="d-flex mb-sm-0 justify-content-xl-center align-items-lg-center  align-items-start">
                          <div>
                            <label className="mb-0 font-size-15 d-flex align-items-center form-label">
                              Mark as paid
                            </label>
                          </div>
                          <div>
                            <i
                              id="mark_as_paid_info"
                              className=" bx bx-info-circle  cursor-pointer mark_as_paid_icon font-size-14 position-relative"
                            ></i>
                            <UncontrolledTooltip target={"mark_as_paid_info"}>
                              {" "}
                              Manually mark subscription as paid{" "}
                            </UncontrolledTooltip>
                          </div>
                          <Switch
                            className={`ms-1 me-2 ${
                              values.paymentDone ? "checked" : "unchecked"
                            }`}
                            onChange={(event) => {
                              setFieldValue("paymentDone", event);
                            }}
                            height={20}
                            width={40}
                            checked={values.paymentDone}
                            uncheckedIcon={<i className="d-none"></i>}
                            checkedIcon={<i className="d-none"></i>}
                            handleDiameter={28}
                          />
                        </div>
                        <p className="mb-0 d-lg-flex d-none justify-content-between align-items-center mt-sm-0 mt-2 ms-sm-0 ms-sm-2 payable-amount-section">
                          {" "}
                          <span className="fw-bold me-2">
                            Payable amount
                          </span>{" "}
                          <span className="text-success font-size-22 fw-bold">
                            $ {+payableAmount + +taxAmount}
                          </span>
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3 px-4 pt-lg-3  d-lg-none d-flex">
                    <Col className="d-flex justify-content-end">
                      <div class="">
                        <div className="d-flex justify-content-between subtotal-container">
                          <div>
                            <div className="mb-2">
                              <span>Subtotal</span>
                            </div>
                            <div className="my-2 tax-name">
                              <span>
                                {TAX_NAME}({TAX_RATE}%)
                              </span>
                            </div>
                          </div>
                          <div>
                            <div className="m-0 text-end">
                              <p className="m-0 text-end">${payableAmount}</p>
                              <p className="text-end mb-0">
                                <div className="my-2">${taxAmount}</div>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                          <div className="d-flex justify-content-between total-container">
                            <div>
                              <strong className=" mt-3">Payable amount</strong>
                            </div>
                            <div>
                              <h4 className="total-value color-secondary">
                                ${+payableAmount + taxAmount}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="me-4">
                      <div className="d-flex mt-3 justify-content-end">
                        <button
                          className="btn-outline me-3"
                          type="button"
                          onClick={() => closeModal()}
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                        <button
                          type="submit"
                          className="btn-fill save-user d-flex"
                          disabled={
                            isEdit
                              ? isPaymentInfoIsLoading ||
                                isEditSubscriptionDetialsLoading
                              : isPaymentInfoIsLoading
                          }
                        >
                          {isLoading ? (
                            <Spinners setLoading={setLoading} />
                          ) : (
                            ""
                          )}{" "}
                          Save{" "}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <ConfirmationModal
        show={confirmationModal}
        title={"Edit subscription plan"}
        description={
          "You've done changes in subscription plan. Are you sure you want to save it?"
        }
        actionName={"Confirm"}
        onAction={() => {
          editSubscriptionPlan();
        }}
        afterAction={isEditIsLoading}
        onCloseClick={() => {
          setConfirmationModal(false);
        }}
      />
      <ToastContainer />
    </React.Fragment>
  );
};

export default CreateSubscription;
