import { CUSTOMERS_CLIENT, INVOICE } from '../../shared/services/api.config';
import { ApiService } from '../../shared/services/api.service';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export class InvoiceService {
    apiService = new ApiService();

    async getInvoiceList(user_uuid, searchText) {
        return this.apiService.get(INVOICE.INVOICE_LIST.replace(/{user_uuid}/, user_uuid), { searchedText: searchText }).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getInvoiceDetailByInvoiceId(invoice_uuid) {
        return this.apiService.get(INVOICE.GET_INVOICE_DETIAL.replace(/{invoice_uuid}/, invoice_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async createInvoice(invoiceInfo) {
        return this.apiService.post(INVOICE.CREATE_INVOICE, invoiceInfo).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async editInvoice(invoice_uuid, updated_invoice_info) {
        return this.apiService.put(INVOICE.DELETE_INVOICE.replace(/{invoice_uuid}/,invoice_uuid),updated_invoice_info).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async deleteInvoice(invoice_uuid) {
        return this.apiService.delete(INVOICE.DELETE_INVOICE.replace(/{invoice_uuid}/, invoice_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async deleteInvoiceItem(invoice_item_uuid) {
        return this.apiService.delete(INVOICE.DELETE_INVOICE_ITEM.replace(/{invoice_item_uuid}/, invoice_item_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async sendInvoice(invoice_uuid_obj) {
        return this.apiService.post(INVOICE.SEND_INVOICE, invoice_uuid_obj).then(response => {
            if (response.status === 200) {
                toast.success(response.data.message, { autoClose: 4000 });
                return response;
            } else {
                toast.error(response.response.data.errors[0].message, { autoClose: 4000 });
            }
            return response;
        }).catch(error => {
            toast.error(error.response.data.errors[0].message, { autoClose: 4000 });
            return error;
        });
    }

    async changeInvoiceStatus(invoice_status_info) {
        return this.apiService.post(INVOICE.CHANGE_INVOICE_STATUS, invoice_status_info).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async downloadInvoice(invoice_uuid_obj) {
        return this.apiService.post(INVOICE.DOWNLOAD_INVOICE, invoice_uuid_obj).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async markInvoiceRead(invoice_uuid_obj) {
        return this.apiService.post(INVOICE.MARK_AS_READ, invoice_uuid_obj).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async createCustomer(customerInfo) {
        return this.apiService.post(CUSTOMERS_CLIENT.CREATE_CUSTOMER, customerInfo).then(response => {
            if (response.status === 200) {
                return response;
            } else {
                toast.error(response.response.data.errors[0].message, { autoClose: 4000 });
            }
            return response;
        }).catch(error =>{
            return error;
        });
    }
}

