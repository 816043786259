import React, { useEffect, useState, useMemo } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import './taxDeductionList.scss';
import Breadcrumb from "../../../shared/components/common/breadcrumb";
import TableContainer from "../../../shared/components/common/tableContainer";
import Skeleton from 'react-loading-skeleton';
import { TaxDeductionService } from "../../service/taxDeductionService.service";
import moment from 'moment';

const TaxDeductionList = () => {
    const taxDeductionService = new TaxDeductionService();
    const [isLoading, setisLoading] = useState(false);
    const [totalTaxAmount, setTaxAmount] = useState(0);
    const [taxList, setTaxList] = useState([]);
    const [searchTerm, setSerachterm] = useState('');
    const [copyTaxList, setCopyTaxList] = useState(taxList);
    // check device
    const [isMediumDevice, setIsMediumDevice] = useState(false);
    const [isExtraSmallDevice, setIsExtraSmallDevice] = useState(false);
    const [isExtraExtraSmallDevice, setIsExtraExtraSmallDevice] = useState(false);

    const UTCtoLocal = (utcDate) => {
        const date = new Date(utcDate);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Dynamic time zone based on user's location
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(localDate);
        return formattedDate;
    }

    const onSearchHandler = (searchTerm) => {
        if (searchTerm) {
            const lowerCaseSearchTerm = searchTerm.toLowerCase();
            setSerachterm(lowerCaseSearchTerm);
            let filteredItems = copyTaxList.filter(item => {
                return item.user_name.toLowerCase().includes(lowerCaseSearchTerm) ||
                    item.user_email.toLowerCase().includes(lowerCaseSearchTerm);
            });
            setTaxList(searchTerm.length === 0 ? copyTaxList : filteredItems);
            const totalTaxAmount = filteredItems.reduce((total, item) => total + parseFloat(item.tax_amount), 0);
            setTaxAmount(filteredItems.length === 0 ? 0 : totalTaxAmount);
        } else {
            setTaxList(copyTaxList);
            setTaxAmount(copyTaxList.length === 0 ? 0 : copyTaxList.reduce((total, item) => total + parseFloat(item.tax_amount), 0))
        }
    }

    const onselectMonth = (value) => {
        if (value) {
            setisLoading(true);
            taxDeductionService.taxDeductionList(value.month, value.year, JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
                if (response.status === 200) {
                    let filteredItems = response.data.filter(item => {
                        return item.user_name.toLowerCase().includes(searchTerm) ||
                            item.user_email.toLowerCase().includes(searchTerm);
                    });
                    const totalTaxAmount = filteredItems.reduce((total, item) => total + parseFloat(item.tax_amount), 0);
                    setTaxAmount(filteredItems.length === 0 ? 0 : totalTaxAmount);
                    setTaxList(searchTerm.length === 0 ? response.data : filteredItems);
                    setCopyTaxList(response.data);
                }
            }).finally(() => {
                setisLoading(false);
            })
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Transaction ID',
                accessor: 'transaction_id',
                filterable: true,
                maxWidth: 400,
                minWidth: 140,
                width: 200,
                Cell: (cellProps) => {
                    return <> {(!isLoading && cellProps.row.original?.transaction_id) && <span
                        className={`customer ${cellProps.row.original.transaction_id?.length > (isExtraExtraSmallDevice ? 16 : (isExtraSmallDevice ? 16 : (isMediumDevice ? 16 : 23))) ? 'customer_ellipsis' : ''}`}
                        data-toggle='tooltip' data-title={cellProps.row.original.transaction_id}>{cellProps.row.original.transaction_id}</span>}
                        {(!isLoading && !cellProps.row.original.transaction_id) && <span className="ps-3"> --- </span>}
                        {isLoading &&
                            <div><Skeleton className='mb-0' width={120} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></div>
                        }
                    </>
                }
            },
            {
                Header: 'User',
                accessor: 'email',
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {!isLoading && (cellProps.row.original.user_name || cellProps.row.original.user_email) && <>
                            <p className={`mb-1  customer-email-text ${cellProps.row.original.user_name.length > (isExtraExtraSmallDevice ? 13 : (isExtraSmallDevice ? 13 : (isMediumDevice ? 13 : 27))) ? 'customer_ellipsis shop-name-tooltip' : ''}`}
                                data-toggle='tooltip' data-title={cellProps.row.original.user_name}
                            >
                                {cellProps.row.original.user_name}</p>
                            <p className={`mb-0 customer-email-text  email-text ${cellProps.row.original.user_email.length > (isExtraExtraSmallDevice ? 13 : (isExtraSmallDevice ? 13 : (isMediumDevice ? 13 : 27))) ? 'customer_ellipsis shop-email-tooltip' : ''}`} id="emailTooltip"
                                data-toggle='tooltip' data-title={cellProps.row.original.user_email}>{cellProps.row.original.user_email}
                            </p>
                        </>
                        }
                        {!isLoading && !(cellProps.row.original.user_name && cellProps.row.original.user_email) && <> <div> --- </div></>
                        }
                        {isLoading && <> <Skeleton className="mb-1" width={'100px'} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton>
                            <Skeleton className="mb-0" baseColor='var(--secondary-white)' width={'100px'} highlightColor="var(--primary-dark-white)"></Skeleton></>
                        }
                    </>;
                }
            },
            {
                Header: 'Created at',
                accessor: 'created_at',
                filterable: true,
                Cell: (cellProps) => {
                    return <div>
                        {!isLoading && cellProps.row.original?.created_at && <div className='d-flex'>

                            <span className={`mb-0 customer-shop-name-text ${cellProps.row.original.created_at.length > (isExtraExtraSmallDevice ? 13 : (isExtraSmallDevice ? 13 : (isMediumDevice ? 13 : 27))) ? 'customer_ellipsis' : ''}`}
                                data-toggle='tooltip' data-title={UTCtoLocal(cellProps.row.original.created_at)}>{UTCtoLocal(cellProps.row.original.created_at)}
                            </span>
                        </div>
                        }
                        {(!isLoading && !cellProps.row.original.created_at) && <span> --- </span>}
                        {isLoading &&
                            <div><Skeleton className='mb-0' width={120} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></div>
                        }
                    </div >;
                }
            },
            {
                Header: 'Paid amount',
                accessor: 'paid_amount',
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {!isLoading && (cellProps.row.original.payment_amount) && <> <p className="mb-1">
                            ${cellProps.row.original.payment_amount}</p>
                        </>
                        }
                        {!isLoading && !(cellProps.row.original.payment_amount) && <> <div> --- </div></>
                        }
                        {isLoading &&
                            <div><Skeleton className='mb-0' width={120} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></div>
                        }
                    </>;
                }
            },
            {
                Header: 'Tax amount',
                accessor: 'tax_amount',
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {!isLoading && (cellProps.row.original.tax_amount) && <> <p className="mb-1">
                            ${cellProps.row.original.tax_amount}</p>
                        </>
                        }
                        {isLoading &&
                            <div><Skeleton className='mb-0' width={120} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></div>
                        }
                    </>;
                }
            },
            {
                Header: 'Paid on',
                accessor: 'paid_on',
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {
                            !isLoading && (

                                <span className={`mb-0 customer-shop-name-text ${cellProps.row.original?.paid_on?.length > (isExtraExtraSmallDevice ? 13 : (isExtraSmallDevice ? 13 : (isMediumDevice ? 13 : 27))) ? 'customer_ellipsis' : ''}`}
                                    data-toggle='tooltip' data-title={UTCtoLocal(cellProps.row.original.paid_on)}>{UTCtoLocal(cellProps.row.original.paid_on)}
                                </span>
                            )
                        }
                        {!isLoading && !(cellProps.row.original.paid_on) && <> <div> --- </div></>
                        }
                        {isLoading &&
                            <div><Skeleton className='mb-0' width={120} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></div>
                        }
                    </>;
                }
            },
            {
                Header: 'Payment Method',
                accessor: 'payment_method',
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {!isLoading && (cellProps.row.original.payment_method) && <> <p className="mb-1">
                            {cellProps.row.original.payment_method}</p>
                        </>
                        }
                        {!isLoading && !(cellProps.row.original.payment_method) && <> <div> --- </div></>
                        }
                        {isLoading &&
                            <div><Skeleton className='mb-0' width={120} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></div>
                        }
                    </>;
                }
            },
        ],
        [isLoading, isExtraSmallDevice, isExtraExtraSmallDevice, isMediumDevice]
    );

    useEffect(() => {
        function handleResize() {
            window.innerWidth <= 1024 ? setIsMediumDevice(true) : setIsMediumDevice(false);
            window.innerWidth <= 610 ? setIsExtraSmallDevice(true) : setIsExtraSmallDevice(false);
            window.innerWidth <= 281 ? setIsExtraExtraSmallDevice(true) : setIsExtraExtraSmallDevice(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useState(() => {
        onselectMonth({ month: moment().month() + 1, year: moment().year() });
    }, []);

    return (
        <React.Fragment>
            <div className="page-content pb-0">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb breadcrumbItem="Tax Deductions" />
                    <Row>
                        <Col xs="12" className="tax-deduction-list">
                            <Card className="position-relative mb-0 list-card">
                                {<CardBody>
                                    {/* list table */}
                                    {<TableContainer className='w-100'
                                        columns={columns}
                                        data={taxList?.length < 6 && isLoading ? [0, 1, 2, 3, 5, 6] : taxList}
                                        isPagination={false}
                                        isAddOptions={true}
                                        TOTAL_COLLECTED_AMOUNT={totalTaxAmount}
                                        searchPlaceholder={'Search by name & email'}
                                        tableClass="align-middle table-nowrap"
                                        onSearch={onSearchHandler}
                                        selectedValue={onselectMonth}
                                        isLoading={isLoading}
                                        listName={'tax-list'}
                                    />}
                                </CardBody>}
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}
export default TaxDeductionList;