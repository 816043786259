import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row, Spinner, DropdownItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, Alert, UncontrolledTooltip } from "reactstrap";
import './getPaid.scss';
import { ShopOwnerGetPaid } from "../../service/ShopOwnerGetPaid.service";
import ConfirmationModal from "../../../shared/components/common/confirmationModal";
import ChangeLinkedAccount from "../../../shared/components/common/changeLinkedAccount";

const GetPaid = () => {
    const ShopOwnerGetPaidService = new ShopOwnerGetPaid();

    const [isLinkIsGenerated, setisLinkIsGenerated] = useState(true);
    const [showDropdown, setShowDropdown] = useState(false);
    const [accountStatus, setAccountStaus] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showAccountLinkModal, setShowAccountLinkModal] = useState(false);
    const [isDeleted, setisDeleted] = useState(false);
    const [username, setUserName] = useState();

    const getShopownerAccountStatus = () => {
        setAccountStaus(undefined);
        ShopOwnerGetPaidService.verifyStripeAccountStatus(JSON.parse(sessionStorage.getItem('userLogin'))?.data.user_uuid).then(response => {
            if (response.status === 200) {
                setAccountStaus(response.data.status);
                if (response.data.status === 'completed') {
                    setUserName(response.data.name);
                }
            }
        });
    }

    useState(() => {
        getShopownerAccountStatus();
    }, []);

    const onClickLinkAccount = () => {
        setisLinkIsGenerated(false);
        let user_uuid = {
            user_uuid: JSON.parse(sessionStorage.getItem('userLogin'))?.data.user_uuid
        }
        ShopOwnerGetPaidService.generateLink(user_uuid).then(response => {
            if (response.status === 200) {
                let newTab = window.open();
                newTab.opener = null;
                newTab.location.href = response.data.url;
            }
        }).catch(error => {
            console.error(error)
        }).finally(() => {
            setisLinkIsGenerated(true);
        });
    }

    const AcountStatus = (data) => {
        if (data) {
            getShopownerAccountStatus();
        }
    }

    const deleteAccountHandle = () => {
        setisDeleted(false);
        ShopOwnerGetPaidService.unlinkAccount(JSON.parse(sessionStorage.getItem('userLogin'))?.data.user_uuid).then(response => {
            if (response.status === 200) {
                setisDeleted(true);
                getShopownerAccountStatus();
                setAccountStaus(undefined);
            }
        });
    }

    return (
        <React.Fragment>
            <div className="page-content preview-quote-page pb-0">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <div className="row page-title-box d-flex align-items-center">
                        <Col>
                            <h4 className="my-2 font-size-18">Getting Paid</h4>
                        </Col>
                    </div>
                    <div className="get-paid-container">
                        <Row>
                            <Col>
                                <div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">Available payment methods <i className="dripicons-card card-icon " /> </h5>
                                        <i className="mdi mdi-refresh-circle text-success font-size-30 cursor-pointer" id="refreshIcon" onClick={() => { setAccountStaus(undefined); setTimeout(() => {
                                            getShopownerAccountStatus();
                                        }, 5000);  }} />
                                        <UncontrolledTooltip target={'refreshIcon'}>Refresh</UncontrolledTooltip>
                                    </div>
                                    <ul>
                                        <li className="paymentInfo">Setup the payment methods you'd like to make available for clients.</li>
                                        <li className="paymentInfo">Any checked payment methods will appear as defaults for new invoices.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        {
                            accountStatus === 'completed' && (
                                <Row>
                                    <Col>
                                        <Alert className="p-2 payment-completed">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <i className="bx bx-check text-success me-2 check-icon" />
                                                </div>
                                                <div>
                                                    Your bank account is already set up with Stripe for receiving payments for client invoices. If needed, you may unlink or update your details at any time. Let us know if you need further assistance.
                                                </div>
                                            </div>
                                        </Alert>
                                    </Col>
                                </Row>
                            )
                        }
                        <Row className="">
                            <Col>
                                <Alert className={` alert-info border-0 px-md-3 px-sm-1 px-2 py-2 mb-0 alert-message`}>
                                    <div className="d-flex  align-items-center">
                                        <div>
                                            <i className="dripicons-lightbulb home-icon card-icon font-size-18 me-2" />
                                        </div>
                                        <div>
                                            <span className="fw-bold">Print Shop Master</span> does not charge any processing fees on top of those from the payment processor.
                                        </div>
                                    </div>
                                </Alert>
                            </Col>
                        </Row>
                        {
                            accountStatus !== undefined && (
                                <Row className="mt-4">
                                    <Col md={4} sm={6}>
                                        <Card className={`${accountStatus === 'completed' ? 'payment-card cursor-pointer' : accountStatus === 'not_completed' ? 'bordered-payment-card' : ''} payment-card`} onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                                            <CardBody className="p-2">
                                                <div>
                                                    {
                                                        accountStatus === 'completed' && (
                                                            <div className="checkbox">
                                                                <div>
                                                                    <label class="control control-checkbox">
                                                                        <input type="checkbox" disabled="disabled" checked="checked" />
                                                                        <div class="control_indicator"></div>
                                                                    </label>
                                                                </div>

                                                                <div className="dropdown-container">
                                                                    {
                                                                        showDropdown && accountStatus === 'completed' && (
                                                                            <UncontrolledDropdown className="option-dropdown ">
                                                                                <DropdownToggle tag="a" className="card-drop">
                                                                                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu className="dropdown-menu-end mt-2">
                                                                                    <DropdownItem onClick={() => { setShowModal(true); }}
                                                                                        className="d-flex align-items-center">
                                                                                        <span className="dropdown-menu-text">Unlink account</span>
                                                                                    </DropdownItem>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    <div className={`mb-5 ${accountStatus === 'completed' ? 'mt-0' : 'mt-4'} text-center`}>
                                                        <p className="mb-0">Bank Transfer</p>
                                                        <i className="fab fa-stripe stripe-icon" />
                                                        <p>Fees vary. <a href="https://stripe.com/pricing" target="_blabj">Learn more at Stripe</a></p>
                                                    </div>
                                                    <div className="pt-4">
                                                        {
                                                            accountStatus === 'completed' ? (
                                                                <div className="text-center mb-2 paymentInfo ">connected as {username}</div>
                                                            ) :
                                                                (
                                                                    <button className="btn link-account-btn border-0 d-flex align-items-center justify-content-center" onClick={onClickLinkAccount}>{!isLinkIsGenerated ? <Spinner color="dark" className='me-2' /> : ''} Link your account</button>
                                                                )
                                                        }
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        }
                        {accountStatus === undefined && <Spinner className='me-2 position-absolute download-quote-spinner' />}
                    </div>
                </Container>
                <ConfirmationModal
                    show={showModal}
                    title={'Delete stripe account Information'}
                    description={'Are you sure you want to delete Stripe Account Information?'}
                    actionName={'Delete'}
                    onCloseClick={() => { setShowModal(false); }}
                    onAction={() => deleteAccountHandle()}
                    afterAction={isDeleted}
                />
                <ChangeLinkedAccount
                    show={showAccountLinkModal}
                    title={'Change linked account'}
                    actionName={'save'}
                    onCloseClick={() => { setShowAccountLinkModal(false); }}
                    isAccountUpdated={AcountStatus}
                />
            </div>
        </React.Fragment >
    )
}

export default GetPaid;