import React, { useState, useCallback } from "react";
import { Row, Col, CardBody, Card, NavItem, NavLink, TabPane, TabContent, Container, Input, Label, Form, FormFeedback, Modal, ModalHeader, UncontrolledTooltip } from "reactstrap";
// style
import "./register.scss";
import classnames from "classnames"
//Dropzone
import { useDropzone } from 'react-dropzone';
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
// navigation
import { Link, useNavigate, useLocation } from "react-router-dom";
// validation error 
import { registerValidationErr, Regex } from '../../../shared/static/constants/constant';
// for contact number
import { PatternFormat } from "react-number-format";
// service
import { AuthenticationService } from '../../service/authentication.service';
// toast message
import { ToastContainer } from "react-toastify";
// loader
import Spinners from '../../../shared/components/common/spinner';
import { CustomValidator } from '../../../shared/helpers/customValidators';
import TaxSelect from "../../../shared/components/common/addTax";
import AvatarEditor from 'react-avatar-editor';
import { useRef } from "react";
import { QuoteService } from '../../../quotes/service/quote.service';
import ImagePreviewModal from '../../../shared/components/common/imagePreviewModal';
import SubscriptionPassModal from "../../../shared/components/common/subscriptionPassModal";
import RichTextEditor from "react-rte";

const Register = props => {
  // loader
  const [isLoading, setLoading] = useState(false);
  const [quoteNotes, setQuoteNotes] = useState(RichTextEditor.createEmptyValue());
  const [invoiceNotes, setInvoiceNotes] = useState(RichTextEditor.createEmptyValue());
  // navigation
  const navigate = useNavigate();
  const { state } = useLocation();
  const cropRef = useRef(null);
  const [slideValue, setSlideValue] = useState(10);
  const [ishowcropimage, setishowCropimage] = useState();
  const [getTaxInfo, setTaxInfo] = useState([]);
  const quoteService = new QuoteService();
  const [isSubscribed, setisSubScribed] = useState(false);

  // auth service
  const authenticationService = new AuthenticationService();
  // for shop contact number
  const [shopContactNo, setShopContactNo] = useState('');
  // for header tab
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1]);
  const [previewImage, setPreviewImage] = useState(null);

  const [shopLogoTypeError, setShopLogoTypeError] = useState(null);
  // toggle tab
  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 2) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
    // navigate to login
    if (tab === 3 && validation.isValid && !(shopContactNo.length > 0 && shopContactNo.length < 10)) {
      signUp();
    }
  }


  // cropimage and replace with orignal image
  const handlePreviewSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      const randomString = generateRandomString(8);
      const uniqueFileName = `${randomString}_${selectedFiles.path}`;
      let croppedImageFile = new File([blob], uniqueFileName, { type: "image/" });
      croppedImageFile.preview = URL.createObjectURL(blob);
      setselectedFiles(croppedImageFile);
      croppedImageFile = '';
      setishowCropimage(false);
      setSlideValue(10);
    }
  }

  const updatedTaxInfo = (newTaxInfo) => {
    setTaxInfo(newTaxInfo);
  }

  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  // form
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      name: '',
      password: '',
      shopName: '',
      shopLogo: '',
      shopEmail: '',
      shopAddress: '',
      shopCity: '',
      shopState: '',
      shopCountry: '',
      shopZipCode: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required(registerValidationErr.ERR_EMAIL_REQUIRED)
        .email(registerValidationErr.ERR_EMAIL_INVALID),
      name: Yup.string().required(registerValidationErr.ERR_NAME_REQUIRED)
        .matches(Regex.ONLY_CHAR, registerValidationErr.ERR_NAME_INVALID),
      shopCity: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_CITY_INVALID),
      shopState: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_STATE_INVALID),
      shopCountry: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_COUNTRY_INVALID),
      password: Yup.string()
        .required(registerValidationErr.ERR_PASSWORD_REQUIRED)
        .min(8, registerValidationErr.ERR_PASSWORD_INVALID)
        .test(
          'validator-custom-password',
          async function (value) {
            const validation = await CustomValidator.passwordValidation(value);
            if (!validation?.isValid) {
              return this.createError({
                path: this.path,
                message: validation?.errorMessage,
              });
            } else {
              return true;
            }
          }
        ),
      shopName: Yup.string().matches(Regex.SHOP_NAME, registerValidationErr.ERR_SHOPNAME_INVALID),
      shopEmail: Yup.string().email(registerValidationErr.ERR_EMAIL_INVALID),
      shopZipCode: Yup.string().matches(Regex.ZIP_CODE, registerValidationErr.ERR_INVALID_ZIP_CODE).nullable()
    })
  });

  // call sign up Api 
  const signUp = () => {
    setLoading(true);
    const userSignUpDetails = {
      email: validation.values.email,
      name: validation.values.name,
      password: validation.values.password,
      shop_name: validation.values.shopName,
      shop_logo: selectedFiles,
      shop_email: validation.values.shopEmail,
      shop_contact_number: shopContactNo,
      shop_address: validation.values.shopAddress,
      shop_city: validation.values.shopCity,
      shop_state: validation.values.shopState,
      shop_country: validation.values.shopCountry,
      shop_zip_code: validation.values.shopZipCode,
      quote_notes: quoteNotes.toString('html') === '<p><br></p>' ? null : quoteNotes.toString('html'),
      invoice_notes: invoiceNotes.toString('html') === '<p><br></p>' ? null : invoiceNotes.toString('html')
    }
    const formData = new FormData();
    Object.entries(userSignUpDetails).forEach(([key, value]) => {
      formData.append(key, value);
    });
    authenticationService.signUp(formData).then(response => {
      if (response.status === 200) {
        if (getTaxInfo.length !== 0) {
          getTaxInfo.forEach(obj => delete obj.id);
          let TAX_INFO = {
            'customer_shop_info_uuid': response.data.customer_shop_info_uuid,
            'tax_items': getTaxInfo
          }
          quoteService.addTaxInfo(TAX_INFO).then((response) => {
            if (response.status == 200) {
              setisSubScribed(true);
            }
          }).finally(() => {
            setLoading(false);
          });
        } else {
          setLoading(false);
          setisSubScribed(true);
        }
      } else {
        setLoading(false);
      }
    }).catch(() => {
      setLoading(false);
    });
  }
  // toggle eye icon for password
  const [visiblePassword, setVisiblePassword] = useState(false);

  // shop logo file upload
  const [selectedFiles, setselectedFiles] = useState();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: useCallback((files, rejectedFiles) => {
      if (rejectedFiles && rejectedFiles.length > 0) {
        setShopLogoTypeError('Please select a valid image file.');
      } else {
        setShopLogoTypeError('');
        setishowCropimage(true);
        setSlideValue(10);
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        );
        setselectedFiles(files[0]);
      }
    }),
    multiple: false
  });

  // delete selected logo file
  function removeFile() {
    setselectedFiles(null);
  }

  return (
    <React.Fragment>
      <SubscriptionPassModal
        show={isSubscribed}
        actionName={'close'}
        onCloseClick={() => { setisSubScribed(false); navigate("/login"); }}
        description={"Thank you for registering with us! Your 7-day trial period will commence now. We hope you enjoy exploring our service. If you have any questions or need assistance, feel free to reach out to our support team at "}
      />
      <div className="register-page my-4" >
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={7} xl={7}>
              <Card className="py-5 px-3 px-lg-5 mb-0">
                {/* title */}
                <div className="bg-white">
                  <Row className="justify-content-center">
                    <Col className="col-5 my-auto text-center col">
                      <img src="/assets/images/Print_Shop_Master_svg_logo.svg" alt="logo" className="img-fluid logo-img" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="py-0 px-0">
                  <div className="header-text">
                    <h3 className="header-text text-center my-4">Registration</h3>
                  </div>
                  <div className="wizard clearfix">
                    {/* header nav tab */}
                    <div className="steps clearfix">
                      <ul className="text-center header-tab-ul">
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => { setactiveTab(1); }}
                          >
                            <h5 className="my-2"> Personal Details</h5>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => { setactiveTab(2); }}
                          >
                            <h5 className="my-2"> Shop &nbsp;
                              <br className="tab-space"></br>
                              Details</h5>
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix pt-4 px-0">
                      {/* tab content */}
                      <TabContent activeTab={activeTab} className="body pt-0">
                        {/* tab pane 1 */}
                        <TabPane tabId={1}>
                          <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            {/* name */}
                            <div className="mb-3">
                              <Label className="form-label">Name <span className='text-danger'>*</span></Label>
                              <Input
                                id="name"
                                name="name"
                                className="form-control"
                                placeholder="Enter name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={validation.touched.name && validation.errors.name ? true : false}
                              />
                              {validation.touched.name && validation.errors.name ? (<FormFeedback type="invalid">{validation.errors.name}</FormFeedback>) : null}
                            </div>
                            {/* email */}
                            <div className="mb-3">
                              <Label className="form-label">Email <span className='text-danger'>*</span></Label>
                              <Input
                                id="email"
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={validation.touched.email && validation.errors.email ? true : false}
                              />
                              {validation.touched.email && validation.errors.email ? (<FormFeedback type="invalid">{validation.errors.email}</FormFeedback>) : null}
                            </div>
                            {/* password */}
                            <div className="mb-3 position-relative">
                              <Label className="form-label">Password <span className='text-danger'>*</span> <i className="bx bx-info-circle register-info-icon position-relative" id="passwordInfo">
                                <div className='info_tooltip'>
                                  <ul className='mt-1 ps-1 pe-1'>
                                    <li className="mb-2">Your password length must be greater than or equal to 8</li>
                                    <li className="mb-2">Any three among below four conditions should match,</li>
                                    <ul>
                                      <li className="mb-2">Must contain at least 1 uppercase character</li>
                                      <li className="mb-2">Must contain at least 1 lowercase character</li>
                                      <li className="mb-2">Must contain at least 1 numeric value</li>
                                      <li className="mb-2">Must contain at least 1 special character</li>
                                    </ul>
                                  </ul>
                                </div></i></Label>
                              <Input
                                name="password"
                                type={visiblePassword ? 'text' : 'password'}
                                placeholder="Enter Password"
                                autoComplete='off'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={validation.touched.password && validation.errors.password ? true : false}
                              />
                              {validation.values.password.length > 0 && (<i className={`bi login-eye ${!(validation.touched.password && validation.errors.password) ? 'right-5px' : ''}  ${visiblePassword ? 'bi-eye' : 'bi-eye-slash'}`} onClick={() => { setVisiblePassword(!visiblePassword) }}></i>)}
                              {validation.touched.password && validation.errors.password ? (<FormFeedback type="invalid">{validation.errors.password}</FormFeedback>) : null}
                            </div>
                          </Form>
                        </TabPane>
                        {/* tab pane 2 */}
                        <TabPane tabId={2}>
                          <div>
                            <Form className="shop-details-form">
                              {/* shop name */}
                              <div className="mb-3">
                                <Label for="shopName">
                                  Shop Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="shopName"
                                  placeholder="Enter Name"
                                  value={validation.values.shopName}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={validation.touched.shopName && validation.errors.shopName ? true : false}
                                />
                                {validation.touched.shopName && validation.errors.shopName ? (<FormFeedback type="invalid">{validation.errors.shopName}</FormFeedback>) : null}
                              </div>
                              {/* shop logo */}
                              <div className="mb-3">
                                <Label for="shopLogo">
                                  Shop Logo
                                </Label>
                                {/* drag and drop logo image */}
                                {selectedFiles === undefined || selectedFiles == null ? (
                                  <div className={`dropzone ${shopLogoTypeError ? 'mb-1' : ''}`}>
                                    <div
                                      className="dz-message needsclick p-0"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="mb-0">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6 className="font-size-11">Drop files here or click to upload.</h6>
                                    </div>
                                  </div>
                                ) : (<div className="dropzone-previews" id="file-previews">
                                  {
                                    selectedFiles !== undefined &&
                                    <Card
                                      className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete"
                                      key={selectedFiles.name + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col lg={2} className="col-2">
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light preview-logo-img cursor-pointer"
                                              alt={selectedFiles?.name}
                                              src={selectedFiles?.preview}
                                              onClick={() => setPreviewImage(selectedFiles?.preview)}
                                            />
                                          </Col>
                                          <Col lg={8} className="col-8">
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {selectedFiles?.name}
                                            </Link>
                                          </Col>
                                          <Col lg={2} className="text-end col-2"><i className="bx bx-trash font-size-17 cursor-pointer text-danger" onClick={() => {
                                            removeFile()
                                          }}></i>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  }
                                </div>)}
                                <span className="text-danger image-error-msg">{shopLogoTypeError}</span>
                              </div>
                              <Row className="mb-3">
                                {/* shop email */}
                                <Col lg={6} className="mb-lg-0 mb-3">
                                  <Label for="shopEmail">
                                    Shop Email
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="shopEmail"
                                    placeholder="Enter Email"
                                    value={validation.values.shopEmail || ''}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={validation.touched.shopEmail && validation.errors.shopEmail ? true : false}
                                  />
                                  {validation.touched.shopEmail && validation.errors.shopEmail ? (<FormFeedback type="invalid">{validation.errors.shopEmail}</FormFeedback>) : null}
                                </Col>
                                {/* shop contact number */}
                                <Col lg={6} className="mb-lg-0">
                                  <Label for="shopContactNo">
                                    Shop Contact No.
                                  </Label>
                                  <PatternFormat
                                    className="form-control"
                                    id="shopContactNo"
                                    name="phone"
                                    placeholder="Enter Contact Number"
                                    value={validation.values.shopContactNo || ""}
                                    onChange={validation.handleChange}
                                    format="###-###-####"
                                    onBlur={validation.handleBlur}
                                    onValueChange={(values) => { setShopContactNo(values.value); }}
                                  />
                                  {shopContactNo && shopContactNo.length > 0 && shopContactNo.length < 10 ? <span className="invalid-file">{registerValidationErr.ERR_CONTACT_NO_INVALID}</span> : ''}
                                </Col>
                              </Row>
                              {/* shop address */}
                              <div className="mb-3">
                                <Label for="shopAddress">
                                  Shop Address
                                </Label>
                                <textarea
                                  className="form-control"
                                  id="shopAddress"
                                  placeholder="Enter Address"
                                  rows="2"
                                  value={validation.values.shopAddress || ''}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                ></textarea>
                              </div>
                              <Row className="mb-3">
                                {/* shop city */}
                                <Col lg={6} className="mb-lg-0 mb-3">
                                  <Label for="shopCity">
                                    City
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="shopCity"
                                    placeholder="Enter City"
                                    value={validation.values.shopCity || ''}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={validation.touched.shopCity && validation.errors.shopCity ? true : false}
                                  />
                                  {validation.touched.shopCity && validation.errors.shopCity ? (<FormFeedback type="invalid">{validation.errors.shopCity}</FormFeedback>) : null}
                                </Col>
                                {/* shop state */}
                                <Col lg={6}>
                                  <Label for="shopState">
                                    State
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="shopState"
                                    placeholder="Enter State"
                                    value={validation.values.shopState || ''}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={validation.touched.shopState && validation.errors.shopState ? true : false}
                                  />
                                  {validation.touched.shopState && validation.errors.shopState ? (<FormFeedback type="invalid">{validation.errors.shopState}</FormFeedback>) : null}
                                </Col>
                              </Row>
                              {/* shop country */}
                              <Row className="mb-3">
                                <Col lg={6} className="mb-lg-0 mb-3">
                                  <Label for="shopCountry">
                                    Country
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="shopCountry"
                                    placeholder="Enter Country"
                                    value={validation.values.shopCountry || ''}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={validation.touched.shopCountry && validation.errors.shopCountry ? true : false}
                                  />
                                  {validation.touched.shopCountry && validation.errors.shopCountry ? (<FormFeedback type="invalid">{validation.errors.shopCountry}</FormFeedback>) : null}
                                </Col>
                                {/* shop zip code */}
                                <Col lg={6}>
                                  <Label for="shopZipCode">
                                    Zip Code
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="shopZipCode"
                                    placeholder="Enter Zip Code"
                                    value={validation.values.shopZipCode || ''}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      const formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10);
                                      if (formattedValue.length > 5) {
                                        validation.handleChange({
                                          target: {
                                            name: 'shopZipCode',
                                            value: `${formattedValue.slice(0, 5)}-${formattedValue.slice(5)}`,
                                          },
                                        });
                                      } else {
                                        validation.handleChange({
                                          target: {
                                            name: 'shopZipCode',
                                            value: formattedValue,
                                          },
                                        });
                                      }
                                    }}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.shopZipCode && validation.errors.shopZipCode ? true : false
                                    }
                                    onKeyDown={(e) => {
                                      // Allow Backspace if the length is greater than 0
                                      if (e.key === 'Backspace' && validation.values.shopZipCode.length > 0) {
                                        return;
                                      }

                                      // Limit to 10 characters including hyphen
                                      if (e.target.value.length >= 10 && e.key !== 'Backspace') {
                                        e.preventDefault();
                                      }
                                      // Allow numeric digits, hyphen, and Backspace
                                      if (!(
                                        (e.key >= '0' && e.key <= '9') ||
                                        e.key === 'Backspace' || e.key === '-'
                                      )) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  {validation.touched.shopZipCode && validation.errors.shopZipCode ? (<FormFeedback type="invalid">{validation.errors.shopCountry}</FormFeedback>) : null}
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                {/* quote notes */}
                                <Col >
                                  <Label for="quote_notes">
                                    Quote Notes <i id="quote_tooltip" className="bx bx-info-circle cursor-pointer notes-info-icon position-absolute">
                                    </i>
                                    <UncontrolledTooltip target={"quote_tooltip"} placement="top">Notes will be included in every quote</UncontrolledTooltip>
                                  </Label>
                                  <RichTextEditor editorClassName="editor custom-editor"
                                    editorStyle={{ fontSize: "13px", fontFamily: "Poppins, sans-serif", maxHeight: '170px', overflow: 'scroll' }}
                                    placeholder="Enter quote notes"
                                    value={quoteNotes}
                                    onChange={(e) => { setQuoteNotes(e) }} />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col>
                                  <Label for="invoice_notes">
                                    Invoice Notes <i id="invoice_tooltip" className="bx bx-info-circle cursor-pointer notes-info-icon position-absolute">
                                    </i>
                                    <UncontrolledTooltip target={"invoice_tooltip"} placement="top">Notes will be included in every invoice</UncontrolledTooltip>
                                  </Label>
                                  <RichTextEditor editorClassName="editor custom-editor"
                                    editorStyle={{ fontSize: "13px", fontFamily: "Poppins, sans-serif", maxHeight: '170px', overflow: 'scroll' }}
                                    placeholder="Enter invoice notes"
                                    value={invoiceNotes}
                                    onChange={(e) => { setInvoiceNotes(e) }} />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col className="mb-lg-0 mb-3 d-flex">
                                  <TaxSelect options={getTaxInfo} updatedTaxInfo={updatedTaxInfo} shouldShowSelectedTax={false} componentName={'register'} />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    {/* footer button */}
                    <div className="actions clearfix px-0">
                      <div className="d-flex justify-content-between  align-items-center">
                        <div className="">
                          <p className='mb-0 '>
                            <Link to={state === null ? "/login" : "/"} className="fw-medium header-text d-flex align-items-center back-to-login color-secondary">
                              <i className="bx bx-caret-left font-size-20"></i>
                              <span className="back-login-link">{state === null ? 'Back to  Login' : 'Back'} </span>
                            </Link>{" "}
                          </p>
                        </div>
                        <div className="d-flex">
                          <button className={
                            activeTab === 1 ? "previous disabled btn-outline" : "previous btn-outline"
                          }
                            onClick={() => {
                              toggleTab(activeTab - 1)
                            }}
                          >
                            Previous
                          </button>
                          <button className={(!validation?.isValid || !validation.dirty) || (shopContactNo.length > 0 && shopContactNo.length < 10 && activeTab === 2) ? "next disabled btn-fill ms-3" : "next btn-fill ms-3"}
                            disabled={(!validation?.isValid || !validation.dirty) || (shopContactNo.length > 0 && shopContactNo.length < 10 && activeTab === 2)}

                            onClick={() => {
                              toggleTab(activeTab + 1)
                            }}
                          >
                            <div className='d-flex  justify-content-center align-items-center'>
                              {isLoading ? <Spinners className="" setLoading={setLoading} /> : ''}
                              {activeTab === 2 ? 'Register' : 'Next'}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* crop profile crop */}
      <Modal isOpen={ishowcropimage} centered={true} toggle={() => { setishowCropimage(false); setselectedFiles(null) }} className="image-crop-modal mb-0">
        {/* modal header */}
        <ModalHeader toggle={() => { setishowCropimage(false); setselectedFiles(null) }} tag="h4">
        </ModalHeader>
        <div className="pb-0 modal-body">
          <div className="modal-dialog  modal_dialog_container">
            <div className="modal-content border-0 modal_content paragraph_modal">
              <div className="modal-body border-0 modal_body pt-0 pb-0">
                <div className='avatar-editor-wrapper'>
                  <AvatarEditor
                    ref={cropRef}
                    image={selectedFiles?.preview}
                    style={{ width: "100%", height: "100%" }}
                    border={50}
                    borderRadius={150}
                    color={[0, 0, 0, 0.72]}
                    scale={slideValue / 10}
                    rotate={0}
                  />
                </div>
                <div
                  className="input-group preview-zoom-slider-wrapper my-3"
                >
                  <input
                    type="range"
                    min={10}
                    max={50}
                    value={slideValue}
                    onChange={(e) => setSlideValue(e.target.value)}
                    className="form-range slider preview-zoom-slider"
                  />
                </div>
              </div>
              <div className='modal-footer pb-0 modal_footer mt-0 border-0 justify-content-end'>
                <button className='btn me-3 btn-outline '
                  onClick={(e) => {
                    e.preventDefault();
                    setishowCropimage(false);
                    setselectedFiles(null);
                  }}>
                  <div>Cancel</div>
                </button>
                <button type="submit" className="btn btn-fill save_user " onClick={handlePreviewSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Modal for image preview */}
      <ImagePreviewModal imgSrc={previewImage} onCloseClick={() => setPreviewImage(null)}></ImagePreviewModal>
      {/* loader */}
      {isLoading ? <Spinners setLoading={setLoading} /> : ''}
      {/* toast message */}
      <ToastContainer />
    </React.Fragment>
  );
};

export default Register;
