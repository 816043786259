import { useState } from "react";
import { UncontrolledTooltip, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import AddClientModal from "./addClientModal";
import { InvoiceService } from "../../../invoices/service/invoice.service";
import { ToastContainer } from "react-toastify"

const ChooseClientDropdown = ({ showDropdown, customerQuoteClientList, invoice_client_details, button_title, toggleDropdown, onAction, fromComponent }) => {
    const [inputValue, setInputValue] = useState('');
    const [showAddClientModal, setShowAddClientModal] = useState(false);
    const [afterAction, setAfterAction] = useState(false);
    const invoiceService = new InvoiceService();
    const [isError, setIsError] = useState(false);

    const onClickSaveClient = (newClientInfo) => {
        setAfterAction(false);
        invoiceService.createCustomer({ client_company_name: newClientInfo.invoice_client_details.client_company_name,client_name: newClientInfo.invoice_client_details?.client_name ,client_email: newClientInfo.invoice_client_details.client_email, client_address: newClientInfo.invoice_client_details.client_address, client_city: newClientInfo.invoice_client_details.client_city, client_state: newClientInfo.invoice_client_details.client_state, client_zip_code: newClientInfo.invoice_client_details.client_zip_code === null ? '' : newClientInfo.invoice_client_details.client_zip_code, client_country: newClientInfo.invoice_client_details.client_country }).then((response) => {
            if (response.status === 200) {
                setAfterAction(true);
                onAction(newClientInfo.invoice_client_details);
                setShowAddClientModal(false);
                setIsError(false);
            } else {
                setIsError(true);
                setAfterAction(false);
            }
        }).finally(() => {
        });
    }

    return (
        <div>
            {
                !showDropdown && invoice_client_details === '' && (
                    <button className={`btn form-control ${fromComponent === 'preview-quote' ? 'ms-2' : ''} choose-a-recipient p-2 mb-2 d-flex justify-content-between`} onClick={() => { toggleDropdown(); }}> {button_title} <i className="mdi mdi-chevron-down down-icon font-size-20" /> </button>
                )
            }
            {
                showDropdown && (
                    <Dropdown
                        isOpen={showDropdown}
                        toggle={() => toggleDropdown()}
                        className="d-inline-block pt-0"
                        onClick={(e) => { e.preventDefault(); }}
                    >
                        <DropdownToggle
                            className={`btn d-flex border-0 pt-0 ps-0 align-items-center`}
                            id="page-header-user-dropdown"
                            tag="button"
                            onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                        >
                            <input
                                type="text"
                                value={inputValue}
                                placeholder="search by client name..."
                                className={`d-block client-search-bar form-control`}
                                onBlur={() => { }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 32) {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setInputValue(inputValue + ' ')
                                    }
                                }}
                                onChange={(e) => { setInputValue(e.target.value) }}
                                onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                            />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu select-client-dropdown-menu px-2" >
                            <button className='btn btn-success add-new-client-btn d-flex align-items-center' type='button' onClick={() => { toggleDropdown(); setShowAddClientModal(true); }}><i className="me-1 bx bx-plus-circle" /> Add a new Client</button>
                            <hr className='mt-1 mb-1' />
                            {customerQuoteClientList
                                .filter((option) =>
                                    option?.client_name.toLowerCase().includes(inputValue.toLowerCase()) ||
                                    option.client_email.toLowerCase().includes(inputValue.toLowerCase())
                                ).map((option, index) => (
                                    <button className={`btn d-flex dropdwon-option align-items-center justify-content-between `} onClick={() => {
                                        delete option.quote_client_info_uuid;
                                        onAction(option);
                                        toggleDropdown();
                                    }} >
                                        <div>
                                            <p className='mb-0 text-start option-client-company-name' id={`tax_name${index}`}>{option?.client_name}</p>
                                            {
                                                option?.client_name?.length > 28 && (
                                                    <UncontrolledTooltip target={`tax_name${index}`} style={{ background: 'black' }}>
                                                        {option?.client_name}
                                                    </UncontrolledTooltip>
                                                )
                                            }
                                            <p className='mb-0 text-start option-client-email' id={`option_client${index}`}>{option.client_email}</p>
                                            {
                                                option.client_email.length > 28 && (
                                                    <UncontrolledTooltip target={`option_client${index}`} style={{ background: 'black' }}>
                                                        {option.client_email}
                                                    </UncontrolledTooltip>
                                                )
                                            }
                                        </div>
                                    </button>
                                ))}
                            {
                                customerQuoteClientList.filter((option) =>
                                    option?.client_name.toLowerCase().includes(inputValue.toLowerCase()) ||
                                    option.client_email.toLowerCase().includes(inputValue.toLowerCase())
                                ).length === 0 && (
                                    <p className='d-flex justify-content-center align-items-center no-tax-info pe-2'><i className='bx bx-info-circle me-1' /> No Client available.</p>
                                )
                            }
                        </DropdownMenu>
                    </Dropdown>
                )}
            <AddClientModal fromPeople={false} show={showAddClientModal} title={'New Client'} onCloseClick={() => setShowAddClientModal(false)} actionName={'save'} onAction={onClickSaveClient} afterAction={afterAction} getError={isError} />
            {/* toast message */}
            <ToastContainer />
        </div>
    )
}

export default ChooseClientDropdown;