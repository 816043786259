import React from 'react';
import './quoteProgressBar.scss';

const QuoteProgressBar = ({ statuses, currentStatus }) => {
    const getStatusClass = (status) => {
        if (status === currentStatus) return 'current';
        if (statuses.indexOf(status) < statuses.indexOf(currentStatus)) return 'done';
        return 'remaining';
    };

    return (
        <div className="progress-bar pt-0 status-progress-bar">
            {statuses.map((status, index) => (
                <React.Fragment key={index}>
                    {index > 0 && <div className={`progress-line ${getStatusClass(statuses[index])}`}></div>}
                    <div className={`progress-circle ${getStatusClass(status)}`}>
                        <div className={`progress-label ${getStatusClass(status)}`}>{status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}</div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default QuoteProgressBar;