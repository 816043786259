import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import withRouter from "../shared/components/common/withRouter";

import SidebarContent from "./sidebarContent";

const Sidebar = props => {

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box p-0">
          <div to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src="/assets/images/Print_Shop_Master_small_logo.svg" alt="" height="40" />
            </span>
            <span className="logo-lg">
              <img src="/assets/images/Print Shop Master-sidebar.svg" alt="" className="logo-large" />
            </span>
          </div>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(Sidebar));
