import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { AWS_S3_URL } from '../../static/constants/constant';

const ImageUpload = ({ selectedImage, handleImageChange, handlePreviewImage, handleDeleteImage, index, quoteItemDetails, isEditMode, isFromPreviewQuote = false }) => {
    return (
        <div className="img-upload-container">
            {selectedImage && (
                <div className={`img-preview-div d-flex ${isFromPreviewQuote ? 'preview-quote-img-preview-div justify-content-start' : 'justify-content-center'}`}>
                    <div className="image-preview cursor-pointer">
                        <img className="image-preview" src={selectedImage} alt="Selected" onClick={() => handlePreviewImage(selectedImage)} />
                    </div>
                    {isEditMode && (
                        <i onClick={() => handleDeleteImage(index, quoteItemDetails.quote_item_uuid)} id={`removeImg-${index}`} className={`mdi mdi-trash-can text-danger cursor-pointer font-size-13 mt-1 position-absolute item-img-delete ${isFromPreviewQuote ? 'preview-img-delete-icon' : ''}`}>
                            <UncontrolledTooltip placement="bottom" target={`removeImg-${index}`} > Remove</UncontrolledTooltip>
                        </i>
                    )}
                </div>
            )}
            {(!selectedImage && quoteItemDetails.item_image_url) && (
                <div className={`img-preview-div d-flex ${isFromPreviewQuote ? 'preview-quote-img-preview-div justify-content-start' : 'justify-content-center'}`}>
                    <div className="image-preview cursor-pointer">
                        <img className="image-preview" src={`${AWS_S3_URL + quoteItemDetails.item_image_url}`} alt="Selected" onClick={() => handlePreviewImage(`${AWS_S3_URL + quoteItemDetails.item_image_url}`)} />
                    </div>
                    {isEditMode && (
                        <i onClick={() => handleDeleteImage(index, quoteItemDetails.quote_item_uuid)} id={`removeImgUrl-${index}`} className={`mdi mdi-trash-can text-danger cursor-pointer font-size-13 mt-1 position-absolute item-img-delete ${isFromPreviewQuote ? 'preview-img-delete-icon' : ''}`}>
                            <UncontrolledTooltip placement="bottom" target={`removeImgUrl-${index}`} > Remove</UncontrolledTooltip>
                        </i>
                    )}
                </div>
            )}
            {(!selectedImage && !quoteItemDetails.item_image_url && isEditMode) && (
                <div className={`wrapper ${isFromPreviewQuote ? '' : 'd-flex flex-column align-items-center'}`}>
                    <div className="btnimg cursor-pointer">
                        <label htmlFor={`imageInput-${index}`}>
                            <i className="bx bx-image-add btnimg d-flex justify-content-center align-items-center cursor-pointer"></i>
                        </label>
                    </div>
                    <input
                        className={`quote-file ${isFromPreviewQuote ? 'preview-quote-file' : ''}`}
                        type="file"
                        id={`imageInput-${index}`}
                        onChange={(e) => handleImageChange(e, index)}
                        accept="image/*"
                    />
                    <UncontrolledTooltip placement="bottom" target={`imageInput-${index}`}>Upload Image</UncontrolledTooltip>
                </div>
            )}
        </div>
    );
};

ImageUpload.propTypes = {
    selectedImage: PropTypes.string,
    handleImageChange: PropTypes.func.isRequired,
    handlePreviewImage: PropTypes.func.isRequired,
    handleDeleteImage: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    quoteItemDetails: PropTypes.object.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isFromPreviewQuote: PropTypes.bool
};

export default ImageUpload;
