import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
// css
import './pricingPage.scss'
import { CustomerService } from "../../../customers/service/customer.service";
import { useNavigate } from "react-router-dom";
import { Plan_Duration, PRICINGPAGE_DESCRIPTION, SUBSCRIPTION_CALC_TYPE, SUBSCRIPTION_PLAN_UUID } from "../../static/constants/constant";

const PricingPage = props => {
    const customerservice = new CustomerService();
    const [isLoading, setisLoading] = useState(false);
    const [subscriptionPlanList, setsubscriptionPlanList] = useState([]);
    const [filteredSubscriptionPlan, setFilteredSubscriptionPlanList] = useState([]);
    const [selectedPlanDuration, setSelectedPlanDuration] = useState(Plan_Duration.Yearly);
    const [selectedPlanType, setSelectedPlanType] = useState(SUBSCRIPTION_CALC_TYPE.ALL);
    const navigate = useNavigate();

    useEffect(() => {
        setisLoading(true);
        customerservice.getSubscriptionplanDetailByUserId(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                if (response.data.subscription_expired === 1) {
                    getDetails();
                } else {
                    if ((response.data.subscription_exists === 1 && response.data.subscription_payment_succeeded === 1) || (response.data.subscription_exists === 1 && response.data.subscription_payment_succeeded === 0)) {
                        response.data.subscription_plan_details.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.STANDARD_PLAN ? navigate("/dashboard") : navigate('/quote-calculator');
                    } else {
                        getDetails();
                    }
                }
            }
        })
    }, []);

    const getDetails = () => {
        customerservice.getSubscriptionPlanList().then((response) => {
            if (response.status === 200) {
                setsubscriptionPlanList(response.data);
                const filteredPlans = response.data.filter(
                    (plan) => plan.calculator_type === selectedPlanType
                );
                setFilteredSubscriptionPlanList(filteredPlans);
            }
        }).finally(() => {
            setisLoading(false);
        });
    }

    const filterPlans = (selectedPlanType) => {
        setSelectedPlanType(selectedPlanType);
        const filteredPlans = subscriptionPlanList.filter(
            (plan) => plan.calculator_type === selectedPlanType
        );
        setFilteredSubscriptionPlanList(filteredPlans);
    }

    return (
        <React.Fragment>
            <div className="page-content pricing-page">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="text-center mb-4">
                                {/* heading */}
                                <h3>Choose your Pricing plan</h3>
                                {/* description */}
                                <p className="text-muted mb-0 mt-4 pricing-description">
                                    {PRICINGPAGE_DESCRIPTION}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col sm={12}>
                            <div className="d-sm-flex justify-content-center  align-items-center mt-1 category-container">
                                <div className="d-flex justify-content-start justify-content-md-start justify-content-sm-end">
                                    <div className={`p-2 pe-3 ps-3 ${selectedPlanType === SUBSCRIPTION_CALC_TYPE.EMBROIDERY ? 'selected-duration' : 'duration-label'} cursor-pointer `} onClick={() => { filterPlans(SUBSCRIPTION_CALC_TYPE.EMBROIDERY) }} >
                                        <span>{SUBSCRIPTION_CALC_TYPE.EMBROIDERY}</span>
                                    </div>
                                    <div className={`p-2 pe-3 ps-3 ${selectedPlanType === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING ? 'selected-duration' : 'duration-label'} cursor-pointer `} onClick={() => { filterPlans(SUBSCRIPTION_CALC_TYPE.SCREENPRINTING) }} >
                                        <span>{SUBSCRIPTION_CALC_TYPE.SCREENPRINTING}</span>
                                    </div>
                                    <div className={`p-2 pe-3 ps-3 ${selectedPlanType === SUBSCRIPTION_CALC_TYPE.ALL ? 'selected-duration' : 'duration-label'} cursor-pointer`} onClick={() => { filterPlans(SUBSCRIPTION_CALC_TYPE.ALL) }}>
                                        <span>Both</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className="d-flex justify-content-center  align-items-center a mb-4">
                                <div className="btn-group justify-content-end mb-3 btn-group-example plan-duration-switch  mt-3" role="group">
                                    <button type="button" className={`btn btn-sm w-sm plan-duration-button ${selectedPlanDuration === Plan_Duration.Monthly&& "active" }`}onClick={() => {setSelectedPlanDuration(Plan_Duration.Monthly);}} >
                                        {Plan_Duration.Monthly}
                                    </button>
                                    <button type="button"className={`btn btn-sm w-sm plan-duration-button ${selectedPlanDuration === Plan_Duration.Yearly && "active" }`} onClick={() => { setSelectedPlanDuration(Plan_Duration.Yearly) }} >
                                       {Plan_Duration.Yearly}
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        !isLoading ? (
                            <Row className="justify-content-center">
                                {filteredSubscriptionPlan && filteredSubscriptionPlan.map(plan => (
                                    <Col key={plan.subscription_plan_uuid} className="mb-4 mb-md-0" xxl="3" xl="4" md="6">
                                        <Card className={`plan-box mb-0 pricing-card 
                                            ${plan.subscription_plan_uuid ===
                                                SUBSCRIPTION_PLAN_UUID.EMBROIDERY_PREMIUM_PLAN ||
                                                plan.subscription_plan_uuid ===
                                                SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_PREMIUM_PLAN ||
                                                plan.subscription_plan_uuid ===
                                                SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_PREMIUM_PLAN
                                                ? "pricing-standard-card"
                                                : ""}`}>
                                            <CardBody className="p-4">
                                                <div>
                                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                        <h5 className="d-inline mb-0">{plan.plan_name}</h5>
                                                        <i className={`bx ${plan.subscription_plan_uuid ===
                                                            SUBSCRIPTION_PLAN_UUID.EMBROIDERY_PREMIUM_PLAN ||
                                                            plan.subscription_plan_uuid ===
                                                            SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_PREMIUM_PLAN ||
                                                            plan.subscription_plan_uuid ===
                                                            SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_PREMIUM_PLAN
                                                            ? "bx-run"
                                                            : "bx-walk"
                                                            }  h1 text-yellow mb-0`} style={{ lineHeight: 1 }} />
                                                    </div>
                                                    <div className={`plan-description-container `}>
                                                        <p className="text-muted mt-2 mb-0">{plan.plan_description}</p>
                                                    </div>
                                                </div>
                                                <div className="py-4 mt-1">
                                                    <h2 className="price">
                                                        ${selectedPlanDuration === 'monthly' ? plan.price_per_month : plan.price_per_year}/{" "}
                                                        <span className="font-size-13">{selectedPlanDuration === 'monthly' ? 'month' : 'year'} </span>
                                                    </h2>
                                                </div>
                                                <div className="plan-features text-muted mt-1 pt-2">
                                                    {plan.subscription_plan_uuid ===
                                                        SUBSCRIPTION_PLAN_UUID.BASIC_PALN && (
                                                            <p className="mb-1"> <i className="bx bx-check color-primary me-2" />{" "} Embroidery Quote Calculator </p>
                                                        )}
                                                    {plan.subscription_plan_uuid ===
                                                        SUBSCRIPTION_PLAN_UUID.EMBROIDERY_PREMIUM_PLAN && (
                                                            <>
                                                                <p className="mb-1"><i className="bx bx-check color-primary me-2" />{" "} Dashboard </p>
                                                                <p className="mb-1 text-start text-muted text-wrap"><i className="bx bx-check color-primary me-2" />{" "}Embroidery Quote Calculator</p>
                                                                <p className="mb-1 text-start text-muted"><i className="bx bx-check color-primary me-2" />{" "}Quotes </p>
                                                                <p className="mb-0 text-start text-muted"> <i className="bx bx-check color-primary me-2" />{" "} Invoices </p>
                                                            </>
                                                        )}
                                                    {plan.subscription_plan_uuid ===
                                                        SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_BASIC_PLAN && (
                                                            <>
                                                                <p className="mb-1 text-start text-muted text-wrap"><i className="bx bx-check color-primary me-2" />{" "} Screen-printing Quote Calculator</p>
                                                            </>
                                                        )}
                                                    {plan.subscription_plan_uuid ===
                                                        SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_PREMIUM_PLAN && (
                                                            <>
                                                                <p className="mb-1 text-start text-muted"><i className="bx bx-check color-primary me-2" />{" "}   Dashboard </p>
                                                                <p className="mb-1 text-start text-muted text-wrap"><i className="bx bx-check color-primary me-2" />{" "} Screen-Printing Quote Calculator </p>
                                                                <p className="mb-1 text-start text-muted"><i className="bx bx-check color-primary me-2" />{" "} Quotes </p>
                                                                <p className="mb-0 text-start text-muted"><i className="bx bx-check color-primary me-2" />{" "}Invoices</p>
                                                            </>
                                                        )}
                                                    {plan.subscription_plan_uuid ===
                                                        SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_BASIC_PLAN && (
                                                            <>
                                                                <p className="mb-1 text-start text-muted text-wrap"><i className="bx bx-check color-primary me-2" />{" "} Embroidery Quote Calculator</p>
                                                                <p className="mb-1 text-start text-muted text-wrap"><i className="bx bx-check color-primary me-2" />{" "} Screen-printing Quote Calculator</p>
                                                            </>
                                                        )}
                                                    {plan.subscription_plan_uuid ===
                                                        SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_PREMIUM_PLAN && (
                                                            <>
                                                                <p className="mb-1 text-start text-muted"> <i className="bx bx-check color-primary me-2" />{" "} Dashboard </p>
                                                                <p className="mb-1 text-start text-muted text-wrap"><i className="bx bx-check color-primary me-2" />{" "} Embroidery Quote Calculator</p>
                                                                <p className="mb-1 text-start text-muted text-wrap"><i className="bx bx-check color-primary me-2" />{" "}  Screen-Printing Quote Calculator </p>
                                                                <p className="mb-1 text-start text-muted"><i className="bx bx-check color-primary me-2" />{" "}Quotes </p>
                                                                <p className="mb-0 text-start text-muted"><i className="bx bx-check color-primary me-2" />{" "} Invoices</p>
                                                            </>
                                                        )}
                                                </div>
                                                <div className="text-center plan-btn mt-4">
                                                    <button className="btn btn-fill btn-sm waves-effect waves-light pay-now-btn" onClick={() => {
                                                        navigate('/pay-subscription', { state: { planInfo: plan, selectedDuration: selectedPlanDuration, isPaymentMode: false } })
                                                    }}>Choose plan</button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <Spinner className='me-2 position-absolute download-quote-spinner' />
                        )
                    }
                </Container>
            </div>
        </React.Fragment >
    )
}


export default PricingPage;