import './subscriptionMiddleware.scss';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import React, { useEffect, useState } from 'react';
import { CustomerService } from '../../../customers/service/customer.service';
import { useNavigate } from 'react-router-dom';
import Spinners from './spinner';

const SubscriptionMiddleware = ({ title, onclickcancle, description, show, closeModal, isFromAuthGuard }) => {
    const customerService = new CustomerService();
    const [subscriptionDetails, setSubscriptionDetails] = useState(undefined);
    const [planInfo, setPlanInfo] = useState();
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (show) {
            setLoading(true);
            customerService.getSubscriptionplanDetailByUserId(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
                if (response.status === 200) {
                    let subscriptionDetail = response.data;
                    setSubscriptionDetails(subscriptionDetail);
                    customerService.getSubscriptionPlanList().then((response) => {
                        if (response.status === 200) {
                            setLoading(false);
                            if(subscriptionDetail.subscription_plan_details !== null){
                                setPlanInfo(response.data.find((obj) => obj.subscription_plan_uuid === subscriptionDetail.subscription_plan_details.subscription_plan_uuid))
                            }
                        }
                    })
                }
            });
        }
    }, [show]);

    const onclickConfirmClick = () => {
        subscriptionDetails.subscription_payment_succeeded === 0 && subscriptionDetails.subscription_exists === 1 ?
            navigate('/pay-subscription', { state: { planInfo: planInfo, selectedDuration: subscriptionDetails.subscription_plan_details.subscription_duration, isPaymentMode: true, subscription_plan_uuid: subscriptionDetails.subscription_plan_details.user_subscription_plan_uuid, start_date: subscriptionDetails.subscription_plan_details.start_date, end_date: subscriptionDetails.subscription_plan_details.end_date } })
            : navigate('/pricing');
        closeModal();
    }

    return (
        <Modal className='add-client' size="md" isOpen={show} centered={true}>
            {/* header */}
            <ModalHeader tag="h4" className={`${isFromAuthGuard ? 'border-0' : ''}`}>
                {title}
            </ModalHeader>
            <div className={`modal-content ${isFromAuthGuard ? 'border-0' : ''}`}>
                <ModalBody>
                    <div className='text-center'>
                        <div className='avatar-md mx-auto mb-4'>
                            <div className={`avatar-title ${isFromAuthGuard ? 'auth-icon-container' : 'info-icon-container '} rounded-circle text-primary h1`}>
                                <i className={`${isFromAuthGuard ? 'fas fa-bahai auth-icon' : " dripicons-warning info-icon"}  `}></i>
                            </div>
                        </div>
                    </div>
                    <p className={`text-muted mb-4 font-size-16 ${isFromAuthGuard ? 'text-center' : ''}`}>
                        {!isFromAuthGuard ? description : 'Oops! it looks like this feature is exclusive to our '}
                        {
                            isFromAuthGuard && (
                                <span className='fw-bold text-success'>standard</span>
                            )
                        }
                        {
                            isFromAuthGuard && (
                                ' plan members!'
                            )
                        }
                    </p>
                    <div className="hstack justify-content-end mb-0">
                        {
                            isFromAuthGuard ? (
                                <div className=''>
                                    <button className='btn btn-fill' onClick={onclickcancle}>Go Back</button>
                                </div>
                            ) :
                                isLoading ? (
                                    <button className='btn ms-2 btn-fill' disabled={isLoading}><Spinners setLoading={setLoading} /></button>
                                ) : (
                                    <div className='d-flex align-items-center justify-content-end mb-0'>
                                        {
                                            ((subscriptionDetails && subscriptionDetails?.trial_period_expired === 1 && subscriptionDetails.subscription_exists === 0) ||
                                                (subscriptionDetails && subscriptionDetails?.subscription_exists === 1 && subscriptionDetails.subscription_expired === 1 && subscriptionDetails.trial_period_expired === 1)) && (
                                                <button type="button" className={`me-3 btn btn-danger `} disabled={subscriptionDetails === undefined} onClick={onclickcancle}>Cancel</button>
                                            )
                                        }
                                        {
                                            ((subscriptionDetails && subscriptionDetails.subscription_payment_succeeded === 0 && subscriptionDetails.subscription_exists === 1) ||
                                                (subscriptionDetails && subscriptionDetails.trial_period_expired === 1 && subscriptionDetails.subscription_exists === 0) ||
                                                (subscriptionDetails && subscriptionDetails.subscription_exists === 1 && subscriptionDetails.subscription_expired === 1 && subscriptionDetails.trial_period_expired === 1)) &&
                                            (
                                                <button type='button' className='btn-fill' onClick={onclickConfirmClick} disabled={subscriptionDetails === undefined} >Upgrade now</button>
                                            )
                                        }
                                    </div>
                                )
                        }
                    </div>
                </ModalBody>
            </div>
        </Modal>
    )
}

export default SubscriptionMiddleware;