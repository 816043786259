import { ApiService } from '../../shared/services/api.service';
import { CUSTOMERS_CLIENT } from '../../shared/services/api.config';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";

export class PeopleService {
    apiService = new ApiService();

    // delete people
    deletePeople(peopleId) {
        return this.apiService.delete(`${CUSTOMERS_CLIENT.DELETE}/${peopleId}`).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    // update people
    updatePeople(peopleId, data) {
        return this.apiService.put(`${CUSTOMERS_CLIENT.UPDATE_CLIENT}/${peopleId}`, data).then(response => {
            if (response.status === 200) {
                return response;
            } else {
                toast.error(response.response.data.errors[0].message, { autoClose: 4000 });
            }
            return response;
        }).catch(error => {
            toast.error(error.response.data.errors[0].message, { autoClose: 4000 });
            return error;
        });
    }
}