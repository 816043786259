import React, { useEffect, useRef, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { Role } from '../authentication/model/authentication.model';

// Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "../shared/components/common/withRouter";
import { Link } from "react-router-dom";
import { SUBSCRIPTION_PLAN_UUID } from "../shared/static/constants/constant";

const SidebarContent = props => {
  const ref = useRef();
  // get user role
  const [userRole, setUserRole] = useState("");
  const [getUserInfo, setUserInfo] = useState('');

  useEffect(() => {
    if (sessionStorage.getItem('userLogin')) {
      const userDetail = JSON.parse(sessionStorage.getItem("userLogin"));
      setUserInfo(userDetail);
      setUserRole(userDetail.data?.role_name);
    }
  }, [props.success]);

  useEffect(() => {
    const userDetail = JSON.parse(sessionStorage.getItem("userLogin"));
    setUserInfo(userDetail);
    setUserRole(userDetail.data?.role_name);
    activeMenu();
  }, [JSON.parse(sessionStorage.getItem('userLogin'))]);

  // Function to activate parent dropdown
  const activateParentDropdown = useCallback((item, menuId) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== menuId) {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Function to remove activation
  const removeActivation = (items) => {
    for (var i = 0; i < items?.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.length && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "admin-side-menu" && parent2El.id !== "customer-side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById(userRole === Role.Admin ? "admin-side-menu" : "customer-side-menu");
    const items = ul?.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items?.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem, userRole === Role.Admin ? "admin-side-menu" : "customer-side-menu");
    }
  }, [path.pathname, userRole, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    const menuId = JSON.parse(sessionStorage.getItem('userLogin')).data.role_name !== Role.Admin ? "admin-side-menu" : "customer-side-menu";
    const menuElement = document.getElementById(menuId);
    if (menuElement) {
      new MetisMenu(`#${menuId}`);
      activeMenu();
    } else {
      console.error(`Menu element with ID "${menuId}" not found.`);
    }
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <div className="text-center d-lg-none"><img className="small-device-logo" src="/assets/images/Print Shop Master-sidebar.svg" /></div>
          {userRole !== Role.Customer && (
            <ul className="mt-lg-0 mt-3 metismenu list-unstyled" id="admin-side-menu">
              <li>
                <Link to="/dashboard" >
                  <i className="bx bx-home-circle"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li >
                <Link to="/customer-list" >
                  <i className="bx bxs-user-detail"></i>
                  <span>Customers</span>
                </Link>
              </li>
              <li>
                <Link to="/tax-deduction">
                  <i className="mdi-percent-outline mdi" />
                  <span>Tax Deductions</span>
                </Link>
              </li>
            </ul>
          )}

          {
            userRole !== Role.Admin && (getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.BASIC_PALN || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_BASIC_PLAN || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_BASIC_PLAN )&& (
              <ul className="mt-lg-0 mt-3 metismenu list-unstyled" id="customer-side-menu">
                <li>
                  <Link to="/dashboard" >
                    <i className="bx bx-home-circle"></i>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li>
                  <Link to="/quote-calculator">
                    <i className='bx bx-calculator'></i>
                    <span>Quote Calculator</span>
                  </Link>
                </li>
              </ul>
            )
          }
          {
            (userRole !== Role.Admin && (getUserInfo?.data?.subscription_details.subscription_plan_details === null || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_PREMIUM_PLAN || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_PREMIUM_PLAN || getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.STANDARD_PLAN)) && (
              <ul className="mt-lg-0 mt-3 metismenu list-unstyled" id="customer-side-menu">
                <li>
                  <Link to="/dashboard" >
                    <i className="bx bx-home-circle"></i>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li>
                  <Link to="/quote-calculator">
                    <i className='bx bx-calculator'></i>
                    <span>Quote Calculator</span>
                  </Link>
                </li>
                <li>
                  <Link to="/quote-list">
                    <i className="bx bx-receipt"></i>
                    <span>Quotes</span>
                  </Link>
                </li>
                <li>
                  <Link to="/invoice-list">
                    <i className="combined-invoice-icon mdi mdi-file-outline">
                      <i className="overlay-dollar-icon mdi mdi-currency-usd"></i>
                    </i>
                    <span>Invoices</span>
                  </Link>
                </li>
                <li>
                  <Link to="/people">
                    <i className="bx bx-user"></i>
                    <span>People</span>
                  </Link>
                </li>
              </ul>
            )
          }
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};


export default withRouter(SidebarContent);
