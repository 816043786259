import React, { useEffect, useState, useMemo, useRef } from "react";
import withRouter from "../../../shared/components/common/withRouter";
import { Card, CardBody, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Select from "react-select";
import TableContainer from "../../../shared/components/common/tableContainer";
import ConfirmationModal from "../../../shared/components/common/confirmationModal";
// loader
import Skeleton from 'react-loading-skeleton';
import Breadcrumb from "../../../shared/components/common/breadcrumb";
import AddClientModal from "../../../shared/components/common/addClientModal";
import { PeopleType } from "../../model/people.model";
import './peopleList.scss';
// service
import { PeopleService } from "../../service/people.service";
import { QuoteService } from "../../../quotes/service/quote.service";
import { InvoiceService } from "../../../invoices/service/invoice.service";
// toast message
import { ToastContainer, toast } from "react-toastify";

const PeopleList = () => {
    // loader
    const [isLoading, setLoading] = useState(false);
    const [peopleList, setPeopleList] = useState([]);
    const [allPeopleList, setAllPeopleList] = useState([]);

    //delete quote
    const [deleteModal, setDeleteModal] = useState(false);
    const [afterAction, setAfterAction] = useState(false);
    const [people, setPeople] = useState();
    const [isEditClient, setIsEditClient] = useState(false);

    const [isError, setIsError] = useState(false);

    const [showAddClientModal, setShowAddClientModal] = useState(false);
    const [afterPeopleAction, setAfterPeopleAction] = useState(false);
    // for select dropdown
    const [selectedGroup, setselectedGroup] = useState(null);
    const filteredTypeRef = useRef(null);

    // service 
    const peopleService = new PeopleService();
    const quoteService = new QuoteService();
    const invoiceService = new InvoiceService();

    // check device
    const [isMediumDevice, setIsMediumDevice] = useState(false);
    const [isExtraSmallDevice, setIsExtraSmallDevice] = useState(false);
    const [isExtraExtraSmallDevice, setIsExtraExtraSmallDevice] = useState(false);

    useEffect(() => {
        getPeopleListInfo();
    }, []);

    const peopleTypeOption = [
        { label: PeopleType.Lead.toUpperCase(), value: PeopleType.Lead.toUpperCase(), className: 'lead-option' },
        { label: PeopleType.Client.toUpperCase(), value: PeopleType.Client.toUpperCase(), className: 'client-option' },
        { label: PeopleType.Other.toUpperCase(), value: PeopleType.Other.toUpperCase(), className: 'other-option' }];

    // get customer list
    const getPeopleListInfo = (searchedText) => {
        setLoading(true);
        quoteService.getCustomerQuoteClientList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid, searchedText).then((response) => {
            if (response.status === 200) {
                setPeopleList(response.data);
                setAllPeopleList(response.data);
                setLoading(false);
                applyFilter(filteredTypeRef.current, response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.errors[0]?.message, { autoClose: 4000 });
            setLoading(false);
        });
    }

    // for check device
    useEffect(() => {
        function handleResize() {
            window.innerWidth <= 1024 ? setIsMediumDevice(true) : setIsMediumDevice(false);
            window.innerWidth <= 610 ? setIsExtraSmallDevice(true) : setIsExtraSmallDevice(false);
            window.innerWidth <= 281 ? setIsExtraExtraSmallDevice(true) : setIsExtraExtraSmallDevice(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const changeSidebarType = (val, people,) => {
        let data = peopleList.find((e) => e === people);
        data.type = val.value.toLowerCase();
        let updatedVal = {
            "client_company_name": people.client_company_name,
            "client_email": people.client_email,
            "cleint_name": people.client_name,
            "type": val.value.toLowerCase()
        }
        updateClient(people, updatedVal, true);
    }

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            textTransform: 'uppercase',
            ...(optionStyles[state.data.className] || {}),
        }),
        menu: (provided) => ({
            ...provided,
            width: '175px',
        }),
        valueContainer: (provided, state) => {
            let backgroundStyle = '';
            if (state.selectProps.value.value === PeopleType.Other.toUpperCase()) {
                backgroundStyle = 'var(--read-status-badge)';
            } else if (state.selectProps.value.value === PeopleType.Lead.toUpperCase()) {
                backgroundStyle = 'var(--sent-status-badge)';
            } else if (state.selectProps.value.value === PeopleType.Client.toUpperCase()) {
                backgroundStyle = 'var(--approved-status-badge)';
            }
            return {
                ...provided,
                background: backgroundStyle,
                borderRadius: '5px',
            };
        },
    };

    const getOptionStyles = (state) => {
        const selectedOption = peopleTypeOption.find((option) => option.value === state.value);
        if (selectedOption) {
            return optionStyles[selectedOption.className];
        } else {
            return {};
        }
    };

    // custom css for option
    const optionStyles = {
        'lead-option': {
            color: 'black',
            margin: '8px 19px',
            maxWidth: '60%',
            backgroundColor: 'var(--sent-status-badge)',
            borderRadius: '4px',
            padding: '2px 6px',
            fontSize: '12px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
        },
        'client-option': {
            color: 'black',
            margin: '8px 19px',
            maxWidth: '60%',
            backgroundColor: 'var(--approved-status-badge)',
            borderRadius: '4px',
            padding: '2px 6px',
            fontSize: '12px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
        },
        'other-option': {
            color: 'black',
            margin: '8px 19px',
            maxWidth: '60%',
            backgroundColor: 'var(--read-status-badge)',
            borderRadius: '4px',
            padding: '2px 6px',
            fontSize: '12px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
        },
    };

    // custom option
    const CustomOption = ({ innerProps, label, isSelected }) => {
        return (
            <div className="d-flex align-items-center justify-content-between type-option">
                <div {...innerProps} style={{ ...getOptionStyles({ value: label, isSelected }) }}>
                    {label.toUpperCase()}
                </div>
                {isSelected && <i className="bx bx-check" style={{ marginRight: '8px', fontSize: '20px' }} />}
            </div>
        );
    };

    // quote list Column
    const columns = useMemo(
        () => [
            {
                Header: ' ',
                accessor: 'profile',
                Cell: (cellProps) => {
                    return <div>
                        {
                            !isLoading &&
                            <div className="avtar-x">
                                <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                                    {cellProps.row.original.client_name.split(" ")[1] ? cellProps.row.original.client_name.split(" ")[0].charAt(0) + '' + cellProps.row.original.client_name.split(" ")[1].charAt(0) : cellProps.row.original.client_name.split(" ")[0].slice(0, 2)}
                                </div>
                            </div>}
                        {isLoading && <div className="avtar-x"><Skeleton baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="h-100 bg-soft rounded-circle" /></div>}
                    </div>
                }
            },
            {
                Header: 'Contact',
                accessor: 'client_company_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {!isLoading && (cellProps.row.original.client_name) && <>
                            <p className={`mb-0 company-name-email ${cellProps.row.original.client_name.length > (isExtraExtraSmallDevice ? 18 : (isExtraSmallDevice ? 18 : (isMediumDevice ? 18 : 35))) ? 'customer_ellipsis shop-email-tooltip' : ''}`} id="emailTooltip"
                                data-toggle='tooltip' data-title={cellProps.row.original.client_name}>{cellProps.row.original.client_name}
                            </p>
                        </>}
                        {isLoading && <><Skeleton className={`mb-1 ${isMediumDevice ? 'company-name-email' : ''} `} height={20} width={200} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></>}
                    </>;
                }
            },
            {
                Header: 'Company Name',
                accessor: 'client_email_',
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {!isLoading && (cellProps.row.original.client_company_name) && <>
                            <p className={`mb-1 company-name-email ${cellProps.row.original.client_company_name.length > (isExtraExtraSmallDevice ? 20 : (isExtraSmallDevice ? 20 : (isMediumDevice ? 20 : 35))) ? 'customer_ellipsis company-name-tooltip shop-email-tooltip' : ''}`} id="companyNameTooltip"
                                data-toggle='tooltip' data-title={cellProps.row.original.client_company_name}>
                                {cellProps.row.original.client_company_name}</p>
                        </>
                        }
                        {isLoading && <><Skeleton className={`mb-0 ${isMediumDevice ? 'company-name-email' : ''} `} height={20} width={200} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton> </>}
                    </>;
                }
            },
            {
                Header: 'Company Email',
                accessor: 'client_email',
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {!isLoading && (cellProps.row.original.client_email) && <>
                            <p className={`mb-0 gray-email company-name-email ${cellProps.row.original.client_email.length > (isExtraExtraSmallDevice ? 18 : (isExtraSmallDevice ? 18 : (isMediumDevice ? 18 : 35))) ? 'customer_ellipsis shop-email-tooltip' : ''}`} id="emailTooltip"
                                data-toggle='tooltip' data-title={cellProps.row.original.client_email}>{cellProps.row.original.client_email}
                            </p>
                        </>
                        }
                        {isLoading && <><Skeleton className={`mb-0 ${isMediumDevice ? 'company-name-email' : ''} `} height={20} width={200} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></>}
                    </>;
                }
            },
            {
                Header: 'Type',
                accessor: 'type',
                filterable: true,
                maxWidth: 400,
                minWidth: 140,
                width: 200,
                Cell: (cellProps) => {
                    let selectedType = peopleTypeOption.find((e) => e.value?.toLowerCase() === cellProps.row.original.type?.toLowerCase());
                    return <>
                        {(!isLoading && cellProps.row.original.type) && <Select
                            value={selectedType}
                            onChange={(selectedGroupName) => {
                                setselectedGroup(prevSelectedGroup => {
                                    return selectedGroupName;
                                });
                                selectedType = selectedGroupName;
                                changeSidebarType(selectedGroupName, cellProps.row.original);
                            }}
                            options={peopleTypeOption.map((e, key) => ({
                                label: e.label,
                                value: e.value,
                                className: e.className,
                                style: e.style
                            }))}
                            styles={customStyles}
                            components={{ Option: CustomOption }}
                            className={`select-dropdown form-control select2-selection p-0 people-list-type ${selectedType.value === PeopleType.Other.toUpperCase() ? 'other-type' : (selectedType.value === PeopleType.Lead.toUpperCase()) ? 'lead-type' : (selectedType.value === PeopleType.Client.toUpperCase()) ? 'client-type' : ''}`}
                            isSearchable={false}
                        />}
                        {isLoading && <div><Skeleton className={`mb-0 ${isMediumDevice ? 'width-100' : ''} `} width={'30%'} height={'35px'} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></div>}
                    </>
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return <div className="d-flex align-items-center justify-content-end">
                        {!isLoading &&
                            <>
                                <UncontrolledDropdown>
                                    <DropdownToggle tag="a" className="card-drop" onClick={(e) => handleDropdownToggleClick(e)}>
                                        <i className="mdi mdi-dots-horizontal font-size-18 text-yellow dot-icon"></i>
                                    </DropdownToggle>

                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem
                                            onClick={(e) => {
                                                onClickAction('edit', cellProps.row.original);
                                            }} className="d-flex align-items-center">
                                            <i className="mdi mdi-pencil font-size-16 me-2 text-success dropdown-menu-icon" id="edittooltip"></i>
                                            <span className="dropdown-menu-text">Edit</span>
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={(e) => {
                                                const peopleData = cellProps.row.original;
                                                onClickAction('delete', peopleData);
                                            }} className="d-flex align-items-center">
                                            <i className="mdi mdi-trash-can font-size-16 me-2 text-danger dropdown-menu-icon" id="deletetooltip"></i>
                                            <span className="dropdown-menu-text">Delete</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </>
                        }
                        {isLoading && <Skeleton className="mb-1 dot-icon" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" width={20}></Skeleton>}
                    </div>
                }
            },
        ],
        [isLoading, isExtraSmallDevice, isExtraExtraSmallDevice, isMediumDevice]
    );

    // for delete quote
    const onClickAction = (action, data) => {
        setPeople(data);
        if (action === 'edit') {
            setShowAddClientModal(true);
            setIsEditClient(true);
            setAfterPeopleAction(false);
        } else {
            setAfterAction(false);
            setDeleteModal(true);
        }
    }

    const handleDropdownToggleClick = (event) => {
        // Prevent the event from propagating to the table row
        event.stopPropagation();
    };
    // on search
    const onSearchHandler = (search) => {
        getPeopleListInfo(search);
    }
    const onSelectedPeopleType = (select) => {
        filteredTypeRef.current = select;
        applyFilter(select);
    }
    const applyFilter = (select, allPeopleVal = null) => {
        let filteredList = [];
        if (select) {
            if (select.value !== "All Types") {
                filteredList = allPeopleVal ? allPeopleVal.filter(obj => obj.type.toLowerCase() === select.value.toLowerCase()) : allPeopleList.filter(obj => obj.type.toLowerCase() === select.value.toLowerCase());
                setPeopleList(filteredList);
            } else {
                setPeopleList(allPeopleVal ? allPeopleVal : allPeopleList);
            }
        }
    }
    const deletePeople = () => {
        peopleService.deletePeople(people.quote_client_info_uuid).then((response) => {
            if (response.status === 200) {
                setAfterAction(true);
                setPeople(null);
                getPeopleListInfo();
            }
        });
    }
    // on click save
    const onClickSaveClient = (newClientInfo) => {
        if (isEditClient) {
            updateClient(people, newClientInfo, false);
        } else {
            createClient(newClientInfo);
        }
    }

    // for update client
    const updateClient = (people, newClientInfo, fromList) => {
        peopleService.updatePeople(people.quote_client_info_uuid, newClientInfo).then((response) => {
            if (response.status === 200) {
                setIsError(false);
                setIsEditClient(false);
                applyFilter(filteredTypeRef.current);
                setAfterPeopleAction(true);
                setPeople(null);
                if (!fromList) {
                    getPeopleListInfo();
                }
            } else {
                setAfterPeopleAction(false);
                setIsError(true);
            }
        });
    }
    // for create client
    const createClient = (newClientInfo) => {
        invoiceService.createCustomer(newClientInfo).then((response) => {
            if (response.status === 200) {
                setIsError(false);
                setAfterPeopleAction(true);
                setTimeout(() => { getPeopleListInfo(); }, 1000);
            } else {
                setAfterPeopleAction(false);
                setIsError(true);
            }
        });
    }

    return (
        <React.Fragment>
            {/* delete people modal */}
            <ConfirmationModal
                show={deleteModal}
                onCloseClick={() => setDeleteModal(false)}
                title={<>Are you sure you want to remove{" "}<span className="color-primary">{people?.client_name}</span>{" "}from your contacts?</>}
                description={"Documents you've already sent to them won't be affected."}
                onAction={() => deletePeople()}
                afterAction={afterAction}
                actionName={'Delete'}
            />
            {/* quote list table */}
            <div className="page-content people-list-page pb-0">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb breadcrumbItem="People" buttonName="New Contact" handleCreate={() => { setShowAddClientModal(true); setAfterPeopleAction(false); setIsEditClient(false) }} />
                    <Row>
                        <Col xs="12" className="quote-list">
                            <Card className="position-relative mb-0 list-card">
                                {<CardBody>
                                    {/* list table */}
                                    {<TableContainer className='w-100'
                                        columns={columns}
                                        data={peopleList.length < 6 && isLoading ? [0, 1, 2, 3] : peopleList}
                                        isPagination={false}
                                        isAddOptions={false}
                                        searchPlaceholder={'Search by contact client name, company name & email'}
                                        tableClass="align-middle table-nowrap"
                                        onSearch={onSearchHandler}
                                        selectedPeopleTypeValue={onSelectedPeopleType}
                                        isLoading={isLoading}
                                        isQuoteList={false}
                                        listName={'people-list'}
                                    />}
                                </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* add edit client modal */}
            <AddClientModal fromPeople={true} show={showAddClientModal} title={isEditClient ? 'Edit Contact' : 'New Contact'} onCloseClick={() => { setShowAddClientModal(false); setPeople(null); }} actionName={'save'} onAction={onClickSaveClient} afterAction={afterPeopleAction} people={people} getError={isError} />
            {/* toast message */}
            <ToastContainer />
        </React.Fragment>
    )
}

export default withRouter(PeopleList);