import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import './paymentPage.scss';
import { Container, Row, Col, Card, CardBody, Spinner, UncontrolledTooltip, Label } from "reactstrap";
import { CustomerService } from "../../../customers/service/customer.service";
import moment from 'moment';
import CreditCardPopUp from "./creditcardPopup";
import Slidewithcontrol from "./slidewithcontrol";
import Spinners from './spinner';
import { paymentMessage, SUBSCRIPTION_PLAN_UUID, TAX_NAME, TAX_RATE } from "../../static/constants/constant";

const PaymentPage = () => {
    const customerService = new CustomerService();
    const { state } = useLocation();
    const [isLoading, setisLoading] = useState(true);
    const [isSubscriptionLoading, setisSubscriptionLoading] = useState(false);
    const [discountApplied_uuid, setDiscountApplied_uuid] = useState([]);
    const [payableAmount, setPayableAmount] = useState(0);
    const [discountMessage, setDiscountMessage] = useState([]);
    const [credit_card_information, setCreditCardInformation] = useState([]);
    const [show, showAddCreditCardInfo] = useState(false);
    const [paymentInfo, setpaymentInfo] = useState();
    const navigate = useNavigate();
    const [paymentStatus, setpaymentStatus] = useState('unknown');
    const [paymentResponseMessage, setPaymentResponseMessage] = useState('');
    const [lastFourDigit, setLastFourDigit] = useState();
    const [cardType, setCardType] = useState();
    const [taxAmount, setTaxAmount] = useState();
    const [subscription_uuid, setSubscription_uuid] = useState(state?.subscription_plan_uuid);

    useState(() => {
        let userLoginInfo = JSON.parse(sessionStorage.getItem('userLogin'));
        customerService.getSubscriptionplanDetailByUserId(userLoginInfo.data.user_uuid).then((response) => {
            if (response.status === 200) {
                if (response.data.subscription_plan_details !== null) {
                    setSubscription_uuid(response.data.subscription_plan_details.user_subscription_plan_uuid);
                }
                if (response.data.subscription_expired === 1) {
                    getPlanDetails(userLoginInfo);
                } else {
                    if (response.data.subscription_exists === 1 && response.data.subscription_payment_succeeded === 1) {
                        response.data.subscription_plan_details.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.STANDARD_PLAN ? navigate('/dashboard') : navigate('/quote-calculator');
                    } else {
                        getPlanDetails(userLoginInfo);
                    }
                }
            }
        })
    }, [state]);

    const getPlanDetails = (userLoginInfo) => {
        setisLoading(true);
        if (state?.selectedDuration !== 'monthly') {
            customerService.getSubscriptionDiscountListbySubscriptionPanId(state?.planInfo.subscription_plan_uuid).then((response) => {
                if (response.status === 200) {
                    let price = state?.selectedDuration === 'monthly' ? state?.planInfo.price_per_month : state?.planInfo.price_per_year;
                    let uuidArray = [];
                    setDiscountApplied_uuid([]);
                    let discountMessageArray = [];
                    let oneMonthFreeDiscount = response.data.find(discount => {
                        return discount.discount_type === 'first_50';
                    });
                    let overallDiscount = response.data.find((discount) => {return discount.discount_type !== "first_50";});
                    if (overallDiscount !== undefined) {
                        if (oneMonthFreeDiscount !== undefined && oneMonthFreeDiscount.no_of_time_applied < 50) {
                            let oneMonthAmount = price - oneMonthFreeDiscount.discount_amount;
                            let discountWithPercentage = oneMonthAmount * (overallDiscount.discount_percentage / 100);
                            setPayableAmount((oneMonthAmount - discountWithPercentage).toFixed(2));
                            uuidArray.push(oneMonthFreeDiscount.subscription_discount_master_uuid);
                            discountMessageArray.push(oneMonthFreeDiscount.discount_description);
                        } else {
                            let discountPercentage = overallDiscount.discount_percentage / 100;
                            let finalAmount = price * (1 - discountPercentage);
                            setPayableAmount(finalAmount.toFixed(2));
                        }
                        uuidArray.push(overallDiscount.subscription_discount_master_uuid);
                        discountMessageArray.push(overallDiscount.discount_description);
                    }
                    setDiscountApplied_uuid(uuidArray);
                    setDiscountMessage(discountMessageArray);
                }
            });
        } else {
            setPayableAmount(state?.planInfo.price_per_month);
        }
        customerService.customerCardPaymentMethod(userLoginInfo.data.user_uuid).then((response) => {
            if (response.status === 200) {
                setCreditCardInformation(response.data);
            }
        }).finally(() => {
            setisLoading(false);
        });
    }

    const handleChangeCreditCardInfo = (creditcardInfo) => {
        setCreditCardInformation(creditcardInfo);
        setpaymentInfo(undefined);
    }
    const [startDate, setStartDate] = useState();
    const [endDate, setendDate] = useState();

    useEffect(() => {
        let startDate = moment();
        var endDate = new Date(moment(startDate).format('DD MMM YYYY'));
        if (state?.selectedDuration === 'monthly') {
            endDate.setMonth(new Date(moment(startDate).format('DD MMM YYYY')).getMonth() + 1);
            endDate.setDate(endDate.getDate() - 1);
        } else {
            endDate.setFullYear(endDate.getFullYear() + 1);
            endDate.setDate(endDate.getDate() - 1);
        }
        setStartDate(startDate);
        setendDate(moment(endDate).format('YYYY-MM-DD'));
    }, [])

    useEffect(() => {
        if (credit_card_information.length > 0) {
            handleSelectedCardInfo(credit_card_information[0].id);
        }
    }, [credit_card_information]);

    const handleSelectedCardInfo = (selectedCardInfo) => {
        setpaymentInfo({
            'user_uuid': JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid,
            'payment_method': 'card',
            'payment_amount': +payableAmount,
            'payment_method_id': selectedCardInfo
        });
    }

    const onClickPayNow = () => {
        let subscriptionInfo = {
            'subscription_plan_uuid': state?.planInfo.subscription_plan_uuid,
            'user_uuid': JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid,
            'start_date': moment().format('YYYY-MM-DD'),
            'end_date': moment(endDate).format('YYYY-MM-DD'),
            'subscription_duration': state?.selectedDuration
        }
        if (state?.selectedDuration !== 'monthly') {
            subscriptionInfo.discount_applied = discountApplied_uuid
        }
        setisSubscriptionLoading(true);
        if (subscription_uuid === undefined) {
            customerService.createUserSubscriptionPlan(subscriptionInfo).then((response) => {
                if (response.status === 200) {
                    let paymentdata = {
                        'payment_method': 'card',
                        'payment_amount': +(+payableAmount + +taxAmount),
                        'tax_amount': taxAmount,
                        'payment_method_id': paymentInfo.payment_method_id,
                        'user_subscription_plan_uuid': response.data.user_subscription_plan_uuid
                    }
                    setSubscription_uuid(paymentdata.user_subscription_plan_uuid);
                    customerService.createUserSubscriptionPayment(paymentdata).then((response) => {
                        if (response.status === 200) {
                            setpaymentStatus('done');
                            setisSubscriptionLoading(false);
                            setPaymentResponseMessage('Congratulations!! Your subscription created successfully.');
                        } else {
                            setpaymentStatus('error');
                            setPaymentResponseMessage(response.response.data.errors[0].message);
                        }
                    }).finally(() => {
                        setisSubscriptionLoading(false);
                    });
                } else {
                    setisSubscriptionLoading(false);
                }
            });
        } else {
            let updated_subscription_plan_info = {
                'payment_method': 'card',
                'payment_amount': +(+payableAmount + +taxAmount),
                'tax_amount': taxAmount,
                'payment_method_id': paymentInfo.payment_method_id,
                'user_subscription_plan_uuid': subscription_uuid
            }
            customerService.createUserSubscriptionPayment(updated_subscription_plan_info).then((response) => {
                if (response.status === 200) {
                    setpaymentStatus('done');
                    setisSubscriptionLoading(false);
                    setPaymentResponseMessage('Congratulations!! Your subscription created successfully.');
                } else {
                    setpaymentStatus('error');
                    setPaymentResponseMessage(response.response.data.errors[0].message);
                    setCardType(response.response.data.errors[0].payment_details.card.brand);
                    setLastFourDigit(response.response.data.errors[0].payment_details.last4);
                }
            }).finally(() => {
                setisSubscriptionLoading(false);
            });
        }
    }

    useEffect(() => {
        let percentageDecimal = TAX_RATE / 100;
        let percentageAmount = payableAmount * percentageDecimal;
        setTaxAmount(+percentageAmount.toFixed(2))
    }, [payableAmount]);

    return (
        <React.Fragment>
            <div className={`${paymentStatus === 'unknown' ? '' : ' pb-0 pt-0'} page-content payment-page`}>
                {
                    paymentStatus === 'unknown' ? (
                        <Container fluid>
                            <Row className="justify-content-center">
                                <Col lg={12} xl={10} xxl={9} md={12}>
                                    <Card className="p-4">
                                        <div className="border subscription-plan-container">
                                            <div className="d-md-flex d-none justify-content-between align-items-center border-bottom px-4 pt-3  pb-2  subscription-plan-box">
                                                <div className="d-md-block d-none">
                                                    <div className=" justify-content-between align-items-center">
                                                        <h3 className="d-inline plan-name mb-0">{state?.planInfo?.plan_name} <i className="bx bx-info-circle cursor-pointer text-yellow font-size-14 ms-2" id="plan_description" /> <UncontrolledTooltip target={'plan_description'} placement="bottom">{state?.planInfo?.plan_description}</UncontrolledTooltip> </h3>
                                                    </div>
                                                    <div className="d-flex font-size-14 mt-2">
                                                        <div className="mt-sm-0 mt-3 font-size-18 plan-duration">Duration from <span className="fw-bold">{moment().format('MMM DD, YYYY')}</span>  to  <span className="fw-bold">{moment(endDate).format('MMM DD, YYYY')}</span>  </div>
                                                    </div>
                                                </div>
                                                <h3 className="price mb-0 d-md-block d-none">
                                                    ${state?.selectedDuration === 'monthly' ? state?.planInfo?.price_per_month : state?.planInfo?.price_per_year}/{" "}
                                                    <span className="font-size-13"> {state?.selectedDuration === 'monthly' ? 'month' : 'year'}</span>
                                                </h3>
                                            </div>
                                            <div className="d-md-none d-block  border-bottom px-4 pt-3  pb-2  subscription-plan-box">
                                                <div className="">
                                                    <div className="mb-2 justify-content-between align-items-center">
                                                        <h5 className="d-inline plan-name mb-0">{state?.planInfo.plan_name} <i className="bx bx-info-circle cursor-pointer text-yellow font-size-14 ms-2" id="plan_description" /> <UncontrolledTooltip target={'plan_description'} placement="bottom">{state?.planInfo?.plan_description}</UncontrolledTooltip> </h5>
                                                    </div>
                                                    <h3 className="price mb-0 d-md-none d-block text-end">
                                                        ${state?.selectedDuration === 'monthly' ? state?.planInfo.price_per_month : state?.planInfo.price_per_year}/{" "}
                                                        <span className="font-size-13"> {state?.selectedDuration === 'monthly' ? 'month' : 'year'}</span>
                                                    </h3>
                                                    <div className="d-flex font-size-14 ">
                                                        <div className="mt-sm-0 mt-3 font-size-18 plan-duration">Duration from <span className="fw-bold">{moment().format('MMM DD, YYYY')}</span>  to  <span className="fw-bold">{moment(endDate).format('MMM DD, YYYY')}</span>  </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Row className="py-3 px-4 justify-content-between">
                                                <Col xl={state?.isPaymentMode ? 12 : 10} md={state?.isPaymentMode ? 12 : 8} className="order-md-1 order-2" >
                                                    {
                                                        discountMessage.length !== 0 && (
                                                            <div>
                                                                <Label className=" mb-2 d-flex align-items-center"><i className="bx me-2 text-warning bxs-discount font-size-18" /> Discount applied </Label>
                                                                <div>
                                                                    <div className="d-flex align-items-center justify-content-start" >
                                                                        <div>
                                                                            {
                                                                                discountMessage.map((message, index) => (<p key={index} className="d-flex mb-1"><i className="bx me-2 text-warning dripicons-dot font-size-20" />{message}</p>))
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </Col>
                                                {
                                                    subscription_uuid === undefined && (
                                                        <Col className="text-end order-md-2 order-1 mb-sm-0 mb-3" md={2}>
                                                            <button className="add-customer py-1 btn  edit-plan-btn" onClick={() => { navigate('/pricing'); }}>Edit Plan</button>
                                                        </Col>
                                                    )
                                                }
                                            </Row>
                                        </div>
                                        <div>
                                            <Row className="mt-3 mb-3 px-4 py-3">
                                                <Col sm={12} className="">
                                                    <div className="mb-3 d-md-flex justify-content-between align-items-center">
                                                        <div>
                                                            <Label>Saved cards<span className="text-danger">*</span> </Label>
                                                            <small className="d-block">Please select or add new card with you want to use for payment.</small>
                                                        </div>
                                                        <div className="d-flex fw-bold align-items-center mt-md-0 mt-3 justify-content-end">
                                                            Add new card
                                                            <div id="add-credit-card-icon-container ms-1" className="cursor-pointer add-credit-card-icon-container" onClick={(event) => { event.stopPropagation(); showAddCreditCardInfo(true); }}>
                                                                <i className="mdi add-crdt-card-info-icon text-white mdi-plus"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {
                                                    !isLoading && (
                                                        <Slidewithcontrol items={credit_card_information} isCardIsDeleted={handleChangeCreditCardInfo} handleSelectedCardInfo={handleSelectedCardInfo} isEndClient={false} />
                                                    )
                                                }
                                            </Row>
                                        </div>
                                        <Row className="mb-3 px-4 pt-3">
                                            <Col className="d-flex justify-content-end">
                                                <div class="">
                                                    <div className="d-flex justify-content-between subtotal-container">
                                                        <div>
                                                            <div className="mb-2">
                                                                <span>Subtotal</span>
                                                            </div>
                                                            <div className="my-2 tax-name">
                                                                <span>{TAX_NAME}({TAX_RATE}%)</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="m-0 text-end">
                                                                <p className="m-0 text-end">
                                                                    ${payableAmount}
                                                                </p>
                                                                <p className="text-end mb-0">
                                                                    <div className="my-2">
                                                                        ${taxAmount}
                                                                    </div>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end mt-2">
                                                        <div className="d-flex justify-content-between total-container">
                                                            <div>
                                                                <strong className=" mt-3">Payable amount</strong>
                                                            </div>
                                                            <div>
                                                                <h4 className="total-value color-secondary">
                                                                    ${+payableAmount + taxAmount}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="px-4">
                                            <Col>
                                                <div className="d-flex mt-3 justify-content-end">
                                                    <button type="submit" className="btn btn-fill d-flex align-items-center pay-now-btn" disabled={paymentInfo === undefined} onClick={() => { onClickPayNow(); }} >{isSubscriptionLoading ? <Spinners setLoading={setisSubscriptionLoading} /> : ''} Pay Now</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    ) : (
                        <div className="animation-container">
                            <Card className="animation_card">
                                <CardBody className="animation_card_body">
                                    <div className="text-left">
                                        <img className="payment_status_image" src={`${paymentStatus === 'done' ? '/assets/images/payment_successfull.png' : '/assets/images/payment_failed.png'}`} alt="payment status icon" />
                                        {
                                            paymentStatus === 'done' ? (
                                                <h1 className="text-left mb-2 mt-3 ps-1">Payment Successful</h1>
                                            ) : <h1 className="text-left fw-bold mb-2 mt-3  ps-1">
                                                Oh no, your payment failed
                                            </h1>
                                        }
                                        {
                                            paymentStatus === 'error' && paymentResponseMessage && (
                                                <p className="text-left payment_failed_message text-muted mt-0 mb-1 ps-1 ">{paymentMessage.SUBSCRIPTION_PAYMENT_FAILED.replace(/{customer_name}/, JSON.parse(sessionStorage.getItem('userLogin'))?.data.name).replace(/{payment_failure_message}/, paymentResponseMessage)}</p>
                                            )
                                        }
                                        {
                                            paymentStatus === 'done' && (
                                                <p className="text-left payment_failed_message text-muted mt-0 mb-1 ps-1 ">{paymentResponseMessage}</p>
                                            )
                                        }
                                        {
                                            paymentStatus === 'error' && paymentResponseMessage && (
                                                <h5 className="text-left text-muted payment_error_messages mt-0 mb-4 ps-1">{paymentMessage.SUBSCRIPTION_PAYMENT_FAILED_MESSAGE.replace(/{card_type}/, cardType).replace(/{card_number}/, lastFourDigit)}</h5>
                                            )
                                        }
                                        {
                                            paymentStatus === 'error' ? (
                                                <button className="btn btn-danger text-center mt-3 curser-pointer ms-1" onClick={() => { setpaymentStatus('unknown'); setpaymentInfo(undefined); }}>Try Again</button>
                                            ) : <button className="btn text-center curser-pointer ms-1 btn-success mt-4" onClick={() => { sessionStorage.setItem('shouldRefresh', JSON.stringify(true)); state?.planInfo?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.STANDARD_PLAN ? navigate('/dashboard', { state: { updatesessionStorage: true } }) : navigate('/quote-calculator', { state: { updatesessionStorage: true } }); }}>Continue</button>
                                        }
                                    </div>

                                </CardBody>
                            </Card>


                        </div>
                    )
                }
            </div >
            {
                isLoading && (
                    <Spinner className='me-2 position-absolute download-quote-spinner' />
                )
            }
            <CreditCardPopUp show={show} oncloseClick={() => { showAddCreditCardInfo(!show); }} onSubmit={handleChangeCreditCardInfo} isEndClient={false} />
        </React.Fragment >
    )
}

export default PaymentPage;