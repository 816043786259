import React, { Component } from "react";
import { Carousel, CarouselItem, Card, CardBody, Col, Row , UncontrolledTooltip} from "reactstrap";
import ConfirmationModal from './confirmationModal';
import { CustomerService } from "../../../customers/service/customer.service";

class Slidewithcontrol extends Component {
    constructor(props) {
        super(props);
        const { items } = this.props;
        this.state = { activeIndex: 0, showModal: false, isDeleted: false, deleteCardId: '', selectedCardId: items?.length > 0 ? items[0].id : '', cardColors: {} };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.getCardStyle = [
            { border: "--primary-yellow", background: "--primary-yellow" },
            { border: "--secondary-green", background: "--secondary-green" },
            { border: "--primary-green", background: "--primary-green" },
            { border: "--primary-gray", background: "--primary-gray" }
        ];
        this.customerService = new CustomerService();
    }

    componentDidMount() {
        this.generateCardColors();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.items !== this.props.items) {
            this.generateCardColors();
        }
    }

    generateCardColors() {
        const { items } = this.props;
        const cardColors = {};
        items.forEach(item => {
            cardColors[item.id] = this.getRandomColorForCard();
        });
        if(items.length > 0){
            this.handleCardClick(items[0].id);
        }
        this.setState({ cardColors });
    }

    handleCardClick = (cardId) => {
        this.props.handleSelectedCardInfo(cardId);
        this.setState({ selectedCardId: cardId });
    }

    toggleModal = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    deleteCard() {
        this.setState(prevState => ({
            isDeleted: false
        }));
        this.customerService.deleteCustomerCardPayment({ 'user_uuid': JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid, 'card_payment_method_id': this.state.deleteCardId }).then((response) => {
            if (response.status === 200) {
                this.customerService.customerCardPaymentMethod(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
                    if (response.status === 200) {
                        this.props.isCardIsDeleted(response.data)
                    }
                }).finally(() => {
                    this.setState(prevState => ({
                        isDeleted: !prevState.isDeleted
                    }));
                });
            }
        }).catch(() => {
            this.setState(prevState => ({
                isDeleted: !prevState.isDeleted
            }));
        });
    }

    goToIndex(newIndex) {
        if (this.animating) return
        this.setState({ activeIndex: newIndex })
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const slidesPerView = this.getSlidesPerView();
        const lastIndex = Math.ceil(this.props.items.length / slidesPerView) - 1;
        const nextIndex = this.state.activeIndex === lastIndex ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const slidesPerView = this.getSlidesPerView();
        const lastIndex = Math.ceil(this.props.items.length / slidesPerView) - 1;
        const nextIndex = this.state.activeIndex === 0 ? lastIndex : this.state.activeIndex - 1
        this.setState({ activeIndex: nextIndex });
    }

    getSlidesPerView() {
        return window.innerWidth <= 912 ? 4 : 3;
    }

    getRandomColorForCard() {
        const randomIndex = Math.floor(Math.random() * this.getCardStyle.length);
        return this.getCardStyle[randomIndex];
    }

    render() {
        const { items, isEndClient } = this.props;
        const { activeIndex } = this.state;
        const slidesPerView = this.getSlidesPerView();

        const slides = Array.from({ length: Math.ceil(isEndClient ? items.length / 3 : items.length / slidesPerView) }, (_, i) => {
            const start = i * slidesPerView;
            const end = Math.min(start + slidesPerView, items.length);

            return items.slice(start, end).map((item, index) => (
                <Col xxl={isEndClient ?  12 : 12 / slidesPerView} xl={isEndClient ?  12 : 12 / slidesPerView} lg={ isEndClient ?  12 : 12 / slidesPerView} md={6} key={start + index}>
                    <div className="p-2">
                        <Card className={`border payment-card-container cursor-pointer ${isEndClient ? 'mb-0' : ''} ${item.id === this.state.selectedCardId ? 'selected-card' : ''}`}
                            style={{
                                background: `var(${this.state.cardColors[item.id]?.background})`,
                            }}
                            onClick={() => { this.handleCardClick(item.id) }}
                        >
                            <CardBody className="">
                                {item.id === this.state.selectedCardId && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '-6px',
                                        right: '-5px',
                                        zIndex: '999',
                                    }}>
                                        <i className="bx bx-check-circle selected-card-icon-indicator font-size-22" />
                                    </div>
                                )}
                                <div>
                                    {
                                        item.card.brand === 'visa' || item.card.brand === 'mastercard' ? (
                                            <div className="d-flex align-item-center justify-content-between">
                                                <i className={`bx ${item.card.brand === 'visa' ? 'bxl-visa' : item.card.brand === 'mastercard' ? ' bxl-mastercard' : ''} font-size-36`} />
                                                <i id={`delete-card-${index}`} className="mdi mdi-trash-can font-size-16 cursor-pointer text-danger me-2 dropdown-menu-icon" onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.toggleModal();
                                                    this.setState(prevState => ({
                                                        deleteCardId: item.id
                                                    }));
                                                }} />
                                                <UncontrolledTooltip target={`delete-card-${index}`}>
                                                    Delete
                                                </UncontrolledTooltip>
                                            </div>
                                        ) : (
                                            <div className="d-flex align-item-center justify-content-end pb-2">
                                                <i className="mdi mdi-trash-can font-size-16 text-danger me-2 cursor-pointer dropdown-menu-icon" />
                                            </div>
                                        )
                                    }
                                    <div className="text-end mt-5 d-flex justify-content-between align-items-center">
                                        <p className="mb-0 card-digit">**** **** **** {item.card.last4}</p>
                                        <p className="mb-0 card-duration">{item.card.exp_month}/{item.card.exp_year}</p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            ));
        });

        return (
            <React.Fragment>
                <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} interval={false}>
                    {slides.map((slideItems, index) => (
                        <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={index}>
                            <Row>{slideItems}</Row>
                        </CarouselItem>
                    ))}
                    {
                        items.length === 0 && (
                            <div className="d-flex mt-4 justify-content-center no-card-found-container">
                                <i className="bi bi-info-circle me-2"></i> <span>No Cards Found</span>
                            </div>
                        )
                    }
                </Carousel>
                {
                    slides.length > 1 && (
                        <div className={`d-flex ps-4 justify-content-start ${isEndClient ? 'mt-3' : ''}`}>
                            <a className="carousel-control-prev  me-2" role="button" data-slide="prev" onClick={this.previous}>
                                <i className="bx bx-caret-left"></i>
                            </a>
                            <a className="carousel-control-next" role="button" data-slide="next" onClick={this.next}>
                                <i className="bx bx-caret-right"></i>
                            </a>
                        </div>
                    )
                }
                <ConfirmationModal
                    show={this.state.showModal}
                    onCloseClick={this.toggleModal}
                    title={'Delete card'}
                    description={'Are you sure you want to delete this card?'}
                    actionName={'Delete'}
                    isSendQuote={false}
                    onAction={() => { this.deleteCard() }}
                    afterAction={this.state.isDeleted}
                />
            </React.Fragment>
        );
    }
}

export default Slidewithcontrol;
