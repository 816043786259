import { AUTHENTICATION } from "../../shared/services/api.config";
import { ApiService } from '../../shared/services/api.service';
import { toast } from "react-toastify";
import { resetPswValidationErr } from '../../shared/static/constants/constant';
import 'react-toastify/dist/ReactToastify.css';

export class AuthenticationService {

    apiService = new ApiService();
    // for login
    async logIn(userData) {
        return await this.apiService.post(AUTHENTICATION.LOGIN_URL, userData).then(response => {
            if (response.status === 200) {
                return response;
            } else {
                toast.error(response.response.data.errors[0].message, { autoClose: 4000 });
            }
            return response;
        }).catch(error => {
            toast.error(error.response.data.errors[0].message, { autoClose: 4000 });
            return error;
        });
    }

    // for forgot password
    async forgotPassword(userData) {
        return await this.apiService.post(AUTHENTICATION.FORGET_PASSWORD, userData).then(response => {
            if (response.status === 200) {
                return response;
            } else {
                toast.error(response.response.data.errors[0].message, { autoClose: 4000 });
            }
            return response;
        }).catch(error => {
            toast.error(error.response.data.errors[0].message, { autoClose: 4000 });
            return error;
        });
    }

    // for reset password
    async resetPassword(userData) {
        return await this.apiService.post(AUTHENTICATION.RESET_PASSWORD, userData).then(response => {
            if (response.status === 200) {
                toast.success(resetPswValidationErr.RESETPASSWORD_SUCCSSFULLY, { autoClose: 3000 });
                return response;
            } else {
                toast.error(response.response.data.errors[0].message, { autoClose: 4000 });
            }
            return response;
        }).catch(error => {
            toast.error(error.response.data.errors[0].message, { autoClose: 4000 });
            return error;
        });
    }

    // for sign up
    async signUp(userData) {
        return await this.apiService.post(AUTHENTICATION.SIGN_UP, userData)?.then(response => {
            if (response.status === 200) {
                return response;
            } else {
                toast.error(response.response.data.errors[0].message, { autoClose: 4000 });
            }
            return response;
        }).catch(error => {
            toast.error(error.response.data.errors[0].message, { autoClose: 4000 });
            return error;
        });
    }
}