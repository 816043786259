import { useState } from "react";
import { ShopOwnerGetPaid } from "../../shopOwnerGetPaid/service/ShopOwnerGetPaid.service";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";

const ShopOwnerStripeConnectRefresh = () => {
    const ShopOwnerGetPaidService = new ShopOwnerGetPaid();
    const [isLoading, setisLoading] = useState(false);
    const { user_uuid } = useParams();

    const generateStripeAccountOnboardingLink = () => {
        setisLoading(true);
        let userId = {
            user_uuid: user_uuid
        }
        ShopOwnerGetPaidService.generateLink(userId).then(response => {
            if (response.status === 200) {
                let newTab = window.open();
                newTab.opener = null;
                newTab.location.href = response.data.url;
            }
        }).finally(() => {
            setisLoading(false);
        });
    }

    useState(() => {
        generateStripeAccountOnboardingLink();
    }, []);

    return (
        <div>
            {isLoading && <Spinner className='me-2 position-absolute download-quote-spinner' />}
        </div>
    )
}

export default ShopOwnerStripeConnectRefresh;